import { SelectOption } from 'types/generics'


export const ARVADA_JURISDICTION_ID = 'aeac63c0-79c3-4b43-b88f-cde491520ef1'

const usEast = 'America/New_York'
const usCentral = 'America/Chicago'
const usMountain = 'America/Denver'
const usPacific = 'America/Los_Angeles'
const sydneyTz = 'Australia/Sydney'


export interface JurisdictionInfo {
    name: string
    timezone: string
}



export const jurisdictions: Record<string, JurisdictionInfo> = {
    // Arvada
    [ARVADA_JURISDICTION_ID]: {
        name: 'Arvada',
        timezone: usMountain
    },
    // OSU
    'ec03e492-6150-46b0-809c-19520527be50': {
        name: 'OSU',
        timezone: usEast
    },
    // Stanford
    'b5b007c7-fa41-4a8d-87b2-163c0f34f3fa': {
        name: 'Stanford',
        timezone: usPacific
    },
    // Parramatta
    '519b4760-f72e-49f5-abbf-92d02363b237': {
        name: 'Parramatta',
        timezone: sydneyTz
    },
    // Sydney
    'fe93e63a-4731-4db0-90bf-02225a8d3ab2': {
        name: 'Sydney',
        timezone: sydneyTz
    },
    // New Malibu
    '8eab1f76-555b-417b-8de9-2c1b7d0c8e38': {
        name: 'New Malibu',
        timezone: usPacific
    },
    // FIU
    '7c7e11be-8956-458a-b1ca-27ce1495166d': {
        name: 'Florida International University',
        timezone: usEast
    },
    // St. Augustine
    '84667885-f352-4f5c-8f68-779f124329f7': {
        name: 'St. Augustine, FL',
        timezone: usEast
    },
    // UTA (University of Texas at Arlington)
    'a8d719e6-8aba-4f1b-bfd1-3a307b9f4a65': {
        name: 'UTA',
        timezone: usCentral
    },
    '3d2d543d-c29d-459b-8923-f47cd837f2c5': {
        name: 'App State',
        timezone: usEast
    },
    '4c27e857-79d8-4ba4-95a4-0600c0b58a1a': {
        name: 'Colorado Springs',
        timezone: usMountain
    },
    '45a829e2-4f46-49cd-96da-3675f494fb78': {
        name: 'MasParc - North Eastern University',
        timezone: usEast
    },
}

const idTimeZoneMap: Record<string, string> = Object.entries(jurisdictions).reduce((accum, [id, {timezone}]) => {
    accum[id] = timezone
    return accum
}, {})



export const jurisdictionZone: Record<string, string> = {
    default: usMountain,
    ...idTimeZoneMap
}

export const jurisdictionOptions: SelectOption[] = Object.entries(jurisdictions)
    .reduce((accum, [id, {name, timezone}]) => {
        return accum.concat({
            label: name,
            value: id
        })
    }, [])