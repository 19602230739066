import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DateTime } from 'luxon'
import {
    AvailableCategories,
    AvailableResourceStatistics,
    Category,
    DateRange,
    DayOfTheWeek,
    EventStatisticType,
    OccupancySummary,
    ParkingStatInterval,
    ReportGroupBy,
    ReportParameters,
    ReportSummary,
    ReportType,
    ResourceSlice,
    StatisticClassification
} from './types'
import baseReducer from 'features/baseReducer'
import { DATE_RANGE_OPTIONS } from './dateRange'
import { Epoch } from 'types/generics'
import Terms from './terms'
import { DateRangeType, FinancialReportState, Resource } from 'features/resource/types/paymentTypes'
import { JurisdictionRollupType, PaymentSessionDimension, PaymentSessionReport, PaymentSessionReportResult, PaymentSessionReportWithId, PaymentSessionTimeScale, TimeSeriesVariable } from 'features/resource/types/paymentSessionTypes'
import { LineGroupsAndConfigs } from 'features/resource/paymentReportUtils'


export const ALL_DAYS_OF_THE_WEEK: DayOfTheWeek[] = [1, 2, 3, 4, 5, 6, 7]

export const initialParameters: ReportParameters = {
    availableStatistics: {},
    categoriesLoading: false,
    cumulative: false,
    dateRangeSelections: [DATE_RANGE_OPTIONS.THIS_WEEK],
    daysOfTheWeek: ALL_DAYS_OF_THE_WEEK,
    groupBy: ReportGroupBy.week,
    interval: ParkingStatInterval.hour,
    reportType: ReportType.occupancy,
    resourceCategories: {},
    resources: {},
    selectedCategories: [],
    selectedClassifications: [],
    selectedReportType: ReportType.occupancy,
    selectedResourceIds: [],
    selectedStatisticTypes: [EventStatisticType.sum],
    smoothValue: 0,
    statisticsLoading: false
}

const _now = DateTime.now()
const defaultSummaryMonth = _now.month === 1 ? -12 : _now.month - 1

export const initialSummary: ReportSummary = {
    summaryData: [],
    summaryIsMultiTenant: false,
    summaryLoading: false,
    summaryMonth: defaultSummaryMonth,
    summaryOffset: 4,
    summarySegmentHours: 8,
    summaryUseOccupancy: true
}

export const financialReportState: FinancialReportState = {
    showPaymentSessionReport: false,
    paymentResources: [],
    paymentResourcesLoading: false,
    paymentParametersLoading: false,
    selectedPaymentResources: [],
    rollUpLevel: JurisdictionRollupType.JURISDICTION_ONLY,
    sessionDimensions: PaymentSessionDimension.SUM_SESSION_REVENUE,
    timeScales: [
        PaymentSessionTimeScale.DAILY
    ],
    paymentReportLoading: false,
    paymentReportDateRange: {
        start: null,
        end: null
    },
    selectedPaymentTimeSeriesVariables: [
        TimeSeriesVariable.CURRENT_WEEK
    ],
    paymentSessionReport: [],
    paymentSessionProcessedData: null
}

export const initialState: ResourceSlice = {
    displayActual: true,
    error: null,
    hideResourceIds: [],
    init: false,
    loading: false,
    monthStarts: [],
    overflowSecondsBase: 10972,
    reportState: [],
    reportsLoading: false,
    requestId: null,
    showReport: false,
    terms: Terms,
    weekStarts: [],
    // Parameters
    ...initialParameters,
    // Summary
    ...initialSummary,
    // Financials
    ...financialReportState
}

const resourceSlice = createSlice({
    initialState,
    name: 'resourcesTs',
    reducers: {
        ...baseReducer,
        resetParameters: state => {
            const targetValues = {
                ...initialSummary,
                dateRangeSelections: [DATE_RANGE_OPTIONS.THIS_WEEK],
                daysOfTheWeek: ALL_DAYS_OF_THE_WEEK,
                groupBy: ReportGroupBy.week,
                interval: ParkingStatInterval.hour,
                selectedCategories: [],
                selectedClassifications: [],
                selectedReportType: ReportType.occupancy,
                selectedResourceIds: [],
                selectedStatisticTypes: [EventStatisticType.avg]
            }
            for (let key in targetValues) {
                state[key] = targetValues[key]
            }
        },
        // Loading, init, error flags
        updateDateRange: (
            state,
            {payload}: PayloadAction<UpdateDateRangeAction>
        ) => {
            const {index, dateRange} = payload
            state.dateRangeSelections[index] = {
                ...state.dateRangeSelections[index],
                ...dateRange
            }
        },
        setLoading: (state, {payload}: PayloadAction<boolean>) => {
            state.loading = payload
        },
        setInit: (state, {payload}: PayloadAction<boolean>) => {
            state.init = payload
        },
        setError: (state, {payload}: PayloadAction<null | string | Error>) => {
            state.error = payload
        },
        setCategoriesLoading: (state, {payload}: PayloadAction<boolean>) => {
            state.categoriesLoading = payload
        },
        setResourceCategories: (
            state,
            {payload}: PayloadAction<AvailableCategories>
        ) => {
            state.resourceCategories = payload
        },
        mergeResourceCategories: (
            state,
            {payload}: PayloadAction<AvailableCategories>
        ) => {
            const current = state.resourceCategories
            let resourceCategories: AvailableCategories = {}
            for (let key in payload) {
                const value = payload[key]
                const mergedValue = [...new Set((current[key] || []).concat(value))]
                resourceCategories[key] = mergedValue
            }
            state.resourceCategories = resourceCategories
        },
        //-- Report Parameters
        setDisplayActual: (state, {payload}: PayloadAction<boolean>) => {
            state.displayActual = payload
        },
        setAvailableStatistics: (
            state,
            {payload}: PayloadAction<AvailableResourceStatistics>
        ) => {
            state.availableStatistics = payload
        },
        setStatisticsLoading: (state, {payload}: PayloadAction<boolean>) => {
            state.statisticsLoading = payload
        },
        setMonthStarts: (state, {payload}: PayloadAction<Epoch[]>) => {
            state.monthStarts = payload
        },
        setWeekStarts: (state, {payload}: PayloadAction<Epoch[]>) => {
            state.weekStarts = payload
        },
        setInterval: (state, {payload}: PayloadAction<ParkingStatInterval>) => {
            state.interval = payload
        },
        // Date ranges
        setDateRangeSelections: (
            state,
            {payload}: PayloadAction<DateRange[]>
        ) => {
            state.dateRangeSelections = payload
        },
        removeDateRange: (state, {payload}: PayloadAction<number>) => {
            state.dateRangeSelections = state.dateRangeSelections.filter(
                (_, index) => payload !== index
            )
        },
        removeDateRangeComparisons: state => {
            state.dateRangeSelections = state.dateRangeSelections.slice(0, 1)
        },
        addDateRange: (state, {payload}: PayloadAction<DateRange>) => {
            state.dateRangeSelections = state.dateRangeSelections
                .concat(payload)
                .map((item, index) => ({
                    ...item,
                    id: index
                }))
        },
        setOverflowSecondsBase: (state, {payload}: PayloadAction<number>) => {
            state.overflowSecondsBase = payload
        },
        setReportType: (state, {payload}: PayloadAction<ReportType>) => {
            state.reportType = payload
        },
        setSelectedResourceIds: (state, {payload}: PayloadAction<string[]>) => {
            state.selectedResourceIds = payload
        },
        setSelectedReportType: (state, {payload}: PayloadAction<ReportType>) => {
            state.selectedReportType = payload
        },
        setSelectedCategories: (state, {payload}: PayloadAction<Category[]>) => {
            state.selectedCategories = payload
        },
        setSelectedClassifications: (
            state,
            {payload}: PayloadAction<StatisticClassification[]>
        ) => {
            state.selectedClassifications = payload
        },
        setSelectedStatisticTypes: (
            state,
            {payload}: PayloadAction<EventStatisticType[]>
        ) => {
            state.selectedStatisticTypes = payload
        },
        setSmoothValue: (state, {payload}: PayloadAction<number>) => {
            state.smoothValue = payload
        },
        // Summary
        setSummaryLoading: (state, {payload}: PayloadAction<boolean>) => {
            state.summaryLoading = payload
        },
        setSummaryIsMultiTenant: (state, {payload}: PayloadAction<boolean>) => {
            state.summaryIsMultiTenant = payload
        },
        setSummaryData: (state, {payload}: PayloadAction<OccupancySummary[]>) => {
            state.summaryData = payload
        },
        setSummaryMonth: (state, {payload}: PayloadAction<number>) => {
            state.summaryMonth = payload
        },
        setSummaryOffset: (state, {payload}: PayloadAction<number>) => {
            state.summaryOffset = payload
        },
        setSummarySegmentHours: (state, {payload}: PayloadAction<number>) => {
            state.summarySegmentHours = payload
        },
        setSummaryUseOccupancy: (state, {payload}: PayloadAction<boolean>) => {
            state.summaryUseOccupancy = payload
        },
        //-- Financials
        setShowPaymentSessionReport: (state, {payload}: PayloadAction<boolean>) => {
            state.showPaymentSessionReport = payload
        },
        setPaymentResources: (state, {payload}: PayloadAction<Resource[]>) => {
            state.paymentResources = payload
        },
        setPaymentResourcesLoading: (state, {payload}: PayloadAction<boolean>) => {
            state.paymentResourcesLoading = payload
        },
        setPaymentParametersLoading: (state, {payload}: PayloadAction<boolean>) => {
            state.paymentParametersLoading = payload
        },
        setSelectedPaymentResources: (state, {payload}: PayloadAction<Resource[]>) => {
            state.selectedPaymentResources = payload
        },
        setRollUpLevel: (state, {payload}: PayloadAction<JurisdictionRollupType>) => {
            state.rollUpLevel = payload
        },
        setSessionDimensions: (state, {payload}: PayloadAction<PaymentSessionDimension>) => {
            state.sessionDimensions = payload
        },
        setTimeScales: (state, {payload}: PayloadAction<PaymentSessionTimeScale[]>) => {
            state.timeScales = payload
        },
        setPaymentReportLoading: (state, {payload}: PayloadAction<boolean>) => {
            state.paymentReportLoading = payload
        },
        resetFinancialReports: (state) => {
            Object.entries({...financialReportState}).forEach(([key, value]) => {
                state[key] = value
            })
        },
        setPaymentReportDateRange: (state, {payload}: PayloadAction<DateRangeType>) => {
            state.paymentReportDateRange = payload
        },
        setSelectedPaymentTimeSeriesVariables: (state, {payload}: PayloadAction<TimeSeriesVariable[]>) => {
            state.selectedPaymentTimeSeriesVariables = payload
        },
        // paymentSessionReport
        setPaymentSessionReports: (state, {payload}: PayloadAction<PaymentSessionReportWithId[]>) => {
            state.paymentSessionReport = payload
        },
        setPaymentSessionProcessedData: (state, {payload}: PayloadAction<LineGroupsAndConfigs | null>) => {
            state.paymentSessionProcessedData = payload
        }
    }
})

export const {actions, name, reducer} = resourceSlice


interface UpdateDateRangeAction {
    dateRange: DateRange
    index: number
}
