//-- Statistics

export enum StatisticType {
  chargePointStatus = 'chargePointStatus',
  // SD-632
  // maxSessionTimeExceeded = 'maxSessionTimeExceeded',
  occupancyRate = 'occupancyRate',
  // Occupancy Stats
  occupancyStats = 'occupancyStats',
  occupancyStatsByTimeSegment = 'occupancyStatsByTimeSegment',
  parkingSession = 'parkingSession',
  sessionStats = 'sessionStats',
  tenantOccupancyProfile = 'tenantOccupancyProfile',
  // tenantOccupancyRate = 'tenantOccupancyRate',
  unknownVehicleExit = 'unknownVehicleExit',
  vehicleViolationEvent = 'vehicleViolationEvent',
  // Tenant Occupancy Stats
  tenantOccupancyStats = 'tenantOccupancyStats',
  tenantOccupancyStatsByTimeSegment = 'tenantOccupancyStatsByTimeSegment',
  // Monthly Summary types
  tenantOccupancyCountsByTimeSegment = 'tenantOccupancyCountsByTimeSegment',
  tenantOccupancyCounts = 'tenantOccupancyCounts'
}
