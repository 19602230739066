/**
 * @module Select - Simple HOC for rendering <Select> elements so you don't have to import 5 things
 * @example
 * import { useState } from 'react'
 * const [value, setValue] = useState(1)
 * <SelectField
 *     options={[
 *         { label: 'My Option', value: 1 },
 *         { label: 'My Second Option', value: 2}
 *     ]}
 *     value={value}
 *     onChange={e => setValue(e.target.value)}
 *     label='My Select Field'
 * />
 */
import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import MaterialSelect from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { SelectOption } from 'types/generics'
import sortBy from 'utils/sortBy'

export type { SelectOption } from 'types/generics'

export const Select = ({loading = false, ...props}: SelectProps) =>
    loading ? <CircularProgress size={48} /> : <SelectField {...props} />
export default Select


interface SelectProps extends SelectFieldProps {
    loading?: boolean
}


interface SelectFieldProps extends Record<string, any> {
    className?: string
    fullWidth?: boolean
    helperTextProps?: object
    labelProps?: object
    multiple?: boolean
    onChange(event): void
    options: SelectOption[]
    style?: object
    value: any
}


export const SelectField = ({
                                value,
                                onChange,
                                options,
                                multiple = false,
                                labelProps = {},
                                helperTextProps = {},
                                style = {},
                                className = '',
                                fullWidth = false,
                                ...props
                            }: SelectFieldProps) => (
    <FormControl style={style} className={className} fullWidth={fullWidth}>
        {props.label && <InputLabel {...labelProps}>{props.label}</InputLabel>}
        <MaterialSelect
            value={getValue(value, multiple)}
            onChange={onChange}
            MenuProps={{
                TransitionProps: {unmountOnExit: true}
            }}
            multiple={multiple}
            {...props}
        >
            {sortByLabel(options).map((option, index) => (
                <MenuItem
                    value={String(option.value)}
                    key={`${option.value}-${option.label}-${index}`}
                    disabled={option.disabled}
                >
                    {option.label}
                </MenuItem>
            ))}
        </MaterialSelect>
        {props.helperText && (
            <FormHelperText {...helperTextProps}>{props.helperText}</FormHelperText>
        )}
    </FormControl>
)

const getValue = (value, multiple) => {
    if (null === value) {
        return multiple ? [] : ''
    }
    return value
}

export const sortByLabel = sortBy(({label}) => label)
