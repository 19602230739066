/* eslint-disable */
/**
 * FIXME: CR 2021-Jul-27 - Review the trade-off of refactoring this to slice syntax
 */
import { templateConstants } from '../constants'
import { difference, uniq } from 'lodash'
import { isObject } from 'lodash'

export function templates(state = {}, action) {
  let bayGroupLotsRequested
  let conflicts
  let lotAreaReferenceIds
  let lotAreaReferences
  let lotBayGroups
  let oldPublishedTemplateId
  let publishing
  let templateContainers
  let templateGroups
  let templateIds
  let templateInstanceIds
  let templateInstances
  let templates
  switch (action.type) {
    case templateConstants.OUTLINES_REQUEST:
      return {
        ...state,
        loadingOutlines: true
      }
    case templateConstants.OUTLINES_SUCCESS:
      let outlines = { ...(state['outlines'] || {}) }
      outlines[action.jurisdictionId] = action.outlines
      return {
        ...state,
        outlines,
        loadingOutlines: false
      }
    case templateConstants.OUTLINES_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingOutlines: false
      }

    /////////////////////////////

    case templateConstants.BAYGROUPS_REQUEST:
      lotAreaReferenceIds = [...(state['lotAreaReferenceIds'] || [])]
      bayGroupLotsRequested = [...(state['bayGroupLotsRequested'] || [])]
      lotAreaReferenceIds = lotAreaReferenceIds.filter(
        e => e !== action.lotAreaReferenceId
      )
      bayGroupLotsRequested.push(action.lotAreaReferenceId)

      return {
        ...state,
        lotAreaReferenceIds,
        bayGroupLotsRequested,
        loadingBayGroups: true
      }
    case templateConstants.BAYGROUPS_SUCCESS:
      lotBayGroups = { ...(state['lotBayGroups'] || {}) }
      lotBayGroups[action.lotAreaReferenceId] = action.bayGroups

      bayGroupLotsRequested = [...(state['bayGroupLotsRequested'] || [])]
      bayGroupLotsRequested = bayGroupLotsRequested.filter(
        e => e !== action.lotAreaReferenceId
      )
      return {
        ...state,
        bayGroupLotsRequested,
        lotBayGroups,
        loadingBayGroups: false
      }
    case templateConstants.BAYGROUPS_FAILURE:
      bayGroupLotsRequested = [...(state['bayGroupLotsRequested'] || [])]
      bayGroupLotsRequested = bayGroupLotsRequested.filter(
        e => e !== action.lotAreaReferenceId
      )
      return {
        ...state,
        bayGroupLotsRequested,
        loadingBayGroups: true,
        error: action.error
      }

    /////////////////////////////

    case templateConstants.LOTREFERENCES_REQUEST:
      return {
        ...state,
        loadingReferences: true
      }
    case templateConstants.LOTREFERENCES_SUCCESS:
      lotAreaReferences = [...(state['lotAreaReferences'] || [])]
      lotAreaReferences = uniq([...lotAreaReferences, ...action.references])
      return {
        ...state,
        lotAreaReferences,
        loadingReferences: false
      }
    case templateConstants.LOTREFERENCES_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingReferences: false
      }

    /////////////////////////////

    case templateConstants.SIGNCONFIGS_REQUEST:
      return {
        ...state,
        loadingSignConfigs: true
      }
    case templateConstants.SIGNCONFIGS_SUCCESS:
      let signConfigsDict = {}
      for (let i = 0; i < action.signConfigs.length; i++) {
        signConfigsDict[action.signConfigs[i].id] = action.signConfigs[i]
      }

      return {
        ...state,
        signConfigs: signConfigsDict,
        loadingSignConfigs: false
      }
    case templateConstants.SIGNCONFIGS_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingSignConfigs: false
      }

    /////////////////////////////

    case templateConstants.CLEAR_LOTREFERENCE:
      lotAreaReferenceIds = (state['lotAreaReferenceIds'] || []).filter(
        e => e != action.lotAreaReferenceId
      )
      return {
        ...state,
        lotAreaReferenceIds
      }

    case templateConstants.CLEAR_LOTREFERENCES:
      lotAreaReferenceIds = [...(state['lotAreaReferenceIds'] || [])]
      lotAreaReferenceIds = difference(
        lotAreaReferenceIds,
        action.lotAreaReferenceIds
      )
      return {
        ...state,
        lotAreaReferenceIds
      }

    /////////////////////////////

    case templateConstants.TEMPLATE_GROUPS_REQUEST:
      return {
        ...state,
        loadingTemplateGroups: true
      }
    case templateConstants.TEMPLATE_GROUPS_SUCCESS:
      templateGroups = { ...(state['templateGroups'] || {}) }
      for (let i = 0; i < action.templateGroups.length; i++) {
        templateGroups[action.templateGroups[i].id] = action.templateGroups[i]
      }
      return {
        ...state,
        templateGroups,
        loadingTemplateGroups: false
      }
    case templateConstants.TEMPLATE_GROUPS_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingTemplateGroups: false
      }

    /////////////////////////////

    case templateConstants.TEMPLATE_GROUP_REQUEST:
      return {
        ...state,
        loadingTemplateGroup: true
      }
    case templateConstants.TEMPLATE_GROUP_SUCCESS:
      templateGroups = { ...(state['templateGroups'] || {}) }
      templateGroups[action.templateGroup.id] = action.templateGroup

      return {
        ...state,
        templateGroups,
        loadingTemplateGroup: false
      }

    case templateConstants.TEMPLATE_GROUP_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingTemplateGroup: false
      }

    /////////////////////////////

    case templateConstants.ADD_NEW_TEMPLATE_GROUP_REQUEST:
      return {
        ...state,
        saving: true
      }
    case templateConstants.ADD_NEW_TEMPLATE_GROUP_SUCCESS:
      templateGroups = { ...(state['templateGroups'] || {}) }
      templateGroups[action.templateGroup.id] = action.templateGroup

      return {
        ...state,
        templateGroups,
        saving: false
      }
    case templateConstants.ADD_NEW_TEMPLATE_GROUP_FAILURE:
      return {
        ...state,
        error: action.error,
        saving: false
      }

    /////////////////////////////

    case templateConstants.UPDATE_TEMPLATE_GROUP_REQUEST:
      return {
        ...state,
        saving: true
      }
    case templateConstants.UPDATE_TEMPLATE_GROUP_SUCCESS:
      templateGroups = { ...(state['templateGroups'] || {}) }
      templateGroups[action.templateGroup.id] = action.templateGroup

      return {
        ...state,
        templateGroups,
        saving: false
      }
    case templateConstants.UPDATE_TEMPLATE_GROUP_FAILURE:
      return {
        ...state,
        error: action.error,
        saving: false
      }

    /////////////////////////////

    case templateConstants.DELETE_TEMPLATE_GROUP_REQUEST:
      return {
        ...state,
        deleting: true
      }
    case templateConstants.DELETE_TEMPLATE_GROUP_SUCCESS:
      templateGroups = { ...(state['templateGroups'] || {}) }
      delete templateGroups[action.templateGroupId]

      return {
        ...state,
        templateGroups,
        deleting: false
      }
    case templateConstants.DELETE_TEMPLATE_GROUP_FAILURE:
      return {
        ...state,
        error: action.error,
        deleting: false
      }

    /////////////////////////////

    case templateConstants.REGISTER_TEMPLATE_GROUP:
      templateGroups = { ...(state['templateGroups'] || {}) }
      templateGroups[action.templateGroup.id] = action.templateGroup

      return {
        ...state,
        templateGroups,
        saving: false
      }

    /////////////////////////////

    case templateConstants.TEMPLATE_CONTAINERS_REQUEST:
      return {
        ...state,
        loadingTemplateContainers: true
      }

    case templateConstants.TEMPLATE_CONTAINERS_SUCCESS:
      templateContainers = { ...(state['templateContainers'] || {}) }
      for (let i = 0; i < action.templateContainers.length; i++) {
        templateContainers[action.templateContainers[i].id] =
          action.templateContainers[i]
      }
      return {
        ...state,
        templateContainers,
        loadingTemplateContainers: false
      }
    case templateConstants.TEMPLATE_CONTAINERS_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingTemplateContainers: false
      }

    /////////////////////////////

    case templateConstants.TEMPLATE_CONTAINER_REQUEST:
      return {
        ...state,
        loadingTemplateContainer: true
      }
    case templateConstants.TEMPLATE_CONTAINER_SUCCESS:
      templateContainers = { ...(state['templateContainers'] || {}) }
      templateContainers[action.templateContainer.id] = action.templateContainer

      return {
        ...state,
        templateContainers,
        loadingTemplateContainer: false
      }

    case templateConstants.TEMPLATE_CONTAINER_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingTemplateContainer: false
      }

    /////////////////////////////

    case templateConstants.ADD_NEW_TEMPLATE_CONTAINER_REQUEST:
      return {
        ...state,
        saving: true
      }
    case templateConstants.ADD_NEW_TEMPLATE_CONTAINER_SUCCESS:
      templateContainers = { ...(state['templateContainers'] || {}) }
      if (!isObject(action.templateContainer.templategroup)) {
        action.templateContainer.templategroup = {
          id: action.templateContainer.templategroup
        }
      }
      templateContainers[action.templateContainer.id] = action.templateContainer

      return {
        ...state,
        templateContainers,
        saving: false
      }
    case templateConstants.ADD_NEW_TEMPLATE_CONTAINER_FAILURE:
      return {
        ...state,
        error: action.error,
        saving: false
      }

    /////////////////////////////

    case templateConstants.UPDATE_TEMPLATE_CONTAINER_REQUEST:
      return {
        ...state,
        saving: true
      }
    case templateConstants.UPDATE_TEMPLATE_CONTAINER_SUCCESS:
      templateContainers = { ...(state['templateContainers'] || {}) }
      templateContainers[action.templateContainer.id] = action.templateContainer

      return {
        ...state,
        templateContainers,
        saving: false
      }
    case templateConstants.UPDATE_TEMPLATE_CONTAINER_FAILURE:
      return {
        ...state,
        error: action.error,
        saving: false
      }

    /////////////////////////////

    case templateConstants.DELETE_TEMPLATE_CONTAINER_REQUEST:
      return {
        ...state,
        deleting: true
      }
    case templateConstants.DELETE_TEMPLATE_CONTAINER_SUCCESS:
      templateContainers = { ...(state['templateContainers'] || {}) }
      delete templateContainers[action.templateContainerId]

      return {
        ...state,
        templateContainers,
        deleting: false
      }
    case templateConstants.DELETE_TEMPLATE_CONTAINER_FAILURE:
      return {
        ...state,
        error: action.error,
        deleting: false
      }

    /////////////////////////////

    case templateConstants.REGISTER_TEMPLATE_CONTAINER:
      templateContainers = { ...(state['templateContainers'] || {}) }
      if (!isObject(action.templateContainer.templategroup)) {
        action.templateContainer.templategroup = {
          id: action.templateContainer.templategroup
        }
      }
      templateContainers[action.templateContainer.id] = action.templateContainer

      return {
        ...state,
        templateContainers
      }

    /////////////////////////////

    case templateConstants.TEMPLATES_REQUEST:
      return {
        ...state,
        loadingTemplates: true
      }

    case templateConstants.TEMPLATES_SUCCESS:
      templates = { ...(state['templates'] || {}) }
      for (let i = 0; i < action.templates.length; i++) {
        templates[action.templates[i].id] = action.templates[i]
      }
      return {
        ...state,
        templates,
        loadingTemplates: false
      }
    case templateConstants.TEMPLATES_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingTemplates: false
      }

    /////////////////////////////

    case templateConstants.TEMPLATE_REQUEST:
      return {
        ...state,
        loadingTemplate: true
      }
    case templateConstants.TEMPLATE_SUCCESS:
      templates = { ...(state['templates'] || {}) }
      templates[action.template.id] = action.template

      return {
        ...state,
        templates,
        loadingTemplate: false
      }
    case templateConstants.TEMPLATE_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingTemplate: false
      }

    /////////////////////////////

    case templateConstants.REGISTER_TEMPLATE:
      templates = { ...(state['templates'] || {}) }
      if (!isObject(action.template.templatecontainer)) {
        action.template.templatecontainer = {
          id: action.template.templatecontainer
        }
      }
      if (!isObject(action.template.signConfig)) {
        action.template.signConfig = { id: action.template.signConfig }
      }
      templates[action.template.id] = action.template

      return {
        ...state,
        templates
      }

    /////////////////////////////
    case templateConstants.ADD_NEW_TEMPLATE_REQUEST:
      return {
        ...state,
        saving: true
      }
    case templateConstants.ADD_NEW_TEMPLATE_SUCCESS:
      templates = { ...(state['templates'] || {}) }
      if (!isObject(action.template.templatecontainer)) {
        action.template.templatecontainer = {
          id: action.template.templatecontainer
        }
      }
      if (!isObject(action.template.signConfig)) {
        action.template.signConfig = { id: action.template.signConfig }
      }
      templates[action.template.id] = action.template

      return {
        ...state,
        templates,
        saving: false
      }
    case templateConstants.ADD_NEW_TEMPLATE_FAILURE:
      return {
        ...state,
        error: action.error,
        saving: false
      }

    /////////////////////////////

    case templateConstants.COPYFROM_TEMPLATE_REQUEST:
      return {
        ...state,
        saving: true
      }
    case templateConstants.COPYFROM_TEMPLATE_SUCCESS:
      templates = { ...(state['templates'] || {}) }
      if (!isObject(action.template.templatecontainer)) {
        action.template.templatecontainer = {
          id: action.template.templatecontainer
        }
      }
      if (!isObject(action.template.signConfig)) {
        action.template.signConfig = { id: action.template.signConfig }
      }
      templates[action.template.id] = action.template

      return {
        ...state,
        templates,
        saving: false
      }
    case templateConstants.COPYFROM_TEMPLATE_FAILURE:
      return {
        ...state,
        error: action.error,
        saving: false
      }

    /////////////////////////////

    case templateConstants.UPDATE_TEMPLATE_REQUEST:
      return {
        ...state,
        saving: true
      }
    case templateConstants.UPDATE_TEMPLATE_SUCCESS:
      templates = { ...(state['templates'] || {}) }
      templates[action.template.id] = action.template

      return {
        ...state,
        templates,
        saving: false
      }
    case templateConstants.UPDATE_TEMPLATE_FAILURE:
      return {
        ...state,
        error: action.error,
        saving: false
      }

    /////////////////////////////

    case templateConstants.DELETE_TEMPLATE_REQUEST:
      return {
        ...state,
        deleting: true
      }
    case templateConstants.DELETE_TEMPLATE_SUCCESS:
      templates = { ...(state['templates'] || {}) }
      delete templates[action.templateId]

      return {
        ...state,
        templates,
        deleting: false
      }
    case templateConstants.DELETE_TEMPLATE_FAILURE:
      return {
        ...state,
        error: action.error,
        deleting: false
      }

    /////////////////////////////

    case templateConstants.TEMPLATE_INSTANCES_REQUEST:
      return {
        ...state,
        loadingTemplateInstances: true
      }

    case templateConstants.TEMPLATE_INSTANCES_SUCCESS:
      templateInstances = { ...(state['templateInstances'] || {}) }
      conflicts = { ...(state['conflicts'] || {}) }

      for (let i = 0; i < action.templateInstances.length; i++) {
        templateInstances[action.templateInstances[i].id] =
          action.templateInstances[i]
        delete conflicts[action.templateInstances[i].id]
      }
      return {
        ...state,
        templateInstances,
        conflicts,
        loadingTemplateInstances: false
      }
    case templateConstants.TEMPLATE_INSTANCES_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingTemplateInstances: false
      }

    /////////////////////////////

    case templateConstants.TEMPLATE_INSTANCE_REQUEST:
      return {
        ...state,
        loadingTemplateInstance: true
      }
    case templateConstants.TEMPLATE_INSTANCE_SUCCESS:
      templateInstances = { ...(state['templateInstances'] || {}) }
      conflicts = { ...(state['conflicts'] || {}) }

      templateInstances[action.templateInstance.id] = action.templateInstance
      delete conflicts[action.templateInstance.id]

      return {
        ...state,
        templateInstances,
        conflicts,
        loadingTemplateInstance: false
      }

    case templateConstants.TEMPLATE_INSTANCE_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingTemplateInstance: false
      }

    /////////////////////////////

    case templateConstants.REGISTER_TEMPLATE_INSTANCE:
      templateInstances = { ...(state['templateInstances'] || {}) }
      conflicts = { ...(state['conflicts'] || {}) }
      if (!isObject(action.templateInstance.template)) {
        action.templateInstance.template = {
          id: action.templateInstance.template
        }
      }
      templateInstances[action.templateInstance.id] = action.templateInstance
      delete conflicts[action.templateInstance.id]

      return {
        ...state,
        templateInstances,
        conflicts
      }

    /////////////////////////////

    case templateConstants.ADD_NEW_TEMPLATE_INSTANCE_REQUEST:
      return {
        ...state,
        saving: true
      }
    case templateConstants.ADD_NEW_TEMPLATE_INSTANCE_SUCCESS:
      templateInstances = { ...(state['templateInstances'] || {}) }

      if (!isObject(action.templateInstance.template)) {
        action.templateInstance.template = {
          id: action.templateInstance.template
        }
      }
      templateInstances[action.templateInstance.id] = action.templateInstance

      return {
        ...state,
        templateInstances,
        saving: false
      }
    case templateConstants.ADD_NEW_TEMPLATE_INSTANCE_FAILURE:
      return {
        ...state,
        error: action.error,
        saving: false
      }

    /////////////////////////////

    case templateConstants.UPDATE_TEMPLATE_INSTANCE_REQUEST:
      return {
        ...state,
        saving: true
      }
    case templateConstants.UPDATE_TEMPLATE_INSTANCE_SUCCESS:
      templateInstances = { ...(state['templateInstances'] || {}) }
      conflicts = { ...(state['conflicts'] || {}) }

      if (!isObject(action.templateInstance.template)) {
        action.templateInstance.template = {
          id: action.templateInstance.template
        }
      }
      templateInstances[action.templateInstance.id] = action.templateInstance
      delete conflicts[action.templateInstance.id]

      return {
        ...state,
        templateInstances,
        conflicts,
        saving: false
      }
    case templateConstants.UPDATE_TEMPLATE_INSTANCE_FAILURE:
      return {
        ...state,
        error: action.error,
        saving: false
      }

    /////////////////////////////

    case templateConstants.DELETE_TEMPLATE_INSTANCE_REQUEST:
      return {
        ...state,
        deleting: true
      }
    case templateConstants.DELETE_TEMPLATE_INSTANCE_SUCCESS:
      templateInstances = { ...(state['templateInstances'] || {}) }
      conflicts = { ...(state['conflicts'] || {}) }

      delete templateInstances[action.templateInstanceId]
      delete conflicts[action.templateInstanceId]

      return {
        ...state,
        templateInstances,
        conflicts,
        deleting: false
      }
    case templateConstants.DELETE_TEMPLATE_INSTANCE_FAILURE:
      return {
        ...state,
        error: action.error,
        deleting: false
      }

    /////////////////////////////

    case templateConstants.PUBLISH_TEMPLATE_VERSION_REQUEST:
      publishing = { ...(state['publishing'] || {}) }
      publishing[action.templateContainerId] = action.version

      return {
        ...state,
        publishing,
        publishingStatus: 1
      }

    case templateConstants.PUBLISH_TEMPLATE_VERSION_SUCCESS:
      publishing = { ...(state['publishing'] || {}) }
      templates = { ...(state['templates'] || {}) }
      templateInstances = { ...(state['templateInstances'] || {}) }

      delete publishing[action.templateContainerId]

      templateIds = Object.keys(templates)
      templateIds.forEach(templateId => {
        if (
          templates[templateId].templatecontainer.id ===
          action.templateContainerId
        ) {
          if (templates[templateId].published === true) {
            oldPublishedTemplateId = templateId
          }
          templates[templateId].published = false
        }
      })
      templates[action.template.id] = action.template

      templateInstanceIds = Object.keys(templateInstances)
      templateInstanceIds.forEach(templateInstanceId => {
        let templateInstance = templateInstances[templateInstanceId]
        if (templateInstance.template.id === oldPublishedTemplateId) {
          templateInstance.template.id = action.template.id
        }
      })

      return {
        ...state,
        publishing,
        templates,
        templateInstances,
        publishingStatus: 0
      }

    case templateConstants.PUBLISH_TEMPLATE_VERSION_FAILURE:
      publishing = { ...(state['publishing'] || {}) }
      delete publishing[action.templateContainerId]

      return {
        ...state,
        error: action.error,
        publishing,
        publishingStatus: 0
      }

    /////////////////////////////

    case templateConstants.UNPUBLISH_TEMPLATE_REQUEST:
      publishing = { ...(state['publishing'] || {}) }
      publishing[action.templateContainerId] = -1

      return {
        ...state,
        publishing,
        publishingStatus: 2
      }

    case templateConstants.UNPUBLISH_TEMPLATE_SUCCESS:
      publishing = { ...(state['publishing'] || {}) }
      templates = { ...(state['templates'] || {}) }
      templateInstances = { ...(state['templateInstances'] || {}) }

      delete publishing[action.templateContainerId]

      templateIds = Object.keys(templates)
      templateInstanceIds = Object.keys(templateInstances)

      templateIds.forEach(templateId => {
        if (
          templates[templateId].templatecontainer.id ===
          action.templateContainerId
        ) {
          templates[templateId].published = false
          templateInstanceIds.forEach(templateInstanceId => {
            if (
              templateInstances[templateInstanceId].template.id === templateId
            ) {
              templateInstances[templateInstanceId].published = false
            }
          })
        }
      })

      return {
        ...state,
        publishing,
        templates,
        templateInstances,
        publishingStatus: 0
      }

    case templateConstants.UNPUBLISH_TEMPLATE_FAILURE:
      publishing = { ...(state['publishing'] || {}) }
      delete publishing[action.templateContainerId]

      return {
        ...state,
        error: action.error,
        publishing,
        publishingStatus: 0
      }

    /////////////////////////////

    case templateConstants.PUBLISH_TEMPLATE_INSTANCE_REQUEST:
      publishing = { ...(state['publishing'] || {}) }
      publishing[action.templateContainerId] = 1

      return {
        ...state,
        publishing,
        publishingStatus: 3
      }

    case templateConstants.PUBLISH_TEMPLATE_INSTANCE_SUCCESS:
      publishing = { ...(state['publishing'] || {}) }
      templateInstances = { ...(state['templateInstances'] || {}) }

      delete publishing[action.templateContainerId]

      templateInstances[action.templateInstance.id] = action.templateInstance

      return {
        ...state,
        publishing,
        templateInstances,
        publishingStatus: 0
      }

    case templateConstants.PUBLISH_TEMPLATE_INSTANCE_FAILURE:
      publishing = { ...(state['publishing'] || {}) }
      delete publishing[action.templateContainerId]

      return {
        ...state,
        error: action.error,
        publishing,
        publishingStatus: 0
      }

    /////////////////////////////

    case templateConstants.UNPUBLISH_TEMPLATE_INSTANCE_REQUEST:
      publishing = { ...(state['publishing'] || {}) }
      publishing[action.templateContainerId] = -1

      return {
        ...state,
        publishing,
        publishingStatus: 4
      }

    case templateConstants.UNPUBLISH_TEMPLATE_INSTANCE_SUCCESS:
      publishing = { ...(state['publishing'] || {}) }
      templateInstances = { ...(state['templateInstances'] || {}) }

      delete publishing[action.templateContainerId]
      templateInstances[action.templateInstance.id] = action.templateInstance

      return {
        ...state,
        publishing,
        templateInstances,
        publishingStatus: 0
      }

    case templateConstants.UNPUBLISH_TEMPLATE_INSTANCE_FAILURE:
      publishing = { ...(state['publishing'] || {}) }
      delete publishing[action.templateContainerId]

      return {
        ...state,
        error: action.error,
        publishing,
        publishingStatus: 0
      }

    /////////////////////////////

    case templateConstants.CONFLICT_DETECTION_TEMPLATE_INSTANCE_REQUEST:
      conflicts = { ...(state['conflicts'] || {}) }
      delete conflicts[action.templateInstanceId]

      return {
        ...state,
        conflicts,
        conflictChecking: true
      }

    case templateConstants.CONFLICT_DETECTION_TEMPLATE_INSTANCE_SUCCESS:
      conflicts = { ...(state['conflicts'] || {}) }
      conflicts[action.templateInstanceId] = action.conflicts

      return {
        ...state,
        conflicts,
        conflictChecking: false
      }

    case templateConstants.CONFLICT_DETECTION_TEMPLATE_INSTANCE_FAILURE:
      conflicts = { ...(state['conflicts'] || {}) }
      delete conflicts[action.templateInstanceId]

      return {
        ...state,
        error: action.error,
        conflicts,
        conflictChecking: false
      }

    /////////////////////////////

    case templateConstants.CONFLICT_DETECTION_TEMPLATE_REQUEST:
      conflicts = {}

      return {
        ...state,
        conflicts,
        conflictChecking: true
      }

    case templateConstants.CONFLICT_DETECTION_TEMPLATE_SUCCESS:
      conflicts = { ...(state['conflicts'] || {}) }

      Object.keys(action.conflicts).forEach(templateInstanceId => {
        conflicts[templateInstanceId] = action.conflicts[templateInstanceId]
      })

      return {
        ...state,
        conflicts,
        conflictChecking: false
      }

    case templateConstants.CONFLICT_DETECTION_TEMPLATE_FAILURE:
      conflicts = {}

      return {
        ...state,
        error: action.error,
        conflicts,
        conflictChecking: false
      }

    /////////////////////////////

    case templateConstants.CONFLICT_DETECTION_RESET:
      conflicts = { ...(state['conflicts'] || {}) }

      for (let i = 0; i < (action.templateInstanceIds || []).length; i++) {
        delete conflicts[action.templateInstanceIds[i]]
      }

      return {
        ...state,
        conflicts,
        conflictChecking: false
      }

    default:
      return state
  }
}
