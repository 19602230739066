import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'

import CustomInput from 'components/CustomInput/CustomInput'
import CustomisableSelect from 'views/Forms/controls/CustomisableSelect'
import tariffTypeStyle from 'assets/jss/spot-admin/views/newTemplateGroupStyle'

const TariffTypes = [
  { label: 'Pro-rated Fee', value: 'PRO_RATED' },
  { label: 'Pro-rated Fee With Daily Cap', value: 'PRO_RATED_WITH_DAILY_CAP' },
  {
    label: 'Pro-rated Fee With Period Cap',
    value: 'PRO_RATED_WITH_PERIOD_CAP'
  },
  { label: 'Fixed Fee', value: 'FIXED' },
  { label: 'Free', value: 'FREE' }
]

class TariffType extends React.Component {
  constructor(props) {
    super(props)
    let value = props.value

    this.state = {
      tariffTypeSelected: value.subtype,
      currency: value.currency || props.baseCurrency,
      chargeInterval: value.chargeInterval || 1,
      displayCharge: value.displayCharge,
      displayChargeUnitSize: value.displayChargeUnitSize,
      minimumChargeUnit: value.minimumChargeUnit,
      cappedAmount: value.cappedAmount
    }

    this.handleTariffTypeChange = this.handleTariffTypeChange.bind(this)
    this.handleTariffTypeAttributeChange =
      this.handleTariffTypeAttributeChange.bind(this)
  }

  componentDidUpdate() {
    const { reset, value, baseCurrency } = this.props

    if (reset) {
      this.setState({
        tariffTypeSelected: value.subtype,
        currency: value.currency,
        chargeInterval: value.chargeInterval || baseCurrency,
        displayCharge: value.displayCharge,
        displayChargeUnitSize: value.displayChargeUnitSize,
        minimumChargeUnit: value.minimumChargeUnit,
        cappedAmount: value.cappedAmount
      })
    }
  }

  handleTariffTypeChange(selected) {
    const { tariffTypeSelected, currency } = this.state
    const { controlId, onTariffTypeChange, baseCurrency } = this.props

    if (tariffTypeSelected !== selected) {
      this.setState({
        tariffTypeSelected: selected
      })
      onTariffTypeChange(controlId, {
        type: 'TariffType',
        subtype: selected,
        chargeInterval: 1,
        currency: currency || baseCurrency,
        displayCharge: 1,
        displayChargeUnitSize: 60,
        cappedAmount: 0
      })
    }
  }

  handleTariffTypeAttributeChange(fieldId, newValue) {
    const {
      tariffTypeSelected,
      currency,
      chargeInterval,
      displayCharge,
      displayChargeUnitSize,
      cappedAmount
    } = this.state
    const { controlId, onTariffTypeChange, baseCurrency } = this.props

    let newTariffStructure = {
      type: 'TariffType',
      subtype: tariffTypeSelected,
      chargeInterval,
      currency: currency || baseCurrency,
      displayCharge,
      displayChargeUnitSize,
      cappedAmount
    }

    newTariffStructure[fieldId] = newValue

    this.setState(newTariffStructure)

    onTariffTypeChange(controlId, newTariffStructure)
  }

  renderSubTypeSpecific(subtype, existingValues) {
    const { disabled } = this.props

    const fields = [
      {
        fieldId: 'minimumChargeUnit',
        label: 'MINIMUM CHARGE UNIT (in minutes)',
        default: 1,
        types: [
          'PRO_RATED',
          'PRO_RATED_WITH_DAILY_CAP',
          'PRO_RATED_WITH_PERIOD_CAP'
        ]
      },
      {
        fieldId: 'chargeInterval',
        label: 'CHARGE INTERVAL (minute units)',
        default: 1,
        types: [
          'PRO_RATED',
          'PRO_RATED_WITH_DAILY_CAP',
          'PRO_RATED_WITH_PERIOD_CAP'
        ]
      },
      {
        fieldId: 'displayCharge',
        label: 'DISPLAY CHARGE ($)',
        default: 0,
        types: [
          'PRO_RATED',
          'PRO_RATED_WITH_DAILY_CAP',
          'PRO_RATED_WITH_PERIOD_CAP',
          'FIXED'
        ]
      },
      {
        fieldId: 'displayChargeUnitSize',
        label: 'DISPLAY CHARGE UNIT SIZE (in minutes)',
        default: 60,
        types: [
          'PRO_RATED',
          'PRO_RATED_WITH_DAILY_CAP',
          'PRO_RATED_WITH_PERIOD_CAP'
        ]
      },
      {
        fieldId: 'cappedAmount',
        label: 'CAPPED AMOUNT ($)',
        default: 0,
        types: ['PRO_RATED_WITH_DAILY_CAP', 'PRO_RATED_WITH_PERIOD_CAP']
      }
    ]

    let requiredFields = fields.filter(
      e => e.types.findIndex(selected => selected === subtype) !== -1
    )

    if (requiredFields.length === 0) {
      return null
    }

    return (
      <div>
        {requiredFields.map((field, key) => {
          return (
            <CustomInput
              key={key}
              id={`${field.fieldId}`}
              labelText={field.label}
              formControlProps={{ fullWidth: true }}
              inputProps={{
                onChange: event =>
                  this.handleTariffTypeAttributeChange(
                    `${field.fieldId}`,
                    Number(event.target.value)
                  ),
                type: 'number',
                value: `${existingValues[field.fieldId] || field.default}`,
                disabled
              }}
            />
          )
        })}
      </div>
    )
  }

  render() {
    const { controlId, disabled } = this.props
    const { tariffTypeSelected } = this.state

    return (
      <div>
        <Field initialValue={tariffTypeSelected} name={controlId}>
          {props => (
            <CustomisableSelect
              controlId={controlId}
              disabled={disabled}
              handleChange={newSelection =>
                this.handleTariffTypeChange(newSelection)
              }
              items={TariffTypes}
              label={'Tariff Category'}
              value={tariffTypeSelected}
            />
          )}
        </Field>
        {this.renderSubTypeSpecific(tariffTypeSelected, this.state)}
      </div>
    )
  }
}

TariffType.propTypes = {
  classes: PropTypes.object.isRequired,
  controlId: PropTypes.string.isRequired,
  baseCurrency: PropTypes.string.isRequired,
  onTariffTypeChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired
}

export default withStyles(tariffTypeStyle)(TariffType)
