import * as Comlink from 'comlink'
import { v4 as uuidv4 } from 'uuid'
import { DateTime, IANAZone } from 'luxon'
import { groupBy, prop } from 'ramda'

import { actions, name } from './resourceSlice'
import { alertActions } from 'features/alert/alertSlice'
import { ErrorResponseCode } from 'types/request'
import { getJurisdictionId } from 'utils/useJurisdictionId'
import paymentService from 'services/paymentReport.service'
import { handleError } from 'features/resource/resourceActions'
import { DateRangeType, Resource } from 'features/resource/types/paymentTypes'
import { JurisdictionRollupType, PaymentSessionDimension, PaymentSessionReportRequest, PaymentSessionReportResult, PaymentSessionReportWithId, PaymentSessionTimeScale, ProviderDefinition, TimeScaleProperties, TimeSeriesVariable } from 'features/resource/types/paymentSessionTypes'
import { allowedDateRangeForResource, allowedDateRangeForResources, lineGroupsAndConfigsForReports } from 'features/resource/paymentReportUtils'
import sleep from 'utils/sleep'


const initFinancialReports = () => async (dispatch, getState) => {
    const jurisdictionId = getJurisdictionId()
    return dispatch(loadPaymentResources({jurisdictionId}))
}


const loadPaymentResources = ({jurisdictionId}: { jurisdictionId: string }) => async (dispatch, getState) => {
    dispatch(actions.setPaymentResourcesLoading(true))
    try {
        const {resources} = await paymentService.getAvailablePaymentResources({jurisdictionId})
        dispatch(actions.setPaymentResources(resources))
    } catch (error) {
        dispatch(handleError(error))
    }
    dispatch(actions.setPaymentResourcesLoading(false))
    dispatch(actions.setShowPaymentSessionReport(false))
}


const setSelectedResources = (selectedResources: Resource[]) => async (dispatch, getState) => {
    dispatch(actions.setSelectedPaymentResources(selectedResources))
    dispatch(actions.setPaymentParametersLoading(true))
    try {
        await sleep(500)
        if (selectedResources.length) {
            const dateRange = allowedDateRangeForResources(selectedResources)
            const dateRangeHasStartAndEnd = dateRange?.start && dateRange?.end
            if (!dateRangeHasStartAndEnd) {
                return
            }
            const {start, end} = dateRange
            dispatch(actions.setPaymentParametersLoading(false))
            dispatch(actions.setShowPaymentSessionReport(false))
            return dispatch(onDateRangeChange({start, end}))
        }
    } catch (error) {
        dispatch(handleError(error))
    }
    dispatch(actions.setPaymentParametersLoading(false))
    dispatch(actions.setShowPaymentSessionReport(false))
}


const setSelectedPaymentTimeSeriesVariables = (value) => async (dispatch, getState) => {
    dispatch(actions.setSelectedPaymentTimeSeriesVariables(value))
    dispatch(actions.setShowPaymentSessionReport(false))
}


const onDimensionsChange = (value: PaymentSessionDimension | null) => async (dispatch, getState) => {
    dispatch(actions.setSessionDimensions(value || PaymentSessionDimension.SUM_SESSION_REVENUE))
    dispatch(actions.setShowPaymentSessionReport(false))
    return true
}

const onDateRangeChange = (value: DateRangeType) => async (dispatch, getState) => {
    // FIXME
    dispatch(actions.setPaymentReportDateRange(value))
    dispatch(actions.setShowPaymentSessionReport(false))
    return value
}

const onTimeScalesChange = (value: PaymentSessionTimeScale[]) => async (dispatch, getState) => {
    dispatch(actions.setTimeScales(value))
    dispatch(actions.setShowPaymentSessionReport(false))
}

const setRollUpLevel = (value) => async (dispatch, getState) => {
    dispatch(actions.setRollUpLevel(value))
    if (getState()[name].showPaymentSessionReport) {
        dispatch(actions.setShowPaymentSessionReport(false))
    }
}


const generateReport = () => async (dispatch, getState) => {
    const {
        paymentReportLoading,
        rollUpLevel,
        sessionDimensions,
        timeScales,
        selectedPaymentTimeSeriesVariables,
        selectedPaymentResources

    } = getState()[name]
    const jurisdictionId = getJurisdictionId()

    // Exit early if we're already generating a report (mis-click/double-click prevention)
    if (paymentReportLoading
        || !jurisdictionId) {
        return
    }
    dispatch(actions.setShowPaymentSessionReport(false))
    dispatch(actions.setPaymentReportLoading(true))
    try {
        const response = await fetchReport({
            jurisdictionId,
            rollUpLevel,
            sessionDimensions: [sessionDimensions],
            timeScales,
            timeSeriesVariables: selectedPaymentTimeSeriesVariables,
            resources: selectedPaymentResources
        })
        const {result} = response
        const reportsWithIds: PaymentSessionReportWithId[] = result.map(report => ({...report, id: uuidv4()}))
        dispatch(actions.setPaymentSessionReports([]))
        const lineGroupsAndConfigs = lineGroupsAndConfigsForReports(sessionDimensions, reportsWithIds)
        dispatch(actions.setPaymentSessionProcessedData(lineGroupsAndConfigs))
    } catch (error) {
        dispatch(handleError(error))
    }

    dispatch(actions.setPaymentReportLoading(false))
    dispatch(actions.setShowPaymentSessionReport(true))
}


export interface FetchFinancialReportParams {
    jurisdictionId: string
    rollUpLevel: JurisdictionRollupType
    sessionDimensions: PaymentSessionDimension[]
    timeScales: PaymentSessionTimeScale[]
    timeSeriesVariables: TimeSeriesVariable[]
    resources: Resource[]
}


export const fetchReport = async ({jurisdictionId, rollUpLevel, sessionDimensions, timeScales, timeSeriesVariables, resources}: FetchFinancialReportParams, signal?: AbortSignal): Promise<PaymentSessionReportResult> => {
    const timeScaleField: Record<string, TimeScaleProperties | TimeScaleProperties[]> = timeScales.reduce((accum, timeScale) => {
        accum[timeScale] = {
            timeSeriesVariable: timeSeriesVariables
        }
        return accum
    }, {})

    const body: PaymentSessionReportRequest = {
        jurisdictionId,
        rollUpLevel,
        timeScale: timeScaleField,
        providerDefinition: selectedResourcesToProviderDefinitions(resources),
        dimensions: [sessionDimensions].flat()
    }
    return await paymentService.getPaymentReport(body, signal)
}


function selectedResourcesToProviderDefinitions(selectedResources: Resource[]): ProviderDefinition[] {
    const providerMapping: Record<string, Resource[]> = groupBy(prop('provider'), selectedResources)

    const providerZoneMappings: Record<string, string[]> = Object.entries(providerMapping).reduce((accum, [key, resources]) => {
        accum[key] = (accum[key] || []).concat(
            resources.map(({providerZoneId}) => providerZoneId).filter(Boolean)
        ).flat()
        return accum
    }, {})

    return Object.entries(providerZoneMappings).reduce((list, [provider, providerZoneId]) => {
        return list.concat({
            provider,
            providerZoneId
        })
    }, [])
}



export const paymentActions = {
    initFinancialReports,
    setSelectedResources,
    onDimensionsChange,
    onDateRangeChange,
    onTimeScalesChange,
    setSelectedPaymentTimeSeriesVariables,
    generateReport,
    setRollUpLevel
}
export default paymentActions