import { createSlice } from '@reduxjs/toolkit'
import { setState } from 'features/baseReducer'
import { v4 as uuidv4 } from 'uuid'


const MAX_AGE = 5000

// AlertTypes correspond with the color system: success, danger, warning
export const AlertTypes = {
    success: 'success',
    error: 'danger',
    warning: 'warning',
    clear: 'clear',
    info: 'info'
}

export const initialState = {
    message: '',
    type: '',
    id: null,
    timestamp: null
}

const alertSlice = createSlice({
    initialState,
    name: 'alert',
    reducers: {
        setState
    }
})

export const {actions, name, reducer} = alertSlice

export const success = message => dispatch => {
    const newState = {
        message,
        type: AlertTypes.success,
        id: uuidv4(),
        timestamp: Date.now()
    }
    dispatch(actions.setState(newState))
    setTimeout(() => dispatch(clearStaleAlert(newState.id)), MAX_AGE + 1)
    return newState
}

export const error = message => dispatch => {
    const newState = {
        message,
        type: AlertTypes.error,
        id: uuidv4(),
        timestamp: Date.now()
    }
    dispatch(actions.setState(newState))
    setTimeout(() => dispatch(clearStaleAlert(newState.id)), MAX_AGE + 1)
    return newState
}

export const warning = message => dispatch => {
    const newState = {
        message,
        type: AlertTypes.warning,
        id: uuidv4(),
        timestamp: Date.now()
    }
    dispatch(actions.setState(newState))
    setTimeout(() => dispatch(clearStaleAlert(newState.id)), MAX_AGE + 1)
    return newState
}


export const info = message => dispatch => {
    const newState = {
        message,
        type: AlertTypes.info,
        id: uuidv4(),
        timestamp: Date.now()
    }
    dispatch(actions.setState(newState))
    setTimeout(() => dispatch(clearStaleAlert(newState.id)), MAX_AGE + 1)
    return newState
}

export const clear = () => dispatch => {
    const newState = {
        message: null,
        type: null,
        id: null,
        timestamp: null
    }
    dispatch(actions.setState(newState))
    return newState
}

const clearStaleAlert = staleId => (dispatch, getState) => {
    const {id, timestamp} = getState()[name]
    const deltaMs = Date.now() - (timestamp || Date.now() - MAX_AGE + 1)
    if (id === staleId && deltaMs >= MAX_AGE) {
        dispatch(clear())
    } else if (deltaMs <= MAX_AGE) {
        setTimeout(()=> dispatch(clearStaleAlert(staleId)), MAX_AGE)
    }
}

export const alertActions = {
    success,
    error,
    warning,
    info,
    clear
}
