import { ClientConfig } from 'features/dashboard/types'


const jurisdictionConfigMap = {
    // Arvada
    'aeac63c0-79c3-4b43-b88f-cde491520ef1': () => import('config/configs/arvada'),
    // OSU
    'ec03e492-6150-46b0-809c-19520527be50': () => import('config/configs/osu'),
    // St Augustine
    '84667885-f352-4f5c-8f68-779f124329f7': () => import('config/configs/stAugustine'),
    // Co Springs
    '4c27e857-79d8-4ba4-95a4-0600c0b58a1a': () => import('config/configs/coloradoSprings'),
    // UTA
    'a8d719e6-8aba-4f1b-bfd1-3a307b9f4a65': ()=> import('config/configs/uta'),
    '45a829e2-4f46-49cd-96da-3675f494fb78': ()=> import('config/configs/masParc'),
    '3d2d543d-c29d-459b-8923-f47cd837f2c5': ()=> import('config/configs/appState')
}


export async function loadConfig(
    jurisdictionId: string
): Promise<ClientConfig | null> {
    const loaderForJurisdiction = jurisdictionConfigMap[jurisdictionId]
    return await loaderForJurisdiction?.().then(_module => _module.default)
}
