/* eslint-disable */

import config from 'config'
import { authHeader } from '../helpers'
import cache from '../utils/cache'
import { handlePermissionError } from './shared'

export const templateService = {
  outlines,
  bayGroups,
  lotAreaReferenceIdsFor,
  signConfigs,
  getTemplateGroups,
  getTemplateGroup,
  getTemplateContainers,
  getTemplateContainer,
  getTemplates,
  getTemplate,
  getTemplateInstances,
  getTemplateInstance,
  addNewTemplateGroup,
  addNewTemplateContainer,
  addNewTemplate,
  copyTemplateFromExisting,
  addNewTemplateInstance,
  updateTemplateGroup,
  updateTemplateContainer,
  updateTemplate,
  updateTemplateInstance,
  deleteTemplateGroup,
  deleteTemplateContainer,
  deleteTemplate,
  deleteTemplateInstance,
  publishTemplateVersion,
  unpublishTemplate,
  publishTemplateInstance,
  unpublishTemplateInstance,
  determineTemplateInstanceConflicts
}

const fetch = window.fetch

function outlines(jurisdictionId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }
  return cache.getOr(
    `${config.apiUrl}/1.4/templates/outlines/${jurisdictionId}`,
    async () => {
      const response = await fetch(
        `${config.apiUrl}/1.4/templates/outlines/${jurisdictionId}`,
        requestOptions
      )
      return handleOutlinesResponse(response)
    }
  )
}

function handleOutlinesResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      // unauthorised
      if (response.status === 401) {
        throw response
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    return data.outlines
  })
}

function bayGroups(jurisdictionId, lotAreaReferenceId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(
    `${config.apiUrl}/1.4/templates/baygroups/${jurisdictionId}/${lotAreaReferenceId}`,
    requestOptions
  ).then(handleBayGroupResponse)
}

function handleBayGroupResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      // unauthorised
      if (response.status === 401) {
        // Redirect if 401 response returned from api
        return handlePermissionError()
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    return data.bayGroups
  })
}

function lotAreaReferenceIdsFor(
  jurisdictionId,
  bayGroupAreaReferenceIds,
  pamReferenceIds
) {
  let requestBody = {}

  if (bayGroupAreaReferenceIds) {
    if (!Array.isArray(bayGroupAreaReferenceIds)) {
      requestBody['bayGroups'] = [bayGroupAreaReferenceIds]
    } else {
      requestBody['bayGroups'] = bayGroupAreaReferenceIds
    }
  }

  if (pamReferenceIds) {
    if (!Array.isArray(pamReferenceIds)) {
      requestBody['pamReferenceIds'] = [pamReferenceIds]
    } else {
      requestBody['pamReferenceIds'] = pamReferenceIds
    }
  }

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(requestBody)
  }

  return fetch(
    `${config.apiUrl}/1.4/templates/lotreferences/${jurisdictionId}`,
    requestOptions
  )
    .then(handleLotAreaReferenceIdsForResponse)
    .then(references => {
      return references
    })
}

function handleLotAreaReferenceIdsForResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      if (response.status === 401) {
        // Redirect if 401 response returned from api
        return handlePermissionError()
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    return data.references
  })
}

function signConfigs(jurisdictionId) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  }

  return fetch(
    `${config.apiUrl}/1.4/templates/signconfigs/${jurisdictionId}?templatableOnly=true`,
    requestOptions
  )
    .then(handleSignConfigsResponse)
    .then(signConfigs => {
      return signConfigs
    })
}

function handleSignConfigsResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      if (response.status === 401) {
        // Redirect if 401 response returned from api
        return handlePermissionError()
      }

      if (response.status === 404) {
        return []
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    return data.signConfigs
  })
}

function getTemplateGroups(jurisdictionId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(
    `${config.apiUrl}/1.4/templates/templategroups/${jurisdictionId}`,
    requestOptions
  ).then(handleGetTemplateGroupsResponse)
}

function handleGetTemplateGroupsResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      // unauthorised
      if (response.status === 401) {
        // Redirect if 401 response returned from api
        return handlePermissionError()
      }

      // no records found
      if (response.status == 404) {
        return []
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    return data.templateGroups
  })
}

function getTemplateGroup(templateGroupId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(
    `${config.apiUrl}/1.4/templates/templategroup/${templateGroupId}`,
    requestOptions
  ).then(handleGetTemplateGroupResponse)
}

function handleGetTemplateGroupResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      // unauthorised
      if (response.status === 401) {
        // Redirect if 401 response returned from api
        return handlePermissionError()
      }

      // no records found
      if (response.status == 404) {
        return {}
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    return data.templateGroup
  })
}

function getTemplateContainers(jurisdictionId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(
    `${config.apiUrl}/1.4/templates/templatecontainers/${jurisdictionId}`,
    requestOptions
  ).then(handleGetTemplateContainersResponse)
}

function handleGetTemplateContainersResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      // unauthorised
      if (response.status === 401) {
        // Redirect if 401 response returned from api
        return handlePermissionError()
      }

      // no records found
      if (response.status == 404) {
        return []
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    return data.templateContainers
  })
}

function getTemplateContainer(templateContainerId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(
    `${config.apiUrl}/1.4/templates/templatecontainer/${templateContainerId}`,
    requestOptions
  ).then(handleGetTemplateContainerResponse)
}

function handleGetTemplateContainerResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      // unauthorised
      if (response.status === 401) {
        // Redirect if 401 response returned from api
        return handlePermissionError()
      }

      // no records found
      if (response.status == 404) {
        return {}
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    return data.templateContainer
  })
}

function getTemplates(jurisdictionId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(
    `${config.apiUrl}/1.4/templates/${jurisdictionId}`,
    requestOptions
  ).then(handleGetTemplatesResponse)
}

function handleGetTemplatesResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      // unauthorised
      if (response.status === 401) {
        // Redirect if 401 response returned from api
        return handlePermissionError()
      }

      // no records found
      if (response.status == 404) {
        return []
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    return data.templates
  })
}

function getTemplate(templateId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(
    `${config.apiUrl}/1.4/templates/template/${templateId}`,
    requestOptions
  ).then(handleGetTemplateResponse)
}

function handleGetTemplateResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      // unauthorised
      if (response.status === 401) {
        // Redirect if 401 response returned from api
        return handlePermissionError()
      }

      // no records found
      if (response.status == 404) {
        return {}
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    return data.template
  })
}

function getTemplateInstances(jurisdictionId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(
    `${config.apiUrl}/1.4/templates/templateinstance/jurisdictionref/${jurisdictionId}`,
    requestOptions
  ).then(handleGetTemplateInstancesResponse)
}

function handleGetTemplateInstancesResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      // unauthorised
      if (response.status === 401) {
        // Redirect if 401 response returned from api
        return handlePermissionError()
      }

      // no records found
      if (response.status == 404) {
        return []
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    return data.templateInstances
  })
}

function getTemplateInstance(templateInstanceId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(
    `${config.apiUrl}/1.4/templates/templateinstance/${templateInstanceId}`,
    requestOptions
  ).then(handleGetTemplateInstanceResponse)
}

function handleGetTemplateInstanceResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      // unauthorised
      if (response.status === 401) {
        // Redirect if 401 response returned from api
        return handlePermissionError()
      }

      // no records found
      if (response.status == 404) {
        return {}
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    return data.templateInstance
  })
}

function addNewTemplateGroup(templateGroup) {
  let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'))
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify({ templateGroup, jurisdictionId: jurisdiction.id })
  }

  return fetch(`${config.apiUrl}/1.4/templates/templategroup`, requestOptions)
    .then(handleNewTemplateGroupResponse)
    .then(templateGroup => {
      return templateGroup
    })
}

function handleNewTemplateGroupResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      if (response.status === 401) {
        // Redirect if 401 response returned from api
        return handlePermissionError()
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    return data.templateGroup
  })
}

function addNewTemplateContainer(templateContainer) {
  let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'))
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify({ templateContainer, jurisdictionId: jurisdiction.id })
  }

  return fetch(
    `${config.apiUrl}/1.4/templates/templatecontainer`,
    requestOptions
  )
    .then(handleNewTemplateContainerResponse)
    .then(templateContainer => {
      return templateContainer
    })
}

function handleNewTemplateContainerResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      if (response.status === 401) {
        // Redirect if 401 response returned from api
        return handlePermissionError()
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    return data.templateContainer
  })
}

function addNewTemplate(template) {
  let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'))
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify({ template, jurisdictionId: jurisdiction.id })
  }

  return fetch(`${config.apiUrl}/1.4/templates/template`, requestOptions)
    .then(handleNewTemplateResponse)
    .then(template => {
      return template
    })
}

function handleNewTemplateResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      if (response.status === 401) {
        // Redirect if 401 response returned from api
        return handlePermissionError()
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    return data.template
  })
}

function copyTemplateFromExisting(templateId) {
  let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'))
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify({ jurisdictionId: jurisdiction.id })
  }

  return fetch(
    `${config.apiUrl}/1.4/templates/template/copyfrom/${templateId}`,
    requestOptions
  )
    .then(handleCopyTemplateFromExistingResponse)
    .then(template => {
      return template
    })
}

function handleCopyTemplateFromExistingResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      if (response.status === 401) {
        // Redirect if 401 response returned from api
        return handlePermissionError()
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    return data.template
  })
}

function addNewTemplateInstance(templateInstance) {
  let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'))
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify({ templateInstance, jurisdictionId: jurisdiction.id })
  }

  return fetch(
    `${config.apiUrl}/1.4/templates/templateinstance`,
    requestOptions
  )
    .then(handleNewTemplateInstanceResponse)
    .then(templateInstance => {
      return templateInstance
    })
}

function handleNewTemplateInstanceResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      if (response.status === 401) {
        // Redirect if 401 response returned from api
        return handlePermissionError()
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    return data.templateInstance
  })
}

function updateTemplateGroup(templateGroup) {
  let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'))
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify({ templateGroup, jurisdictionId: jurisdiction.id })
  }

  return fetch(
    `${config.apiUrl}/1.4/templates/templategroup/${templateGroup.id}`,
    requestOptions
  )
    .then(handleUpdateTemplateGroupResponse)
    .then(templateGroup => {
      return templateGroup
    })
}

function handleUpdateTemplateGroupResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      if (response.status === 401) {
        // Redirect if 401 response returned from api
        return handlePermissionError()
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    return data.templateGroup
  })
}

function updateTemplateContainer(templateContainer) {
  let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'))
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify({ templateContainer, jurisdictionId: jurisdiction.id })
  }

  return fetch(
    `${config.apiUrl}/1.4/templates/templatecontainer/${templateContainer.id}`,
    requestOptions
  )
    .then(handleUpdateTemplateContainerResponse)
    .then(templateContainer => {
      return templateContainer
    })
}

function handleUpdateTemplateContainerResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      if (response.status === 401) {
        // Redirect if 401 response returned from api
        return handlePermissionError()
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    return data.templateContainer
  })
}

function updateTemplate(template) {
  let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'))
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify({ template, jurisdictionId: jurisdiction.id })
  }

  return fetch(
    `${config.apiUrl}/1.4/templates/template/${template.id}`,
    requestOptions
  )
    .then(handleUpdateTemplateResponse)
    .then(template => {
      return template
    })
}

function handleUpdateTemplateResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      if (response.status === 401) {
        // Redirect if 401 response returned from api
        return handlePermissionError()
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    return data.template
  })
}

function updateTemplateInstance(templateInstance) {
  let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'))
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify({ templateInstance, jurisdictionId: jurisdiction.id })
  }

  return fetch(
    `${config.apiUrl}/1.4/templates/templateinstance/${templateInstance.id}`,
    requestOptions
  )
    .then(handleUpdateTemplateInstanceResponse)
    .then(templateInstance => {
      return templateInstance
    })
}

function handleUpdateTemplateInstanceResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      if (response.status === 401) {
        // Redirect if 401 response returned from api
        return handlePermissionError()
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    return data.templateInstance
  })
}

function deleteTemplateGroup(templateGroupId) {
  let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'))
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify({ jurisdictionId: jurisdiction.id })
  }

  return fetch(
    `${config.apiUrl}/1.4/templates/templategroup/${templateGroupId}`,
    requestOptions
  )
    .then(handleDeleteTemplateGroupResponse)
    .then(status => {
      return status
    })
}

function handleDeleteTemplateGroupResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      if (response.status === 401) {
        // Redirect if 401 response returned from api
        return handlePermissionError()
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    return data.status
  })
}

function deleteTemplateContainer(templateContainerId) {
  let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'))
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify({ jurisdictionId: jurisdiction.id })
  }

  return fetch(
    `${config.apiUrl}/1.4/templates/templatecontainer/${templateContainerId}`,
    requestOptions
  )
    .then(handleDeleteTemplateContainerResponse)
    .then(status => {
      return status
    })
}

function handleDeleteTemplateContainerResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      if (response.status === 401) {
        // Redirect if 401 response returned from api
        return handlePermissionError()
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    return data.status
  })
}

function deleteTemplate(templateId) {
  let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'))
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify({ jurisdictionId: jurisdiction.id })
  }

  return fetch(
    `${config.apiUrl}/1.4/templates/template/${templateId}`,
    requestOptions
  )
    .then(handleDeleteTemplateResponse)
    .then(status => {
      return status
    })
}

function handleDeleteTemplateResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      if (response.status === 401) {
        // Redirect if 401 response returned from api
        return handlePermissionError()
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    return data.status
  })
}

function deleteTemplateInstance(templateInstanceId) {
  let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'))
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify({ jurisdictionId: jurisdiction.id })
  }

  return fetch(
    `${config.apiUrl}/1.4/templates/templateinstance/${templateInstanceId}`,
    requestOptions
  )
    .then(handleDeleteTemplateInstanceResponse)
    .then(status => {
      return status
    })
}

function handleDeleteTemplateInstanceResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      if (response.status === 401) {
        // Redirect if 401 response returned from api
        return handlePermissionError()
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    return data.status
  })
}

function publishTemplateVersion(templateContainerId, version) {
  let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'))
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify({ jurisdictionId: jurisdiction.id })
  }

  return fetch(
    `${config.apiUrl}/1.4/templates/template/switch/${templateContainerId}/${version}`,
    requestOptions
  )
    .then(handlePublishTemplateVersionResponse)
    .then(template => {
      return template
    })
}

function handlePublishTemplateVersionResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      if (response.status === 401) {
        // Redirect if 401 response returned from api
        return handlePermissionError()
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    return data.template
  })
}

function unpublishTemplate(templateContainerId) {
  let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'))
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify({ jurisdictionId: jurisdiction.id })
  }

  return fetch(
    `${config.apiUrl}/1.4/templates/template/unpublish/${templateContainerId}?avoidCache=true`,
    requestOptions
  )
    .then(handleUnpublishTemplateResponse)
    .then(templateContainerId => {
      return templateContainerId
    })
}

function handleUnpublishTemplateResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      if (response.status === 401) {
        // Redirect if 401 response returned from api
        return handlePermissionError()
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    return data.templateContainerId
  })
}

function publishTemplateInstance(templateInstanceId) {
  let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'))
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify({ jurisdictionId: jurisdiction.id })
  }

  return fetch(
    `${config.apiUrl}/1.4/templates/templateinstance/publish/${templateInstanceId}?avoidCache=true`,
    requestOptions
  )
    .then(handlePublishTemplateInstanceResponse)
    .then(templateInstance => {
      return templateInstance
    })
}

function handlePublishTemplateInstanceResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      if (response.status === 401) {
        // Redirect if 401 response returned from api
        return handlePermissionError()
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    return data.templateInstance
  })
}

function unpublishTemplateInstance(templateInstanceId) {
  let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'))
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify({ jurisdictionId: jurisdiction.id })
  }

  return fetch(
    `${config.apiUrl}/1.4/templates/templateinstance/unpublish/${templateInstanceId}?avoidCache=true`,
    requestOptions
  )
    .then(handleUnpublishTemplateInstanceResponse)
    .then(templateInstance => {
      return templateInstance
    })
}

function handleUnpublishTemplateInstanceResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      if (response.status === 401) {
        // Redirect if 401 response returned from api
        return handlePermissionError()
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    return data.templateInstance
  })
}

function determineTemplateInstanceConflicts(templateInstanceId) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  }

  return fetch(
    `${config.apiUrl}/1.4/templates/conflicts/${templateInstanceId}`,
    requestOptions
  )
    .then(handleTemplateInstanceConflictsResponse)
    .then(conflicts => {
      return conflicts
    })
}

function handleTemplateInstanceConflictsResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      if (response.status === 401) {
        // Redirect if 401 response returned from api
        return handlePermissionError()
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    return data.conflicts
  })
}

function determineTemplateConflicts(templateId) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  }

  return fetch(
    `${config.apiUrl}/1.4/templates/templateconflicts/${templateId}`,
    requestOptions
  )
    .then(handleTemplateConflictsResponse)
    .then(conflicts => {
      return conflicts
    })
}

function handleTemplateConflictsResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      if (response.status === 401) {
        // Redirect if 401 response returned from api
        return handlePermissionError()
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    return data.conflicts
  })
}
