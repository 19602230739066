import React from 'react'
import Close from '@material-ui/icons/Close'
import classNames from 'classnames'
import cx from 'classnames'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Snack from '@material-ui/core/SnackbarContent'
import styles from 'assets/jss/material-dashboard-pro-react/components/snackbarContentStyle'

const useStyles = makeStyles(styles)

export default function SnackbarContent(props) {
  const classes = useStyles()
  const { message, color, close, icon, rtlActive } = props
  const messageClasses = classNames({
    [classes.iconMessage]: icon !== undefined
  })
  const action =
    close === undefined
      ? []
      : [
          <IconButton
            className={classes.iconButton}
            key="close"
            aria-label="Close"
            color="inherit"
          >
            <Close className={classes.close} />
          </IconButton>
        ]
  const iconClasses = cx({
    [classes.icon]: classes.icon,
    [classes.infoIcon]: color === 'info',
    [classes.successIcon]: color === 'success',
    [classes.warningIcon]: color === 'warning',
    [classes.dangerIcon]: color === 'danger',
    [classes.primaryIcon]: color === 'primary',
    [classes.roseIcon]: color === 'rose'
  })
  return (
    <Snack
      message={
        <div>
          {icon !== undefined ? <props.icon className={iconClasses} /> : null}
          <span className={messageClasses}>{message}</span>
        </div>
      }
      classes={{
        root: classes.root + ' ' + classes[color],
        message: classes.message
      }}
      action={action}
    />
  )
}

SnackbarContent.defaultProps = {
  color: 'info'
}

SnackbarContent.propTypes = {
  message: PropTypes.node.isRequired,
  color: PropTypes.oneOf([
    'info',
    'success',
    'warning',
    'danger',
    'primary',
    'rose'
  ]),
  close: PropTypes.bool,
  icon: PropTypes.object
}
