/* eslint-disable */

import config from 'config'
import { authHeader } from '../helpers'
import { handlePermissionError } from './shared'

export const reportService = {
  getAuditLogs,
  getImpactLogs,
  getTimeFilters
}

const fetch = window.fetch

function getAuditLogs(params = {}) {
  let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'))
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  let isFirstParameter = true

  let path = `${config.apiUrl}/1.4/audit-logs`
  if (jurisdiction) {
    path = `${path}${isFirstParameter ? '?' : '&'}jurisdictionId=${
      jurisdiction.id
    }`
    isFirstParameter = false
  }

  if (params) {
    if (params.logTypes) {
      if (!Array.isArray(params.logTypes)) {
        path = `${path}${isFirstParameter ? '?' : '&'}logTypes=${
          params.logTypes
        }`
        isFirstParameter = false
      } else {
        if (params.logTypes.length > 0) {
          path = `${path}${
            isFirstParameter ? '?' : '&'
          }logTypes=${params.logTypes.join(',')}`
          isFirstParameter = false
        }
      }
    }

    if (params.timeFilterMode) {
      path = `${path}${isFirstParameter ? '?' : '&'}timeFilterMode=${
        params.timeFilterMode
      }`
      isFirstParameter = false
    }

    if (params.fromDate) {
      path = `${path}${
        isFirstParameter ? '?' : '&'
      }fromDate=${params.fromDate.toISOString()}`
      isFirstParameter = false
    }

    if (params.toDate) {
      path = `${path}${
        isFirstParameter ? '?' : '&'
      }toDate=${params.toDate.toISOString()}`
      isFirstParameter = false
    }

    if (params.timeZone) {
      path = `${path}${isFirstParameter ? '?' : '&'}timeZone=${params.timeZone}`
      isFirstParameter = false
    }

    if (params.actions) {
      if (!Array.isArray(params.actions)) {
        path = `${path}${isFirstParameter ? '?' : '&'}actions=${params.actions}`
        isFirstParameter = false
      } else {
        if (params.actions.length > 0) {
          path = `${path}${
            isFirstParameter ? '?' : '&'
          }logTypes=${params.actions.join(',')}`
          isFirstParameter = false
        }
      }
    }

    if (params.userId) {
      path = `${path}${isFirstParameter ? '?' : '&'}userId=${params.userId}`
      isFirstParameter = false
    }

    if (params.exposeAuthUser !== undefined) {
      path = `${path}${isFirstParameter ? '?' : '&'}exposeAuthUser=${
        params.exposeAuthUser ? 'true' : 'false'
      }`
      isFirstParameter = false
    }

    if (params.exposeResources !== undefined) {
      path = `${path}${isFirstParameter ? '?' : '&'}exposeResources=${
        params.exposeResources ? 'true' : 'false'
      }`
    }
  }

  return fetch(path, requestOptions).then(handleGetAuditLogsResponse)
}

function handleGetAuditLogsResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      // unauthorised
      if (response.status === 401) {
        // Redirect if 401 response returned from api
        return handlePermissionError()
      }

      // no records found
      if (response.status == 404) {
        return []
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    return data.report
  })
}

function getImpactLogs(params = {}) {
  let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'))
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  let isFirstParameter = true

  let path = `${config.apiUrl}/1.4/impact-logs`
  if (jurisdiction) {
    path = `${path}${isFirstParameter ? '?' : '&'}jurisdictionId=${
      jurisdiction.id
    }`
    isFirstParameter = false
  }

  if (params) {
    if (params.timeFilterMode) {
      path = `${path}${isFirstParameter ? '?' : '&'}timeFilterMode=${
        params.timeFilterMode
      }`
      isFirstParameter = false
    }

    if (params.fromDate) {
      path = `${path}${
        isFirstParameter ? '?' : '&'
      }fromDate=${params.fromDate.toISOString()}`
      isFirstParameter = false
    }

    if (params.toDate) {
      path = `${path}${
        isFirstParameter ? '?' : '&'
      }toDate=${params.toDate.toISOString()}`
      isFirstParameter = false
    }

    if (params.timeZone) {
      path = `${path}${isFirstParameter ? '?' : '&'}timeZone=${params.timeZone}`
      isFirstParameter = false
    }
  }

  return fetch(path, requestOptions).then(handleGetImpactLogsResponse)
}

function handleGetImpactLogsResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      // unauthorised
      if (response.status === 401) {
        // Redirect if 401 response returned from api
        return handlePermissionError()
      }

      // no records found
      if (response.status == 404) {
        return []
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    let report = data.report.results

    report = report.map(row => {
      row.affected = convertAffectedToHumanReadable(row.affected)
      return row
    })

    return data.report
  })
}

function convertAffectedToHumanReadable(affected) {
  let response = { text: [], ...affected }

  Object.keys(affected)
    .sort()
    .forEach(priority => {
      let configurations = affected[priority]
      configurations.forEach(configuration => {
        if (configuration.impacts['lots']) {
          response.text.push({
            entry: `The entire lot`,
            priority: Number(priority),
            signConfigId: configuration.signConfig,
            templateId: configuration.templateId,
            templateInstanceId: configuration.templateInstanceId,
            attributes: configuration.attributes
          })
        }

        if (configuration.impacts['bayGroups']) {
          response.text.push({
            entry: `${configuration.impacts['bayGroups'].length} bay group${
              configuration.impacts['bayGroups'].length > 1 ? 's' : ''
            }`,
            priority: Number(priority),
            signConfigId: configuration.signConfig,
            templateId: configuration.templateId,
            templateInstanceId: configuration.templateInstanceId,
            attributes: configuration.attributes
          })
        }

        if (configuration.impacts['pamReferenceIds']) {
          response.text.push({
            entry: `${configuration.impacts['pamReferenceIds'].length} bay${
              configuration.impacts['pamReferenceIds'].length > 1 ? 's' : ''
            }`,
            priority: Number(priority),
            signConfigId: configuration.signConfig,
            templateId: configuration.templateId,
            templateInstanceId: configuration.templateInstanceId,
            attributes: configuration.attributes
          })
        }
      })
    })
  return response
}

function getTimeFilters(params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  let isFirstParameter = true

  let path = `${config.apiUrl}/1.4/audit-logs/time-filters`

  if (params.includeNow !== undefined) {
    path = `${path}${isFirstParameter ? '?' : '&'}includeNow=${
      params.includeNow ? 'true' : 'false'
    }`
    isFirstParameter = false
  }

  if (params.includePast !== undefined) {
    path = `${path}${isFirstParameter ? '?' : '&'}includePast=${
      params.includePast ? 'true' : 'false'
    }`
    isFirstParameter = false
  }

  if (params.includeFuture !== undefined) {
    path = `${path}${isFirstParameter ? '?' : '&'}includeFuture=${
      params.includeFuture ? 'true' : 'false'
    }`
    isFirstParameter = false
  }

  if (params.includeCustom !== undefined) {
    path = `${path}${isFirstParameter ? '?' : '&'}includeCustom=${
      params.includeCustom ? 'true' : 'false'
    }`
    isFirstParameter = false
  }

  if (params.includeNone !== undefined) {
    path = `${path}${isFirstParameter ? '?' : '&'}includeNone=${
      params.includeNone ? 'true' : 'false'
    }`
  }

  return fetch(path, requestOptions).then(handleGetTimeFiltersResponse)
}

function handleGetTimeFiltersResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      // unauthorised
      if (response.status === 401) {
        // Redirect if 401 response returned from api
        return handlePermissionError()
      }

      // no records found
      if (response.status == 404) {
        return []
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    return data.filters
  })
}
