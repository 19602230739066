import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Config, ConfigVersion, StaticFileData } from 'features/config/types/config'
import baseReducer from 'features/baseReducer'
import { ConfigSlice } from 'features/config/types/slice'
import { ICustomer } from 'features/config/types/customer'
import { ConfigEnvironment } from 'config'


export const initialState: ConfigSlice = {
    init: false,
    loading: false,
    requestId: null,
    customers: [],
    selectedCustomerId: null,
    config: null,
    configLoading: false,
    configVersions: [],
    stageVersions: [],
    developmentVersions: [],
    selectedConfigVersion: null,
    selectedConfigEnvironment: null,
    staticFileData: [],
    staticFilesLoading: false,
    staticFilesSaving: false
}

const configSlice = createSlice({
    initialState,
    name: 'config',
    reducers: {
        ...baseReducer,
        reset: (state) => {
            for (let key in initialState) {
                state[key] = initialState[key]
            }
        },

        setInit: (state, {payload}: PayloadAction<boolean>) => {
            state.init = payload
        },
        setLoading: (state, {payload}: PayloadAction<boolean>) => {
            state.loading = payload
        },
        setRequestId: (state, {payload}: PayloadAction<string | null>) => {
            state.requestId = payload
        },
        setCustomers: (state, {payload}: PayloadAction<ICustomer[]>) => {
            state.customers = payload
        },
        setSelectedCustomerId: (
            state,
            {payload}: PayloadAction<number | null>
        ) => {
            state.selectedCustomerId = payload
        },
        setConfig: (state, {payload}: PayloadAction<Config | null>) => {
            state.config = payload
        },
        setConfigLoading: (state, {payload}: PayloadAction<boolean>) => {
            state.configLoading = payload
        },
        setConfigVersions: (state, {payload}: PayloadAction<ConfigVersion[]>) => {
            state.configVersions = payload
        },
        setStageVersions: (state, {payload}: PayloadAction<ConfigVersion[]>) => {
            state.stageVersions = payload
        },
        setDevelopmentVersions: (state, {payload}: PayloadAction<ConfigVersion[]>) => {
            state.developmentVersions = payload
        },
        setSelectedConfigVersion: (state, {payload}: PayloadAction<string | null>) => {
            state.selectedConfigVersion = payload
        },
        setSelectedConfigEnvironment: (state, { payload }: PayloadAction<ConfigEnvironment|null>)=> {
            state.selectedConfigEnvironment = payload
        },
        setStaticFileData: (state, {payload}: PayloadAction<StaticFileData[]>) => {
            state.staticFileData = payload
        },
        setStaticFilesLoading: (state, {payload}: PayloadAction<boolean>) => {
            state.staticFilesLoading = payload
        },
        setStaticFilesSaving: (state, {payload}: PayloadAction<boolean>) => {
            state.staticFilesSaving = payload
        }
    }
})

export const {actions, name, reducer} = configSlice
