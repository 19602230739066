import { DateTime, IANAZone, Zone } from 'luxon'
import { v4 as uuidv4 } from 'uuid'
import {
    Category,
    DateRange,
    DayOfTheWeek,
    DayRange,
    LineGroup,
    OccupancyDatum,
    OccupancyReport,
    OccupancySeries,
    OverflowDatum,
    ReportGroupBy,
    ReportSeriesBase,
    ReportState,
    ReportType,
    SeriesData,
    StatisticType,
    WeekRange,
    WorkerParams
} from 'features/resource/types'
import {
    colors,
    evColors,
    MS_PER_DAY,
    MS_PER_WEEK,
    overflowColors
} from 'constants/constants'
import cyclicIndex from 'utils/cyclicIndex'
import { displayShortDate } from 'utils/dateFormat'
import { Epoch, EpochUtc } from 'types/generics'
import { ParkingStatInterval } from 'features/resource/types/parkingStat'
import { ReportTypeStatisticMap } from 'features/resource/mappings'
import { sortAsc } from 'utils/sortBy'

//-- Report Type utils
const overflowReportTypes = [ReportType.profileComparison]

// prettier-ignore
// @ts-ignore - WHY DOES THIS MAKE YOU MAD, TYPESCRIPT?! WHAT DO YOU WANT FROM ME?!
export const isOverflow = (reportType: ReportType | StatisticType): boolean => overflowReportTypes.includes(reportType)

export const isHistogram = (reportType: ReportType | StatisticType): boolean =>
    ReportType.profileComparison === reportType

export const isPrediction = (reportType: ReportType | StatisticType): boolean =>
    ReportType.predictive === reportType

export const isEv = (reportType: ReportType | StatisticType): boolean =>
    ReportType.ev === reportType

export const isFinancial = (reportType: string): boolean => 'financial' === reportType

export const categoryDisplayName = (category: Category): string => {
    let value = 'Visitor'
    try {
        const parsed = JSON.parse(category).permitType || ''
        return !parsed
            ? value
            : parsed.replaceAll('Permit Type ', '').replaceAll('Permit', '').trim()
    } catch (error) {
        return value
    }
}

//-- Date utils

export const getTzOffsetMs = (zoneName: string) => {
    const assetZone = IANAZone.create(zoneName)
    return getZoneOffsetMs(assetZone) - getMyTzOffsetMs()
}

export const getZoneOffsetMs = (zone: Zone): number => {
    const offsetMinutes = zone.offset(Date.now())
    return offsetMinutes * 60 * 1000
}

export const getMyTzOffsetMs = (): number => {
    return getZoneOffsetMs(DateTime.now().zone)
}

export const getClosestMonday = (epoch: Epoch, zoneName: string): DateTime => {
    const original = DateTime.fromMillis(epoch, {zone: zoneName})
    let target = original.set({
        weekday: 1,
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 1
    })
    // If it jumped to next week, go back a week
    if (target.toMillis() > epoch) {
        target = target.minus({days: 7})
    }
    return target
}

export const weekStartsForDateRanges = (
    weekStarts: Epoch[],
    dateRanges: DateRange[],
    tzOffsetMs: number
): Epoch[] => {
    if (!(weekStarts.length && dateRanges.length)) {
        return []
    }
    // const tzOffsetMs = getTzOffsetMs(weekStarts[0])
    // const getClosestWeekStart = closest(weekStarts)
    let matchingWeekStarts = new Set()

    dateRanges.forEach(dateRange => {
        const minDate = dateRange.start - MS_PER_WEEK
        const maxDate = dateRange.end + tzOffsetMs + MS_PER_WEEK
        const weekStartsInRange = weekStarts.filter(
            weekStart => weekStart >= minDate && weekStart <= maxDate
        )
        weekStartsInRange.forEach(weekStart => matchingWeekStarts.add(weekStart))
    })
    return [...matchingWeekStarts].filter(Boolean).sort(sortAsc) as Epoch[]
}

/**
 * @func dateRangeSelectionsToWeekRanges - Transpose DateRange[] -> WeekRange[]. Used when grouping by week
 */
export const dateRangeSelectionsToWeekRanges = (
    dateRangeSelections: DateRange[],
    weekStarts: EpochUtc[],
    tzOffsetMs: number,
    zoneName: string
): WeekRange[] => {
    let weekRanges: WeekRange[] = []
    for (let dateRange of dateRangeSelections) {
        weekRanges = weekRanges.concat(
            dateRangeToWeekRanges(dateRange, weekStarts, tzOffsetMs, zoneName)
        )
    }
    return weekRanges
}

const dateRangeToWeekRanges = (
    dateRange: DateRange,
    weekStarts: number[],
    tzOffsetMs: number,
    zoneName: string
): WeekRange[] => {
    const start = DateTime.fromMillis(dateRange.start)
        .setZone(zoneName)
        .set({hours: 0, minutes: 0, seconds: 0, milliseconds: 0})
        .toMillis()
    const end = DateTime.fromMillis(dateRange.end)
        .setZone(zoneName)
        .set({hours: 23, minutes: 59, seconds: 59, milliseconds: 999})
        .toMillis()
    const weekStartsForRange = getMatchingWeekStarts(
        {start, end},
        weekStarts
    ).sort()
    return weekStartsForRange
        .map((base, index) => {
            const rangeStart = index === 0 ? start : base
            const rangeEnd = base + (MS_PER_WEEK - 1)
            return {
                ...dateRange,
                base,
                end: rangeEnd,
                start: rangeStart,
                tzOffsetMs
            }
        })
        .filter(({end, start}) => end > start)
}

const _getClosestWeekStart = (weekStarts: number[]) => {
    const list = [...weekStarts].sort().reverse()
    return (epoch: number): number => {
        return list.find(base => base < epoch)
    }
}

const getMatchingWeekStarts = (
    {start, end}: { start: number; end: number },
    weekStarts: number[]
) => {
    return weekStarts.filter(base => base < end && base >= start - MS_PER_WEEK)
}

/**
 * @func dateRangeSelectionsToDayRanges - Transpose dateRange[] -> DayRange[]. Used when grouping by day
 */
export const dateRangeSelectionsToDayRanges = (
    dateRangeSelections: DateRange[],
    weekStarts: EpochUtc[],
    tzOffsetMs: number,
    daysOfTheWeek: DayOfTheWeek[],
    zoneName: string
): DayRange[] => {
    let weekRanges: DayRange[] = []
    for (let dateRange of dateRangeSelections) {
        weekRanges = weekRanges.concat(
            dateRangeToDayRange(
                dateRange,
                weekStarts,
                tzOffsetMs,
                daysOfTheWeek,
                zoneName
            )
        )
    }
    return weekRanges
}

export const dateRangeToDayRange = (
    dateRange: DateRange,
    weekStarts: number[],
    tzOffsetMs: number,
    daysOfTheWeek: DayOfTheWeek[],
    zoneName: string
): DayRange[] => {
    const start: number = DateTime.fromMillis(dateRange.start)
        .setZone(zoneName)
        .set({hours: 0, minutes: 0, seconds: 0, milliseconds: 0})
        .toMillis()
    const end: number = DateTime.fromMillis(dateRange.end)
        .setZone(zoneName)
        .set({hours: 23, minutes: 59, seconds: 59, milliseconds: 999})
        .toMillis()
    const weekStartsForRange = getMatchingWeekStarts(
        {start, end},
        weekStarts
    ).sort()
    let dayRanges: DayRange[] = []
    for (let base of weekStartsForRange) {
        let dayIndex = 0
        while (dayIndex < 7) {
            const dayStartEpoch = base + MS_PER_DAY * dayIndex
            const dayEndEpoch = dayStartEpoch + (MS_PER_DAY - 1)
            const dayStartDt = DateTime.fromMillis(dayStartEpoch, {zone: zoneName})
            const {weekday} = dayStartDt
            if (
                daysOfTheWeek.includes(weekday) &&
                dayStartEpoch >= start &&
                dayEndEpoch <= end
            ) {
                dayRanges.push({
                    ...dateRange,
                    base,
                    end: dayEndEpoch,
                    start: dayStartEpoch,
                    tzOffsetMs,
                    weekday
                })
            }
            dayIndex++
        }
    }
    return dayRanges
}


export interface ChartGroup extends ReportState {
    selectedResourceIds: string[]
    selectedCategories: Category[]
}


export const ReportTypeColorMap = {
    [ReportType.occupancy]: colors,
    [ReportType.predictive]: colors,
    [ReportType.profileComparison]: overflowColors,
    [ReportType.ev]: evColors
}

export const getOccupancyLineGroups = (
    slice: WorkerParams,
    chartGroup: ChartGroup,
    occupancyReport: OccupancyReport,
    zoneName: string
): LineGroup[] => {
    const {selectedResourceIds, tzOffsetMs} = chartGroup
    const {
        dateRangeSelections,
        daysOfTheWeek,
        groupBy,
        resources,
        selectedCategories,
        selectedReportType,
        weekStarts
    } = slice
    const weekRanges: WeekRange[] | DayRange[] =
        ReportGroupBy.week === groupBy
            ? dateRangeSelectionsToWeekRanges(
                dateRangeSelections,
                weekStarts,
                tzOffsetMs,
                zoneName
            )
            : dateRangeSelectionsToDayRanges(
                dateRangeSelections,
                weekStarts,
                tzOffsetMs,
                daysOfTheWeek,
                zoneName
            )

    const statisticTypes: StatisticType[] =
        ReportTypeStatisticMap[selectedReportType] ||
        ReportTypeStatisticMap[ReportType.occupancy]

    return weekRanges.reduce(
        (accum: LineGroup[], weekRange: WeekRange | DayRange, index: number) => {
            selectedResourceIds.forEach((resourceId, resourceIndex) => {
                const baseUtc: EpochUtc = DateTime.fromMillis(weekRange.base)
                    .toUTC()
                    .toMillis()
                const resourceName = resources[resourceId].name
                let statisticTypesWithData: [
                    string,
                    ReportSeriesBase<OccupancyDatum>[]
                ][] = Object.entries(occupancyReport.reports[resourceId]).filter(
                    ([type, list]) => {
                        const seriesList = list.filter(Boolean).find(({series}) => {
                            // Search for the first series with a dataset
                            return Object.values(series).find(seriesList =>
                                Boolean(seriesList.length)
                            )
                        })
                        return seriesList ? type : false
                    }
                )
                if (
                    [ReportType.occupancy, ReportType.predictive].includes(
                        selectedReportType
                    ) &&
                    selectedCategories.length
                ) {
                    statisticTypesWithData = statisticTypesWithData.filter(
                        ([type, _]) => type === StatisticType.tenantOccupancyProfile
                    )
                }
                if (!(statisticTypesWithData && statisticTypesWithData.length)) {
                    // The report dataset was empty for the available statistic, just default to the
                    // first statisticType & use an empty dataset
                    statisticTypesWithData = [[statisticTypes[0], []]]
                }
                const [statisticType, _] = statisticTypesWithData[0]
                const reportData = occupancyReport.reports[resourceId][
                    statisticType
                    ]?.find(({base}) => base === weekRange.base)
                if (!reportData) {
                    return
                }
                const seriesBase = reportData!.base as number
                const seriesCategoryMap = reportData!
                    .series as unknown as OccupancySeries<OccupancyDatum>
                const categoryKeys = selectedCategories.length
                    ? selectedCategories
                    : ['overall', '{}', '*', '_DIRECT_']
                const capacity = reportData!.capacity || (null as number | null)

                categoryKeys.forEach((category: string, categoryIndex: number) => {
                    if (!(category in seriesCategoryMap)) {
                        return
                    }
                    const color = cyclicIndex(
                        ReportTypeColorMap[selectedReportType] || colors,
                        index + resourceIndex + categoryIndex
                    )
                    const dateRangeForSerialization = {
                        ...weekRange,
                        base: seriesBase
                    }
                    const series = (seriesCategoryMap[category] ?? []) as OccupancyDatum[]
                    const seriesData: SeriesData[] =
                        ReportGroupBy.week === groupBy
                            ? serializeLineDataForWeek(
                                series,
                                dateRangeForSerialization as WeekRange,
                                resourceName,
                                tzOffsetMs,
                                capacity
                            )
                            : serializeLineDataForDay(
                                series,
                                weekRange as DayRange,
                                resourceName,
                                tzOffsetMs,
                                capacity
                            )
                    const lineGroup: LineGroup = {
                        base: weekRange.base,
                        baseUtc,
                        category: ['overall', '{}', '*', '_DIRECT_'].includes(category)
                            ? undefined
                            : category,
                        color,
                        dateRange: weekRange,
                        displayName: lineGroupDisplayName(resourceName, weekRange),
                        id: uuidv4(),
                        index: index + resourceIndex + categoryIndex,
                        isEmpty: !seriesData.length,
                        resourceId,
                        resourceName,
                        start: weekRange.start,
                        style: {
                            data: {
                                stroke: color
                            },
                            labels: {
                                fontSize: 10,
                                fill: color
                            }
                        },
                        seriesData,
                        type: statisticType as StatisticType,
                        tzOffsetMs
                    }
                    accum.push(lineGroup)
                })
            })
            return accum
        },
        []
    )
}

export const lineGroupDisplayName = (
    resourceName: string,
    dateRange: DateRange
): string => {
    const {start, end} = dateRange
    return `${resourceName} ${displayShortDate(
        DateTime.fromMillis(start)
    )} - ${displayShortDate(DateTime.fromMillis(end))}`
}

const serializeLineDataForWeek = (
    data: OccupancyDatum[] | OverflowDatum[],
    weekRange: WeekRange,
    resourceName: string,
    tzOffsetMs: number,
    capacity: number | null
): SeriesData[] => {
    const now = DateTime.now().toMillis()
    // prettier-ignore
    // @ts-ignore
    return data.filter(({sb}) => {
            const timestamp = weekRange.base + sb * 1000
            return (
                weekRange.start <= timestamp &&
                weekRange.end >= timestamp &&
                timestamp <= now
            )
        })
        .map(datum => {
            const timestamp = weekRange.base + datum.sb * 1000
            if ('or' in datum) {
                const {c, o, or, sb} = datum
                const y = Math.round(or * 1000) / 10
                return {
                    ...datum,
                    y,
                    x: sb,
                    base: weekRange.base,
                    timestamp,
                    timestampUtc: timestamp + tzOffsetMs,
                    displayName: resourceName,
                    c,
                    o,
                    or,
                    sb,
                    tzOffsetMs
                } as SeriesData
            }
            // of = overflow count
            // oc = occupied space count
            // sb = secondsBase
            const {oc, of, sb} = datum
            return {
                ...datum,
                y: oc,
                x: sb,
                base: weekRange.base,
                timestamp,
                timestampUtc: timestamp + tzOffsetMs,
                displayName: resourceName,
                of,
                oc,
                sb,
                tzOffsetMs
            } as SeriesData
        })
}

const serializeLineDataForDay = (
    data: OccupancyDatum[] | OverflowDatum[],
    dayRange: DayRange,
    resourceName: string,
    tzOffsetMs: number,
    capacity: number | null
): SeriesData[] => {
    const now = DateTime.now().toMillis()
    // prettier-ignore
    // @ts-ignore
    return data.filter(({sb}) => {
            const timestamp = dayRange.base + sb * 1000
            return (
                dayRange.start <= timestamp &&
                dayRange.end >= timestamp &&
                timestamp <= now
            )
        })
        .map(datum => {
            const timestamp = dayRange.base + datum.sb * 1000
            const x = DateTime.fromMillis(timestamp)
                .diff(DateTime.fromMillis(dayRange.start))
                .as('hours')
            if ('or' in datum) {
                const {c, o, or, sb} = datum
                const y = Math.round(or * 1000) / 10
                return {
                    ...datum,
                    y,
                    x,
                    base: dayRange.base,
                    timestamp,
                    timestampUtc: timestamp + tzOffsetMs,
                    displayName: resourceName,
                    c,
                    o,
                    or,
                    sb,
                    tzOffsetMs
                } as SeriesData
            }
            // of = overflow count
            // oc = occupied space count
            // sb = secondsBase
            const {oc, of, sb} = datum
            return {
                ...datum,
                y: oc,
                x,
                base: dayRange.base,
                timestamp,
                timestampUtc: timestamp + tzOffsetMs,
                displayName: resourceName,
                of,
                oc,
                sb,
                tzOffsetMs
            } as SeriesData
        })
}


export function secondsToHumanString(durationSeconds: number): string {
    if (durationSeconds < 3600) {
        const [minutes, seconds] = new Date(durationSeconds * 1000)
            .toISOString()
            .substring(14, 19)
            .split(':')
            .map(Number)
        const roundedMinutes = seconds >= 30 ? minutes + 1 : minutes
        return roundedMinutes ? `${roundedMinutes} minutes` : `${seconds} seconds`
    }

    const [hours, minutes, seconds] = new Date(durationSeconds * 1000)
        .toISOString()
        .substring(11, 19)
        .split(':')
        .map(Number)
    const roundedMinutes = seconds >= 30 ? minutes + 1 : minutes
    return `${hours} hours, ${roundedMinutes} minutes`
}


export function secondsToHours(durationSeconds: number): number {
    const [hours, minutes, seconds] = new Date(durationSeconds * 1000)
        .toISOString()
        .substring(11, 19)
        .split(':')
        .map(Number)
    return hours
}


export function secondsToDisplayHours(durationSeconds: number): string {
    const secondsPerHour = 60 * 60
    return (durationSeconds / secondsPerHour).toFixed(0)
}


export const intervalToMs = (interval: ParkingStatInterval): number =>
    interval * (1000 * 60)

export const getMonthStartEnd = (
    summaryMonth: number,
    zoneName: string
): { monthEnd: DateTime; monthStart: DateTime } => {
    let monthStart = DateTime.now()
        .setZone(zoneName)
        .set({
            month: Math.abs(summaryMonth),
            day: 1,
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
        })
    if (summaryMonth < 0) {
        monthStart = monthStart.minus({years: 1})
    }
    const monthEnd = monthStart
        .plus({month: 1})
        .minus({day: 1})
        .set({hour: 23, minute: 59})
    return {
        monthEnd,
        monthStart
    }
}


export function msSinceMidnight(epoch: Epoch, zone: string): number {
    const dt = DateTime.fromMillis(epoch, {zone})
    const midnight = dt.set({hour: 0, minute: 0, second: 0, millisecond: 0})
    return dt.diff(midnight, 'millisecond').as('millisecond')
}
