export enum ErrorResponseCode {
  NOT_FOUND = 404,
  UNAUTHORIZED = 401,
  INVALID = 422,
  EXCEPTION = 500
}

export const responseStatusCodes = {
    incorrectFormat: 400, // Incorrect format (ex. we expected JSON, but received XML)
    unauthorized: 401, // Not authenticated, or invalid due to permissions
    forbidden: 403,
    notFound: 404,
    invalid: 409, // Invalid payload
    invalidCredentials: 422 // User tried to log in but the email/pw were incorrect
}
export default responseStatusCodes