import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { MessageForm, MessageFormData } from 'views/Forms/MessageForm/MessageForm'
import { messageActions } from 'features/message/messageSlice'



export const NewMessage = () => {
    const dispatch = useDispatch()
    const history = useHistory()


    const onSubmit = async (data: MessageFormData): Promise<void> => {
        const wasSuccessful = await dispatch(messageActions.addNewMessage(data))
        if (wasSuccessful) {
            history.push(`/message-admin`)
        }
    }

    const onCancel = () => {
        history.push(`/message-admin`)
    }

    return (
        <MessageForm
            message={null}
            onCancel={onCancel}
            onSubmit={onSubmit}
            readOnlyMode={false}
        />
    )
}
export default NewMessage