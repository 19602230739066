import { DateTime } from 'luxon'
import { DateRange } from './types'
import { getClosestMonday } from 'features/resource/reportUtils'

export const dateCeil = dateTime =>
  dateTime.set({ hours: 23, minutes: 59, seconds: 59 })

export const dateFloor = dateTime =>
  dateTime.set({ hours: 0, minutes: 0, seconds: 0, millisecond: 0 })

const now = DateTime.now()
const tonightRightBeforeMidnight = dateCeil(now)
export const startOfToday = dateFloor(now)

const yesterday = now.minus({ days: 1 })

const lastMonday = (() => {
  const original = now
  const target = original.set({
    weekday: 1,
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0
  })
  const dayDiff = original.diff(target).as('day')
  return dayDiff >= 1
    ? target
    : dayDiff < -1
    ? target.minus({ days: 7 })
    : target
})()

export interface DateRangeOptions {
  TODAY: DateRange
  THIS_WEEK: DateRange
  LAST_WEEK: DateRange
  LAST_MONTH: DateRange
  CUSTOM: DateRange
}

export const DATE_RANGE_OPTIONS: DateRangeOptions = {
  TODAY: {
    dateRangeId: 0,
    end: tonightRightBeforeMidnight.toMillis(),
    id: 0,
    isCustom: false,
    label: 'Today',
    start: startOfToday.toMillis()
  },
  THIS_WEEK: {
    dateRangeId: 2,
    end: tonightRightBeforeMidnight.toMillis(),
    id: 2,
    isCustom: false,
    label: 'This Week',
    start: lastMonday.toMillis()
  },
  LAST_WEEK: {
    dateRangeId: 1,
    end: lastMonday.minus({ days: 1 }).toMillis(),
    id: 1,
    isCustom: false,
    label: 'Last Week',
    start: lastMonday.minus({ days: 7 }).toMillis()
  },
  LAST_MONTH: {
    dateRangeId: 3,
    end: tonightRightBeforeMidnight.toMillis(),
    id: 3,
    isCustom: false,
    label: 'Last 28 Days',
    start: startOfToday.minus({ days: 28 }).toMillis()
  },
  CUSTOM: {
    dateRangeId: 4,
    end: tonightRightBeforeMidnight.toMillis(),
    id: 4,
    isCustom: true,
    label: 'Custom',
    start: lastMonday.toMillis()
  }
}

export const getDateRangeOptions = (zoneName: string): DateRangeOptions => {
  const _now = DateTime.now().setZone(zoneName)
  const _tonightRightBeforeMidnight = dateCeil(_now)
  const _startOfToday = dateFloor(_now)
  const _lastMonday = getClosestMonday(_now.toMillis(), zoneName)

  return {
    TODAY: {
      dateRangeId: 0,
      end: _tonightRightBeforeMidnight.toMillis(),
      id: 0,
      isCustom: false,
      label: 'Today',
      start: _startOfToday.toMillis()
    },
    THIS_WEEK: {
      dateRangeId: 2,
      end: _tonightRightBeforeMidnight.toMillis(),
      id: 2,
      isCustom: false,
      label: 'This Week',
      start: _lastMonday.toMillis()
    },
    LAST_WEEK: {
      dateRangeId: 1,
      end: _lastMonday.minus({ days: 1 }).toMillis(),
      id: 1,
      isCustom: false,
      label: 'Last Week',
      start: _lastMonday.minus({ days: 7 }).toMillis()
    },
    LAST_MONTH: {
      dateRangeId: 3,
      end: _tonightRightBeforeMidnight.toMillis(),
      id: 3,
      isCustom: false,
      label: 'Last 28 Days',
      start: startOfToday.minus({ days: 28 }).toMillis()
    },
    CUSTOM: {
      dateRangeId: 4,
      end: _tonightRightBeforeMidnight.toMillis(),
      id: 4,
      isCustom: true,
      label: 'Custom',
      start: _lastMonday.toMillis()
    }
  }
}
