import { createSlice } from '@reduxjs/toolkit'
import { alertActions } from 'features/alert/alertSlice'
import baseReducer from 'features/baseReducer'
import { areaService } from 'services'

export const initialState = {
  areas: undefined,
  error: null,
  loading: false
}

const areaSlice = createSlice({
  initialState,
  name: 'areas',
  reducers: {
    ...baseReducer
  }
})

export const { actions, name, reducer } = areaSlice

export const getAll = processingStatuses => async dispatch => {
  dispatch(
    actions.setState({
      loading: true
    })
  )
  try {
    const areas = await areaService.outline(processingStatuses)
    const newState = {
      areas,
      loading: false,
      error: null
    }
    dispatch(actions.setState(newState))
    return newState
  } catch (error) {
    const newState = {
      error,
      loading: false,
      areas: undefined
    }
    dispatch(actions.setState(newState))
    dispatch(alertActions.error(error))
    return newState
  }
}

export const areaActions = {
  getAll
}
