import moment from 'moment-timezone'
import { DateTime, Zone } from 'luxon'
import { Epoch } from 'types/generics'
import { DateRange } from 'features/resource/types'

export function timeStampStringInLocale(locale, momentDate) {
  let formatString = 'ddd DD/MM/YYYY h:mm a'
  switch (locale) {
    case 'en-US':
      formatString = 'ddd MM/DD/YYYY h:mm a'
      break
    default:
      break
  }

  return momentDate.format(formatString)
}

export function currentTimestampInTimezone(requestedTimezone) {
  let timeZone = requestedTimezone || 'America/New_York'
  return moment.tz(new Date(), timeZone)
}

export const customRangeText = ({
  start,
  end
}: {
  start: Epoch
  end: Epoch
}): string => {
  const startText = !start ? ' ' : displayDate(DateTime.fromMillis(start))
  const endText = !end ? ' ' : displayDate(DateTime.fromMillis(end))
  return `${startText} - ${endText}`
}

/**
 * @func displayDate
 * @param {DateTime} dateTime
 * @returns {string}
 * @example
 * >>> displayDate(DateTime.now()) //=> 'Nov 24, 2021'
 */
export const displayDate = (dateTime: DateTime): string =>
  dateTime.toLocaleString(DateTime.DATE_MED)

/**
 * @func displayShortDate - Get a formatted date string, without the "year" portion
 * @param {DateTime|null} dateTime
 * @returns {string}
 * @example
 * >>> displayShortDate(DateTime.now()) //=> 'Nov 24'
 */
export const displayShortDate = (dateTime: DateTime): string =>
  dateTime?.toLocaleString({ month: 'short', day: 'numeric' }) ?? ''

export const displayShortDateRange = (
  dateRange: DateRange,
  zone: string | Zone
): string =>
  `${displayShortDate(
    DateTime.fromMillis(dateRange.start, { zone })
  )} - ${displayShortDate(DateTime.fromMillis(dateRange.end, { zone }))}`

export const displayShortDateRangeStartDay = (
  dateRange: DateRange,
  zone: string | Zone
): string =>
  DateTime.fromMillis(dateRange.start, { zone }).toLocaleString({
    weekday: 'short',
    month: 'short',
    day: 'numeric'
  })

/**
 * @func displayDateTime
 * @example
 * > displayDateTime(DateTime.now()) //=> 'May 14, 2022, 9:30 AM'
 */
export const displayDateTime = (dateTime: DateTime): string =>
  dateTime.toLocaleString(DateTime.DATETIME_MED)

/**
 * @func formatTime
 * @example
 * > displayDateTime(DateTime.now()) //=> '09:30 AM'
 */
export const formatTime = (dateTime: DateTime): string =>
  dateTime.toLocaleString(DateTime.TIME_SIMPLE)
