import React, { useEffect, useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { alertActions } from 'features/alert/alertSlice'
import { ICreateTemplateInstance, ITemplateInstance } from 'types/template/templateInstance'
import logError from 'utils/logError'
import { selectTemplateInstances } from 'actions/templateSelectors'
import { templateActions } from 'actions'
import { TemplateInstanceForm } from './TemplateInstanceForm'
import { useJurisdictionId } from 'utils/useJurisdictionId'


export interface EditTemplateInstanceProps extends Record<string, any> {
    instanceId: string
    readOnlyMode: boolean
}


export const EditTemplateInstance = ({instanceId, readOnlyMode}: EditTemplateInstanceProps) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const jurisdictionId = useJurisdictionId()
    const templateInstance: ITemplateInstance | null = useSelector(selectTemplateInstances)[instanceId] || null
    const templateId = templateInstance?.template?.id
    const containerId = templateInstance?.template?.templatecontainer

    useEffect(() => {
        dispatch(templateActions.getTemplateInstances(jurisdictionId))
    }, [])


    const onSubmit = async (data: ICreateTemplateInstance): Promise<void> => {
        try {
            await dispatch(templateActions.updateTemplateInstance({
                ...data,
                id: instanceId
            }))
            dispatch(alertActions.success('Successfully updated Template Instance'))
            history.push(`/template-admin/template-detail/${containerId}`)
        } catch (error) {
            logError(error)
            dispatch(alertActions.error('Encountered an error while attempting to update Template Instance'))
        }
        return
    }

    const onCancel = () => {
        if (containerId) {
            history.push(`/template-admin/template-detail/${containerId}`)
        } else {
            history.push(`/template-admin/`)
        }
        return
    }


    if (!(templateId && templateInstance)) {
        return <CircularProgress />
    }

    return (
        <TemplateInstanceForm
            onCancel={onCancel}
            onSubmit={onSubmit}
            readOnlyMode={readOnlyMode}
            templateId={templateId}
            templateInstance={templateInstance}
        />
    )
}
export default EditTemplateInstance