/* eslint-disable */
import React from 'react'
import { connect } from 'react-redux'
import SweetAlert from 'react-bootstrap-sweetalert'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import Tooltip from '@material-ui/core/Tooltip'
import { v4 as uuidv4 } from 'uuid'

// material-ui icons
import Add from '@material-ui/icons/Add'
import Edit from '@material-ui/icons/Edit'
import Delete from '@material-ui/icons/Delete'
import Map from '@material-ui/icons/Map'
import Collections from '@material-ui/icons/Collections'
import Publish from '@material-ui/icons/Publish'
import Backspace from '@material-ui/icons/Backspace'
import ScreenShare from '@material-ui/icons/ScreenShare'
import Warning from '@material-ui/icons/Warning'
import HighlightOff from '@material-ui/icons/HighlightOff'
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn'
import ViewHeadline from '@material-ui/icons/ViewHeadline'
import Refresh from '@material-ui/icons/Refresh'

// core components
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Table from 'components/Table/Table'
import Button from 'components/CustomButtons/Button'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import Snackbar from 'components/Snackbar/Snackbar'
import TemplateSelector from 'views/Components/TemplateSelector'
import templateContainersStyle from 'assets/jss/spot-admin/views/templateContainersStyle'
import ProgressModal from 'views/Components/ProgressModal'
import ConflictDisplay from 'views/Components/ConflictDisplay'
import { alertActions } from 'features/alert/alertSlice'

import { templateActions } from 'actions'
import { userActions } from 'features/user/userSlice'
import { values, cloneDeep, intersection } from 'lodash'

import {
  determinePeriodRange,
  convertAppliesToRestrictedPeriodStructure,
  convertAppliesToPeriodRange,
  convertFromUTCToTimezone
} from 'utils/TimeStructures'
import {
  timeStampStringInLocale,
  currentTimestampInTimezone
} from 'utils/dateFormat'

const DEFAULT_SIGNCONFIG = 'UNDEFINED'
const DEFAULT_COLOR = '#03A9F4'
const DEFAULT_PRIORITY = 20

class TemplateVersionsList extends React.Component {
  constructor(props) {
    super(props)

    const { match } = props

    const jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'))
    const templateContainerId = match.params.id

    this.state = {
      prompt: null,
      snackBarOpen: false,
      snackBarStatus: 'success',
      snackBarMessage: '',
      templateContainerId,
      jurisdiction,
      validated: {}
    }

    this.showNotification = this.showNotification.bind(this)
    this.removePrompt = this.removePrompt.bind(this)

    this.addTemplateVersion = this.addTemplateVersion.bind(this)
    this.addTemplateInstance = this.addTemplateInstance.bind(this)
    this.editAffected = this.editAffected.bind(this)
    this.viewAffected = this.viewAffected.bind(this)
    this.editTemplateVersionDetails = this.editTemplateVersionDetails.bind(this)
    this.viewTemplateVersionDetails = this.viewTemplateVersionDetails.bind(this)
    this.editTemplateInstanceDetails =
      this.editTemplateInstanceDetails.bind(this)
    this.viewTemplateInstanceDetails =
      this.viewTemplateInstanceDetails.bind(this)
    this.copyTemplateVersion = this.copyTemplateVersion.bind(this)
    this.copyToAnotherTemplatePrompt =
      this.copyToAnotherTemplatePrompt.bind(this)
    this.copyToAnotherTemplate = this.copyToAnotherTemplate.bind(this)
    this.deleteTemplateVersion = this.deleteTemplateVersion.bind(this)
    this.deleteTemplateInstance = this.deleteTemplateInstance.bind(this)

    this.publishTemplateVersion = this.publishTemplateVersion.bind(this)
    this.unpublishTemplateVersion = this.unpublishTemplateVersion.bind(this)
    this.publishTemplateInstance = this.publishTemplateInstance.bind(this)
    this.unpublishTemplateInstance = this.unpublishTemplateInstance.bind(this)

    this.promptPublishTemplateVersion =
      this.promptPublishTemplateVersion.bind(this)
    this.promptUnpublishTemplateVersion =
      this.promptUnpublishTemplateVersion.bind(this)
    this.promptPublishTemplateInstance =
      this.promptPublishTemplateInstance.bind(this)
    this.promptUnpublishTemplateInstance =
      this.promptUnpublishTemplateInstance.bind(this)

    this.determineConflicts = this.determineConflicts.bind(this)
    this.handleCloseConflictModal = this.handleCloseConflictModal.bind(this)

    this.delete = this.delete.bind(this)
    this.goBack = this.goBack.bind(this)

    this.handleRefresh = this.handleRefresh.bind(this)
  }

  // eslint-disable
  componentWillMount() {
    const {
      dispatch,
      templateGroups,
      templateContainers,
      templates,
      templateInstances,
      signConfigs,
      jurisdictionDefaults,
      checkingJurisdictionDefaults
    } = this.props
    const { jurisdiction } = this.state
    //let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'));

    if (Object.keys(templateGroups).length === 0) {
      dispatch(templateActions.getTemplateGroups(jurisdiction.id))
    }

    if (Object.keys(templateContainers).length === 0) {
      dispatch(templateActions.getTemplateContainers(jurisdiction.id))
    }

    if (Object.keys(templates).length === 0) {
      dispatch(templateActions.getTemplates(jurisdiction.id))
    }

    if (Object.keys(signConfigs).length === 0) {
      dispatch(templateActions.getSignConfigs(jurisdiction.id))
    }

    if (Object.keys(templateInstances).length === 0) {
      dispatch(templateActions.getTemplateInstances(jurisdiction.id))
    }

    if (
      !checkingJurisdictionDefaults &&
      Object.keys(jurisdictionDefaults).length === 0
    ) {
      dispatch(userActions.checkJurisdictionDefaults(jurisdiction.id))
    }
  }

  componentWillUnmount() {
    if (this.notificationTimer) {
      clearTimeout(this.notificationTimer)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { dispatch, alert } = this.props

    if (alert.type) {
      this.showNotification(alert.type, alert.message)
      dispatch(alertActions.clear())
    }
  }

  handleRefresh() {
    const { dispatch } = this.props
    const { jurisdiction } = this.state

    dispatch(templateActions.getTemplates(jurisdiction.id))
    dispatch(templateActions.getTemplateInstances(jurisdiction.id))
    dispatch(templateActions.getTemplateContainers(jurisdiction.id))
  }

  promptPublishTemplateVersion(e, templateVersion) {
    const { classes } = this.props

    e.stopPropagation()

    this.setState({
      prompt: (
        <SweetAlert
          warning
          style={{ display: 'block' }}
          title="Warning!"
          onConfirm={() => this.publishTemplateVersion(templateVersion)}
          onCancel={() => this.removePrompt()}
          confirmBtnCssClass={classes.button + ' ' + classes.success}
          cancelBtnCssClass={classes.button + ' ' + classes.danger}
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        >
          You are about to publish this template version! Are you sure?
        </SweetAlert>
      )
    })
  }

  publishTemplateVersion(templateVersion) {
    const { templateContainerId } = this.state
    const { dispatch } = this.props

    dispatch(
      templateActions.publishTemplateVersion(
        templateContainerId,
        templateVersion.version
      )
    )
    this.removePrompt()
  }

  promptUnpublishTemplateVersion(e, templateVersion) {
    const { classes } = this.props

    e.stopPropagation()

    this.setState({
      prompt: (
        <SweetAlert
          warning
          style={{ display: 'block' }}
          title="Warning!"
          onConfirm={() => this.unpublishTemplateVersion(templateVersion)}
          onCancel={() => this.removePrompt()}
          confirmBtnCssClass={classes.button + ' ' + classes.success}
          cancelBtnCssClass={classes.button + ' ' + classes.danger}
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        >
          You are about to unpublish this template version! Are you sure?
        </SweetAlert>
      )
    })
  }

  unpublishTemplateVersion(templateVersion) {
    const { templateContainerId } = this.state
    const { dispatch, conflicts } = this.props

    dispatch(templateActions.unpublishTemplate(templateContainerId))
    this.removePrompt()

    let templateInstanceIds = Object.keys(conflicts)
    dispatch(templateActions.resetTemplateConflicts(templateInstanceIds))
  }

  promptPublishTemplateInstance(e, templateInstance) {
    const { classes } = this.props

    e.stopPropagation()

    this.setState({
      prompt: (
        <SweetAlert
          warning
          style={{ display: 'block' }}
          title="Warning!"
          onConfirm={() => this.publishTemplateInstance(templateInstance)}
          onCancel={() => this.removePrompt()}
          confirmBtnCssClass={classes.button + ' ' + classes.success}
          cancelBtnCssClass={classes.button + ' ' + classes.danger}
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        >
          You are about to publish this template instance! Are you sure?
        </SweetAlert>
      )
    })
  }

  publishTemplateInstance(templateInstance) {
    const { templateContainerId } = this.state
    const { dispatch } = this.props

    dispatch(
      templateActions.publishTemplateInstance(
        templateContainerId,
        templateInstance.id
      )
    )
    this.removePrompt()
  }

  promptUnpublishTemplateInstance(e, templateInstance) {
    const { classes } = this.props

    e.stopPropagation()

    this.setState({
      prompt: (
        <SweetAlert
          warning
          style={{ display: 'block' }}
          title="Warning!"
          onConfirm={() => this.unpublishTemplateInstance(templateInstance)}
          onCancel={() => this.removePrompt()}
          confirmBtnCssClass={classes.button + ' ' + classes.success}
          cancelBtnCssClass={classes.button + ' ' + classes.danger}
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        >
          You are about to unpublish this template instance! Are you sure?
        </SweetAlert>
      )
    })
  }

  unpublishTemplateInstance(templateInstance) {
    const { templateContainerId } = this.state
    const { dispatch } = this.props

    dispatch(
      templateActions.unpublishTemplateInstance(
        templateContainerId,
        templateInstance.id
      )
    )
    this.removePrompt()

    dispatch(templateActions.resetTemplateConflicts([templateInstance.id]))
  }

  determineConflicts(e, templateInstance) {
    const { dispatch } = this.props

    dispatch(
      templateActions.determineTemplateInstanceConflicts(templateInstance.id)
    )
  }

  addTemplateInstance(e, templateId) {
    const { history } = this.props

    let currentTemplateId = this.determineCurrentTemplateIdForInstances()
    history.push(
      `/template-admin/template-instance/new-template-instance/${currentTemplateId}`
    )
  }

  editTemplateInstanceDetails(e, templateInstance) {
    const { history } = this.props

    history.push(
      `/template-admin/template-instance/edit-template-instance/${templateInstance.id}`
    )
  }

  viewTemplateInstanceDetails(e, templateInstance) {
    const { history } = this.props

    history.push(
      `/template-admin/template-instance/view-template-instance/${templateInstance.id}`
    )
  }

  addTemplateVersion(e, templateContainerId) {
    const { dispatch, templates, signConfigs } = this.props

    if (Object.keys(signConfigs).length === 0) return

    let user = localStorage.getItem('user')

    const signConfigsArray = values(signConfigs)
    const index = signConfigsArray.findIndex(
      e => e.category === DEFAULT_SIGNCONFIG
    )
    const defaultSignConfigId =
      index === -1 ? signConfigsArray[0].id : signConfigsArray[index].id

    const description =
      Object.keys(templates).length > 0 ? 'New version' : 'Initial version'

    // establish default signConfig structure
    const configId = uuidv4()
    let signConfigStructure = {}
    signConfigStructure[configId] = {
      signConfig: defaultSignConfigId,
      color: DEFAULT_COLOR,
      description: DEFAULT_SIGNCONFIG,
      priority: DEFAULT_PRIORITY
    }

    const newTemplate = {
      description: {
        'en-US': description
      },
      affected: {
        lots: [],
        bayGroups: [],
        pamReferenceIds: []
      },
      signConfig: signConfigStructure,
      attributes: {},
      published: false,
      modifiedBy: user.id,
      templatecontainer: templateContainerId
    }

    dispatch(templateActions.addNewTemplate(newTemplate))
  }

  editAffected(e, template) {
    const { history } = this.props

    history.push(`/template-admin/template-affected/${template.id}`)
  }

  viewAffected(e, template) {
    const { history } = this.props

    history.push(`/template-admin/view-template-affected/${template.id}`)
  }

  editTemplateVersionDetails(e, template) {
    const { history } = this.props

    history.push(
      `/template-admin/template-version/edit-template-version/${template.id}`
    )
  }

  viewTemplateVersionDetails(e, template) {
    const { history } = this.props

    history.push(
      `/template-admin/template-version/view-template-version/${template.id}`
    )
  }

  copyTemplateVersion(e, template) {
    const { dispatch } = this.props

    dispatch(templateActions.copyTemplateFromExisting(template.id))
  }

  copyToAnotherTemplate({ sourceTemplateId, targetTemplateContainerId }) {
    const { dispatch, templates } = this.props
    let user = localStorage.getItem('user')

    this.setState({
      prompt: null
    })

    let newTemplate = cloneDeep(templates[sourceTemplateId])
    delete newTemplate.id
    delete newTemplate.version
    delete newTemplate.createdAt
    delete newTemplate.updatedAt
    delete newTemplate.lastPublished

    newTemplate.modifiedBy = user.id
    newTemplate.published = false
    newTemplate.description[
      'en-US'
    ] = `${newTemplate.description['en-US']} (copy)`
    newTemplate.templatecontainer = targetTemplateContainerId

    dispatch(
      templateActions.copyTemplateToAnotherTemplateContainer(newTemplate)
    )
  }

  copyToAnotherTemplatePrompt(e, template) {
    const { templateGroups, templateContainers } = this.props

    this.setState({
      prompt: (
        <TemplateSelector
          title="Select template to copy to"
          templateGroups={templateGroups}
          templateContainers={templateContainers}
          templateId={template.id}
          formInitialValues={{
            templateGroupIdSelected:
              templateContainers[template.templatecontainer.id].templategroup
                .id,
            templateContainerIdSelected: template.templatecontainer.id
          }}
          onCancel={() => this.removePrompt()}
          onConfirm={response => this.copyToAnotherTemplate(response)}
        />
      )
    })
  }

  delete(e, template) {
    const { dispatch } = this.props

    dispatch(templateActions.deleteTemplate(template.id))
    this.setState({
      prompt: null
    })
  }

  deleteInstance(e, templateInstance) {
    const { dispatch } = this.props

    dispatch(templateActions.deleteTemplateInstance(templateInstance.id))
    this.setState({
      prompt: null
    })
  }

  deleteTemplateVersion(e, template) {
    const { classes } = this.props

    this.setState({
      prompt: (
        <SweetAlert
          warning
          style={{ display: 'block' }}
          title="Warning!"
          onConfirm={() => this.delete(e, template)}
          onCancel={() => this.removePrompt()}
          confirmBtnCssClass={classes.button + ' ' + classes.success}
          cancelBtnCssClass={classes.button + ' ' + classes.danger}
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        >
          You are about to delete this version. Are you sure?
        </SweetAlert>
      )
    })
  }

  deleteTemplateInstance(e, templateInstance) {
    const { classes } = this.props

    this.setState({
      prompt: (
        <SweetAlert
          warning
          style={{ display: 'block' }}
          title="Warning!"
          onConfirm={() => this.deleteInstance(e, templateInstance)}
          onCancel={() => this.removePrompt()}
          confirmBtnCssClass={classes.button + ' ' + classes.success}
          cancelBtnCssClass={classes.button + ' ' + classes.danger}
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        >
          You are about to delete this instance. Are you sure?
        </SweetAlert>
      )
    })
  }

  goBack(e) {
    const { history } = this.props

    history.goBack()
  }

  removePrompt() {
    this.setState({
      prompt: null
    })
  }

  showNotification(status, message) {
    if (!this.state.snackBarOpen) {
      this.setState({
        snackBarOpen: true,
        snackBarStatus: status,
        snackBarMessage: message
      })
      this.notificationTimer = setTimeout(
        function () {
          this.setState({
            snackBarOpen: false
          })
          this.notificationTimer = null
        }.bind(this),
        3000
      )
    }
  }

  renderActionButtons(template) {
    const { classes, publishing, readOnlyMode } = this.props
    const { templateContainerId } = this.state

    const actionButtons = [
      {
        task: 'viewAffected',
        color: 'success',
        icon: Map,
        displayIfReadOnlyMode: true,
        displayIfWriteMode: false,
        displayIfEverPublished: true,
        tooltip: 'View Affected Parking Assets'
      },
      {
        task: 'editAffected',
        color: 'rose',
        icon: Map,
        displayIfReadOnlyMode: false,
        displayIfWriteMode: true,
        displayIfPublished: false,
        displayIfEverPublished: false,
        disableIfPublishing: true,
        tooltip: 'Edit Affected Parking Assets'
      },
      {
        task: 'copyTemplateVersion',
        color: 'primary',
        icon: Collections,
        displayIfReadOnlyMode: false,
        displayIfWriteMode: true,
        displayIfEverPublished: true,
        tooltip: 'Create New Version Based On This Version'
      },
      {
        task: 'copyToAnotherTemplatePrompt',
        color: 'info',
        icon: ScreenShare,
        displayIfReadOnlyMode: false,
        displayIfWriteMode: true,
        displayIfEverPublished: true,
        tooltip: 'Copy This Version To Another Template'
      },
      {
        task: 'viewTemplateVersionDetails',
        color: 'success',
        icon: ViewHeadline,
        displayIfReadOnlyMode: true,
        displayIfWriteMode: false,
        displayIfEverPublished: true,
        tooltip: `View This Version's Details`
      },
      {
        task: 'editTemplateVersionDetails',
        color: 'rose',
        icon: Edit,
        displayIfReadOnlyMode: false,
        displayIfWriteMode: true,
        displayIfPublished: false,
        displayIfEverPublished: false,
        disableIfPublishing: true,
        tooltip: `Edit This Version's Details`
      },
      {
        task: 'promptPublishTemplateVersion',
        color: 'warning',
        icon: Publish,
        displayIfReadOnlyMode: false,
        displayIfWriteMode: true,
        displayIfPublished: false,
        displayIfEverPublished: true,
        disableIfPublishing: true,
        tooltip: 'Publish This Version'
      },
      {
        task: 'promptUnpublishTemplateVersion',
        color: 'danger',
        icon: HighlightOff,
        displayIfReadOnlyMode: false,
        displayIfWriteMode: true,
        displayIfPublished: true,
        displayIfEverPublished: true,
        disableIfPublishing: true,
        tooltip: 'Unpublish This Version'
      },
      {
        task: 'deleteTemplateVersion',
        color: 'danger',
        icon: Delete,
        displayIfReadOnlyMode: false,
        displayIfWriteMode: true,
        displayIfPublished: false,
        displayIfEverPublished: false,
        disableIfPublishing: true,
        tooltip: 'Delete This Version'
      }
    ]

    let renderedButtons = actionButtons

    renderedButtons = renderedButtons.filter(e => {
      if (
        readOnlyMode &&
        e.displayIfReadOnlyMode !== undefined &&
        e.displayIfReadOnlyMode === false
      )
        return false
      if (
        !readOnlyMode &&
        e.displayIfEverPublished === true &&
        template.lastPublished
      )
        return true
      if (
        !readOnlyMode &&
        e.displayIfWriteMode !== undefined &&
        e.displayIfWriteMode === false
      )
        return false
      return true
    })

    if (template.published) {
      renderedButtons = renderedButtons.filter(
        e => e.displayIfPublished || e.displayIfPublished === undefined
      )
    } else {
      renderedButtons = renderedButtons.filter(
        e => !e.displayIfPublished || e.displayIfPublished === undefined
      )
    }

    if (template.lastPublished) {
      renderedButtons = renderedButtons.filter(e => e.displayIfEverPublished)
    }

    const isPublishing = publishing[templateContainerId] !== undefined

    return (
      <div>
        {renderedButtons.map((button, key) => {
          return (
            <Tooltip key={key} title={button.tooltip}>
              <Button
                color={button.color}
                className={classes.actionButton}
                disabled={button.disableIfPublishing && isPublishing}
                onClick={e => this[button.task](e, template)}
              >
                <button.icon className={classes.icon} />
                {''}
              </Button>
            </Tooltip>
          )
        })}
      </div>
    )
  }

  determineTemplateVersionsForTemplate(templates, templateContainerId) {
    let templateIds = Object.keys(templates)
    let templateVersions = []

    for (let i = 0; i < templateIds.length; i++) {
      if (
        templates[templateIds[i]].templatecontainer.id === templateContainerId
      ) {
        templateVersions.push(templates[templateIds[i]])
      }
    }
    // CR 2021-Oct-13 - Return a copy to avoid directly mutating the store state
    return JSON.parse(JSON.stringify(templateVersions))
  }

  determineTemplateInstancesForTemplateVersions(
    templateInstances,
    templateVersions
  ) {
    let templateInstanceIds = Object.keys(templateInstances)
    let templateSpecificInstances = []

    if (templateVersions.length !== 0) {
      let templateVersionIds = templateVersions.map(version => version.id)
      for (let i = 0; i < templateInstanceIds.length; i++) {
        if (
          templateVersionIds.findIndex(
            versionId =>
              versionId ===
              templateInstances[templateInstanceIds[i]].template.id
          ) !== -1
        ) {
          templateSpecificInstances.push(
            templateInstances[templateInstanceIds[i]]
          )
        }
      }
    }
    // CR 2021-Oct-13 - Return a copy to avoid directly mutating the store state
    return JSON.parse(JSON.stringify(templateSpecificInstances))
  }

  determineCurrentTemplateIdForInstances() {
    const { templateInstances, templates } = this.props
    const { templateContainerId } = this.state

    let templateVersions = this.determineTemplateVersionsForTemplate(
      templates,
      templateContainerId
    )
    templateVersions = templateVersions.sort((a, b) => a.version - b.version) // sort in ascending order
    let specificTemplateInstances =
      this.determineTemplateInstancesForTemplateVersions(
        templateInstances,
        templateVersions
      )

    if (specificTemplateInstances.length > 0) {
      return specificTemplateInstances[0].template.id
    }

    for (let i = 0; i < templateVersions.length; i++) {
      if (templateVersions[i].published) return templateVersions[i].id
    }

    if (templateVersions.length > 0) return templateVersions[0].id

    return null
  }

  renderInstanceActionButtons(templateInstance, issues, hasPublishedVersion) {
    const { classes, publishing, conflicts, readOnlyMode } = this.props
    const { templateContainerId } = this.state

    const actionButtons = [
      {
        task: 'viewTemplateInstanceDetails',
        color: 'success',
        icon: ViewHeadline,
        displayIfReadOnlyMode: true,
        displayIfWriteMode: false,
        displayIfNotValidated: true,
        displayIfValidated: true,
        displayIfPublished: false,
        displayIfEverPublishedAndPending: true,
        displayIfExpired: true,
        disableIfPublishing: true,
        tooltip: `View This Instance's Details`
      },
      {
        task: 'editTemplateInstanceDetails',
        color: 'rose',
        icon: Edit,
        displayIfReadOnlyMode: false,
        displayIfWriteMode: true,
        displayIfNotValidated: true,
        displayIfValidated: true,
        displayIfPublished: false,
        displayIfEverPublishedAndPending: true,
        displayIfExpired: false,
        disableIfPublishing: true,
        tooltip: `Edit This Instance's Details`
      },
      {
        task: 'determineConflicts',
        color: 'rose',
        icon: AssignmentTurnedIn,
        displayIfReadOnlyMode: false,
        displayIfWriteMode: true,
        displayIfNotValidated: true,
        displayIfValidated: false,
        displayIfPublished: false,
        displayIfEverPublished: true,
        displayIfIssues: false,
        displayOnlyIfHasPublishedVersion: true,
        displayIfExpired: false,
        disableIfPublishing: true,
        tooltip: 'Determine Template Wide Conflicts'
      },
      {
        task: 'promptPublishTemplateInstance',
        color: 'warning',
        icon: Publish,
        displayIfReadOnlyMode: false,
        displayIfWriteMode: true,
        displayIfNotValidated: false,
        displayIfValidated: true,
        displayIfPublished: false,
        displayIfEverPublished: true,
        displayIfIssues: false,
        displayOnlyIfHasPublishedVersion: true,
        displayIfExpired: false,
        disableIfPublishing: true,
        tooltip: 'Publish This Instance'
      },
      {
        task: 'promptUnpublishTemplateInstance',
        color: 'danger',
        icon: HighlightOff,
        displayIfReadOnlyMode: false,
        displayIfWriteMode: true,
        displayIfNotValidated: true,
        displayIfValidated: true,
        displayIfPublished: true,
        displayIfEverPublished: true,
        displayIfIssues: false,
        displayOnlyIfHasPublishedVersion: true,
        disableIfPublishing: true,
        tooltip: 'Unpublish This Instance'
      },
      {
        task: 'deleteTemplateInstance',
        color: 'danger',
        icon: Delete,
        displayIfReadOnlyMode: false,
        displayIfWriteMode: true,
        displayIfNotValidated: true,
        displayIfValidated: true,
        displayIfPublished: false,
        displayIfEverPublishedAndPending: true,
        displayIfIssues: true,
        disableIfPublishing: true,
        tooltip: 'Delete This Instance'
      }
    ]

    let overlaps = issues.overlaps[templateInstance.id]
    let status = issues.status[templateInstance.id]
    let validated =
      conflicts[templateInstance.id] &&
      conflicts[templateInstance.id].length === 0

    let renderedButtons = actionButtons
    renderedButtons = renderedButtons.filter(e => {
      if (
        readOnlyMode &&
        e.displayIfReadOnlyMode !== undefined &&
        e.displayIfReadOnlyMode === false
      )
        return false
      if (status === 'Expired' && e.displayIfExpired === true) return true
      if (
        !readOnlyMode &&
        e.displayIfWriteMode !== undefined &&
        e.displayIfWriteMode === false
      )
        return false
      return true
    })

    if (templateInstance.published) {
      renderedButtons = renderedButtons.filter(
        e => e.displayIfPublished || e.displayIfPublished === undefined
      )
    } else {
      renderedButtons = renderedButtons.filter(
        e => !e.displayIfPublished || e.displayIfPublished === undefined
      )
    }

    if (templateInstance.lastPublished) {
      renderedButtons = renderedButtons.filter(
        e => e.displayIfEverPublished || e.displayIfEverPublished === undefined
      )
      if (status === 'Pending') {
        renderedButtons = renderedButtons.filter(
          e =>
            e.displayIfEverPublishedAndPending ||
            e.displayIfEverPublishedAndPending === undefined
        )
      }
    }

    if (hasPublishedVersion) {
      renderedButtons = renderedButtons.filter(
        e =>
          e.displayOnlyIfHasPublishedVersion ||
          e.displayOnlyIfHasPublishedVersion === undefined
      )
    } else {
      renderedButtons = renderedButtons.filter(
        e =>
          !e.displayOnlyIfHasPublishedVersion ||
          e.displayOnlyIfHasPublishedVersion === undefined
      )
    }

    if (validated) {
      renderedButtons = renderedButtons.filter(
        e => e.displayIfValidated || e.displayIfValidated === undefined
      )
    } else {
      renderedButtons = renderedButtons.filter(
        e => e.displayIfNotValidated || e.displayIfNotValidated === undefined
      )
    }

    if (overlaps) {
      renderedButtons = renderedButtons.filter(e => e.displayIfIssues)
    }

    if (status === 'Expired') {
      renderedButtons = renderedButtons.filter(
        e => e.displayIfExpired || e.displayIfExpired === undefined
      )
    }

    const isPublishing = publishing[templateContainerId] !== undefined

    return (
      <div>
        {renderedButtons.map((button, key) => {
          return (
            <Tooltip key={key} title={button.tooltip}>
              <Button
                color={button.color}
                className={classes.actionButton}
                disabled={button.disableIfPublishing && isPublishing}
                onClick={e => this[button.task](e, templateInstance)}
              >
                <button.icon className={classes.icon} />
                {' '}
              </Button>
            </Tooltip>
          )
        })}
      </div>
    )
  }

  checkForIssues(specificInstances, timezone) {
    const { templates, templateInstances } = this.props
    const { templateContainerId } = this.state

    let templateSpecificInstances = specificInstances
    if (!templateSpecificInstances) {
      let templateVersions = this.determineTemplateVersionsForTemplate(
        templates,
        templateContainerId
      )
      templateVersions = templateVersions.sort((a, b) => b.version - a.version) // sort descending by version
      templateSpecificInstances =
        this.determineTemplateInstancesForTemplateVersions(
          templateInstances,
          templateVersions
        )
    }

    let issues = {
      overlaps: {},
      status: {}
    }

    let currentTimezoneTime = currentTimestampInTimezone(timezone)

    // convert all applies to date ranges
    for (let i = 0; i < templateSpecificInstances.length; i++) {
      let dateRange = convertAppliesToPeriodRange(
        templateSpecificInstances[i].applies
      )
      templateSpecificInstances[i].dateRange = {
        start: convertFromUTCToTimezone(dateRange.start, timezone),
        end: convertFromUTCToTimezone(dateRange.end, timezone)
      }
    }

    // scan through and flag overlaps
    for (let i = 0; i < templateSpecificInstances.length - 1; i++) {
      let sourceDateRange = templateSpecificInstances[i].dateRange
      for (let j = i + 1; j < templateSpecificInstances.length; j++) {
        let testDateRange = templateSpecificInstances[j].dateRange
        //console.log(`comparing start ${testDateRange.start.format()} => end ${sourceDateRange.end.format()}`);
        if (
          testDateRange.start.isAfter(sourceDateRange.end) ||
          testDateRange.start.isSame(sourceDateRange.end)
        )
          continue
        //console.log(`comparing end ${testDateRange.end.format()} <= start ${sourceDateRange.start.format()} ${testDateRange.end.isSame(sourceDateRange.start)}`);
        if (
          testDateRange.end.isBefore(sourceDateRange.start) ||
          testDateRange.end.isSame(sourceDateRange.start)
        )
          continue

        // overlap
        if (!issues.overlaps[templateSpecificInstances[i].id])
          issues.overlaps[templateSpecificInstances[i].id] = []
        if (!issues.overlaps[templateSpecificInstances[j].id])
          issues.overlaps[templateSpecificInstances[j].id] = []

        issues.overlaps[templateSpecificInstances[i].id].push(
          templateSpecificInstances[j].id
        )
        issues.overlaps[templateSpecificInstances[j].id].push(
          templateSpecificInstances[i].id
        )
      }
    }

    // scan through and determine pending, current and expired
    for (let i = 0; i < templateSpecificInstances.length; i++) {
      let sourceDateRange = templateSpecificInstances[i].dateRange

      if (
        sourceDateRange.start.isBefore(currentTimezoneTime) &&
        sourceDateRange.end.isAfter(currentTimezoneTime)
      ) {
        issues.status[templateSpecificInstances[i].id] = 'Current'
        continue
      }

      if (sourceDateRange.start.isAfter(currentTimezoneTime)) {
        issues.status[templateSpecificInstances[i].id] = 'Pending'
        continue
      }

      if (sourceDateRange.end.isBefore(currentTimezoneTime)) {
        issues.status[templateSpecificInstances[i].id] = 'Expired'
        continue
      }

      issues.status[templateSpecificInstances[i].id] = 'Unknown'
    }

    return issues
  }

  renderConflictDetails(issues, conflicts) {
    const { templateInstances } = this.props

    let conflictFragment = (
      <React.Fragment>
        <span>
          <h3>Conflicts with:</h3>
          {issues &&
            issues.map((templateInstanceId, key) => {
              return (
                <h4 key={`i${key}`}>
                  Instance <b>{templateInstances[templateInstanceId].name}</b>
                </h4>
              )
            })}
          {conflicts &&
            conflicts.map((conflict, key) => {
              return (
                <h4 key={`c${key}`}>
                  <b>{conflict.templateName}</b> Instance{' '}
                  <b>{conflict.templateInstanceName}</b>
                </h4>
              )
            })}
        </span>
      </React.Fragment>
    )

    this.setState({ conflictFragment })
  }

  handleCloseConflictModal() {
    this.setState({ conflictFragment: null })
  }

  renderWarning(issues, conflicts) {
    if (!issues && (!conflicts || conflicts.length === 0)) return null

    return (
      <Warning
        style={{ marginRight: '10px' }}
        color="error"
        onClick={() => this.renderConflictDetails(issues, conflicts)}
      />
    )
    // return (
    //   <Tooltip title={
    //       <React.Fragment>
    //         <span>
    //           <p>Conflicts with: </p>
    //           {issues && issues.map( (templateInstanceId, key) => {
    //             return (<p key={`i${key}`}>	&#x2022; {templateInstances[templateInstanceId].name}</p>)
    //           })}
    //           {conflicts && conflicts.map( (conflict, key) => {
    //             return (<p key={`c${key}`}>	&#x2022; {conflict.templateName} instance {conflict.templateInstanceName}</p>)
    //           })}
    //         </span>
    //       </React.Fragment>
    //     } >
    //     <Warning style={{ marginRight: '10px' }} color="error" />
    //   </Tooltip>
    // );
  }

  obtainJurisdictionDetail() {
    const { templateGroups, templateContainers, templates } = this.props

    let templateGroupIds = Object.keys(templateGroups)
    for (let i = 0; i < templateGroupIds.length; i++) {
      if (
        templateGroups[templateGroupIds[i]].jurisdiction &&
        templateGroups[templateGroupIds[i]].jurisdiction.attributes
      ) {
        return templateGroups[templateGroupIds[i]].jurisdiction
      }
    }

    let templateContainerIds = Object.keys(templateContainers)
    for (let i = 0; i < templateContainerIds.length; i++) {
      if (
        templateContainers[templateContainerIds[i]].jurisdiction &&
        templateContainers[templateContainerIds[i]].jurisdiction.attributes
      ) {
        return templateContainers[templateContainerIds[i]].jurisdiction
      }
    }

    let templateIds = Object.keys(templates)
    for (let i = 0; i < templateIds.length; i++) {
      if (
        templates[templateIds[i]].jurisdiction &&
        templates[templateIds[i]].jurisdiction.attributes
      ) {
        return templates[templateIds[i]].jurisdiction
      }
    }

    return null
  }

  determineTitleForPublishingStatus(publishingStatus) {
    switch (publishingStatus) {
      case 1:
        return 'Publishing template version...'
      case 2:
        return 'Unpublishing template version...'
      case 3:
        return 'Publishing template instance...'
      case 4:
        return 'Unpublishing template instance...'
      default:
        return null
    }
  }

  render() {
    const {
      classes,
      templateContainers,
      conflictChecking,
      publishingStatus,
      templates,
      templateInstances,
      conflicts,
      readOnlyMode,
      loading,
      jurisdictionDefaults
    } = this.props
    const {
      prompt,
      snackBarStatus,
      snackBarOpen,
      snackBarMessage,
      templateContainerId,
      conflictFragment
    } = this.state

    if (templateContainers === undefined || loading) return <h2>Loading...</h2>

    if (Object.keys(jurisdictionDefaults).length === 0) return null

    let timezone = jurisdictionDefaults['TIMEZONE'] || 'America/New_York'
    let defaultLocale = jurisdictionDefaults['DEFAULT_LOCALE'] || 'en-US'

    let templateVersions = this.determineTemplateVersionsForTemplate(
      templates,
      templateContainerId
    )
    templateVersions = templateVersions.sort((a, b) => b.version - a.version) // sort descending by version

    let templateSpecificInstances =
      this.determineTemplateInstancesForTemplateVersions(
        templateInstances,
        templateVersions
      )
    for (let i = 0; i < templateSpecificInstances.length; i++) {
      templateSpecificInstances[i].appliesPeriod = determinePeriodRange(
        convertAppliesToRestrictedPeriodStructure(
          templateSpecificInstances[i].applies
        )
      )
    }

    templateSpecificInstances = templateSpecificInstances.sort(
      (a, b) => b.appliesPeriod.start - a.appliesPeriod.start
    )
    let instanceIssues = this.checkForIssues(
      templateSpecificInstances,
      timezone
    )

    let templateContainer = templateContainers[templateContainerId]
    let hasPublishedVersion =
      templateVersions.filter(e => e.published).length > 0

    if (!templateContainer) {
      return (
        <GridContainer>
          <GridItem xs={9}>
            {!templateContainer && <h3>No Template currently available.</h3>}
          </GridItem>
        </GridContainer>
      )
    }

    return (
      <GridContainer>
        {conflictChecking ? (
          <ProgressModal
            open={Boolean(conflictChecking)}
            title="Checking for conflicts..."
          />
        ) : null}
        {publishingStatus ? (
          <ProgressModal
            open={Boolean(publishingStatus)}
            title={this.determineTitleForPublishingStatus(publishingStatus)}
          />
        ) : null}
        {conflictFragment ? (
          <ConflictDisplay
            open={Boolean(conflictFragment)}
            conflictDetails={conflictFragment}
            closeFunction={this.handleCloseConflictModal}
          />
        ) : null}
        <GridItem xs={7}>
          <h3>Template: {templateContainer.name}</h3>
        </GridItem>
        <GridItem xs={5}>
          <Tooltip title="Return to List of Template Groups">
            <Button
              color="danger"
              style={{ float: 'right', paddingRight: '20px' }}
              onClick={e => this.goBack(e)}
            >
              <Backspace className={classes.icon} />
              Template Groups
            </Button>
          </Tooltip>
          <Tooltip title="Refresh template list">
            <Button
              color="info"
              style={{ float: 'right', marginRight: '10px' }}
              onClick={() => this.handleRefresh()}
            >
              <Refresh className={classes.icon} />
              Refresh
            </Button>
          </Tooltip>
        </GridItem>
        {templateContainer.description && (
          <GridItem xs={12}>
            <h4>{templateContainer.description['en-US']}</h4>
          </GridItem>
        )}
        <GridItem xs={12}>
          {prompt}
          <Snackbar
            place="tr"
            color={snackBarStatus}
            message={snackBarMessage}
            open={snackBarOpen}
            closeNotification={() => this.setState({ snackBarOpen: false })}
            close
          />
        </GridItem>

        <Card>
          <CardHeader>
            <h4 className={classes.cardIconTitle}>Available Versions</h4>
          </CardHeader>
          <CardBody>
            {templateVersions.length === 0 && (
              <h4>No template versions currently available.</h4>
            )}
            {/*  Template Versions Table */}
            {templateVersions.length > 0 && (
              <Table
                hover
                striped
                tableHead={['Version', 'Description', 'Published?', 'Actions']}
                tableData={templateVersions.map(templateVersion => {
                  if (
                    templateVersion.published ||
                    templateVersion.lastPublished
                  ) {
                    if (templateVersion.published) hasPublishedVersion = true
                    return {
                      color: templateVersion.published ? 'warning' : 'danger',
                      data: [
                        templateVersion.version,
                        templateVersion.description['en-US'],
                        templateVersion.published ? 'Yes' : 'No',
                        this.renderActionButtons(templateVersion)
                      ]
                    }
                  }
                  return [
                    templateVersion.version,
                    templateVersion.description['en-US'],
                    templateVersion.published ? 'Yes' : 'No',
                    this.renderActionButtons(templateVersion)
                  ]
                })}
                customCellClasses={[
                  classes.columnVersion,
                  classes.columnDescription,
                  classes.columnPublished,
                  classes.columnActions
                ]}
                customClassesForCells={[0, 1, 2, 3]}
                customHeadCellClasses={[classes.center, classes.right]}
                customHeadClassesForCells={[2, 3]}
              />
            )}

            {readOnlyMode || (
              <Tooltip
                title={`Add A New Template Version For ${templateContainer.name}`}
              >
                <Button
                  color="success"
                  style={{ marginTop: '20px' }}
                  onClick={e =>
                    this.addTemplateVersion(e, templateContainer.id)
                  }
                >
                  <Add className={classes.icon} />
                  Add Template Version
                </Button>
              </Tooltip>
            )}
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <h4 className={classes.cardIconTitle}>Instances</h4>
          </CardHeader>
          <CardBody>
            {templateSpecificInstances.length === 0 && (
              <h4>No template instances currently available.</h4>
            )}
            {templateSpecificInstances.length > 0 && (
              <Table
                hover
                striped
                tableHead={[
                  'Name',
                  'Description',
                  'Applies',
                  'Status',
                  'Active',
                  'Actions'
                ]}
                tableData={templateSpecificInstances.map(templateInstance => {
                  let timeRangeString = `${timeStampStringInLocale(
                    defaultLocale,
                    templateInstance.appliesPeriod.start
                  )} - ${timeStampStringInLocale(
                    defaultLocale,
                    templateInstance.appliesPeriod.end
                  )}`

                  if (
                    templateInstance.published ||
                    (templateInstance.lastPublished &&
                      instanceIssues.status[templateInstance.id] !==
                        'Pending') ||
                    instanceIssues.status[templateInstance.id] === 'Expired'
                  ) {
                    return {
                      color: templateInstance.published ? 'warning' : 'danger',
                      data: [
                        templateInstance.name,
                        templateInstance.description['en-US'],
                        <div>
                          {this.renderWarning(
                            instanceIssues.overlaps[templateInstance.id],
                            conflicts[templateInstance.id]
                          )}
                          {timeRangeString}
                        </div>,
                        instanceIssues.status[templateInstance.id],
                        templateInstance.published ? 'Yes' : 'No',
                        this.renderInstanceActionButtons(
                          templateInstance,
                          instanceIssues,
                          hasPublishedVersion
                        )
                      ]
                    }
                  }
                  return [
                    templateInstance.name,
                    templateInstance.description['en-US'],
                    <div>
                      {this.renderWarning(
                        instanceIssues.overlaps[templateInstance.id],
                        conflicts[templateInstance.id]
                      )}
                      {timeRangeString}
                    </div>,
                    instanceIssues.status[templateInstance.id],
                    templateInstance.published ? 'Yes' : 'No',
                    this.renderInstanceActionButtons(
                      templateInstance,
                      instanceIssues,
                      hasPublishedVersion
                    )
                  ]
                })}
                customCellClasses={[
                  classes.columnName,
                  classes.columnDescription,
                  classes.columnPeriods,
                  classes.columnPublished,
                  classes.columnPublished,
                  classes.columnActions
                ]}
                customClassesForCells={[0, 1, 2, 3, 4, 5]}
                customHeadCellClasses={[classes.center, classes.right]}
                customHeadClassesForCells={[4, 5]}
              />
            )}
            {templateVersions.length > 0 &&
              (readOnlyMode || (
                <Tooltip
                  title={`Add A New Template Instance For ${templateContainer.name}`}
                >
                  <Button
                    color="success"
                    style={{ marginTop: '20px' }}
                    onClick={e => this.addTemplateInstance(e)}
                  >
                    <Add className={classes.icon} />
                    Add Template Instance
                  </Button>
                </Tooltip>
              ))}
          </CardBody>
        </Card>
      </GridContainer>
    )
  }
}

function mapStateToProps(state) {
  const { templates, users, alert } = state

  let readOnlyMode = false
  const user = JSON.parse(localStorage.getItem('user'))
  if (user.roles) {
    if (
      intersection(
        ['superAdmin', 'corporateAdmin', 'templateAdmin', 'templateMaintainer'],
        user.roles.map(o => o.role)
      ).length === 0
    )
      readOnlyMode = true
  }

  if (!templates) {
    return {}
  }

  return {
    templates: templates.templates || {},
    templateContainers: templates.templateContainers || {},
    templateInstances: templates.templateInstances || {},
    templateGroups: templates.templateGroups || {},
    signConfigs: templates.signConfigs || {},
    conflicts: templates.conflicts || {},
    conflictChecking: templates.conflictChecking || false,
    loading: templates.loadingTemplateContainers || false,
    saving: templates.saving || false,
    deleting: templates.deleting || false,
    publishing: templates.publishing || {},
    publishingStatus: templates.publishingStatus || 0,
    readOnlyMode,
    jurisdictionDefaults: users.jurisdictionDefaults || {},
    checkingJurisdictionDefaults: users.checkingJurisdictionDefaults || false,
    alert: alert || null
  }
}

export default connect(mapStateToProps)(
  withStyles(templateContainersStyle)(TemplateVersionsList)
)
/* eslint-enable */
