import { configureStore } from '@reduxjs/toolkit'
import { allReducers } from 'reducers'
import { isProd } from 'config'

let middleware = []

export const store = configureStore({
  reducer: allReducers,
  middleware: getDefaultMiddleware => [
    ...getDefaultMiddleware(),
    ...middleware
  ],
  devTools: !isProd
})
