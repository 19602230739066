import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import ErrorComponent from './ErrorComponent'
import { isProd } from 'config'

if (isProd) {
  Bugsnag.start({
    apiKey: 'd487bccdf4dd6be1969b7bafc281d251',
    plugins: [new BugsnagPluginReact()],
    onError(event) {
      const userItem = localStorage.getItem('user')
      const user = !userItem ? null : JSON.parse(userItem)

      if (user?.id) {
        const id = String(user.id)
        const name = `${user.firstName} ${user.lastName}`
        event.setUser(id, name)
      }
    }
  })
}

type ErrorProps = Record<string, any>

interface ErrorState {
  hasError: boolean
}

export class ErrorBoundary extends React.Component<ErrorProps, ErrorState> {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false
    }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    if (!isProd) {
      console.error(error)
      // throw error
    } else {
      Bugsnag.notify(error, event => {
        event.severity = 'error'
        event.unhandled = true
      })
    }
  }

  render() {
    if (this.state.hasError) {
      return <ErrorComponent />
    }
    return this.props.children
  }
}

export default ErrorBoundary
