const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
}

export function authHeader() {
  try {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'))
    if (user && user.token) {
      return {
        ...defaultHeaders,
        'X-Api-Token': user.token
      }
    } else {
      return {
        ...defaultHeaders
      }
    }
  } catch (error) {
    return {
      ...defaultHeaders
    }
  }
}
