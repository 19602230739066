/**
 * @func cyclicIndex - Get `index` from array.  If `index` > `arr`.length,
 * it will get the index from the array as if the array repeated until
 * we were able to get the index.
 *
 * @example
 * var myArr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
 * myArr.length //=> 11
 * cyclicIndex(myArr, 12) //=> 1
 * cyclicIndex(myArr, 13) //=> 2
 * cyclicIndex(myArr, 14) //=> 3
 */
export const cyclicIndex = (arr, index) => {
  const len = arr.length
  return index < len ? arr[index] : arr[index % len]
}
export default cyclicIndex
