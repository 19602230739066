import * as Comlink from 'comlink'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DateTime } from 'luxon'
import { v4 as uuidv4 } from 'uuid'

import { alertActions } from 'features/alert/alertSlice'
import {
    AvailableResourceStatistics,
    AvailableStatisticsResponse,
    DateRange,
    OccupancyPredictionReport,
    OccupancyReport,
    OverflowReport,
    ParkingStatsTwoIntervalResponse,
    ReportType,
    Resource,
    ResourceStatistics,
    WorkerParams
} from 'features/resource/types'
import baseReducer from 'features/baseReducer'
import {
    ClientConfig,
    DashboardReportState,
    DashboardReportTypes,
    DashboardSlice,
    DashboardWorker,
    FeedConfig, FinancialReportConfig,
    GroupConfig, isFinancial,
    LiveOccupancyState,
    ParkingEventType,
    ParkingStatInterval,
    ParkingStatState,
    ReportConfig
} from './types'
import DashboardWorker_ from './dashboardWorker?worker'
import {
    DATE_RANGE_OPTIONS,
    getDateRangeOptions
} from 'features/resource/dateRange'
import { EntityMap, Epoch } from 'types/generics'
import { ErrorResponseCode } from 'types/request'
import { getClosestMonday } from 'features/resource/reportUtils'
import { getJurisdictionId } from 'utils/useJurisdictionId'
import {
    initialParameters,
    initialSummary
} from 'features/resource/resourceSlice'
import { loadConfig } from 'config/configs'
import {
    loadOccupancyPredictionReport,
    requestOccupancyReport,
    requestRealTimeOccupancyReport,
    requestStats
} from 'features/resource/resourceActions'
import logError from 'utils/logError'
import { resourceService } from 'services'
import toObject from 'utils/toObject'
import toPojo from 'utils/toPojo'
import { LineGroupsAndConfigs, lineGroupsAndConfigsForReports } from 'features/resource/paymentReportUtils'
import { fetchReport } from 'features/resource/paymentActions'
import { PaymentSessionReportWithId } from 'features/resource/types/paymentSessionTypes'


const dashboardWorker = Comlink.wrap(
    new DashboardWorker_()
) as unknown as DashboardWorker

export const initialState: DashboardSlice = {
    availableStatistics: {},
    configLoading: false,
    dashboardConfig: null,
    error: null,
    feedStates: {},
    init: false,
    lastRefresh: Date.now(),
    liveOccupancyStates: {},
    loadingReportIds: [],
    monthStarts: [],
    reportStates: {},
    resources: {},
    weekStarts: [],
    paymentReports: {}
}

const dashboardSlice = createSlice({
    initialState,
    name: 'dashboardTs',
    reducers: {
        ...baseReducer,
        setInit(state, {payload}: PayloadAction<boolean>) {
            state.init = payload
        },
        setConfigLoading(state, {payload}: PayloadAction<boolean>) {
            state.configLoading = payload
        },
        setError(state, {payload}: PayloadAction<null | string | Error>) {
            state.error = payload ? String(payload) : payload
        },
        setDashboardConfig(state, {payload}: PayloadAction<ClientConfig | null>) {
            state.dashboardConfig = payload
        },
        updateReportConfig(state, {payload}: PayloadAction<UpdateReportConfig>) {
            const {groupId, reportId, reportConfig} = payload
            for (let groupIndex in state.dashboardConfig!.groupConfigs) {
                const groupConfig = state.dashboardConfig.groupConfigs[groupIndex]
                if (groupId === groupConfig.id) {
                    for (let configIndex in groupConfig.reportConfigs) {
                        const config = groupConfig.reportConfigs[configIndex]
                        if (reportId === config.id) {
                            // @ts-ignore
                            groupConfig.reportConfigs[configIndex] = {
                                ...config,
                                ...reportConfig
                            }
                            state.dashboardConfig.groupConfigs[groupIndex] = groupConfig
                            break
                        }
                    }
                }
            }
        },
        setLoadingReportIds(state, {payload}: PayloadAction<string[]>) {
            state.loadingReportIds = payload
        },
        removeLoadingReportId(state, {payload}: PayloadAction<string>) {
            state.loadingReportIds = state.loadingReportIds.filter(
                id => payload !== id
            )
        },
        addLoadingReportId(state, {payload}: PayloadAction<string>) {
            if (!state.loadingReportIds.includes(payload)) {
                state.loadingReportIds.push(payload)
            }
        },
        setResources(state, {payload}: PayloadAction<EntityMap<Resource>>) {
            state.resources = payload
        },
        setWeekStarts(state, {payload}: PayloadAction<Epoch[]>) {
            state.weekStarts = payload
        },
        setMonthStarts(state, {payload}: PayloadAction<Epoch[]>) {
            state.monthStarts = payload
        },
        setAvailableStatistics(
            state,
            {payload}: PayloadAction<AvailableResourceStatistics>
        ) {
            state.availableStatistics = payload
        },
        setReportStates(
            state,
            {payload}: PayloadAction<EntityMap<DashboardReportState>>
        ) {
            state.reportStates = payload
        },
        updateReportState(state, {payload}: PayloadAction<UpdateReportState>) {
            const {id, reportState} = payload
            state.reportStates[id] = reportState
        },
        updateReportStateOverflowSecondsBase(
            state,
            {payload}: PayloadAction<UpdateReportStateOverflowSecondsBase>
        ) {
            const {id, overflowSecondsBase} = payload
            if (state.reportStates[id]) {
                state.reportStates[id].overflowSecondsBase = overflowSecondsBase
            }
        },
        removeReportState(state, {payload}: PayloadAction<string>) {
            delete state.reportStates[payload]
        },
        setLiveOccupancyStates(
            state,
            {payload}: PayloadAction<EntityMap<LiveOccupancyState>>
        ) {
            state.liveOccupancyStates = payload
        },
        updateLiveOccupancyState(
            state,
            {payload}: PayloadAction<LiveOccupancyState>
        ) {
            state.liveOccupancyStates[payload.id] = payload
        },
        removeLiveOccupancyState(state, {payload}: PayloadAction<string>) {
            delete state.liveOccupancyStates[payload]
        },
        setFeedStates(
            state,
            {payload}: PayloadAction<EntityMap<ParkingStatState>>
        ) {
            state.feedStates = payload
        },
        updateFeedState(state, {payload}: PayloadAction<ParkingStatState>) {
            state.feedStates[payload.id] = payload
        },
        updateLastRefresh(state, {payload}: PayloadAction<number>) {
            state.lastRefresh = payload
        },
        setPaymentReports(state, {payload}: PayloadAction<Record<string, LineGroupsAndConfigs | null>>) {
            state.paymentReports = payload
        },
        updatePaymentReport(state, {payload}: PayloadAction<{ id: string, data: LineGroupsAndConfigs }>) {
            state.paymentReports[payload.id] = payload.data
        }
    }
})


interface UpdateReportConfig {
    groupId: string
    reportId: string
    reportConfig: Partial<ReportConfig | FeedConfig | FinancialReportConfig>
}


interface UpdateReportState {
    id: string
    reportState: DashboardReportState
}


interface UpdateReportStateOverflowSecondsBase {
    id: string
    overflowSecondsBase: number
}


export const {actions, name, reducer} = dashboardSlice

const init =
    (abortController: AbortController) => async (dispatch, getState) => {
        try {
            // Load the initial dashboard config
            dispatch(actions.setConfigLoading(true))
            const jurisdictionId = getJurisdictionId()
            const config: ClientConfig | null = jurisdictionId
                ? await loadConfig(jurisdictionId)
                : null
            if (!config) {
                // if this customer doesn't have a dashboard config, we need to throw an error
                // to stop execution & ensure the user sees an error message
                throw new Error(
                    `Could not find a configuration for ${jurisdictionId}. Please contact Customer Support.`
                )
            }
            dispatch(actions.setDashboardConfig(config))
            // Set up our resources & week starts (used across all reports)
            await dispatch(loadResourcesAndWeekStarts(abortController))
            // Now start building out the individual reports
            await dispatch(generateDashboardReports(abortController))
            dispatch(actions.setConfigLoading(false))
            dispatch(actions.setInit(true))
        } catch (error) {
            // Let the callee handle unauthorized errors
            if (ErrorResponseCode.UNAUTHORIZED === error?.status) {
                throw error
            }
            dispatch(handleError(error))
        }
    }

const loadResourcesAndWeekStarts =
    (abortController: AbortController) => async (dispatch, getState) => {
        const {resources} = await resourceService.getAll(
            getJurisdictionId(),
            abortController.signal
        )
        dispatch(
            actions.setResources(toObject(resources, ({resourceId}) => resourceId))
        )
        const {dashboardConfig} = getState()[name]
        if (!dashboardConfig) {
            return
        }
        const {groupConfigs} = dashboardConfig
        const reportConfigs: ReportConfig[] = groupConfigs.flatMap(
            groupConfig => groupConfig.reportConfigs
        )
        const allReportResourceIds: string[] = reportConfigs.flatMap(
            ({selectedResourceIds}) => selectedResourceIds
        )
        const selectedResourceIds: string[] = [...new Set(allReportResourceIds)]
        const {availableStatistics}: AvailableStatisticsResponse =
            await resourceService.getAvailableStatistics(
                selectedResourceIds,
                getJurisdictionId(),
                abortController.signal
            )
        dispatch(actions.setAvailableStatistics(availableStatistics))

        // Extract weekStarts & monthStarts
        let weekStarts: Epoch[] = []
        let monthStarts: Epoch[] = []
        for (let resourceId in availableStatistics) {
            const statisticsForResource: ResourceStatistics | null =
                availableStatistics[resourceId] || null
            if (statisticsForResource && selectedResourceIds.includes(resourceId)) {
                Object.values(statisticsForResource).forEach(statistic => {
                    const weekStartSource =
                        statistic?.TIME_SERIES?.WEEK_START ||
                        statistic?.TIME_GROUPING?.WEEK_START ||
                        {}
                    weekStarts = weekStarts.concat(
                        Object.keys(weekStartSource).map(Number)
                    )
                    const monthStartsSource = statistic?.TIME_GROUPING?.MONTH_START || {}
                    monthStarts = monthStarts.concat(
                        Object.keys(monthStartsSource).map(Number)
                    )
                })
            }
        }

        dispatch(actions.setWeekStarts([...new Set(weekStarts.filter(Boolean))]))
        dispatch(actions.setMonthStarts([...new Set(monthStarts.filter(Boolean))]))
        return Promise.resolve(true)
    }

const generateDashboardReports =
    (abortController: AbortController) => async (dispatch, getState) => {
        const {dashboardConfig} = getState()[name]
        if (!dashboardConfig) {
            return
        }
        const {groupConfigs} = dashboardConfig
        // Mark all reports as 'loading'
        const allReportIds: string[] = groupConfigs
            .map(({reportConfigs}) => reportConfigs.map(({id}) => id))
            .flat(3)
        dispatch(dashboardActions.setLoadingReportIds(allReportIds))

        for (let groupConfig of groupConfigs) {
            for (let reportConfig of groupConfig.reportConfigs) {
                await dispatch(
                    dashboardActions.updateDashboardReport(
                        {groupId: groupConfig.id, reportId: reportConfig.id},
                        {},
                        false,
                        abortController
                    )
                )
                await nextTick()
            }
        }
        dispatch(actions.setLoadingReportIds([]))
    }


async function nextTick(): Promise<boolean> {
    return new Promise(resolve => {
        requestAnimationFrame(() => resolve(true))
    })
}


const updateFinancialReport = ({groupId, reportId, config}: { groupId: string; reportId: string, config: FinancialReportConfig }) => async (dispatch, getState) => {
    const {id, index} = config
    // Params
    const {
        jurisdictionId,
        rollUpLevel,
        sessionDimensions,
        timeScales,
        timeSeriesVariables,
        resources
    } = config
    const response = await fetchReport({
        jurisdictionId,
        rollUpLevel,
        sessionDimensions,
        timeScales,
        timeSeriesVariables,
        resources
    })
    const {result} = response
    const reportsWithIds: PaymentSessionReportWithId[] = result.map(report => ({...report, id: uuidv4()}))
    const lineGroupsAndConfigs: LineGroupsAndConfigs = lineGroupsAndConfigsForReports(sessionDimensions[0], reportsWithIds)
    dispatch(dashboardActions.updatePaymentReport({id: reportId, data: lineGroupsAndConfigs}))
    dispatch(dashboardActions.removeLoadingReportId(reportId))
    dispatch(dashboardActions.removeLoadingReportId(id))
}

const updateDashboardReport =
    (
        {groupId, reportId}: { groupId: string; reportId: string },
        extraParams: Partial<WorkerParams> = {},
        setLoading: boolean = true,
        abortController?: AbortController
    ) =>
        async (dispatch, getState) => {
            const {
                availableStatistics,
                dashboardConfig,
                lastRefresh,
                monthStarts,
                resources,
                weekStarts
            } = getState()[name]
            if (!dashboardConfig) {
                return
            }
            const {groupConfigs, zoneName} = dashboardConfig
            const group: GroupConfig = groupConfigs.find(
                groupConfig => groupId === groupConfig.id
            )
            const reportConfig: ReportConfig | FeedConfig | FinancialReportConfig | null =
                group?.reportConfigs.find(config => reportId === config.id)
            if (!reportConfig) {
                return
            }
            const isFinancialType = isFinancial(reportConfig.dashboardReportType)
            if (isFinancialType) {
                return dispatch(
                    updateFinancialReport({
                        groupId,
                        reportId,
                        config: reportConfig as FinancialReportConfig
                    })
                )
            }
            if (setLoading) {
                dispatch(dashboardActions.addLoadingReportId(reportId))
            }
            const dateRangeOptions = getDateRangeOptions(zoneName)

            const baseReportState: WorkerParams = {
                ...initialParameters,
                ...initialSummary,
                availableStatistics,
                dateRangeSelections: [dateRangeOptions.CUSTOM],
                monthStarts,
                overflowSecondsBase: 10972,
                reportState: [],
                resources,
                weekStarts,
                ...extraParams
            }

            const now = DateTime.now().setZone(zoneName)
            const closestMonday = getClosestMonday(now.toMillis(), zoneName)
            const jurisdictionId = getJurisdictionId()

            // @ts-ignore
            const {
                dashboardReportType,
                // @ts-ignore
                daysOfTheWeek,
                // @ts-ignore
                groupBy,
                reportType,
                // @ts-ignore
                selectedCategories,
                // @ts-ignore
                selectedResourceIds,
                // @ts-ignore
                weekOffset
            } = reportConfig
            // const reportType = dashboardReportType in ReportType
            //     ? dashboardReportType
            //     : ReportType.occupancy

            let dateRangeSelection: DateRange =
                weekOffset === 0
                    ? dateRangeOptions.THIS_WEEK
                    : weekOffset === 1
                        ? dateRangeOptions.LAST_WEEK
                        : {
                            ...dateRangeOptions.CUSTOM,
                            start: closestMonday.minus({weeks: weekOffset}).toMillis(),
                            end: closestMonday
                                .minus({weeks: weekOffset - 1})
                                .plus({days: 6, hours: 23, minutes: 59, seconds: 59})
                                .toMillis()
                        }
            if (
                DashboardReportTypes.statsFeed === dashboardReportType ||
                DashboardReportTypes.statsChart === dashboardReportType
            ) {
                const {intervals, keys, statisticTypes} = reportConfig as FeedConfig
                const [interval, ..._] = intervals
                // Set the interval & statisticType selections on the resource slice
                baseReportState.selectedStatisticTypes = statisticTypes
                baseReportState.interval = interval
                // Weekly reports need to encompass the last 8 mondays
                if (ParkingStatInterval.week === interval) {
                    dateRangeSelection = {
                        ...dateRangeOptions.CUSTOM,
                        start: closestMonday.minus({weeks: 8 + weekOffset}).toMillis()
                    }
                }
                // Daily reports need to include the last 4 mondays
                else if (ParkingStatInterval.day === interval) {
                    dateRangeSelection = {
                        ...dateRangeOptions.CUSTOM,
                        start: closestMonday.minus({weeks: 4 + weekOffset}).toMillis()
                    }
                }
            }

            const reportSlice = toPojo<WorkerParams>({
                ...baseReportState,
                groupBy,
                daysOfTheWeek,
                selectedCategories,
                selectedResourceIds,
                reportType,
                selectedReportType: reportType,
                dateRangeSelections: [dateRangeSelection]
            })

            // Occupancy, profile, ev reports
            if (
                ReportType.occupancy === dashboardReportType ||
                ReportType.profileComparison === dashboardReportType ||
                ReportType.ev === dashboardReportType
            ) {
                const occupancyReport: OccupancyReport | OverflowReport = toPojo(
                    await requestOccupancyReport(reportSlice, lastRefresh, abortController)
                )
                const reportState = await dashboardWorker.generateReport(
                    reportSlice as WorkerParams,
                    reportConfig as ReportConfig,
                    groupId as string,
                    zoneName,
                    occupancyReport as OccupancyReport
                )
                dispatch(
                    dashboardActions.updateReportState({
                        id: reportConfig.id,
                        reportState
                    })
                )
            } else if (ReportType.predictive === dashboardReportType) {
                // Predictive
                const occupancyReport: OccupancyReport | OverflowReport = toPojo(
                    await requestOccupancyReport(reportSlice, lastRefresh, abortController)
                )
                const occupancyPrediction: OccupancyPredictionReport = toPojo(
                    await loadOccupancyPredictionReport(
                        selectedResourceIds,
                        weekStarts,
                        reportSlice.dateRangeSelections,
                        selectedCategories,
                        abortController
                    )
                )
                const reportState = await dashboardWorker.generateReport(
                    reportSlice,
                    // @ts-ignore
                    reportConfig,
                    groupId,
                    zoneName,
                    occupancyReport as OccupancyReport,
                    occupancyPrediction
                )
                dispatch(
                    dashboardActions.updateReportState({
                        id: reportConfig.id,
                        reportState
                    })
                )
            } else if (DashboardReportTypes.liveOccupancy === dashboardReportType) {
                const occupancyReport: OccupancyReport | OverflowReport = toPojo(
                    await requestOccupancyReport(reportSlice, lastRefresh, abortController)
                )
                // Live Occupancy
                const liveOccupancyState: LiveOccupancyState =
                    await dashboardWorker.generateLiveOccupancy(
                        reportSlice,
                        // @ts-ignore
                        reportConfig,
                        groupId,
                        zoneName,
                        occupancyReport as OccupancyReport
                    )
                dispatch(dashboardActions.updateLiveOccupancyState(liveOccupancyState))
            } else if (DashboardReportTypes.statsFeed === dashboardReportType) {
                // Stats Feed
                if (!jurisdictionId) {
                    return
                }
                const statsResponse = (await requestStats(
                    reportSlice,
                    jurisdictionId,
                    zoneName,
                    abortController
                )) as ParkingStatsTwoIntervalResponse
                const feedState: ParkingStatState =
                    await dashboardWorker.generateStatsFeed(
                        reportSlice,
                        reportConfig as FeedConfig,
                        groupId,
                        zoneName,
                        statsResponse
                    )
                dispatch(dashboardActions.updateFeedState(feedState))
            } else if (DashboardReportTypes.statsChart === dashboardReportType) {
                if (!jurisdictionId) {
                    return
                }
                const statsResponse = (await requestStats(
                    reportSlice,
                    jurisdictionId,
                    zoneName,
                    abortController
                )) as ParkingStatsTwoIntervalResponse
                const {keys} = reportConfig as FeedConfig
                const workerMethod = keys.includes(ParkingEventType.parkingSession)
                    ? dashboardWorker.generateSessionChart
                    : dashboardWorker.generateStatsChart
                const reportState: DashboardReportState = await workerMethod(
                    reportSlice,
                    reportConfig as FeedConfig,
                    groupId,
                    zoneName,
                    statsResponse
                )
                dispatch(
                    dashboardActions.updateReportState({
                        id: reportConfig.id,
                        reportState: reportState
                    })
                )
            }
            dispatch(dashboardActions.updateLastRefresh(Date.now()))
            dispatch(dashboardActions.removeLoadingReportId(reportId))
        }

const updateLiveOccupancyReports =
    (lastRefresh: number, abortController?: AbortController) =>
        async (dispatch, getState) => {
            const {
                availableStatistics,
                dashboardConfig,
                monthStarts,
                resources,
                weekStarts
            } = getState()[name]
            if (!dashboardConfig) {
                return
            }
            const {groupConfigs, zoneName} = dashboardConfig

            const dateRangeOptions = getDateRangeOptions(zoneName)

            const baseReportState: WorkerParams = {
                ...initialParameters,
                ...initialSummary,
                availableStatistics,
                dateRangeSelections: [dateRangeOptions.CUSTOM],
                monthStarts,
                overflowSecondsBase: 10972,
                reportState: [],
                resources,
                weekStarts
            }

            // Build out live occupancy
            for (let groupConfig of groupConfigs) {
                const {id, reportConfigs} = groupConfig
                for (let reportConfig of reportConfigs) {
                    const {
                        dashboardReportType,
                        groupBy,
                        daysOfTheWeek,
                        selectedCategories,
                        selectedResourceIds
                    } = reportConfig
                    const reportType =
                        dashboardReportType in ReportType
                            ? dashboardReportType
                            : ReportType.occupancy
                    const reportSlice = toPojo<WorkerParams>({
                        ...baseReportState,
                        groupBy,
                        daysOfTheWeek,
                        selectedCategories,
                        selectedResourceIds,
                        reportType,
                        selectedReportType: reportType
                    })
                    if (DashboardReportTypes.liveOccupancy !== dashboardReportType) {
                        continue
                    }
                    const occupancyReport: OccupancyReport =
                        await requestRealTimeOccupancyReport(
                            reportSlice,
                            lastRefresh,
                            abortController
                        )
                    const liveOccupancyState: LiveOccupancyState =
                        await dashboardWorker.generateLiveOccupancy(
                            reportSlice,
                            toPojo(reportConfig),
                            id,
                            zoneName,
                            toPojo(occupancyReport)
                        )
                    dispatch(
                        dashboardActions.updateLiveOccupancyState(toPojo(liveOccupancyState))
                    )
                }
            }
        }

const handleError = (error: Error | string | any) => dispatch => {
    if ('AbortError' === error?.name) {
        // We don't need to do anything for AbortErrors
        return
    }
    logError(error)
    const value = String(error)
    dispatch(actions.setState({error: value}))
    dispatch(alertActions.error(value))
    throw error
}

export const dashboardActions = {
    ...actions,
    init,
    updateLiveOccupancyReports,
    updateDashboardReport
}
