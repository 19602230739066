/**
 * Payment Session Request Types
 */


/**
 * @example
 {
    "jurisdictionId": "4c27e857-79d8-4ba4-95a4-0600c0b58a1a",
    "rollUpLevel": "jurisdictionAndProviderZone",
    "timeScale": {
        "daily": [
            {
                "year": 2023,
                "month": 12
            },
            {
                "year": [2024],
                "month": [1, 2]
            }
        ]
    },
    "providerDefinition": [
        {
            "provider": "ParkMobile",
            "providerZoneId": ['58952']
        },
        {
            "provider": "Flowbird_CWO2",
            "providerZoneId": ["PE1", "PE2"]
        }
    ],
    "dimensions": [
        "sessionsInitiated",
        "sumSessionRevenue"
    ]
}
 */
export interface PaymentSessionReportRequest {
    jurisdictionId: string
    rollUpLevel: JurisdictionRollupType
    timeScale: TimeScaleMap
    providerDefinition: ProviderDefinition[]
    dimensions: PaymentSessionDimension[]
}


export enum JurisdictionRollupType {
    JURISDICTION_ONLY = 'jurisdictionOnly',
    JURISDICTION_AND_PROVIDER = 'jurisdictionAndProvider',
    JURISDICTION_AND_PROVIDER_ZONE = 'jurisdictionAndProviderZone'
}


export enum PaymentSessionDimension {
    SESSIONS_INITIATED = 'sessionsInitiated',
    SUM_SESSION_REVENUE = 'sumSessionRevenue',
    AVG_SESSION_REVENUE = 'avgSessionRevenue',
    MAX_SESSION_REVENUE = 'maxSessionRevenue',
    MIN_SESSION_REVENUE = 'minSessionRevenue',
    SUM_SESSION_DURATION = 'sumSessionDuration',
    AVG_SESSION_DURATION = 'avgSessionDuration',
    MAX_SESSION_DURATION = 'maxSessionDuration',
    MIN_SESSION_DURATION = 'minSessionDuration'
}


export enum PaymentSessionTimeScale {
    YEARLY = 'yearly', // uses YearlyProperties
    QUARTERLY = 'quarterly', // uses QuarterlyProperties
    QUARTERLY_SPLIT_BY_MONTHS = 'quarterlySplitByMonths', // uses QuarterlyProperties
    MONTHLY = 'monthly', // uses MonthlyProperties
    MONTHLY_SPLIT_BY_WEEKS = 'monthlySplitByWeeks', // uses MonthlyProperties
    MONTHLY_SPLIT_BY_DAYS = 'monthlySplitByDays', // uses MonthlyProperties
    WEEKLY = 'weekly', // uses WeeklyProperties
    WEEKLY_SPLIT_BY_DAYS = 'weeklySplitByDays', // uses WeeklyProperties
    WEEKLY_SPLIT_BY_HOURS = 'weeklySplitByHours', // uses WeeklyProperties
    DAILY = 'daily', // uses DailyProperties
    DAILY_SPLIT_BY_HOURS = 'dailySplitByHours', // uses DailyProperties
    HOURLY = 'hourly' // uses HourlyProperties
}


/////////////////////////////////////////
/// The following are parameters dependent on the provided
/// PaymentSessionTimeScale within the request.
/// All of these have optional parameter fields, and can accept either
/// a single value or an array of values
/////////////////////////////////////////

type YearlyProperties = {
    year?: number | number[]
    timeSeriesVariable?: TimeSeriesVariable | TimeSeriesVariable[]
}

type Quarter = 1 | 2 | 3 | 4
type QuarterlyProperties = {
    year?: number | number[]
    quarter?: Quarter | Quarter[]
    timeSeriesVariable?: TimeSeriesVariable | TimeSeriesVariable[]
}

type Month = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
type MonthlyProperties = {
    year?: number | number[]
    month?: Month | Month[]
    timeSeriesVariable?: TimeSeriesVariable | TimeSeriesVariable[]
}

type DayOfWeek = 1 | 2 | 3 | 4 | 5 | 6 | 7 // 1 = Monday, 7 = Sunday (ISO)

type Week = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24 | 25 | 26 | 27 | 28 | 29 | 30 | 31 | 32 | 33 | 34 | 35 | 36 | 37 | 38 | 39 | 40 | 41 | 42 | 43 | 44 | 45 | 46 | 47 | 48 | 49 | 50 | 51 | 52 | 53

type WeeklyProperties = {
    isoYear?: number | number[]
    week?: Week | Week[]
}

type Day = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24 | 25 | 26 | 27 | 28 | 29 | 30 | 31
type DailyProperties = {
    year?: number | number[]
    isoYear?: number | number[]
    month?: Month | Month[]
    dayOfMonth?: Day | Day[]
    dayOfWeek?: DayOfWeek | DayOfWeek[]
    timeSeriesVariable?: TimeSeriesVariable | TimeSeriesVariable[]
}

type Hour = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23
type HourlyProperties = {
    year?: number | number[]
    isoYear?: number | number[]
    month?: Month | Month[]
    week?: Week | Week[]
    dayOfMonth?: Day | Day[]
    dayOfWeek?: DayOfWeek | DayOfWeek[]
    hour?: Hour | Hour[]
    hourFromBase?: number | number[]
    timeSeriesVariable?: TimeSeriesVariable | TimeSeriesVariable[]
}

// Create a type for the union of all possible TimeScale properties
export type TimeScaleProperties = YearlyProperties | QuarterlyProperties | MonthlyProperties | DailyProperties | WeeklyProperties | HourlyProperties

// A mapping type to associate TimeScale values with their respective properties
// Note only one (mandatory) key/value can be passed within the map structure
type TimeScaleMap = {
    [key in PaymentSessionTimeScale]?: TimeScaleProperties | TimeScaleProperties[]
}


export interface ProviderDefinition {
    provider: string
    providerZoneId?: string | string[]
}


export enum TimeSeriesVariable {
    CURRENT_YEAR = 'currentYear',
    CURRENT_YEAR_TO_DATE = 'currentYearToDate',
    PREVIOUS_YEAR = 'previousYear',  // eg. compare 2024 to 2023
    PREVIOUS_YEAR_TO_DATE = 'previousYearToDate',
    CURRENT_QUARTER = 'currentQuarter',
    CURRENT_QUARTER_TO_DATE = 'currentQuarterToDate',
    PREVIOUS_QUARTER = 'previousQuarter',
    PREVIOUS_QUARTER_TO_DATE = 'previousQuarterToDate',
    PREVIOUS_YEAR_SAME_QUARTER = 'previousYearSameQuarter',
    PREVIOUS_YEAR_SAME_QUARTER_TO_DATE = 'previousYearSameQuarterToDate',
    CURRENT_MONTH = 'currentMonth',
    CURRENT_MONTH_TO_DATE = 'currentMonthToDate',
    PREVIOUS_MONTH = 'previousMonth', // eg compare January 2024 to December 2023
    PREVIOUS_MONTH_TO_DATE = 'previousMonthToDate', // eg compare January 1st - 15th 2024 to December (previous year) 1st - 15th 2023
    PREVIOUS_YEAR_SAME_MONTH = 'previousYearSameMonth', //eg compare January 2024 to January 2023
    PREVIOUS_YEAR_SAME_MONTH_TO_DATE = 'previousYearSameMonthToDate', // eg compare January 1st - 15th 2024 to January 1st - 15th 2023
    CURRENT_WEEK = 'currentWeek',
    CURRENT_WEEK_TO_DATE = 'currentWeekToDate',
    PREVIOUS_WEEK = 'previousWeek',
    PREVIOUS_WEEK_TO_DATE = 'previousWeekToDate',
    PREVIOUS_YEAR_SAME_WEEK = 'previousYearSameWeek',
    PREVIOUS_YEAR_SAME_WEEK_TO_DATE = 'previousYearSameWeekToDate',
    CURRENT_DAY = 'currentDay',
    CURRENT_DAY_TO_DATE = 'currentDayToDate',
    PREVIOUS_DAY = 'previousDay',
    PREVIOUS_DAY_TO_DATE = 'previousDayToDate',
    PREVIOUS_WEEK_SAME_DAY = 'previousWeekSameDay',
    PREVIOUS_WEEK_SAME_DAY_TO_DATE = 'previousWeekSameDayToDate',
    CURRENT_HOUR = 'currentHour',
    PREVIOUS_HOUR = 'previousHour',
    PREVIOUS_DAY_SAME_HOUR = 'previousDaySameHour',
}



/**
 * Response
 */
export interface PaymentSessionReportResult {
    result: PaymentSessionReport[]
}

export interface PaymentSessionReportWithId extends PaymentSessionReport {
    id: string
}

export interface PaymentSessionReport {
    jurisdictionId: string
    provider?: string       // Dependant on the RollupType requested
    providerZoneId?: string       // Dependant on the RollupType requested
    locale: string       // Timezone eg. 'America/New_York' etc
    currency: string
    tag?: string       // Time series tagging
    series: TimeValues[]
}


export interface TimeValues {
    year?: number               // --|
    isoYear?: number            //   |
    quarter?: number            //   |
    month?: number              //   |
    week?: number               //   | Dependant on the TimeSeries
    dayOfMonth?: number         //   | requested
    dayOfWeek?: number          //   |
    hour?: number               //   |
    hourFromBase?: number       // --|
    sessionsInitiated?: number  // --|
    sumSessionRevenue?: number  //   |
    avgSessionRevenue?: number  //   |
    maxSessionRevenue?: number  //   | Dependant on the Dimensions
    minSessionRevenue?: number  //   | requested
    sumSessionDuration?: number //   |
    avgSessionDuration?: number //   |
    maxSessionDuration?: number //   |
    minSessionDuration?: number // --|
}