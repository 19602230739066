import { createSelector } from '@reduxjs/toolkit'
import { initialState, name } from './messageSlice'


export const selectDomain = state => state[name] || initialState

export const domainSelector = key =>
    createSelector([selectDomain], state => state[key])

export const selectMessages = domainSelector('messages')
export const selectLoading = domainSelector('loading')
export const selectSaving = domainSelector('saving')
export const selectDeleting = domainSelector('deleting')
export const selectError = domainSelector('error')