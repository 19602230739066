import chroma from 'chroma-js'
import { DateTime } from 'luxon'
import { AxisConfig, EventStatisticType, ReportGroupBy } from 'features/resource/types'


/** @type {string[]} */
export const colors = [
    'DodgerBlue',
    'darkred',
    'darkmagenta',
    'darkolivegreen',
    'DarkSlateBlue',
    'DarkGoldenRod',
    'DarkSlateGrey',
    'lime',
    'gold',
    'darkcyan',
    'fuchsia',
    'sienna',
    'tomato',
]
/** @type {string[]} */
export const occupancyColors = colors.map(color => chroma(color).darken().hex())
export const evColors = [
    'green',
    'lime',
    'gold',
    'sienna',
    'cornflowerblue',
    'fuchsia',
    'darkcyan',
    'darkolivegreen',
    'darkmagenta',
    'brown',
    'tomato',
    'cyan'
]
export const overflowColors = occupancyColors.map(color =>
    chroma(color).saturate().hex()
)

export const MS_PER_MINUTE = 60 * 1000
export const MS_PER_HOUR = 60 * MS_PER_MINUTE
export const MS_PER_DAY = 24 * MS_PER_HOUR
export const MS_PER_WEEK = 7 * MS_PER_DAY
export const MS_PER_MONTH = MS_PER_DAY * 31
export const tzOffsetMs = DateTime.now().offset * 60 * 1000

const yAxisTickValues = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

const yAxisTickFormats = yAxisTickValues.map(value => `${value}%`)

const weekValues = {
    x: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
}

const dayValues = {
    x: [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24]
}

const now = DateTime.now()

const occupancyY = {
    formats: yAxisTickFormats,
    label: 'Occupancy %',
    values: yAxisTickValues
}

const secondsPerDay = 60 * 60 * 24
const msPerDay = secondsPerDay * 1000
const msPerHour = msPerDay / 24

export const occupancyGroupByAxes: Record<ReportGroupBy, AxisConfig> = {
    [ReportGroupBy.week]: {
        y: occupancyY,
        x: {
            formats: weekValues.x,
            label: 'Day of the Week',
            values: weekValues.x.map((_, index) => secondsPerDay * index)
        }
    },
    [ReportGroupBy.day]: {
        y: occupancyY,
        x: {
            values: dayValues.x,
            label: 'Hour',
            // ["12:00 AM", "1 AM", "2 AM", ...]
            formats: dayValues.x.map(hour =>
                now
                    .set({hour, minute: 0, millisecond: 1})
                    .toLocaleString(DateTime.TIME_SIMPLE)
            )
        }
    }
}

const statisticY = {
    label: '# Events'
}

// Statistics use msSinceMidnight. Convert hours -> milliseconds
const statisticDayXValues = dayValues.x.map(hour => hour * msPerHour)

export const statisticGroupByAxes: Record<ReportGroupBy, AxisConfig> = {
    [ReportGroupBy.week]: {
        y: statisticY,
        x: {
            formats: weekValues.x,
            label: 'Day of the Week',
            values: weekValues.x.map((_, index) => msPerDay * index)
        }
    },
    [ReportGroupBy.day]: {
        y: statisticY,
        x: {
            values: statisticDayXValues,
            label: 'Hour',
            // ["12:00 AM", "1 AM", "2 AM", ...]
            formats: dayValues.x.map(hour =>
                now
                    .set({hour, minute: 0, millisecond: 1})
                    .toLocaleString(DateTime.TIME_SIMPLE)
            )
        }
    }
}

export const StatisticTypeDisplayNameMap = {
    [EventStatisticType.sum]: 'Sum',
    [EventStatisticType.min]: 'Min',
    [EventStatisticType.max]: 'Max',
    [EventStatisticType.avg]: 'Avg',
    [EventStatisticType.stdDev]: 'Std Dev'
}
