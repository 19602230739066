/* eslint-disable */

import React from 'react'
import PropTypes from 'prop-types'
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar'
import { NavLink } from 'react-router-dom'
import cx from 'classnames'
import { uniq, intersection } from 'lodash'

import Avatar from '@material-ui/core/Avatar'
import Collapse from '@material-ui/core/Collapse'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import JurisdictionPicker from './JurisdictionPicker'
import logo from 'assets/img/ModiiLogo.svg'
import sidebarStyle from 'assets/jss/material-dashboard-pro-react/components/sidebarStyle'

var ps

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    if (this.sidebarWrapper && navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.sidebarWrapper, {
        suppressScrollX: true,
        suppressScrollY: false
      })
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy()
    }
  }

  render() {
    const { className, user, /* headerLinks, */ links } = this.props
    return (
      <div
        className={className}
        ref={component => {
          this.sidebarWrapper = component
        }}
      >
        {user}
        {/* {headerLinks} */}
        {links}
      </div>
    )
  }
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openAvatar: false,
      openComponents: this.activeRoute('/components'),
      openForms: this.activeRoute('/forms'),
      openTables: this.activeRoute('/tables'),
      openMaps: this.activeRoute('/maps'),
      openPages: this.activeRoute('-page'),
      miniActive: true
    }
    this.activeRoute.bind(this)
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? true : false
  }

  openCollapse(collapse) {
    var st = {}
    st[collapse] = !this.state[collapse]
    this.setState(st)
  }

  render() {
    const {
      classes,
      color,
      // logo,
      image,
      logoText,
      routes,
      bgColor,
      rtlActive,
      authenticatedUser
    } = this.props

    const itemText =
      classes.itemText +
      ' ' +
      cx({
        [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
        [classes.itemTextMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.itemTextRTL]: rtlActive
      })
    const collapseItemText =
      classes.collapseItemText +
      ' ' +
      cx({
        [classes.collapseItemTextMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.collapseItemTextMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.collapseItemTextRTL]: rtlActive
      })
    const userWrapperClass =
      classes.user +
      ' ' +
      cx({
        [classes.whiteAfter]: bgColor === 'white'
      })
    const caret =
      classes.caret +
      ' ' +
      cx({
        [classes.caretRTL]: rtlActive
      })
    const collapseItemMini =
      classes.collapseItemMini +
      ' ' +
      cx({
        [classes.collapseItemMiniRTL]: rtlActive
      })
    const authUser =
      JSON.parse(localStorage.getItem('user')) || authenticatedUser
    const jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'))
    const jurisdictionId = jurisdiction?.id || null
    const jurisdictionParameter = `jurisdictionId=${jurisdictionId}`
    let roles = authUser.roles || null
    if (jurisdictionId) {
      roles = roles.filter(o => {
        if (o.role === 'superAdmin') {
          return true
        }
        if (o.parameters && o.parameters === jurisdictionParameter) {
          return true
        }
        if (o.role === 'jurisdictionScope' && o.parameters === jurisdictionId) {
          return true
        }
        return false
      })
    }

    const roleNames = uniq(roles.map(o => o.role))
    const isSuperAdmin = roleNames.includes('superAdmin')

    let approvedRoutes = routes.filter(route => {
      if (route.admin) {
        if (route.roles) {
          if (intersection(route.roles, roleNames).length > 0) {
            return true
          } else {
            return false
          }
        } else {
          return true
        }
      } else {
        return true
      }
    })

    var user = (
      <div className={userWrapperClass}>
        <List className={classes.list}>
          <ListItem className={classes.item + ' ' + classes.userItem}>
            <NavLink
              to={'#'}
              className={classes.itemLink + ' ' + classes.userCollapseButton}
              onClick={() => this.openCollapse('openAvatar')}
            >
              <ListItemAvatar>
                <Avatar>
                  {authenticatedUser.firstName[0]}
                  {authenticatedUser.lastName[0]}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`${authenticatedUser.firstName} ${authenticatedUser.lastName}`}
                secondary={
                  <b
                    className={
                      caret +
                      ' ' +
                      classes.userCaret +
                      ' ' +
                      (this.state.openAvatar ? classes.caretActive : '')
                    }
                  />
                }
                disableTypography={true}
                className={itemText + ' ' + classes.userItemText}
              />
            </NavLink>
            <Collapse in={this.state.openAvatar} unmountOnExit>
              <List className={classes.list + ' ' + classes.collapseList}>
                {!isSuperAdmin ? null : (
                  <ListItem className={classes.collapseItem} divider>
                    <JurisdictionPicker />
                  </ListItem>
                )}
                <ListItem className={classes.collapseItem}>
                  <NavLink
                    to="/login"
                    className={
                      classes.itemLink + ' ' + classes.userCollapseLinks
                    }
                  >
                    <span className={collapseItemMini}>{'LG'}</span>
                    <ListItemText
                      primary={'Logout'}
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>
                <ListItem
                  className={classes.collapseItem}
                  onClick={() => {
                    Object.keys(localStorage)
                      .filter(
                        key =>
                          key.startsWith('parkingStats') ||
                          key.startsWith('timeSeries') ||
                          key.includes('occupancyReportSettings')
                      )
                      .forEach(key => {
                        localStorage.removeItem(key)
                      })
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <div
                    className={
                      classes.itemLink + ' ' + classes.userCollapseLinks
                    }
                  >
                    <span className={collapseItemMini}>{'CC'}</span>
                    <ListItemText
                      className={collapseItemText}
                      disableTypography={true}
                      primary={'Clear Cache'}
                    />
                  </div>
                </ListItem>
              </List>
            </Collapse>
          </ListItem>
        </List>
      </div>
    )

    var links = (
      <List className={classes.list}>
        {approvedRoutes.map((prop, key) => {
          if (prop.redirect) {
            return null
          }
          if (prop.collapse) {
            const navLinkClasses =
              classes.itemLink +
              ' ' +
              cx({
                [' ' + classes.collapseActive]: this.activeRoute(prop.path)
              })
            const itemText =
              classes.itemText +
              ' ' +
              cx({
                [classes.itemTextMini]:
                  this.props.miniActive && this.state.miniActive,
                [classes.itemTextMiniRTL]:
                  rtlActive && this.props.miniActive && this.state.miniActive,
                [classes.itemTextRTL]: rtlActive
              })
            const collapseItemText =
              classes.collapseItemText +
              ' ' +
              cx({
                [classes.collapseItemTextMini]:
                  this.props.miniActive && this.state.miniActive,
                [classes.collapseItemTextMiniRTL]:
                  rtlActive && this.props.miniActive && this.state.miniActive,
                [classes.collapseItemTextRTL]: rtlActive
              })
            const itemIcon =
              classes.itemIcon +
              ' ' +
              cx({
                [classes.itemIconRTL]: rtlActive
              })
            const caret =
              classes.caret +
              ' ' +
              cx({
                [classes.caretRTL]: rtlActive
              })
            return (
              <ListItem key={key} className={classes.item}>
                <NavLink
                  to={'#'}
                  className={navLinkClasses}
                  onClick={() => this.openCollapse(prop.state)}
                >
                  <ListItemIcon className={itemIcon}>
                    <prop.icon />
                  </ListItemIcon>
                  <ListItemText
                    primary={prop.name}
                    secondary={
                      <b
                        className={
                          caret +
                          ' ' +
                          (this.state[prop.state] ? classes.caretActive : '')
                        }
                      />
                    }
                    disableTypography={true}
                    className={itemText}
                  />
                </NavLink>
                <Collapse in={this.state[prop.state]} unmountOnExit>
                  <List className={classes.list + ' ' + classes.collapseList}>
                    {prop.views.map((prop, key) => {
                      if (prop.redirect) {
                        return null
                      }
                      const navLinkClasses =
                        classes.collapseItemLink +
                        ' ' +
                        cx({
                          [' ' + classes[color]]: this.activeRoute(prop.path)
                        })
                      const collapseItemMini =
                        classes.collapseItemMini +
                        ' ' +
                        cx({
                          [classes.collapseItemMiniRTL]: rtlActive
                        })
                      return (
                        <ListItem key={key} className={classes.collapseItem}>
                          <NavLink to={prop.path} className={navLinkClasses}>
                            <span className={collapseItemMini}>
                              {prop.mini}
                            </span>
                            <ListItemText
                              primary={prop.name}
                              disableTypography={true}
                              className={collapseItemText}
                            />
                          </NavLink>
                        </ListItem>
                      )
                    })}
                  </List>
                </Collapse>
              </ListItem>
            )
          }
          const navLinkClasses =
            classes.itemLink +
            ' ' +
            cx({
              [' ' + classes[color]]: this.activeRoute(prop.path)
            })
          const itemText =
            classes.itemText +
            ' ' +
            cx({
              [classes.itemTextMini]:
                this.props.miniActive && this.state.miniActive,
              [classes.itemTextMiniRTL]:
                rtlActive && this.props.miniActive && this.state.miniActive,
              [classes.itemTextRTL]: rtlActive
            })
          const itemIcon =
            classes.itemIcon +
            ' ' +
            cx({
              [classes.itemIconRTL]: rtlActive
            })
          return (
            <ListItem key={key} className={classes.item}>
              <NavLink to={prop.path} className={navLinkClasses}>
                <ListItemIcon className={itemIcon}>
                  <prop.icon />
                </ListItemIcon>
                <ListItemText
                  primary={prop.name}
                  disableTypography={true}
                  className={itemText}
                />
              </NavLink>
            </ListItem>
          )
        })}
      </List>
    )

    const logoNormal =
      classes.logoNormal +
      ' ' +
      cx({
        [classes.logoNormalSidebarMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.logoNormalSidebarMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.logoNormalRTL]: rtlActive
      })
    const logoMini =
      classes.logoMini +
      ' ' +
      cx({
        [classes.logoMiniRTL]: rtlActive
      })
    const logoClasses =
      classes.logo +
      ' ' +
      cx({
        [classes.whiteAfter]: bgColor === 'white'
      })
    const logoStyle = this.props.miniActive ? { padding: '0 0.25rem' } : {}
    var brand = (
      <div className={logoClasses}>
        <a href="https://www.modii.co" className={logoMini}>
          <img
            src={logo}
            alt="logo"
            className={classes.img}
            style={logoStyle}
          />
        </a>
        <a href="https://www.modii.co" className={logoNormal}>
          {logoText}
        </a>
      </div>
    )
    const drawerPaper =
      classes.drawerPaper +
      ' ' +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.drawerPaperRTL]: rtlActive
      })
    const sidebarWrapper =
      classes.sidebarWrapper +
      ' ' +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]:
          navigator.platform.indexOf('Win') > -1
      })
    return (
      <div
        ref={component => {
          this.mainPanel = component
        }}
      >
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor={rtlActive ? 'left' : 'right'}
            open={this.props.open}
            classes={{
              paper: drawerPaper + ' ' + classes[bgColor + 'Background']
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              // headerLinks={<HeaderLinks rtlActive={rtlActive} />}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: 'url(' + image + ')' }}
              />
            ) : null}
          </Drawer>
        </Hidden>
        <Hidden smDown>
          <Drawer
            // onMouseOver={() => this.setState({ miniActive: false })}
            // onMouseOut={() => this.setState({ miniActive: true })}
            anchor={rtlActive ? 'right' : 'left'}
            variant="permanent"
            open
            classes={{
              paper: drawerPaper + ' ' + classes[bgColor + 'Background']
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: 'url(' + image + ')' }}
              />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    )
  }
}

Sidebar.defaultProps = {
  bgColor: 'blue'
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(['white', 'black', 'blue']),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf([
    'white',
    'red',
    'orange',
    'green',
    'blue',
    'purple',
    'rose'
  ]),
  // logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  authenticatedUser: PropTypes.object
}

export default withStyles(sidebarStyle)(Sidebar)
