/* eslint-disable */
import React from 'react'
import AssignmentInd from '@material-ui/icons/AssignmentInd'
import { connect } from 'react-redux'
import { FORM_ERROR } from 'final-form'
import SweetAlert from 'react-bootstrap-sweetalert'
import withStyles from '@material-ui/core/styles/withStyles'

import Snackbar from 'components/Snackbar/Snackbar'
import { userActions } from 'features/user/userSlice'
import { userService } from 'services'
import validationFormsStyle from 'assets/jss/spot-admin/views/newTemplateGroupStyle'
import UserForm from 'views/Forms/UserForm'


class EditUserPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            alert: null,
            snackBarOpen: false,
            snackBarStatus: 'success',
            snackBarMessage: '',
            formInitialValues: null
        }

        this.submit = this.submit.bind(this)
        this.cancel = this.cancel.bind(this)
        this.finished = this.finished.bind(this)
        this.showSuccess = this.showSuccess.bind(this)
        this.removeAlert = this.removeAlert.bind(this)
        this.showNotification = this.showNotification.bind(this)
    }

    componentDidMount() {
        const {dispatch, users} = this.props
        if (!users.length) {
            // If the user somehow landed on this page without visiting user-admin first,
            // then we need to load the users for this jurisdiction
            let jurisdiction = JSON.parse(
                localStorage.getItem('user.jurisdiction') || {}
            )
            dispatch(userActions.getAll(jurisdiction.id))
        }
    }

    componentWillUnmount() {
        if (this.notificationTimer) {
            clearTimeout(this.notificationTimer)
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {dispatch, match, users} = nextProps

        if (!users) {
            let jurisdiction = JSON.parse(
                localStorage.getItem('user.jurisdiction') || {}
            )
            dispatch(userActions.getAll(jurisdiction.id))
            return {}
        }

        let editUserId = Number(match.params.id)
        if (prevState.id !== editUserId) {
            let user = users.find(u => editUserId === u.id)
            if (!user) {
                return {}
            }

            return {
                editUserId,
                formInitialValues: {
                    ...user,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    oldUserEmail: user.email,
                    email: user.email,
                    phone: user.phone
                    // roles: user.roles
                }
            }
        }
        return {}
    }

    showNotification(status, message) {
        if (!this.state.snackBarOpen) {
            this.setState({
                snackBarOpen: true,
                snackBarStatus: status,
                snackBarMessage: message
            })
            this.notificationTimer = setTimeout(
                function () {
                    this.setState({
                        snackBarOpen: false
                    })
                    this.notificationTimer = null
                }.bind(this),
                3000
            )
        }
    }

    showSuccess() {
        const {classes} = this.props

        this.setState({
            alert: (
                <SweetAlert
                    success
                    style={{display: 'block'}}
                    title="Success!"
                    onConfirm={() => this.finished()}
                    confirmBtnCssClass={classes.button + ' ' + classes.success}
                    confirmBtnText="Ok"
                >
                    The user has been updated successfully
                </SweetAlert>
            )
        })
    }

    finished() {
        const {history} = this.props

        this.setState({
            alert: null
        })
        history.goBack()
    }

    removeAlert() {
        this.setState({
            alert: null
        })
    }

    async submit(values) {
        const {dispatch} = this.props
        const {editUserId} = this.state
        try {
            let user = {
                email: values.email,
                firstName: values.firstName,
                id: editUserId,
                lastName: values.lastName,
                phone: values.phone
            }
            const updatedUser = await userService.updateUser(user)
            updatedUser.roles = values.roles
            dispatch(userActions.registerUser(updatedUser))
            this.showNotification('success', 'User has been successfully created!')
            this.showSuccess()
            return updatedUser
        } catch (error) {
            this.showNotification('danger', 'There was a problem updating the user!')
            return {
                [FORM_ERROR]: 'There was a problem updating the user'
            }
        }
    }

    cancel(pristine) {
        const {classes, history} = this.props

        if (!pristine) {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{display: 'block'}}
                        title="Warning!"
                        onConfirm={() => this.finished()}
                        onCancel={() => this.removeAlert()}
                        confirmBtnCssClass={classes.button + ' ' + classes.success}
                        cancelBtnCssClass={classes.button + ' ' + classes.danger}
                        confirmBtnText="Yes"
                        cancelBtnText="No"
                        showCancel
                    >
                        Cancelling will discard any changes made. Are you sure?
                    </SweetAlert>
                )
            })
        } else {
            history.goBack()
        }
    }

    render() {
        const {
            alert,
            snackBarOpen,
            snackBarMessage,
            snackBarStatus,
            formInitialValues
        } = this.state
        const {classes, readOnlyMode} = this.props

        return (
            <div>
                {alert}
                <Snackbar
                    place="tr"
                    color={snackBarStatus}
                    icon={AssignmentInd}
                    message={snackBarMessage}
                    open={snackBarOpen}
                    closeNotification={() => this.setState({snackBarOpen: false})}
                    close
                />
                <UserForm
                    classes={classes}
                    user={formInitialValues}
                    onCancel={this.cancel}
                    onSubmit={this.submit}
                    readOnlyMode={!!readOnlyMode}
                />
            </div>
        )
    }
}


function mapStateToProps(state) {
    const {alert, users} = state

    if (!users) {
        return {}
    }

    return {
        users: users.users,
        userListLoading: users.userListLoading,
        assignableRoles: users.assignableRoles,
        requestingAssignableRoles: users.requestingAssignableRoles,
        jurisdictionScopes: users.jurisdictionScopes,
        alert: alert || null
    }
}

export default connect(mapStateToProps)(
    withStyles(validationFormsStyle)(EditUserPage)
)
