import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { MessageForm, MessageFormData } from 'views/Forms/MessageForm/MessageForm'
import { messageActions } from 'features/message/messageSlice'
import { selectLoading, selectMessages } from 'features/message/messageSelectors'
import CircularProgress from '@material-ui/core/CircularProgress'


export interface EditMessageProps extends Record<string, any> {
    messageId: string
    readOnly: boolean
}


export const EditMessage = ({messageId, readOnly}: EditMessageProps) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const messages = useSelector(selectMessages)
    const loading = useSelector(selectLoading)

    useEffect(() => {
        dispatch(messageActions.getMessage(messageId))
    }, [messageId])


    const onSubmit = async (data: MessageFormData): Promise<void> => {
        const wasSuccessful = await dispatch(messageActions.updateMessage({...data, id: messageId}))
        if (wasSuccessful) {
            history.push(`/message-admin`)
        }
    }

    const onCancel = () => {
        history.push(`/message-admin`)
    }

    if (loading || !messages[messageId]) {
        return <CircularProgress />
    }

    return (
        <MessageForm
            message={messages[messageId]}
            onCancel={onCancel}
            onSubmit={onSubmit}
            readOnlyMode={readOnly}
        />
    )
}
export default EditMessage