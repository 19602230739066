import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/components/footerStyle'

const useStyles = makeStyles(styles)

function Footer({ ...props }) {
  const classes = useStyles()
  const { fluid, white } = props
  const containerClass = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white
  })
  const anchorClass =
    classes.a +
    cx({
      [' ' + classes.whiteColor]: white
    })

  return (
    <footer className={classes.footer}>
      <div className={containerClass}>
        <div className={classes.left}></div>
        <p className={classes.right}>
          &copy; {1900 + new Date().getYear()}{' '}
          <a href="https://www.modii.co" className={anchorClass}>
            {'Modii'}
          </a>
        </p>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  fluid: PropTypes.bool,
  white: PropTypes.bool
}

export default Footer
