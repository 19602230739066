export function stripHtml(html: string): string {
    const doc = new DOMParser().parseFromString(html, 'text/html')
    return [...doc.body.childNodes].reduce((text: string, childNode) => {
        if (childNode.textContent) {
            return text.concat('', childNode.textContent)
        }
        return text
    }, '')
}


export default stripHtml