export const sortBy = getter => (list, asc = true) => {
    const sortFn = asc ? sortAsc : sortDesc
    return [...list].sort((a, b) => {
        const leftValue = getter(a)
        const rightValue = getter(b)
        return sortFn(leftValue, rightValue)
    })
}

export default sortBy

export const sortAsc = (left, right) =>
    left < right ? -1 : left > right ? 1 : 0

export const sortDesc = (left, right) =>
    left < right ? 1 : left > right ? -1 : 0
