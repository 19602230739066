import Dexie from 'dexie'

export class AppDb extends Dexie {
  cache!: Dexie.Table<ICacheRecord, number>

  constructor() {
    super('AppDb')

    this.version(1).stores({
      cache: '++id, key, value, timestamp'
    })
  }
}

export interface ICacheRecord {
  id?: number
  key: string
  timestamp: number
  value: object | object[] | any
}

export const Db = new AppDb()
export default Db
