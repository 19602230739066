/* eslint-disable */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import InputAdornment from '@material-ui/core/InputAdornment'

// @material-ui/icons
import Email from '@material-ui/icons/Email'

// core components
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CustomInput from 'components/CustomInput/CustomInput'
import Button from 'components/CustomButtons/Button'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardFooter from 'components/Card/CardFooter'
import SnackbarContent from 'components/Snackbar/SnackbarContent'

import loginPageStyle from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle'

// import { userActions } from 'actions';
import { userActions } from 'features/user/userSlice'

class ResetPasswordPage extends React.Component {
  constructor(props) {
    super(props)

    const { dispatch } = props

    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: 'cardHidden',
      username: '',
      password: '',
      submitted: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleLogin = this.handleLogin.bind(this)
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function () {
        this.setState({ cardAnimaton: '' })
      }.bind(this),
      700
    )
  }

  handleChange(e, name) {
    const { value } = e.target
    this.setState({ [name]: value })
  }

  handleSubmit(e) {
    e.preventDefault()

    this.setState({ submitted: true })
    const { username } = this.state
    const { dispatch } = this.props

    if (username) {
      dispatch(userActions.passwordReset(username, true))
    }
  }

  handleLogin(e) {
    e.preventDefault()

    const { history } = this.props

    history.replace('/login')
  }

  render() {
    const { classes, requestingPasswordReset, alert } = this.props
    const { username, password, submitted } = this.state

    let alertNotice = null
    if (alert.type) {
      alertNotice = (
        <SnackbarContent message={alert.message} color={alert.type} />
      )
    }

    return (
      <div className={classes.content}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={6} md={4}>
              <form name="form" onSubmit={this.handleSubmit}>
                <Card login className={classes[this.state.cardAnimaton]}>
                  <CardHeader
                    className={`${classes.cardHeader} ${classes.textCenter}`}
                    color="primary"
                  >
                    <h4 className={classes.cardTitle}>Reset Password</h4>
                  </CardHeader>
                  <CardBody>
                    <CustomInput
                      labelText="Email"
                      id="username"
                      formControlProps={{
                        fullWidth: true
                      }}
                      value={username}
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        onChange: e => this.handleChange(e, 'username')
                      }}
                    />
                    {alertNotice}
                  </CardBody>
                  <CardFooter className={classes.justifyContentCenter}>
                    <Button
                      color="primary"
                      simple
                      size="lg"
                      disabled={requestingPasswordReset}
                      block
                      onClick={this.handleSubmit}
                    >
                      Reset
                    </Button>
                    <Button
                      color="danger"
                      simple
                      size="lg"
                      block
                      onClick={this.handleLogin}
                    >
                      Login Instead
                    </Button>
                  </CardFooter>
                </Card>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    )
  }
}

ResetPasswordPage.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  const { alert } = state
  const { requestingPasswordReset } = state.users

  return {
    requestingPasswordReset,
    alert
  }
}

export default connect(mapStateToProps)(
  withStyles(loginPageStyle)(ResetPasswordPage)
)
