import { Category } from 'features/resource/types/category'
import { Epoch, EpochUtc } from 'types/generics'


export enum ParkingStatInterval {
    fiveMinutes = 5,
    fifteenMinutes = 15,
    hour = 60,
    day = 1440,
    week = 10080
}


export enum ParkingEventType {
    // SD-632
    // maxSessionTimeExceeded = 'maxSessionTimeExceeded',
    occupancyRate = 'occupancyRate',
    occupancyStats = 'occupancyStats',
    occupancyStatsByTimeSegment = 'occupancyStatsByTimeSegment',
    parkingSession = 'parkingSession',
    sessionStats = 'sessionStats',
    unknownVehicleExit = 'unknownVehicleExit',
    vehicleViolationEvent = 'vehicleViolationEvent',
    // Tenant Occupancy Stats
    tenantOccupancyStats = 'tenantOccupancyStats',
    tenantOccupancyStatsByTimeSegment = 'tenantOccupancyStatsByTimeSegment',

    // Monthly Summary types
    tenantOccupancyCountsByTimeSegment = 'tenantOccupancyCountsByTimeSegment',
    tenantOccupancyCounts = 'tenantOccupancyCounts'
}


export enum EventStatisticType {
    avg = 'AVG',
    avgMax = 'AVGMAX',
    avgMin = 'AVGMIN',
    max = 'MAX',
    min = 'MIN',
    stdDev = 'STDDEV',
    sum = 'SUM'
}


export enum StatisticClassification {
    normal = 'normal',
    violator = 'violator',
    overall = 'overall'
}


export interface ParkingStatState {
    data: EventDatum[]
    eventType: ParkingEventType
    groupId: string
    id: string
    interval: ParkingStatInterval
    selectedResourceIds: string[]
    statisticType: EventStatisticType
    tzOffsetMs: number
}


export interface EventDatum {
    classification?: StatisticClassification
    eventType: ParkingEventType
    interval: ParkingStatInterval
    meta?: EventMeta
    resourceId: string
    statisticType: EventStatisticType
    timestamp: Epoch
    value: number
}


export interface EventMeta {
    count: {
        [StatisticClassification.normal]: number
        [StatisticClassification.violator]: number
        [StatisticClassification.overall]: number
    }
}


//-- Event Feed Responses (/parkingStats)
export interface ParkingStatResponse {
    results: ParkingStatResult[]
}


export interface ParkingStatResult {
    data: ParkingStatDatum[]
    key: ParkingEventType
    resourceId: string
    statisticType: EventStatisticType
}


export interface ParkingStatDatum {
    base: EpochUtc
    classification?: StatisticClassification
    interval: ParkingStatInterval
    statsProfile: number[]
    type: 'ISOWEEK_START'
}


//-- Event Feed Requests (/parkingStats)
export interface ParkingStatRequest {
    jurisdictionId: string
    resources: ParkingStatRequestResource[]
}


export interface ParkingStatRequestResource {
    // Intervals are in minutes
    intervals: ParkingStatInterval[]
    keys: ParkingEventType[]
    resourceIds: string[]
    statisticTypes: EventStatisticType[]
    // Weeks are relative to the 1st of January
    weeks: number[]
    year: number
}


//-- Parking Stats 2 (/parkingStats2)
// Map of parkingEventTypes: dataStructure
// This is used in the /availableStatistics, /report, & /parkingStats2 endpoints
export interface ParkingEventTypeData {
    [ParkingEventType.occupancyRate]?: TimeSeriesStatistic
    // SD-632
    // [ParkingEventType.maxSessionTimeExceeded]?: TimeGroupingStatistic
    [ParkingEventType.unknownVehicleExit]?: TimeGroupingStatistic
    [ParkingEventType.vehicleViolationEvent]?: TimeGroupingStatistic
    [ParkingEventType.sessionStats]?: TimeGroupingStatistic
    [ParkingEventType.occupancyStats]?: TimeGroupingStatistic
    [ParkingEventType.occupancyStatsByTimeSegment]?: TimeGroupingStatistic
}


// parkingStats2 request body
export interface ParkingStatsTwoRequest {
    jurisdictionId: string
    resources: ParkingStatsTwoResourceRequest[]
}


export interface ParkingStatsTwoResourceRequest extends ParkingEventTypeData {
    resourceIds: string[]
}


// parkingStats2 generic response body (see concrete implementations below)
export interface ParkingStatsTwoResponse<T> {
    results: ParkingStatsTwoResponseResult<T>[]
}


// Interval response
export type ParkingStatsTwoIntervalResponse =
    ParkingStatsTwoResponse<ParkingStatsTwoResponseIntervalData>
// Segmented data response
export type ParkingStatsTwoSegmentResponse =
    ParkingStatsTwoResponse<ParkingStatsTwoResponseSegmentData>


export interface ParkingStatsTwoResponseResult<T> {
    capacity: number
    category?: string
    data: T[]
    key: ParkingEventType
    resourceId: string
    statisticType: EventStatisticType
    timeZone: string
}


export type ParkingStatsTwoDataType = 'WEEK_START' | 'MONTH_START'


export interface ParkingStatsTwoResponseIntervalData {
    base: number
    classification?: StatisticClassification
    interval: number
    statsProfile: number[]
    type: ParkingStatsTwoDataType
}


export interface ParkingStatsTwoResponseSegmentData {
    base: number
    offset: number
    segmentHours: number
    statsProfile: number[]
    type: ParkingStatsTwoDataType
}


export interface TimeGroupingStatistic {
    TIME_GROUPING: {
        MONTH_START?: MonthStart
        WEEK_START?: WeekStart
    }
}


export interface TimeSeriesStatistic {
    TIME_SERIES: {
        WEEK_START: {
            [weekStart: Epoch]: {
                start: number
                end: number
            }
        }
    }
}


export interface WeekStart {
    [weekStart: Epoch]: {
        categories?: Category
        intervals: ParkingStatInterval[]
        source: string
        start: number
        statisticTypes: EventStatisticType[]
    }
}


export interface MonthStart {
    [monthStart: Epoch]: {
        categories?: Category
        end: number
        intervals?: ParkingStatInterval[]
        segmentTypes: SegmentType[]
        source: string
        start: number
        statisticTypes: EventStatisticType[]
    }
}


export interface SegmentType {
    offsets: number
    segmentHours: number
}
