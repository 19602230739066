import { Jurisdiction, JurisdictionDetail } from './api'

export enum JurisdictionView {
  simple = 'simple',
  advanced = 'advanced'
}

export interface JurisdictionSlice {
  error: string | null
  init: boolean
  // Detail view
  jurisdictionView: JurisdictionView
  selectedJurisdictionId: string | null
  jurisdictionDetail: JurisdictionDetail | null
  // History
  jurisdictionHistory: JurisdictionDetail[]
  jurisdictionHistoryLoading: boolean
  // List View
  jurisdictions: Record<string, Jurisdiction>
  loading: boolean
  saving: boolean
}
