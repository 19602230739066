/* eslint-disable */

import config from 'config'
import { authHeader } from '../helpers'
import { handlePermissionError } from './shared'

export const messageService = {
  getMessages,
  getMessage,
  addNewMessage,
  updateMessage,
  deleteMessage
}

const fetch = window.fetch

function getMessages(jurisdictionId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(
    `${config.apiUrl}/1.4/messages?jurisdictionReferenceId=${jurisdictionId}&retrieveAll=true`,
    requestOptions
  ).then(handleGetMessagesResponse)
}

function handleGetMessagesResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      // unauthorised
      if (response.status === 401) {
        // Redirect if 401 response returned from api
        return handlePermissionError()
      }

      // no records found
      if (response.status == 404) {
        return []
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    return data.messages
  })
}

function getMessage(messageId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(
    `${config.apiUrl}/1.4/messages/message/${messageId}`,
    requestOptions
  ).then(handleGetMessageResponse)
}

function handleGetMessageResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      // unauthorised
      if (response.status === 401) {
        // Redirect if 401 response returned from api
        return handlePermissionError()
      }

      // no records found
      if (response.status == 404) {
        return {}
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    return data.message
  })
}

function addNewMessage(message) {
  let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'))
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify({ message, jurisdictionId: jurisdiction.id })
  }

  return fetch(`${config.apiUrl}/1.4/messages/message`, requestOptions)
    .then(handleNewMessageResponse)
    .then(message => {
      return message
    })
}

function handleNewMessageResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      if (response.status === 401) {
        // Redirect if 401 response returned from api
        return handlePermissionError()
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    return data.message
  })
}

function updateMessage(message) {
  let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'))
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify({ message, jurisdictionId: jurisdiction.id })
  }

  return fetch(
    `${config.apiUrl}/1.4/messages/message/${message.id}`,
    requestOptions
  )
    .then(handleUpdateMessageResponse)
    .then(message => {
      return message
    })
}

function handleUpdateMessageResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      if (response.status === 401) {
        // Redirect if 401 response returned from api
        return handlePermissionError()
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    return data.message
  })
}

function deleteMessage(messageId) {
  let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'))
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify({ jurisdictionId: jurisdiction.id })
  }

  return fetch(
    `${config.apiUrl}/1.4/messages/message/${messageId}`,
    requestOptions
  )
    .then(handleDeleteMessageResponse)
    .then(status => {
      return status
    })
}

function handleDeleteMessageResponse(response) {
  return response.json().then(data => {
    if (!response.ok) {
      if (response.status === 401) {
        // Redirect if 401 response returned from api
        return handlePermissionError()
      }

      const error = (data && data.errors) || response.statusText
      return Promise.reject(error)
    }

    return data.status
  })
}
