// ##############################
// // // ValidationForms view styles
// #############################

import { cardTitle, dangerColor } from 'assets/jss/material-dashboard-pro-react'
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch'

import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle'

const newTemplateGroupFormsStyle = {
  ...customCheckboxRadioSwitch,
  cardTitle: {
    ...cardTitle,
    color: '#FFFFFF'
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  },
  actionButton: {
    margin: '0 0 0 5px',
    padding: '5px',
    '& svg': {
      marginRight: '0px'
    }
  },
  columnActions: {
    textAlign: 'right'
  },
  formCategory: {
    marginBottom: '0',
    color: '#999999',
    fontSize: '14px',
    padding: '10px 0 10px'
  },
  center: {
    textAlign: 'center'
  },
  justifyContentCenter: {
    justifyContent: 'center'
  },
  registerButton: {
    float: 'right'
  },
  danger: {
    color: dangerColor + '!important'
  },
  right: {
    textAlign: 'right'
  },
  left: {
    textAlign: 'left'
  },
  ...buttonStyle
}

export default newTemplateGroupFormsStyle
