import React, { useEffect, useState } from 'react'
import Cancel from '@material-ui/icons/Cancel'
import Check from '@material-ui/icons/Check'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
    ContentState
    // EditorState,
    // convertFromHTML,
    // convertToRaw
} from 'draft-js'
import { convertFromHTML } from 'draft-js'
import Create from '@material-ui/icons/Create'
import DateTimePicker from 'react-datetime-picker'
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker'
import Done from '@material-ui/icons/Done'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Message from '@material-ui/icons/Message'
import moment from 'moment'
import Revert from '@material-ui/icons/GetApp'
import Style from '@material-ui/icons/Style'
import SweetAlert from 'react-bootstrap-sweetalert'
import { useDispatch, useSelector } from 'react-redux'
import Warning from '@material-ui/icons/Warning'


import Button from 'components/CustomButtons/Button'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import { convertAppliesToPeriodRange } from 'utils/TimeStructures'
import {
    convertRestrictedPeriodStructureToApplies,
    determineTimeStructuresForDateRange
} from 'utils/TimeStructures'
import CustomTextField from 'components/redux-form/CustomTextField/CustomTextField'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Snackbar from 'components/Snackbar/Snackbar'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import editTemplateGroupFormsStyle from 'assets/jss/spot-admin/views/newTemplateGroupStyle'
import { stringOfLengthBetween } from 'helpers/validation'
import { templateActions } from 'actions'
import { templateService } from 'services'
import { userActions } from 'features/user/userSlice'

import { AuthUser, useUser } from 'utils/useUserRoles'
import { IJurisdictionDefaults } from 'features/user/types'
import { ICreateTemplateInstance, ITemplateInstance } from 'types/template/templateInstance'
import { selectCheckingDefaults, selectJurisdictionDefaults } from 'features/user/userSelectors'
import { selectLoading, selectSaving, selectLoadingTemplateInstances, selectTemplateInstances } from 'actions/templateSelectors'
import { useJurisdictionId } from 'utils/useJurisdictionId'

// @ts-ignore
export const useTemplateInstanceStyles = makeStyles(editTemplateGroupFormsStyle)


/**
 * Constants & Interfaces
 * ****************************
 */
export interface TemplateFormData {
    name: string
    description: string
    messageHeader: string
    messageDetail: string
    dateTimeRange: [Date, Date] | string
}


let defaultFromDate = new Date()
defaultFromDate.setHours(0, 0, 0, 0)
defaultFromDate.setDate(defaultFromDate.getDate() + 1)

let defaultToDate = new Date(defaultFromDate)
defaultToDate.setDate(defaultFromDate.getDate() + 1)

const defaultTemplateFormData: TemplateFormData = {
    name: 'Default',
    description: 'Default',
    messageHeader: 'Default',
    messageDetail: 'Default',
    dateTimeRange: [defaultFromDate, defaultToDate]
}


const defaultValidationResults: Partial<TemplateFormData> = {
    name: '',
    description: '',
    messageHeader: '',
    messageDetail: '',
    dateTimeRange: ''
}



/**
 * Type transforms
 * ****************************
 */
function templateFormDataToTemplateInstance(templateFormData: TemplateFormData, userId: number, templateId: string): ICreateTemplateInstance {
    const {name, description, messageHeader, messageDetail, dateTimeRange} = templateFormData
    let startDate = moment(dateTimeRange[0])
    let endDate = moment(dateTimeRange[1])
    let restrictedPeriods = determineTimeStructuresForDateRange(
        startDate,
        endDate
    )
    let applies = convertRestrictedPeriodStructureToApplies(restrictedPeriods)

    const messageAttributes = {
        messageHeader,
        messageDetail
    }
    return {
        applies,
        name,
        description: {
            'en-US': description
        },
        lastPublished: null,
        messageAttributes,
        modifiedBy: userId,
        published: false,
        template: templateId
    }
}


function templateInstanceToFormData(instance: ITemplateInstance, jurisdictionDefaults: IJurisdictionDefaults): TemplateFormData {
    const {name, description, messageAttributes} = instance
    const timeZone = jurisdictionDefaults.TIMEZONE || 'America/New_York'
    const dateRange = convertAppliesToPeriodRange(
        instance.applies,
        timeZone
    )
    const {messageHeader, messageDetail} = messageAttributes || {}
    return {
        name,
        description: description['en-US'] || '',
        dateTimeRange: [dateRange.start.toDate(), dateRange.end.toDate()],
        messageHeader: messageHeader || '',
        messageDetail: messageDetail || ''
    }
}


/**
 * Validation
 * ****************************
 */

function validate(values: TemplateFormData): Partial<TemplateFormData> {
    let errors: Partial<TemplateFormData> = {}

    if (!values.name) {
        errors.name = 'Name is a required field'
    } else if (!stringOfLengthBetween(values.name, 4, 50)) {
        errors.name = 'Name must be between 4 and 50 characters'
    }

    if (!values.description) {
        errors.description = 'Description is a required field'
    } else if (!stringOfLengthBetween(values.description, 1, 200)) {
        errors.description = 'Description must has a maximum of 200 characters'
    }

    const currentDate = new Date()
    const {dateTimeRange} = values
    const [startDate, endDate] = dateTimeRange
    if (endDate < currentDate) {
        errors.dateTimeRange = 'The end date cannot occur in the past'
    }
    if (startDate < currentDate) {
        errors.dateTimeRange = 'The start date cannot occur in the past'
    }
    if (endDate < startDate) {
        errors.dateTimeRange = 'The start date cannot be before the end date'
    }

    return errors
}



export interface TemplateInstanceFormProps extends Record<string, any> {
    onCancel(): void
    onSubmit(values: ICreateTemplateInstance): Promise<void>
    readOnlyMode: boolean
    templateId: string
    templateInstance: ITemplateInstance | null
}



export const TemplateInstanceForm = ({onCancel, onSubmit, readOnlyMode, templateId, templateInstance}: TemplateInstanceFormProps) => {
    const classes = useTemplateInstanceStyles()
    const dispatch = useDispatch()
    const jurisdictionId = useJurisdictionId()
    const user: AuthUser | null = useUser()
    const loading: boolean = useSelector(selectLoading)
    const saving: boolean = useSelector(selectSaving)
    const jurisdictionDefaults = useSelector(selectJurisdictionDefaults)
    const checkingJurisdictionDefaults = useSelector(selectCheckingDefaults)
    const haveJurisdictionDefaults = Object.keys(jurisdictionDefaults).length > 0

    useEffect(() => {
        const needToLoadDefaults = (
            !haveJurisdictionDefaults && !checkingJurisdictionDefaults
        )
        if (needToLoadDefaults) {
            dispatch(userActions.checkJurisdictionDefaults(jurisdictionId))
        }
    }, [haveJurisdictionDefaults, checkingJurisdictionDefaults, jurisdictionId])

    const [data, setData] = useState<TemplateFormData>(() => {
        if (templateInstance) {
            return templateInstanceToFormData(templateInstance, jurisdictionDefaults)
        }
        return {
            ...defaultTemplateFormData
        }
    })

    const [validationResults, setValidationResults] = useState<Partial<TemplateFormData>>({...defaultValidationResults})


    //-- Callbacks
    const updateData = (partialData: Partial<TemplateFormData>) => {
        setData({...data, ...partialData})
    }

    const reset = () => {
        setValidationResults({...defaultValidationResults})
        return onCancel()
    }

    const isValid = () => {
        const result: Partial<TemplateFormData> = validate(data)
        setValidationResults(result)
        return !Object.keys(result).length
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const valid = isValid()
        if (saving || loading || !valid || !user) {
            return
        }
        const asCreateTemplateInstance: ICreateTemplateInstance = templateFormDataToTemplateInstance(
            data,
            Number(user.id),
            templateId
        )
        return onSubmit(asCreateTemplateInstance)
    }


    //-- Render
    if (checkingJurisdictionDefaults || loading) {
        return <CircularProgress />
    }

    const titlePrefix = readOnlyMode
        ? 'View'
        : templateInstance
            ? 'Edit'
            : 'Create'
    const titleSuffix = templateInstance ? 'existing Template Instance' : 'new Template Instance'
    const title = `${titlePrefix} ${titleSuffix}`

    return (
        <Grid container>
            <Grid
                item
                xs={12}
                sm={12}
                md={9}
            >
                <Card>
                    <CardHeader color="primary" icon>
                        <CardIcon color="rose">
                            <Style />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>{title}</h4>
                    </CardHeader>
                    <CardBody>
                        <form onSubmit={handleSubmit} autoComplete="off">
                            <Grid container>
                                {/* Name */}
                                <Grid item xs={12}>
                                    <CustomTextField
                                        disabled={readOnlyMode}
                                        error={!!validationResults.name}
                                        fullWidth
                                        helperText={validationResults.name}
                                        label="NAME *"
                                        onChange={e => updateData({name: e.target.value})}
                                        value={data.name}
                                    />
                                </Grid>
                                {/* Description */}
                                <Grid item xs={12}>
                                    <CustomTextField
                                        disabled={readOnlyMode}
                                        error={!!validationResults.description}
                                        fullWidth
                                        helperText={validationResults.description}
                                        label="DESCRIPTION *"
                                        onChange={e => updateData({description: e.target.value})}
                                        value={data.description}
                                    />
                                </Grid>
                                {/* Date/Time range */}
                                <Grid item xs={12}>
                                    <DateTimeRangePicker
                                        clearIcon={null}
                                        disableClock
                                        disabled={readOnlyMode}
                                        onBlur={() => isValid()}
                                        onChange={newDateTimeRange => {
                                            const [start, end] = newDateTimeRange
                                            updateData({
                                                dateTimeRange: [start, end]
                                            })
                                        }}
                                        value={data.dateTimeRange}
                                    />
                                </Grid>
                                {/* Date Warnings */}
                                {!validationResults.dateTimeRange ? null : (
                                    <div>
                                        <SnackbarContent
                                            message={validationResults.dateTimeRange as string}
                                            color="warning"
                                            icon={Warning}
                                        />
                                    </div>
                                )}
                                {/* Message Header */}
                                <Grid item xs={12}>
                                    <CustomTextField
                                        disabled={readOnlyMode}
                                        fullWidth
                                        label={'MESSAGE HEADER'}
                                        onChange={e => updateData({messageHeader: e.target.value})}
                                        value={data.messageHeader}
                                    />
                                </Grid>
                                {/* Message Detail */}
                                <Grid item xs={12}>
                                    <CustomTextField
                                        disabled={readOnlyMode}
                                        fullWidth
                                        label="MESSAGE DETAIL"
                                        maxRows={4}
                                        minRows={4}
                                        multiline
                                        onChange={e => updateData({messageDetail: e.target.value})}
                                        value={data.messageDetail}
                                        variant={'outlined'}
                                    />
                                </Grid>
                                {readOnlyMode ? null : (
                                    <Grid item xs={12}>
                                        <div className={classes.formCategory}>
                                            <small>*</small> Required fields
                                        </div>
                                    </Grid>
                                )}
                            </Grid>
                            {readOnlyMode ? null : (
                                <Grid
                                    container
                                    justifyContent={'flex-end'}
                                    spacing={2}
                                >
                                    <Grid item>
                                        {/*
                                         // @ts-ignore */}
                                        <Button
                                            className={classes.registerButton}
                                            color="danger"
                                            disabled={saving || loading || readOnlyMode}
                                            onClick={reset}
                                            type={'reset'}
                                        >
                                            <Cancel />
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        {/*
                                         // @ts-ignore */}
                                        <Button
                                            className={classes.registerButton}
                                            color="success"
                                            disabled={saving || loading || readOnlyMode}
                                            type="submit"
                                        >
                                            <Done />
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                        </form>
                    </CardBody>
                </Card>

            </Grid>
        </Grid>
    )


}

export default TemplateInstanceForm