import Dashboard from 'layouts/Dashboard'
import Login from 'views/Pages/LoginPage'
import ResetPasswordPage from 'views/Pages/ResetPasswordPage'

const indexRoutes = [
  { path: '/login', name: 'Login', component: Login, admin: false },
  {
    path: '/resetPassword',
    name: 'Reset Password',
    component: ResetPasswordPage,
    admin: false
  },
  { path: '/', name: 'Home', component: Dashboard, admin: true }
]

export default indexRoutes
