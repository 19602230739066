import Db, { ICacheRecord } from 'utils/db'

const ONE_MINUTE_MS = 60 * 1000
const TEN_MINUTES_MS = 10 * ONE_MINUTE_MS

export class Cache {
  ttl: number

  constructor(ttl = TEN_MINUTES_MS) {
    this.ttl = ttl
  }

  expired(timestamp: number): boolean {
    const now = Number(new Date())
    return now - timestamp > this.ttl
  }

  async get(key) {
    const cacheValue: ICacheRecord | undefined = await Db.cache
      .where('key')
      .equals(key)
      .first()
    // const storedValue = localStorage.getItem(key)
    // const cacheValue = storedValue ? JSON.parse(storedValue) : null
    if (cacheValue && cacheValue.timestamp) {
      await nextTick()
      const isExpired = this.expired(cacheValue.timestamp)
      if (isExpired) {
        await Db.cache.where('key').equals(key).delete()
        // localStorage.removeItem(key)
        return null
      }
      return cacheValue.value
    }
    return null
  }

  async set(key, value) {
    // Delete existing cache record
    await Db.cache.where('key').equals(key).delete()
    const record: ICacheRecord = {
      key,
      value,
      timestamp: new Date().getTime()
    }
    await Db.cache.add(record)
    return value
  }

  async getOr(key, orFn) {
    const cacheValue = await this.get(key)
    if (cacheValue) {
      return cacheValue
    }
    const freshValue = await orFn()
    return this.set(key, freshValue)
  }
}

export const cache = new Cache(TEN_MINUTES_MS)
export default cache

async function nextTick() {
  return await new Promise(resolve => {
    requestAnimationFrame(() => resolve(true))
  })
}
