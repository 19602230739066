import { useMemo } from 'react'

export const getJurisdictionId = (): string | null => {
  const raw = localStorage.getItem('user.jurisdiction')
  return raw ? JSON.parse(raw)?.id : null
}

export const useJurisdictionId = () => {
  return useMemo(() => {
    return getJurisdictionId()
  }, [localStorage.getItem('user.jurisdiction')])
}
