import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { Provider } from 'react-redux'
import App from './App'
import ErrorBoundary from 'components/ErrorBoundary'
import { store } from 'helpers/store'
import ThemeProvider from 'utils/theme'

ReactDOM.render(
  <React.StrictMode>
    {/*
        // @ts-ignore */}
    <ErrorBoundary>
      <ThemeProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </ThemeProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
)
