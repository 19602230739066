/* eslint-disable */
import React, { useState } from 'react'
import AssignmentInd from '@material-ui/icons/AssignmentInd'
import Cancel from '@material-ui/icons/Cancel'
import Clear from '@material-ui/icons/Clear'
import Create from '@material-ui/icons/Create'
import { FORM_ERROR } from 'final-form'

import Button from 'components/CustomButtons/Button'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import {
    checkExistingEmail,
    equal,
    stringOfLengthBetween
} from 'helpers/validation'
import CustomTextField from 'components/redux-form/CustomTextField/CustomTextField'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import logError from 'utils/logError'
import { IdUser } from 'features/user/types'


export interface UserFormData {
    email: string
    firstName: string
    lastName: string
    phone: string
    password: string
    confirmPassword: string
}


const defaultUser: UserFormData = {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    password: '',
    confirmPassword: ''
}

const validate = (values: UserFormData, user: IdUser | null): Partial<UserFormData> => {
    const errors: Partial<UserFormData> = {}

    if (!user) {
        if (values.password && values.confirmPassword) {
            if (!equal(values.password, values.confirmPassword)) {
                errors.confirmPassword = 'Passwords must match'
            }
        }

        if (!values.password) {
            errors.password = 'Password is a required field'
        } else {
            if (!stringOfLengthBetween(values.password, 8, 20)) {
                errors.password = 'Password must be between 8 and 20 characters'
            }
        }
    }

    if (!values.firstName) {
        errors.firstName = 'First name is a required field'
    }

    if (!values.lastName) {
        errors.lastName = 'Last name is a required field'
    }

    if (!values.phone) {
        errors.phone = 'Phone number is a required field'
    }

    return errors
}

const asyncValidate = async (values: UserFormData, user: IdUser | null): Promise<Partial<UserFormData> | null> => {
    const syncValidation = validate(values, user)
    try {
        const result = (!user) || user.email !== values.email
            ? await checkExistingEmail(values.email)
            : {available: true}
        const hasErrors = Object.keys(syncValidation).length
        if (hasErrors) {
            const validationResult = {
                ...syncValidation,
                ...(result.available
                    ? {}
                    : {
                        email: 'This email address has already been registered.'
                    })
            }
            return Promise.resolve(validationResult)
        }
        // Return empty if there are no errors
        return null
    } catch (error) {
        return Promise.resolve({
            email:
                'There was a problem determining whether this email already exists or not. Please try again.',
            ...syncValidation
        })
    }
}


type SubmitResponse = Promise<IdUser | { [FORM_ERROR]: string }>


export interface UserFormProps {
    classes: any
    onSubmit(userFormData: UserFormData): Promise<SubmitResponse>
    onCancel(): void
    readOnlyMode: boolean
    user?: IdUser
}


export const UserForm = ({classes, onSubmit, onCancel, readOnlyMode, user}: UserFormProps) => {
    const [submitting, setSubmitting] = useState(false)
    const [email, setEmail] = useState(user?.email || '')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [firstName, setFirstName] = useState(user?.firstName || '')
    const [lastName, setLastName] = useState(user?.lastName || '')
    const [phone, setPhone] = useState(user?.phone || '')

    const [fieldValidation, setFieldValidation] = useState<UserFormData>({...defaultUser})

    const reset = () => {
        setSubmitting(false)
        setEmail(user?.email || '')
        setPassword('')
        setConfirmPassword('')
        setFirstName(user?.firstName || '')
        setLastName(user?.lastName || '')
        setPhone(user?.phone || '')
        setFieldValidation({...defaultUser})
    }

    const validateUserForm = async (): Promise<boolean> => {
        setSubmitting(true)
        try {
            const fieldValues: UserFormData = {
                email,
                firstName,
                lastName,
                password,
                confirmPassword,
                phone
            }
            const result = await asyncValidate(fieldValues, user || null)
            if (result && Object.keys(result).length) {
                setFieldValidation({...defaultUser, ...result})
                return false
            }
            return true
        } catch (error) {
            logError(error)
            return false
        } finally {
            setSubmitting(false)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (submitting) {
            return false
        }
        const doSubmit = await validateUserForm()
        if (doSubmit) {
            return onSubmit({
                email,
                firstName,
                lastName,
                password,
                confirmPassword,
                phone
            })
        }
        return false
    }
    const title = !user
        ? 'New User Creation Form'
        : readOnlyMode
            ? `View Existing ${user.firstName} ${user.lastName}`
            : `Edit Existing ${user.firstName} ${user.lastName}`

    const saveLabel = !user
        ? 'Create'
        : 'Save'

    // @ts-ignore
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
                <Card>
                    <CardHeader color="primary" icon>
                        <CardIcon color="primary">
                            <AssignmentInd />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>
                            {title}
                        </h4>
                    </CardHeader>
                    <CardBody>
                        <form
                            autoComplete="off"
                            onSubmit={handleSubmit}
                        >
                            <GridContainer>
                                <GridItem xs={9}>
                                    <CustomTextField
                                        fullWidth
                                        disabled={readOnlyMode}
                                        inputProps={{type: 'email'}}
                                        label="Email Address *"
                                        onChange={e => setEmail(e.target.value)}
                                        value={email}
                                        error={!!fieldValidation.email}
                                        helperText={fieldValidation.email}
                                    />
                                </GridItem>
                                {/* Password Fields: Only displayed when readOnly = false */}
                                {readOnlyMode || user
                                    ? null
                                    : (
                                        <>
                                            <GridItem xs={6}>
                                                <CustomTextField
                                                    fullWidth
                                                    inputProps={{type: 'password'}}
                                                    label="Password *"
                                                    onChange={e => setPassword(e.target.value)}
                                                    value={password}
                                                    error={!!fieldValidation.password}
                                                    helperText={fieldValidation.password}
                                                />
                                            </GridItem>
                                            <GridItem xs={6}>
                                                <CustomTextField
                                                    fullWidth
                                                    inputProps={{type: 'password'}}
                                                    label="Confirm Password *"
                                                    onChange={e => setConfirmPassword(e.target.value)}
                                                    value={confirmPassword}
                                                    error={!!fieldValidation.confirmPassword}
                                                    helperText={fieldValidation.confirmPassword}
                                                />
                                            </GridItem>
                                        </>
                                    )
                                }
                                <GridItem xs={6}>
                                    <CustomTextField
                                        fullWidth
                                        disabled={readOnlyMode}
                                        label="First Name *"
                                        onChange={e => setFirstName(e.target.value)}
                                        value={firstName}
                                        error={!!fieldValidation.firstName}
                                        helperText={fieldValidation.firstName}
                                    />
                                </GridItem>
                                <GridItem xs={6}>
                                    <CustomTextField
                                        fullWidth
                                        disabled={readOnlyMode}
                                        label="Last Name *"
                                        onChange={e => setLastName(e.target.value)}
                                        value={lastName}
                                        error={!!fieldValidation.lastName}
                                        helperText={fieldValidation.lastName}
                                    />
                                </GridItem>
                                <GridItem xs={6}>
                                    <CustomTextField
                                        fullWidth
                                        disabled={readOnlyMode}
                                        label="Phone Number *"
                                        onChange={e => setPhone(e.target.value)}
                                        value={phone}
                                        error={!!fieldValidation.phone}
                                        helperText={fieldValidation.phone}
                                    />
                                </GridItem>
                                <GridItem xs={12}>
                                    <div className={classes.formCategory}>
                                        <small>*</small> Required fields
                                    </div>
                                </GridItem>
                            </GridContainer>
                            {/*
                             // @ts-ignore */}
                            <Button
                                color="success"
                                className={classes.registerButton}
                                disabled={submitting}
                                type={'submit'}
                            >
                                <Create className={classes.icon} />
                                {saveLabel}
                            </Button>
                            {/*
                             // @ts-ignore */}
                            <Button
                                color="danger"
                                className={classes.registerButton}
                                style={{marginRight: '10px'}}
                                onClick={() => onCancel()}
                            >
                                <Cancel className={classes.icon} />
                                Cancel
                            </Button>
                            {/*
                             // @ts-ignore */}
                            <Button
                                color="primary"
                                className={classes.registerButton}
                                style={{marginRight: '25px'}}
                                onClick={reset}
                            >
                                <Clear className={classes.icon} />
                                Clear
                            </Button>
                        </form>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}

export default UserForm