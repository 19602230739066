import * as Comlink from 'comlink'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import baseReducer from 'features/baseReducer'
import { DateRange, OccupancyReport } from 'features/resource/types'
import { EntityMap } from 'types/generics'
import { ErrorResponseCode } from 'types/request'
import { getJurisdictionId } from 'utils/useJurisdictionId'
import { getZoneName } from 'utils/useTimeZone'
import { GoogleMapCoordinate } from 'types/coordinate'
import { HeatMapSlice } from 'features/heatMap/types/state'
import _heatMapWorker from './heatMapWorker?worker'
import { HeatMapWorker, OutlineResponse } from 'features/heatMap/types/api'
import logError from 'utils/logError'
import { MS_PER_HOUR } from 'constants/constants'
import {
  Outline,
  ResourceOutline,
  WeightedLocation
} from 'features/heatMap/types/outline'
import { templateService, userService } from 'services'
import { name as resourceSliceName } from 'features/resource/resourceSlice'

const heatMapWorker: HeatMapWorker = Comlink.wrap(new _heatMapWorker())

// 39.79970025964955, -105.08076871514363
export const DEFAULT_CENTER = {
  lat: 39.79970025964955,
  lng: -105.08076871514363
}
export const DEFAULT_ZOOM = 14

export const initialState: HeatMapSlice = {
  available: false,
  init: false,
  loading: false,
  locations: [],
  mapCenter: DEFAULT_CENTER,
  mapZoom: DEFAULT_ZOOM,
  msOffset: MS_PER_HOUR * 12,
  outlines: {}, // {[areaReferenceId: string]: outline}
  resourceOutlines: {},
  selectedDateRange: null,
  selectedResourceIds: []
}

const heatMapSlice = createSlice({
  initialState,
  name: 'heatMap',
  reducers: {
    ...baseReducer,
    setAvailable: (state, { payload }: PayloadAction<boolean>) => {
      state.available = payload
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },
    setInit: (state, { payload }: PayloadAction<boolean>) => {
      state.init = payload
    },
    setLocations: (state, { payload }: PayloadAction<WeightedLocation[]>) => {
      state.locations = payload
    },
    setMapCenter: (state, { payload }: PayloadAction<GoogleMapCoordinate>) => {
      state.mapCenter = payload
    },
    setMapZoom: (state, { payload }: PayloadAction<number>) => {
      state.mapZoom = payload
    },
    setMsOffset: (state, { payload }: PayloadAction<number>) => {
      state.msOffset = payload
    },
    setOutlines: (state, { payload }: PayloadAction<EntityMap<Outline>>) => {
      state.outlines = payload
    },
    setSelectedDateRange: (
      state,
      { payload }: PayloadAction<DateRange | null>
    ) => {
      state.selectedDateRange = payload
    },
    setSelectedResourceIds: (state, { payload }: PayloadAction<string[]>) => {
      state.selectedResourceIds = payload
    },

    setResourceOutlines: (
      state,
      { payload }: PayloadAction<EntityMap<ResourceOutline>>
    ) => {
      state.resourceOutlines = payload
    }
  }
})

export const { actions, name, reducer } = heatMapSlice

//-- Actions
export const loadOutlines = () => async (dispatch, getState) => {
  try {
    const availableResources = getState()[resourceSliceName].resources
    const availableResourceIds: string[] = Object.keys(availableResources)
    const jurisdictionId = getJurisdictionId()
    const outlines: OutlineResponse = await templateService.outlines(
      jurisdictionId
    )
    const outlineEntityMap: EntityMap<Outline> = outlines.reduce(
      (accum, outline) => {
        if (availableResourceIds.includes(outline.areaReferenceId)) {
          accum[outline.areaReferenceId] = outline
        }
        return accum
      },
      {}
    )
    dispatch(actions.setOutlines(outlineEntityMap))
    await dispatch(loadMapCenter())
    dispatch(actions.setAvailable(true))
  } catch (error) {
    // if (error?.status === ErrorResponseCode.UNAUTHORIZED) {
    //   throw error
    // }
    // Template service.outlines throws when a jurisdiction doesn't
    // have any templates/outlines configured
    // Catch the error & flag heatmaps as unavailable
    dispatch(
      actions.setState({
        available: false,
        init: true,
        loading: false
      })
    )
  }
}

export const loadMapCenter = () => async dispatch => {
  const jurisdictionId = getJurisdictionId()
  try {
    const { DEFAULT_LOCATION } = await userService.checkJurisdictionDefaults(
      jurisdictionId
    )
    const { latitude, longitude } = DEFAULT_LOCATION
    // if (latitude && longitude) {
    //     dispatch(actions.setMapCenter({
    //         lat: latitude,
    //         lng: longitude
    //     }))
    //     return true
    // } else {
    //     return false
    // }
    dispatch(
      actions.setMapCenter({
        lat: latitude,
        lng: longitude
      })
    )
    return true
  } catch (error) {
    // Pass
    console.log(
      `No DEFAULT_LOCATION found for jurisdictionId ${jurisdictionId}`
    )
    return false
  }
}

export const generateHeatMap =
  (
    occupancyReport: OccupancyReport,
    updateFields: Partial<HeatMapSlice> = {}
  ) =>
  async (dispatch, getState) => {
    dispatch(actions.setLoading(true))
    try {
      await dispatch(loadOutlines())
      const state = getState()[name]
      const { msOffset } = state
      const resourceState = getState()[resourceSliceName]
      const { dateRangeSelections, resources, selectedResourceIds } =
        resourceState
      const selectedDateRange =
        updateFields.selectedDateRange ||
        state.selectedDateRange ||
        dateRangeSelections[0]
      const dateRangeMin = selectedDateRange.start + msOffset

      const dateRangeWithMsOffset = {
        ...(selectedDateRange || {}),
        start: dateRangeMin,
        end: dateRangeMin + MS_PER_HOUR * 2
      }

      dispatch(
        actions.setState({
          selectedResourceIds,
          ...updateFields,
          selectedDateRange: dateRangeWithMsOffset
        })
      )

      const zoneName = getZoneName()
      const params = JSON.parse(JSON.stringify(getState()[name]))
      const nextState = {
        ...(await heatMapWorker.generateHeatMapLocations(
          params,
          resources,
          occupancyReport,
          zoneName
        )),
        loading: false,
        init: true
      }
      dispatch(actions.setState(nextState))
      return true
    } catch (error) {
      logError(error)
      dispatch(actions.setInit(true))
      dispatch(actions.setLoading(false))
      throw error
    }
  }

export const reset = () => dispatch => {
  dispatch(actions.setState(initialState))
}

export const heatMapActions = {
  ...actions,
  generateHeatMap,
  loadMapCenter,
  loadOutlines,
  reset
}
