import React from 'react'
import {
  createTheme,
  ThemeProvider as MuiThemeProvider
} from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import {
  dangerColor,
  infoColor,
  successColor,
  warningColor
} from 'assets/jss/material-dashboard-pro-react'
import Lexend from '../assets/fonts/LexendRegular.ttf'
import LexendBold from '../assets/fonts/LexendBold.ttf'
import LexendMedium from '../assets/fonts/LexendMedium.ttf'

export const primary = '#156cfc'
export const secondary = '#00a889'
export const primaryDark = '#2462A0'

export const error = dangerColor[0]
export const warn = warningColor[0]
export const success = successColor[0]

// Font config: https://v4.mui.com/customization/typography/
const lexendFont = {
  fontFamily: `'Lexend', sans-serif`,
  fontStyle: 'normal',
  fontWeight: `400 500`,
  src: `
        local("Lexend"),
        local("Lexend"),
        url(${Lexend}) format("opentype")
    `
}

const lexendMedium = {
  ...lexendFont,
  fontWeight: 600,
  src: `
        local("Lexend"),
        local("Lexend"),
        url(${LexendMedium}) format("opentype")
    `
}

const lexendBold = {
  ...lexendFont,
  fontWeight: 700,
  src: `
        local("Lexend"),
        local("Lexend"),
        url(${LexendBold}) format("opentype")
    `
}

const headerFonts = {
  fontWeight: 700
}

const subTitleFonts = {
  fontWeight: 600
}

const otherFonts = {
  fontWeight: 500
}

export const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: primary,
      dark: primaryDark
    },
    secondary: {
      main: secondary
    },
    info: {
      main: infoColor[0]
    }
  },
  typography: {
    fontFamily: `'Lexend', 'Roboto', 'Helvetica', 'Arial', sans-serif`,
    h1: headerFonts,
    h2: headerFonts,
    h3: headerFonts,
    h4: headerFonts,
    h5: headerFonts,
    h6: headerFonts,
    subtitle1: subTitleFonts,
    subtitle2: subTitleFonts,
    caption: subTitleFonts,
    body1: otherFonts,
    body2: otherFonts,
    overline: otherFonts
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [lexendFont, lexendMedium, lexendBold]
      }
    }
  }
})

export const ThemeProvider = ({ ...props }) => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    {props.children}
  </MuiThemeProvider>
)
export default ThemeProvider
