import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'


import { templateActions } from 'actions'
import { TemplateInstanceForm } from './TemplateInstanceForm'
import { ICreateTemplateInstance } from 'types/template/templateInstance'
import { alertActions } from 'features/alert/alertSlice'
import logError from 'utils/logError'


export interface NewTemplateInstanceProps extends Record<string, any> {
    templateId: string
}


export const NewTemplateInstance = ({templateId}: NewTemplateInstanceProps) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const onSubmit = async (data: ICreateTemplateInstance): Promise<void> => {
        try {
            await dispatch(templateActions.addNewTemplateInstance(data))
            dispatch(alertActions.success('Successfully created Template Instance'))
            history.back()
        } catch (error) {
            logError(error)
            dispatch(alertActions.error('Encountered an error while attempting to create Template Instance'))
        }
        return
    }

    const onCancel = () => {
        history.push(`/template-admin/`)
        return
    }

    return (
        <TemplateInstanceForm
            onCancel={onCancel}
            onSubmit={onSubmit}
            readOnlyMode={false}
            templateId={templateId}
            templateInstance={null}
        />
    )
}
export default NewTemplateInstance