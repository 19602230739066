import { useMemo } from 'react'
import { jurisdictionZone } from 'constants/jurisdictions'
import { getJurisdictionId } from 'utils/useJurisdictionId'

export const getZoneName = (): string =>
  jurisdictionZone[getJurisdictionId()] || jurisdictionZone.default

export const useTimeZone = () => {
  return useMemo(() => {
    return getZoneName()
  }, [localStorage.getItem('user.jurisdiction')])
}
export default useTimeZone
