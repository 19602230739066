import { createSelector } from '@reduxjs/toolkit'
import { initialState, name } from './userSlice'
import { IJurisdictionDefaults } from 'features/user/types'


export const selectDomain = state => state[name] || initialState

export const selectUsers = createSelector(
    [selectDomain],
    ({users}) => users
)

export const selectUserListLoading = createSelector(
    [selectDomain],
    ({userListLoading}) => userListLoading
)

export const selectJurisdictionDefaults = createSelector(
    [selectDomain],
    ({jurisdictionDefaults}): IJurisdictionDefaults => jurisdictionDefaults
)

export const selectCheckingDefaults = createSelector(
    [selectDomain],
    ({checkingJurisdictionDefaults}): boolean => checkingJurisdictionDefaults
)

export const selectJurisdictionLocation = createSelector(
    [selectDomain],
    ({jurisdictionDefaults}) => jurisdictionDefaults.DEFAULT_LOCATION
)

export const selectJurisdictionTimezone = createSelector(
    [selectDomain],
    ({jurisdictionDefaults}): string => jurisdictionDefaults.TIMEZONE
)