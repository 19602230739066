import { createSelector } from '@reduxjs/toolkit'


const name = 'templates'

export const selectDomain = state => state[name]

export const domainSelector = key => createSelector([selectDomain], state => state[key])

export const selectSaving = createSelector(
    [selectDomain],
    ({saving}): boolean => saving || false
)

//-- Loading
export const selectLoading = createSelector(
    [selectDomain],
    ({loading}): boolean => loading || false
)
export const selectLoadingOutlines = domainSelector('loadingOutlines')
export const selectLoadingTemplates = domainSelector('loadingTemplates')
export const selectLoadingTemplateContainers = domainSelector('loadingTemplateContainers')
export const selectLoadingTemplateGroups = domainSelector('loadingTemplateGroups')
export const selectLoadingTemplateInstances = domainSelector('loadingTemplateInstances')

// Instances
export const selectTemplateInstances = createSelector(
    [selectDomain],
    ({templateInstances}) => templateInstances || {}
)
export const selectConflicts = createSelector(
    [selectDomain],
    ({conflicts}) => conflicts || {}
)


// Containers
export const selectTemplateContainers = createSelector(
    [selectDomain],
    ({templateContainers}) => templateContainers || {}
)

// Outlines
export const selectOutlines = createSelector(
    [selectDomain],
    ({outlines}) => outlines || {}
)
