/**
 * @module terms - Enum for text label lookups
 */
export const Terms = {
    reportPageTitle: 'Report Engine',
    configCard: 'Report Configuration',
    reportCard: 'Reports',
    advancedSearch: 'Advanced Search',
    // Report Parameters
    resourcePicker: 'Assets',
    resourcePickerSelectAll: 'Select All',
    datePicker: 'Dates',
    weekDayPicker: 'Days to Display',
    groupByPicker: 'Group By',
    // Report Type Radio Group labels
    reportType: 'Report Type',
    reportTypeDefault: 'Utilization',
    reportTypePrediction: 'Predictive',
    reportTypeProfile: 'Profile Time Series',
    reportTypeComparison: 'Profile Comparison',
    reportTypeEv: 'Charge Point Utilization',
    // Report Type - Stats report labels
    reportTypeSummary: 'Monthly Summary',
    // SD-632
    // reportTypeMaxSessionTimeExceeded: 'Max Session Time Exceeded',
    reportTypeParkingSession: 'Parking Sessions',
    reportTypeSessionStats: 'Parking Sessions',
    reportTypeUnknownVehicleExit: 'Unknown Vehicle Exit',
    reportTypeVehicleViolationEvent: 'Vehicle Violation',
    reportTypeOccupancyStats: 'Occupancy Stats',
    reportTypeHeatMap: 'Occupancy Heat Map',
    // Profile, level, category pickers
    profilePicker: 'Profiles',
    profileHelpText: 'User Profiles Associated with a Parking Permit',
    levelPicker: 'Levels',
    levelHelpText: 'Levels for multi-level garage Assets',
    categoryPicker: 'Categories',
    categoryHelpText: 'Bay Category/Type',
    generateReport: 'Generate Report',
    resetParameters: 'Reset',
    summaryProfile: 'User Profiles',
    summaryCategory: 'Bay Categories',
    summaryLevel: 'Levels',
    summaryMonthPickerLabel: 'Month',
    statisticTypePickerLabel: 'Statistic Types',
    legendGroupEmptyDataset: 'No Data To Display',
    summaryOffsetPickerLabel: 'Offset Hours',
    summarySegmentHoursPickerLabel: 'Segment Hours',
    summaryOccupancyProfileLabel: 'Use Profile',
    intervalPickerLabel: 'Interval',
    displayActualLabel: 'Display Actual',
    // Report Utils
    saveParameters: 'Save Parameters',
    saveToDashboard: 'Save to Dashboard',
    exportCsv: 'CSV',
    exportSvg: 'SVG',
    exportExcel: 'Excel',
    exportPdf: 'PDF',
    classificationPickerLabel: 'Classification',
    // Dashboard
    positionTitle: 'Position',
    toggleView: 'Toggle View',
    deleteSetting: 'Delete Report Setting',
    fullWidthToggle: 'Toggle Full Width',
    fullScreenToggle: 'Toggle Full Screen',
    moveLeft: 'Move Left',
    moveRight: 'Move Right',
    // Dashboard action messages
    dashboardDeleteStart: 'Deleting report...',
    dashboardDeleteSuccess: 'Successfully deleted report',
    dashboardDeleteError:
        'We encountered an error whilst deleting report. Please contact support',
    dashboardMoveStart: 'Moving report...',
    dashboardMoveSuccess: 'Successfully moved report',
    dashboardMoveError:
        'We encountered an error whilst moving report. Please contact support',
    // Jurisdictions
    manageJurisdictionsTitle: 'Manage Jurisdictions',
    copyJurisdictionDialogTitle: 'Values to Import',
    // Configs
    manageConfigsTitle: 'Client Configs',
    manageConfigsListTitle: 'Clients',
    // Payment Reports
    paymentReportPageTitle: 'Financial Reports'
}
export default Terms
