import {
  AvailableStatisticsResponse,
  OccupancyCategoryDay,
  OccupancyPredictionReport,
  OccupancyPredictionResourceRequest,
  OccupancyReport,
  OccupancySummary,
  OverflowReport,
  ParkingStatsTwoIntervalResponse,
  ParkingStatsTwoRequest,
  ParkingStatsTwoSegmentResponse,
  ReportCategoryResourceRequest,
  ReportCategoryResponse,
  Resource,
  ResourceReportRequest
} from 'features/resource/types'
import cache, { Cache } from 'utils/cache'
import { createInstance } from 'helpers/request'
import { sortAsc } from 'utils/sortBy'
import config from 'config'

const { VITE_SPOT_REPORTING_API } = import.meta.env

const MS_PER_DAY = 60 * 1000 * 60 * 24
const twentyFourHourCache = new Cache(MS_PER_DAY)

const {apiUrl} = config

export const request = createInstance(
`${apiUrl}/1.4/dynamic`
)

export interface ResourceResponse {
  resources: Resource[]
}

export const getAll = async (
  jurisdictionId: string,
  signal?: AbortSignal
): Promise<ResourceResponse> =>
  cache.getOr(
    `timeSeries/availableResources/${jurisdictionId}`,
    async () =>
      await request.get(
        `/timeSeries/availableResources/${jurisdictionId}?includeCustomNames=true`,
        signal
      )
  )

export const getAvailableStatistics = async (
  resourceIds: string[],
  jurisdictionId: string,
  signal?: AbortSignal
): Promise<AvailableStatisticsResponse> =>
  twentyFourHourCache.getOr(
    `timeSeries/availableStatistics/${jurisdictionId}/${JSON.stringify(
      resourceIds.slice().sort()
    )}`,
    async () =>
      await request.post(
        `/timeSeries/availableStatistics`,
        {
          jurisdictionId: jurisdictionId,
          resources: resourceIds.map(resourceId => ({ resourceId }))
        },
        signal
      )
  )

export const getStatisticReports = async (
  resourceStatsMap: ResourceReportRequest,
  jurisdictionId: string,
  useCache: boolean = true,
  signal?: AbortSignal
): Promise<OccupancyReport | OverflowReport> => {
  const requestFn = async () =>
    await request.post(
      `/timeSeries/report`,
      {
        jurisdictionId: jurisdictionId,
        requests: resourceStatsMap
      },
      signal
    )
  return !useCache
    ? await requestFn()
    : cache.getOr(
        `timeSeries/report/${JSON.stringify({
          jurisdictionId,
          resourceStatsMap
        })}`,
        requestFn
      )
}

export const getReportCategories = async (
  resourceStatsMap: ReportCategoryResourceRequest,
  jurisdictionId: string,
  signal?: AbortSignal
): Promise<ReportCategoryResponse> =>
  cache.getOr(
    `timeSeries/reportCategories/${JSON.stringify({
      jurisdictionId,
      resourceStatsMap
    })}`,
    async () =>
      await request.post(
        `/timeSeries/reportCategories`,
        {
          jurisdictionId: jurisdictionId,
          requests: resourceStatsMap
        },
        signal
      )
  )

export const getOccupancyPredictions = async (
  resourceStatsList: OccupancyPredictionResourceRequest[],
  signal?: AbortSignal
): Promise<OccupancyPredictionReport> =>
  cache.getOr(
    `timeSeries/occupancyPredictions2/${JSON.stringify(resourceStatsList)}`,
    async () =>
      await request.post(
        `/occupancyPrediction2`,
        {
          statisticType: 'EMA',
          resources: resourceStatsList
        },
        signal
      )
  )

export const getStatsTwo = async (
  body: ParkingStatsTwoRequest,
  signal?: AbortSignal
): Promise<
  ParkingStatsTwoIntervalResponse | ParkingStatsTwoSegmentResponse
> => {
  return await request.post('/parkingStats2', body, signal)
  // const cacheString = body.resources.reduce((str, {resourceIds, ...statisticFields}) => {
  //     const resourceIdString = resourceIds.sort().join(',')
  //     const statisticString = Object.entries(statisticFields)
  //         .reduce((statisticCachePart, [statistic, {TIME_GROUPING, TIME_SERIES}]) => {
  //             let tail = `${statistic}/`
  //             if (TIME_GROUPING) {
  //                 Object.values(TIME_GROUPING)
  //                     .flatMap(obj => Object.entries(obj))
  //                     .forEach(([weekStart, weekStartMap]) => {
  //                         tail = `${tail}/${weekStart}/`
  //                         const {categories, intervals, segmentTypes, source, statisticTypes} = weekStartMap
  //                         if (categories) {
  //                             tail = `${tail}categories${categories.sort().join(',')}/`
  //                         }
  //                         if (intervals) {
  //                             tail = `${tail}intervals${intervals.sort().join(',')}/`
  //                         }
  //                         if (source) {
  //                             tail = `${tail}source${source}/`
  //                         }
  //                         if (statisticTypes) {
  //                             tail = `${tail}statisticTypes${statisticTypes.sort().join(',')}/`
  //                         }
  //                         if (segmentTypes) {
  //                             const sortedSegmentsString = segmentTypes.sort((left, right) => sortAsc(left.offsets, right.offsets))
  //                                 .map(({offsets, segmentHours}) => `${offsets}${segmentHours}`)
  //                             tail = `${tail}segmentTypes${sortedSegmentsString}/`
  //                         }
  //                     })
  //             }
  //             if (TIME_SERIES) {
  //                 Object.values(TIME_SERIES)
  //                     .flat(2)
  //                     .forEach((weekStartMap) => {
  //                         const weekStarts = Object.keys(weekStartMap)
  //                             .sort((left, right) => sortAsc(left, right))
  //                             .join(',')
  //                         tail = `${tail}weekStarts${weekStarts}/`
  //                     })
  //             }
  //             return `${statisticCachePart}${tail}`
  //         }, '')
  //     return `${str}/${resourceIdString}/${statisticString}`
  // }, 'timeSeries/parkingStats2')
  // return await cache.getOr(cacheString, async () => await request.post('/parkingStats2', body))
}

export interface SummaryBody {
  dailyList: (OccupancySummary | OccupancyCategoryDay)[]
  weekDay: OccupancySummary | OccupancyCategoryDay
  weekEnd: OccupancySummary | OccupancyCategoryDay
}

const getSummaryReportPdf = async (
  data: SummaryBody,
  isMultiTenant = false,
  signal?: AbortSignal
): Promise<Blob> => {
  const templateName = !isMultiTenant
    ? `monthlySummary`
    : `categoryMonthlySummary`
  const resp = await fetch(`${VITE_SPOT_REPORTING_API}/api/report`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      data,
      template: {
        name: templateName,
        chrome: {
          landscape: true
        }
      }
    }),
    signal: signal || undefined
  })
  return resp.blob()
}

export const resourceService = {
  getAll,
  getAvailableStatistics,
  getOccupancyPredictions,
  getReportCategories,
  getStatisticReports,
  getStatsTwo,
  getSummaryReportPdf
}
