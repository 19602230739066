import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import customInputStyle from 'assets/jss/material-dashboard-pro-react/components/customInputStyle'
import TextField from './TextField'

function CustomTextField({ ...props }) {
  const {
    classes,
    error,
    formControlProps,
    id,
    inputProps,
    inputRootCustomClasses,
    labelProps,
    labelText,
    success,
    white
  } = props

  const labelClasses = classNames({
    [' ' + classes.labelRootError]: error,
    [' ' + classes.labelRootSuccess]: success && !error
  })
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white
  })
  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined
  })
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white
  })
  var formControlClasses
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    )
  } else {
    formControlClasses = classes.formControl
  }
  var feedbackClasses = classes.feedback
  if (inputProps !== undefined) {
    if (inputProps.endAdornment !== undefined) {
      feedbackClasses = feedbackClasses + ' ' + classes.feedbackRight
    }
  }

  // remove classes from props;
  let formProps = { ...props }
  delete formProps.classes

  return (
    <TextField
      className={formControlClasses}
      InputLabelProps={{ className: classes.labelRoot + ' ' + labelClasses }}
      InputProps={{
        classes: {
          input: inputClasses,
          root: marginTop,
          disabled: classes.disabled,
          // underline: underlineClasses
        },
        ...inputProps
      }}
      {...formProps}
    />
  )
}

CustomTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool
}

export default withStyles(customInputStyle)(CustomTextField)
