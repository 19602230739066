export enum MessageType {
    session = 'GLOBAL_SESSION',
    once = 'GLOBAL_ONCE'
}


export enum DateRangeType {
    always = 'ALWAYS',
    fromDate = 'FROM_DATE',
    toDate = 'TO_DATE',
    fromToDate = 'FROM_TO_DATE'
}


export interface IMessage {
    closeButtonText: string
    content: string
    fromDate: string | null
    heading: string
    isActive: boolean
    publishedDate: string | null
    showInPopup: boolean
    toDate: string | null
    type: MessageType
}


export interface IdMessage extends IMessage {
    id: string
}


//-- API Responses
export type MessageListResponse = Record<string, IMessage>

export type MessageDetailResponse = IMessage