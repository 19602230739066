import { EntityMap, IdEntity } from 'types/generics'

interface Getter<T> {
  (item: T): string
}

// const getId: Getter<object extends IdEntity> = (o: object) => o.id

type IdType = object & IdEntity

function getId(o: IdType): string {
  return String(o.id)
}

export function toObject<T>(list: T[], getter: Getter<T>): EntityMap<T> {
  return list.reduce((entityMap, item) => {
    entityMap[getter(item)] = item
    return entityMap
  }, {})
}

export default toObject
