/**
 * @func setPath - Set the value for a given key path
 * @param {string[]} keyList
 * @param {*} value
 * @param {object} original
 * @param {boolean} [mutate=false] - Write directly to `original`, or to a copy?
 * @returns {object}
 *
 * @example
 * >>> const nested = {
        a: 0,
        b: {
            ba: 1,
            bb: 2,
            bc: 3,
            bd: {
                bda: 123,
                bdb: 124
            }
        },
        c: 4,
        d: 5
    }
 * >>> const path = ['b', 'bd', 'bda']
 * >>> const myNestedCopy = setPath(path, 456, nested, false)
 * >>> myNestedCopy[ path[0] ][ path[1] ][ path[2] ] //=> 456
 * >>> nested[ path[0] ][ path[1] ][ path[2] ] //=> 123
 */

export default function setPath(keyList, value, original, mutate = false) {
  const ref = mutate ? original : JSON.parse(JSON.stringify(original))
  let obj = ref
  const lastIndex = keyList.length - 1
  let i = 0
  while (i < keyList.length) {
    const key = keyList[i]
    if (lastIndex === i) {
      obj[key] = value
    } else {
      obj = obj[key]
    }
    i++
  }
  return ref
}
