import React from 'react'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import BlockIcon from '@material-ui/icons/Block'
import Button from '@material-ui/core/Button'
import HomeIcon from '@material-ui/icons/Home'
import { makeStyles } from '@material-ui/core/styles'
import PanToolIcon from '@material-ui/icons/PanTool'
import Typography from '@material-ui/core/Typography'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles({
  root: {
    height: '50vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'nowrap'
  },
  title: {},
  description: {
    padding: '1rem 1rem 3rem 1rem'
  },
  // Image wrapper contains iconWrapper & icons
  imageWrapper: {
    width: '100%',
    height: 'auto',
    maxHeight: 'min(100%, 50vh)',
    display: 'flex'
  },
  iconWrapper: {
    position: 'relative',
    margin: 'auto',
    left: '-1%'
  },
  handIcon: {
    margin: 'auto'
  },
  blockIcon: {
    top: '-45px',
    left: '-37px',
    right: 'initial',
    bottom: 'initial',
    position: 'absolute'
  },
  // actions contains the "back" & "home" buttons
  actions: {
    width: '100%',
    display: 'flex',
    paddingTop: '5rem',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
})

export const PermissionDenied = () => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant={'h4'} align={'center'}>
          Permission Denied
        </Typography>
      </div>
      <div className={classes.description}>
        <Typography variant={'body1'} align={'center'}>
          The page you're trying access has restricted access.
          <br />
          Please contact your system administrator.
        </Typography>
      </div>
      <div className={classes.imageWrapper}>
        <div className={classes.iconWrapper}>
          <PanToolIcon
            className={classes.handIcon}
            color={'primary'}
            style={{ fontSize: 150 }}
          />
          <BlockIcon
            className={classes.blockIcon}
            color={'error'}
            style={{ fontSize: 250 }}
          />
        </div>
      </div>
      <div className={classes.actions}>
        {/* Back button */}
        <Button
          onClick={() => history.go(-1)}
          color={'primary'}
          size={'large'}
          startIcon={<ArrowBackIcon />}
          style={{ margin: 'auto 1rem auto auto' }}
          variant={'contained'}
        >
          Back
        </Button>
        {/*  Home Button */}
        <Button
          onClick={() => {
            history.replace('/')
          }}
          color={'primary'}
          size={'large'}
          startIcon={<HomeIcon />}
          style={{ margin: 'auto auto auto 1rem' }}
          variant={'contained'}
        >
          Home
        </Button>
      </div>
    </div>
  )
}

export default PermissionDenied
