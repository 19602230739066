import { createSlice } from '@reduxjs/toolkit'
import baseReducer from 'features/baseReducer'

const user = JSON.parse(localStorage.getItem('user'))
export const initialState = {
  loggingIn: false,
  loggedIn: Boolean(user),
  user: user || null
}

const authSlice = createSlice({
  initialState,
  name: 'authentication',
  reducers: {
    ...baseReducer
  }
})

export const { actions, name, reducer } = authSlice
