/* eslint-disable */
import React from 'react'
import Cancel from '@material-ui/icons/Cancel'
import { cloneDeep, isObject } from 'lodash'
import { connect } from 'react-redux'
import Create from '@material-ui/icons/Create'
import Done from '@material-ui/icons/Done'
import { Field, Form } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import { FormLabel } from '@material-ui/core'
import Revert from '@material-ui/icons/GetApp'
import Style from '@material-ui/icons/Style'
import SweetAlert from 'react-bootstrap-sweetalert'
import withStyles from '@material-ui/core/styles/withStyles'

import Button from 'components/CustomButtons/Button'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import ColorSelector from 'components/ColorSelector'
import CustomInput from 'components/CustomInput/CustomInput'
import CustomTextField from 'components/redux-form/CustomTextField/CustomTextField'
import CustomisableSelect from './controls/CustomisableSelect'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import newTemplateGroupFormsStyle from 'assets/jss/spot-admin/views/newTemplateGroupStyle'
import Snackbar from 'components/Snackbar/Snackbar'
import { stringOfLengthBetween } from 'helpers/validation'
import TariffType from 'components/TariffType/TariffType'
import { templateActions } from 'actions'
import { templateService } from 'services'

const DEFAULT_SIGNCONFIG = 'UNDEFINED'
const DEFAULT_COLOR = '#03A9F4'
const DEFAULT_PRIORITY = 20

const validate = values => {
  const errors = {}

  if (!values.description) {
    errors.description = 'Description is a required field'
  } else {
    if (!stringOfLengthBetween(values.description, 1, 200)) {
      errors.description = 'Description must has a maximum of 200 characters'
    }
  }

  if (!values.priority) {
    errors.priority = 'Priorities is a required field'
  } else {
    if (!verifyNumber(values.priority)) {
      errors.priority = 'Priorities must be a number'
    } else {
      const nPriority = Number(values.priority)
      if (nPriority < 1 || nPriority > 99) {
        errors.priority = 'Priorities must be a number between 1 - 99'
      }
    }
  }

  return errors
}

const verifyNumber = value => {
  var numberRex = new RegExp('^[0-9]+$')
  if (numberRex.test(value)) {
    return true
  }
  return false
}

class EditTemplateVersionConfigurationPage extends React.Component {
  constructor(props) {
    const { match, templates } = props

    super(props)

    const editTemplateId = match.params.id
    const editConfigId = match.params.configId

    const jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'))

    this.state = {
      alert: null,
      editTemplateId,
      editConfigId,
      jurisdiction,
      snackBarOpen: false,
      snackBarStatus: 'success',
      snackBarMessage: '',
      baseCurrency: jurisdiction['BASE_CURRENCY'] || 'USD',
      initialValuesDefined: false
    }

    this.submit = this.submit.bind(this)
    this.cancel = this.cancel.bind(this)
    this.finished = this.finished.bind(this)
    this.removeAlert = this.removeAlert.bind(this)
    this.confirmContinue = this.confirmContinue.bind(this)
    this.showNotification = this.showNotification.bind(this)

    this.handleColorChange = this.handleColorChange.bind(this)
    this.handleSignConfigChange = this.handleSignConfigChange.bind(this)
    this.handleSignConfigAttributeChange =
      this.handleSignConfigAttributeChange.bind(this)
  }

  componentDidMount() {
    const { dispatch, templates, signConfigs } = this.props
    const { jurisdiction } = this.state

    if (Object.keys(templates).length === 0) {
      dispatch(templateActions.getTemplates(jurisdiction.id))
    }

    if (Object.keys(signConfigs).length === 0) {
      dispatch(templateActions.getSignConfigs(jurisdiction.id))
    }
  }

  componentWillUnmount() {
    if (this.notificationTimer) {
      clearTimeout(this.notificationTimer)
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { signConfigs, templates, match } = nextProps
    const { initialValuesDefined } = prevState

    if (
      !initialValuesDefined &&
      Object.keys(signConfigs).length != 0 &&
      Object.keys(templates).length != 0
    ) {
      const editTemplateId = match.params.id
      const editConfigId = match.params.configId
      const template = templates[editTemplateId]

      // CR 2022-Aug-12 - This serialization/deserialization is necessary
      // to create a deep copy that can be mutated. Otherwise redux will complain
      // about mutating (store state) values
      return JSON.parse(
        JSON.stringify({
          formInitialValues: {
            signConfig: template.signConfig[editConfigId].signConfig,
            color: template.signConfig[editConfigId].color,
            description: template.signConfig[editConfigId].description,
            priority: template.signConfig[editConfigId].priority,
            signConfigAttributes: template.attributes[editConfigId]
          },
          initialValuesDefined: true,
          signConfigSelected: template.signConfig[editConfigId].signConfig,
          signConfigAttributes: template.attributes[editConfigId],
          color: template.signConfig[editConfigId].color
        })
      )
    } else {
      return {}
    }

    return {}
  }

  handleColorChange(newColor) {
    const { color } = this.state

    if (color != newColor) {
      this.setState({ color: newColor })
    }
  }

  handleSignConfigChange(newSignConfigId) {
    const { signConfigSelected } = this.state

    if (signConfigSelected !== newSignConfigId) {
      this.setState({
        signConfigSelected: newSignConfigId
      })
    }
  }

  handleSignConfigAttributeChange(newValue) {
    const { signConfigAttributes } = this.state

    if (JSON.stringify(signConfigAttributes) !== JSON.stringify(newValue)) {
      this.setState({
        signConfigAttributes: newValue
      })
    }
  }

  showNotification(status, message) {
    if (!this.state.snackBarOpen) {
      this.setState({
        snackBarOpen: true,
        snackBarStatus: status,
        snackBarMessage: message
      })
      this.notificationTimer = setTimeout(
        function () {
          this.setState({
            snackBarOpen: false
          })
          this.notificationTimer = null
        }.bind(this),
        3000
      )
    }
  }

  confirmContinue() {
    const { classes } = this.props
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: 'block' }}
          title="Success!"
          onConfirm={() => this.finished()}
          confirmBtnCssClass={classes.button + ' ' + classes.success}
          confirmBtnText="Okay"
        >
          The configuration has been updated successfully!
        </SweetAlert>
      )
    })
  }

  finished() {
    const { history } = this.props

    this.setState({
      alert: null
    })
    history.goBack()
  }

  removeAlert() {
    this.setState({
      alert: null
    })
  }

  submit(values) {
      console.log(`submit()`)
    const { dispatch, templates } = this.props
    const {
      editTemplateId,
      editConfigId,
      jurisdiction,
      signConfigSelected,
      signConfigAttributes,
      color
    } = this.state

    let template = JSON.parse(JSON.stringify(templates[editTemplateId]))

    let newConfiguration = {}
    let newAttributes = {}

    newConfiguration[editConfigId] = {
      signConfig: signConfigSelected,
      color,
      description: values.description,
      priority: values.priority
    }

    newAttributes[editConfigId] = signConfigAttributes || {}
    newAttributes[editConfigId]['INFERRED'] = {
      type: 'Boolean',
      value: true
    }

    template.signConfig = { ...template.signConfig, ...newConfiguration }
    template.attributes = { ...template.attributes, ...newAttributes }

    return templateService
      .updateTemplate(template)
      .then(updatedTemplate => {
        dispatch(templateActions.registerTemplate(updatedTemplate))
        this.showNotification(
          'success',
          'Configuration has been successfully saved!'
        )
        this.confirmContinue()
        return updatedTemplate
      })
      .catch(error => {
        console.error('ERROR creating configuration ', error)
        this.showNotification(
          'danger',
          'There was a problem creating the template configuration!'
        )
        return {
          [FORM_ERROR]:
            'There was a problem creating the template configuration.'
        }
      })
  }

  cancel(pristine) {
    const { classes, history } = this.props

    if (!pristine) {
      this.setState({
        alert: (
          <SweetAlert
            warning
            style={{ display: 'block' }}
            title="Warning!"
            onConfirm={() => this.finished()}
            onCancel={() => this.removeAlert()}
            confirmBtnCssClass={classes.button + ' ' + classes.success}
            cancelBtnCssClass={classes.button + ' ' + classes.danger}
            confirmBtnText="Yes"
            cancelBtnText="No"
            showCancel
          >
            Cancelling will discard any changes made. Are you sure?
          </SweetAlert>
        )
      })
    } else {
      history.goBack()
    }
  }

  render() {
    const {
      alert,
      snackBarOpen,
      snackBarMessage,
      snackBarStatus,
      formInitialValues,
      baseCurrency
    } = this.state
    const { signConfigs, readOnlyMode } = this.props

    return (
      <div>
        {alert}
        <Snackbar
          place="tr"
          color={snackBarStatus}
          icon={Style}
          message={snackBarMessage}
          open={snackBarOpen}
          closeNotification={() => this.setState({ snackBarOpen: false })}
          close
        />
        <EditTemplateVersionConfigurationForm
          readOnlyMode={readOnlyMode}
          baseCurrency={baseCurrency}
          formInitialValues={formInitialValues}
          signConfigs={signConfigs}
          onSignConfigChange={this.handleSignConfigChange}
          onSignConfigAttributeChange={this.handleSignConfigAttributeChange}
          onColorChange={this.handleColorChange}
          onSubmit={this.submit}
          onCancel={this.cancel}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { templates } = state

  if (!templates) {
    return {
      templates: {},
      signConfigs: {}
    }
  }

  return {
    templates: templates.templates || {},
    signConfigs: templates.signConfigs || {},
    loading: templates.loading || false,
    saving: templates.saving || false
  }
}

export default connect(mapStateToProps)(
  withStyles(newTemplateGroupFormsStyle)(EditTemplateVersionConfigurationPage)
)

class EditTemplateVersionConfigurationForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      initialValuesLoaded: false
    }

    this.handlePriorityChange = this.handlePriorityChange.bind(this)
    this.handleColorChange = this.handleColorChange.bind(this)
    this.handleSignConfigChange = this.handleSignConfigChange.bind(this)
    this.handleSignConfigAttributeChange =
      this.handleSignConfigAttributeChange.bind(this)
    this.handleRevert = this.handleRevert.bind(this)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { initialValuesLoaded, reset } = this.state
    const { formInitialValues } = this.props

    if (!initialValuesLoaded && formInitialValues) {
      this.setState({
        initialValuesLoaded: true,
        signConfigSelected: formInitialValues.signConfig,
        signConfigAttributes: formInitialValues.signConfigAttributes || {},
        configurationColor: formInitialValues.color || DEFAULT_COLOR,
        priority: formInitialValues.priority || DEFAULT_PRIORITY
      })
    }

    if (reset) {
      this.setState({ reset: false })
    }
  }

  handleRevert() {
    const {
      formInitialValues,
      onColorChange,
      onSignConfigAttributeChange,
      onSignConfigChange
    } = this.props

    onSignConfigChange(formInitialValues.signConfig)
    onSignConfigAttributeChange(formInitialValues.signConfigAttributes)
    onColorChange(formInitialValues.color)

    this.setState({
      signConfigSelected: formInitialValues.signConfig,
      signConfigAttributes: formInitialValues.signConfigAttributes || {},
      configurationColor: formInitialValues.color || DEFAULT_COLOR,
      priority: formInitialValues.priority || DEFAULT_PRIORITY,
      reset: true
    })
  }

  handlePriorityChange(e, formApi) {
    if (verifyNumber(e.target.value)) {
      formApi.change('priority', Number(e.target.value))
      this.setState({
        priority: e.target.value
      })
    } else {
      if (e.target.value.length === 0) {
        formApi.change('priority', 0)
      }
    }
  }

  handleColorChange(color, formApi) {
    const { onColorChange } = this.props
    this.setState({
      configurationColor: color.hex
    })
    formApi.change('configurationColor', color.hex)
    if (onColorChange) {
      onColorChange(color.hex)
    }
  }

  handleSignConfigChange(newSelection, formApi) {
    const { signConfigSelected, baseCurrency } = this.state
    const { onSignConfigChange, onSignConfigAttributeChange, signConfigs } =
      this.props

    // CR 2022-Aug-12 - This serialization/deserialization is necessary
    // to create a deep copy that can be mutated. Otherwise redux will complain
    // about mutating (store state) values
    const newSignConfig = JSON.parse(JSON.stringify(signConfigs[newSelection]))

    let newSignConfigAttributes = {}

    function generateAttributeStructure(fieldId, attribute, newStructure) {
      if (attribute.fields) {
        attribute.type = 'Structure'
      }

      switch (attribute.type) {
        case 'Number':
          newStructure[fieldId] = {
            type: 'Number',
            value: attribute.default || attribute.minimum || 0
          }
          break
        case 'TariffType':
          newStructure[fieldId] = {
            type: 'TariffType',
            subtype: 'PRO_RATED',
            chargeInterval: 1,
            currency: baseCurrency,
            displayCharge: 1,
            displayChargeUnitSize: 60
          }
          break
        case 'Array':
          if (fieldId === 'RESTRICTED_PERIODS') {
            newStructure[fieldId] = {
              type: 'Structure',
              fields: {}
            }
            let periodStructure = cloneDeep(
              newSignConfig.structures[attribute.structure]
            )
            delete periodStructure['DAY_RANGE']
            delete periodStructure['MONTH_RANGE']
            delete periodStructure['TIME_RANGE']

            generateAttributeStructure(
              fieldId,
              newSignConfig.structures[attribute.structure],
              newStructure[fieldId].fields
            )
          }
          break
        case 'Structure':
          Object.keys(attribute.fields).forEach(subFieldId => {
            generateAttributeStructure(
              subFieldId,
              attribute.fields[subFieldId],
              newStructure
            )
          })

        default:
          break
      }
    }

    Object.keys(newSignConfig.attributes).forEach(key => {
      generateAttributeStructure(
        key,
        newSignConfig.attributes[key],
        newSignConfigAttributes
      )
    })

    if (signConfigSelected !== newSelection) {
      this.setState({
        signConfigSelected: newSelection,
        signConfigAttributes: newSignConfigAttributes,
        reset: true
      })

      formApi.change('signConfig', newSelection)
      formApi.change('signConfigAttributes', newSignConfigAttributes)
      if (onSignConfigChange) {
        onSignConfigChange(newSelection)
      }
      if (onSignConfigAttributeChange) {
        onSignConfigAttributeChange(newSignConfigAttributes)
      }
    }
  }

  handleSignConfigAttributeChange(attribute, newValue, formApi) {
    const { signConfigAttributes } = this.state
    const { onSignConfigAttributeChange } = this.props

    let fieldHierarchy = attribute.split('-')
    fieldHierarchy.splice(0, 1)

    let newAttributes = JSON.parse(
      JSON.stringify(cloneDeep(signConfigAttributes))
    )

    let marker = newAttributes
    for (let i = 0; i < fieldHierarchy.length - 1; i++) {
      if (!marker[fieldHierarchy[i]]) {
        marker[fieldHierarchy[i]] = { fields: {} }
      }

      if (marker[fieldHierarchy[i]] && marker[fieldHierarchy[i]].fields) {
        marker = marker[fieldHierarchy[i]].fields
      } else {
        marker = marker[fieldHierarchy[i]]
      }
    }
    marker[fieldHierarchy[fieldHierarchy.length - 1]] = newValue

    this.setState({
      signConfigAttributes: newAttributes
    })

    formApi.change('signConfigAttributes', newAttributes)
    onSignConfigAttributeChange(newAttributes)
  }

  renderSignConfigAttribute(
    fieldId,
    field,
    selectedAttribute,
    parentString,
    formApi
  ) {
    const { classes, baseCurrency, readOnlyMode } = this.props
    const { reset } = this.state

    let setAttribute = cloneDeep(selectedAttribute) || {}
    if (!field.type) {
      return null
    }

    switch (field.type) {
      case 'Array':
        return null
      case 'Structure':
        if (!setAttribute.fields) {
          setAttribute.fields = {}
        }
        return Object.keys(field.fields).map((subFieldId, key) => {
          return this.renderSignConfigAttribute(
            subFieldId,
            field.fields[subFieldId],
            setAttribute.fields[subFieldId],
            `${parentString}${fieldId}-`,
            formApi
          )
        })
      case 'Number':
        return (
          <GridContainer
            key={`${parentString}${fieldId}`}
            style={{ paddingLeft: '10px', paddingRight: '10px' }}
          >
            <GridItem xs={12} sm={3}>
              <FormLabel className={classes.labelHorizontal}>
                {isObject(field.description)
                  ? field.description['en-US'].toUpperCase()
                  : field.description.toUpperCase()}
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={9}>
              <CustomInput
                id={`input${parentString}${fieldId}`}
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event =>
                    this.handleSignConfigAttributeChange(
                      `input${parentString}${fieldId}`,
                      { type: 'Number', value: Number(event.target.value) },
                      formApi
                    ),
                  type: 'number',
                  value: `${
                    (selectedAttribute || {}).value
                      ? (selectedAttribute || {}).value
                      : 0
                  }`,
                  disabled: readOnlyMode
                }}
              />
            </GridItem>
          </GridContainer>
        )
      case 'TariffType':
        return (
          <GridContainer
            key={`${parentString}${fieldId}`}
            style={{ paddingLeft: '10px', paddingRight: '10px' }}
          >
            <GridItem xs={12} sm={3}>
              <FormLabel className={classes.labelHorizontal}>TARIFF</FormLabel>
            </GridItem>
            <GridItem xs={12} sm={9}>
              <TariffType
                controlId={`input${parentString}${fieldId}`}
                baseCurrency={baseCurrency}
                onTariffTypeChange={(attribute, newValue) =>
                  this.handleSignConfigAttributeChange(
                    attribute,
                    newValue,
                    formApi
                  )
                }
                value={selectedAttribute || {}}
                reset={reset}
                disabled={readOnlyMode}
              />
            </GridItem>
          </GridContainer>
        )
      case 'String':
        return (
          <GridContainer
            key={`${parentString}${fieldId}`}
            style={{ paddingLeft: '10px', paddingRight: '10px' }}
          >
            <GridItem xs={12} sm={3}>
              <FormLabel className={classes.labelHorizontal}>
                {isObject(field.description)
                  ? field.description['en-US'].toUpperCase()
                  : field.description.toUpperCase()}
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={9}>
              <CustomInput
                id={`input${parentString}${fieldId}`}
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event =>
                    this.handleSignConfigAttributeChange(
                      `input${parentString}${fieldId}`,
                      { type: 'String', value: event.target.value },
                      formApi
                    ),
                  type: 'text',
                  value: `${
                    (selectedAttribute || {}).value
                      ? (selectedAttribute || {}).value
                      : ''
                  }`,
                  disabled: readOnlyMode
                }}
              />
            </GridItem>
          </GridContainer>
        )
      case 'Alert':

      default:
        return null
    }
  }

  renderSignConfigFields(signConfig, selectedAttributes, formApi) {
    if (!signConfig) {
      return null
    }

    let displayables = cloneDeep({
      attributes: signConfig.attributes,
      structures: signConfig.structures
    })

    if (Object.keys(displayables.attributes).length === 0) {
      return null
    }

    // handle special case of time restrictions
    if (displayables.structures['PARK_RESTRICTION']) {
      let parkRestriction = displayables.structures['PARK_RESTRICTION']

      delete parkRestriction.fields['DAY_RANGE']
      delete parkRestriction.fields['MONTH_RANGE']
      delete parkRestriction.fields['TIME_RANGE']
    }

    if (displayables.attributes['RESTRICTED_PERIODS']) {
      let restrictedPeriods = displayables.attributes['RESTRICTED_PERIODS']
      if (restrictedPeriods.structure) {
        restrictedPeriods.fields =
          displayables.structures[restrictedPeriods.structure].fields
        restrictedPeriods.type = 'Structure'
        delete displayables.structures[restrictedPeriods.structure]
        delete restrictedPeriods.structure
      }
    }

    // remove inferred as option as it will always be applied
    if (displayables.attributes['INFERRED']) {
      delete displayables.attributes['INFERRED']
    }

    let majorFieldIds = Object.keys(displayables.attributes)
    if (
      displayables.attributes['RESTRICTED_PERIODS'] &&
      Object.keys(displayables.attributes['RESTRICTED_PERIODS'].fields)
        .length === 0
    ) {
      majorFieldIds = majorFieldIds.filter(e => e !== 'RESTRICTED_PERIODS')
    }

    // if after all filters nothing to display then just return null
    if (majorFieldIds.length === 0) {
      return null
    }

    return (
      <GridContainer
        hidden={majorFieldIds.length === 0}
        style={{
          paddingLeft: '20px',
          paddingRight: '20px',
          backgroundColor: '#FFFAC9'
        }}
      >
        <p style={{ paddingTop: '10px', paddingLeft: '10px' }}>
          {signConfig.category.replace(/[_]/g, ' ')} SPECIFIC ATTRIBUTES
        </p>
        {majorFieldIds.map((fieldId, key) => {
          let field = displayables.attributes[fieldId]
          return this.renderSignConfigAttribute(
            fieldId,
            field,
            selectedAttributes[fieldId],
            '-',
            formApi
          )
        })}
        <p>&nbsp;</p>
      </GridContainer>
    )
  }

  render() {
    const {
      classes,
      formInitialValues,
      onCancel,
      onSubmit,
      readOnlyMode,
      signConfigs
    } = this.props
    const { configurationColor, signConfigSelected, signConfigAttributes } =
      this.state

    if (!formInitialValues) {
      return null
    }

    const signConfigIds = Object.keys(signConfigs)

    if (signConfigIds.length === 0) {
      return null
    }

    let selectableSignConfigs = signConfigIds.map(signConfigId => {
      let signConfig = signConfigs[signConfigId]
      return {
        label: signConfig.category.replace(/[_]/g, ' '),
        value: signConfig.id
      }
    })

    selectableSignConfigs = selectableSignConfigs.sort((a, b) => {
      return b.label > a.label ? -1 : 1
    })

    let selectedSignConfig = signConfigs[signConfigSelected]
    if (selectableSignConfigs.length === 0) {
      return null
    }

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={9}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="rose">
                <Style />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{`${
                readOnlyMode ? 'View' : 'Edit'
              } existing Template Configuration`}</h4>
            </CardHeader>
            <CardBody>
              <Form
                onSubmit={values => onSubmit(values)}
                validate={validate}
                initialValues={formInitialValues}
              >
                {({ form, handleSubmit, pristine, submitting }) => (
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <GridContainer>
                      <GridItem xs={12}>
                        <Field name={'description'}>
                          {props => (
                            <CustomTextField
                              disabled={readOnlyMode}
                              label={'DESCRIPTION *'}
                              onChange={props.input.onChange}
                              value={props.input.value}
                            />
                          )}
                        </Field>
                      </GridItem>
                      <GridItem xs={12}>
                        <Field name={'priority'}>
                          {props => (
                            <CustomTextField
                              disabled={readOnlyMode}
                              label={'PRIORITY *'}
                              onChange={event =>
                                this.handlePriorityChange(event, form)
                              }
                              value={props.input.value}
                              fullWidth
                            />
                          )}
                        </Field>
                      </GridItem>
                      <GridItem xs={12}>
                        <h6 style={{ fontWeight: '400' }}>
                          CONFIGURATION MAP COLOR*:
                        </h6>
                        <ColorSelector
                          color={configurationColor}
                          onColorChange={color =>
                            this.handleColorChange(color, form)
                          }
                        />
                      </GridItem>
                      <GridItem xs={12}>
                        <Field name="signConfig">
                          {props => (
                            <CustomisableSelect
                              controlId={'signConfig'}
                              handleChange={newSelection => {
                                this.handleSignConfigChange(newSelection, form)
                              }}
                              items={selectableSignConfigs}
                              label={'Configuration Category *'}
                              value={props.input.value}
                              disabled={readOnlyMode}
                            />
                          )}
                        </Field>
                      </GridItem>
                      {this.renderSignConfigFields(
                        selectedSignConfig,
                        signConfigAttributes,
                        form
                      )}
                      {readOnlyMode || (
                        <GridItem xs={12}>
                          <div className={classes.formCategory}>
                            <small>*</small> Required fields
                          </div>
                        </GridItem>
                      )}
                    </GridContainer>
                    {readOnlyMode || (
                      <Button
                        color="success"
                        type="submit"
                        className={classes.registerButton}
                        disabled={submitting}
                      >
                        <Create className={classes.icon} />
                        Update
                      </Button>
                    )}
                    {readOnlyMode || (
                      <Button
                        color="danger"
                        className={classes.registerButton}
                        style={{ marginRight: '10px' }}
                        disabled={submitting}
                        onClick={() => onCancel(pristine)}
                      >
                        <Cancel className={classes.icon} />
                        Cancel
                      </Button>
                    )}
                    {readOnlyMode || (
                      <Button
                        color="primary"
                        className={classes.registerButton}
                        style={{ marginRight: '25px' }}
                        disabled={pristine || submitting}
                        onClick={() => this.handleRevert(form.reset)}
                      >
                        <Revert className={classes.icon} />
                        Revert
                      </Button>
                    )}
                    {!readOnlyMode || (
                      <Button
                        color="success"
                        className={classes.registerButton}
                        disabled={submitting}
                        onClick={() => onCancel(pristine)}
                      >
                        <Done className={classes.icon} />
                        Ok
                      </Button>
                    )}
                  </form>
                )}
              </Form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

EditTemplateVersionConfigurationForm = withStyles(newTemplateGroupFormsStyle)(
  EditTemplateVersionConfigurationForm
)
