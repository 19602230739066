import Bugsnag from '@bugsnag/js'
import { isProd } from 'config'

type Severity = 'error' | 'warning' | 'info'

export function logError(
  error: Error | any,
  unhandled: boolean = true,
  severity: Severity = 'error'
) {
  if (isProd) {
    Bugsnag.notify(error, event => {
      event.severity = severity
      event.unhandled = unhandled
    })
  } else {
    console.error(error)
  }
}

export const handleError =
  (error: Error | any, unhandled = true) =>
  async () => {
    logError(error, unhandled)
    throw error
  }

export default logError
