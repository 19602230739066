import { Cache } from 'utils/cache'
import { request } from './resource.service'
import { AvailablePaymentResources } from 'features/resource/types/paymentTypes'
import { PaymentSessionReportRequest, PaymentSessionReportResult } from 'features/resource/types/paymentSessionTypes'


const ONE_MINUTE_MS = 60 * 1000
const HOUR_MS = 60 * ONE_MINUTE_MS
const paymentCache = new Cache(HOUR_MS)


const _getAvailablePaymentResources = async ({jurisdictionId}, signal?: AbortSignal): Promise<AvailablePaymentResources> => {
    return await request.get(`/timeSeries/availablePaymentResources/${jurisdictionId}`, signal)
}

const getAvailablePaymentResources = async ({jurisdictionId}, ...args): Promise<AvailablePaymentResources> => {
    return await paymentCache.getOr(
        `paymentResources/${jurisdictionId}`,
        () => _getAvailablePaymentResources({jurisdictionId}, ...args)
    )
}

const getPaymentReport = async (paymentSessionReportRequest: PaymentSessionReportRequest, signal?: AbortSignal): Promise<PaymentSessionReportResult> => {
    return await request.post(
        `/timeSeries/paymentSessionReport`,
        paymentSessionReportRequest,
        signal
    )
}


export const paymentService = {
    getAvailablePaymentResources,
    getPaymentReport
}
export default paymentService

// export const ge