import {
    Category,
    DayOfTheWeek,
    ReportGroupBy,
    ReportState,
    ReportType
} from 'features/resource/types'
import { Epoch } from 'types/generics'
import {
    EventStatisticType,
    ParkingStatInterval,
    ParkingEventType
} from 'features/resource/types/parkingStat'
import { FetchFinancialReportParams } from 'features/resource/paymentActions'


export {
    isOverflow,
    isHistogram,
    isPrediction,
    isEv,
    isFinancial
} from 'features/resource/reportUtils'


// Configs
export interface ClientConfig {
    groupConfigs: GroupConfig[]
    hideResourceIds?: string[]
    jurisdictionId: string
    zoneName: string
}


export interface GroupConfig {
    // Optional: (width, height, maxWidth, maxHeight, etc)
    id: string
    index: number
    reportConfigs: (ReportConfig | FeedConfig | FinancialReportConfig)[]
    style?: object
}


export interface ReportConfig {
    dashboardReportType: DashboardReportType
    daysOfTheWeek: DayOfTheWeek[]
    groupBy: ReportGroupBy
    // UUID for this report
    id: string
    //  Order to display this report within the group
    index: number
    reportType: ReportType
    selectedCategories: Category[]
    selectedResourceIds: string[]
    weekOffset: number
}


export interface FinancialReportConfig extends FetchFinancialReportParams {
    dashboardReportType: DashboardReportType

    // UUID for this report
    id: string
    index: number
    reportType: 'financial'
}


export interface FeedConfig extends ReportConfig {
    intervals: ParkingStatInterval[]
    keys: ParkingEventType[]
    statisticTypes: EventStatisticType[]
}


// Report Types
export type DashboardReportType = ReportType | DashboardReportTypes_ | 'financial'


enum DashboardReportTypes_ {
    liveOccupancy = 'liveOccupancy',
    statsFeed = 'statsFeed',
    statsChart = 'statsChart'
}


export const DashboardReportTypes = {...DashboardReportTypes_, ...ReportType}


export interface LiveOccupancyState {
    groupId: string
    id: string
    lastUpdated: Epoch
    occupancyPercentage: number
    selectedResourceIds: string[]
    spacesAvailable: number
    spacesOccupied: number
}


export interface DashboardReportState extends ReportState {
    groupId: string
    id: string
}


export const isLiveOccupancy = (dashboardReportType: DashboardReportType) =>
    DashboardReportTypes.liveOccupancy === dashboardReportType

export const isStatsFeed = (dashboardReportType: DashboardReportType) =>
    DashboardReportTypes.statsFeed === dashboardReportType

export const isStatsChart = (dashboardReportType: DashboardReportType) =>
    DashboardReportTypes.statsChart === dashboardReportType
