import { authHeader } from './auth-header'
import config from 'config'


export class Req {
    url: string

    constructor(baseUrl: string = config.apiUrl) {
        this.url = baseUrl
    }

    async get(path, signal?: AbortSignal) {
        return this.send(path, {
            method: 'GET',
            signal: signal || undefined
        })
    }

    async post(path, body, signal?: AbortSignal) {
        return this.send(path, {
            method: 'POST',
            body: JSON.stringify(body),
            signal: signal || undefined
        })
    }

    async patch(path, body, signal?: AbortSignal) {
        return this.send(path, {
            method: 'PATCH',
            body: JSON.stringify(body),
            signal: signal || undefined
        })
    }

    async put(path, body, signal?: AbortSignal) {
        return this.send(path, {
            method: 'PUT',
            body: JSON.stringify(body),
            signal: signal || undefined
        })
    }

    async delete(path, signal?: AbortSignal) {
        return this.send(path, {
            method: 'DELETE',
            signal: signal || undefined
        })
    }

    async postFormData(path: string, formData: FormData) {
        const user = JSON.parse(localStorage.getItem('user'))
        return await fetch(this.getUrl(path), {
            method: 'POST',
            body: formData,
            headers: {
                'X-Api-Token': user?.token
            }
        }).then(resp => this.handleResponse(resp))
    }

    async send(path = '', options = {}) {
        const resp = await fetch(this.getUrl(path), {
            ...this.options,
            ...options
        })
        return this.handleResponse(resp)
    }

    handleResponse(resp) {
        if (resp.ok) {
            return HTTP_NO_CONTENT === resp.status ? resp : resp.json()
        }
        return Promise.reject(resp)
    }

    get options() {
        return {
            headers: authHeader()
        }
    }

    getUrl(path) {
        const url = (this.url + path).replaceAll('//', '/')
        return new URL(url).toString()
    }
}


export const HTTP_NO_CONTENT = 204

export const createInstance = (baseUrl): Req => new Req(baseUrl)
