/* eslint-disable */
import React from 'react'
import Assignment from '@material-ui/icons/Assignment'
import Cancel from '@material-ui/icons/Cancel'
import Clear from '@material-ui/icons/Clear'
import { connect } from 'react-redux'
import Create from '@material-ui/icons/Create'
import { Field, Form } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import SweetAlert from 'react-bootstrap-sweetalert'
import queryString from 'query-string'
import withStyles from '@material-ui/core/styles/withStyles'

import Button from 'components/CustomButtons/Button'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import CustomTextField from 'components/redux-form/CustomTextField/CustomTextField'
import CustomisableSelect from './controls/CustomisableSelect'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import newTemplateContainerFormsStyle from 'assets/jss/spot-admin/views/newTemplateGroupStyle'
import Snackbar from 'components/Snackbar/Snackbar'
import { stringOfLengthBetween } from 'helpers/validation'
import { templateActions } from 'actions'
import { templateService } from 'services'


const validate = values => {
    const errors = {}
    if (!values.name) {
        errors.name = 'Name is a required field'
    } else {
        if (!stringOfLengthBetween(values.name, 4, 50)) {
            errors.name = 'Name must be between 4 and 50 characters'
        }
    }
    if (!values.description) {
        errors.description = 'Description is a required field'
    } else {
        if (!stringOfLengthBetween(values.description, 1, 200)) {
            errors.description = 'Description must has a maximum of 200 characters'
        }
    }
    return errors
}


class NewTemplateContainerPage extends React.Component {
    constructor(props) {
        super(props)
        const queryValues = queryString.parse(location.search)
        this.state = {
            alert: null,
            snackBarOpen: false,
            snackBarStatus: 'success',
            snackBarMessage: '',
            templateGroupSelected: queryValues.templateGroupId || null
        }

        this.submit = this.submit.bind(this)
        this.cancel = this.cancel.bind(this)
        this.continue = this.continue.bind(this)
        this.finished = this.finished.bind(this)
        this.removeAlert = this.removeAlert.bind(this)
        this.confirmContinue = this.confirmContinue.bind(this)
        this.showNotification = this.showNotification.bind(this)
        this.handleTemplateGroupChange = this.handleTemplateGroupChange.bind(this)
    }

    componentDidMount() {
        const {dispatch, templateGroups, location} = this.props

        if (Object.keys(templateGroups).length === 0) {
            let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'))
            dispatch(templateActions.getTemplateGroups(jurisdiction.id))
        }
    }

    componentWillUnmount() {
        if (this.notificationTimer) {
            clearTimeout(this.notificationTimer)
        }
    }

    handleTemplateGroupChange(newTemplateGroupId) {
        const {templateGroupSelected} = this.state

        if (templateGroupSelected !== newTemplateGroupId) {
            this.setState({
                templateGroupSelected: newTemplateGroupId
            })
        }
    }

    showNotification(status, message) {
        if (!this.state.snackBarOpen) {
            this.setState({
                snackBarOpen: true,
                snackBarStatus: status,
                snackBarMessage: message
            })
            this.notificationTimer = setTimeout(
                function () {
                    this.setState({
                        snackBarOpen: false
                    })
                    this.notificationTimer = null
                }.bind(this),
                3000
            )
        }
    }

    confirmContinue() {
        const {classes} = this.props
        this.setState({
            alert: (
                <SweetAlert
                    success
                    style={{display: 'block'}}
                    title="Success!"
                    onConfirm={() => this.continue()}
                    onCancel={() => this.finished()}
                    confirmBtnCssClass={classes.button + ' ' + classes.success}
                    cancelBtnCssClass={classes.button + ' ' + classes.danger}
                    confirmBtnText="Add another"
                    cancelBtnText="Finished"
                    showCancel
                >
                    Would you like to add another template container?
                </SweetAlert>
            )
        })
    }

    continue() {
        this.setState({
            alert: null
        })
    }

    finished() {
        const {history} = this.props

        this.setState({
            alert: null
        })
        history.goBack()
    }

    removeAlert() {
        this.setState({
            alert: null
        })
    }

    submit(values) {
        const {dispatch, templateGroups} = this.props
        const {templateGroupSelected} = this.state

        let user = JSON.parse(localStorage.getItem('user'))
        let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'))

        if (
            templateGroupSelected === null ||
            !templateGroups[templateGroupSelected]
        ) {
            return
        }

        let templateContainer = {
            name: values.name,
            description: {'en-US': values.description},
            modifiedBy: user.id,
            jurisdiction: jurisdiction.id,
            templategroup: templateGroupSelected
        }

        return templateService
            .addNewTemplateContainer(templateContainer)
            .then(newTemplateContainer => {
                dispatch(
                    templateActions.registerTemplateContainer(newTemplateContainer)
                )
                this.showNotification(
                    'success',
                    'Template has been successfully created!'
                )
                this.confirmContinue()
                return newTemplateContainer
            })
            .catch(error => {
                console.error('ERROR creating template container ', error)
                this.showNotification(
                    'danger',
                    'There was a problem creating the template!'
                )
                return {
                    [FORM_ERROR]: 'There was a problem creating the template.'
                }
            })
    }

    cancel(pristine) {
        const {classes, history} = this.props

        if (!pristine) {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{display: 'block'}}
                        title="Warning!"
                        onConfirm={() => this.finished()}
                        onCancel={() => this.removeAlert()}
                        confirmBtnCssClass={classes.button + ' ' + classes.success}
                        cancelBtnCssClass={classes.button + ' ' + classes.danger}
                        confirmBtnText="Yes"
                        cancelBtnText="No"
                        showCancel
                    >
                        Cancelling will discard any changes made. Are you sure?
                    </SweetAlert>
                )
            })
        } else {
            history.goBack()
        }
    }

    render() {
        const {
            alert,
            snackBarOpen,
            snackBarMessage,
            snackBarStatus,
            templateGroupSelected
        } = this.state
        const {templateGroups} = this.props

        return (
            <div>
                {alert}
                <Snackbar
                    place="tr"
                    color={snackBarStatus}
                    icon={Assignment}
                    message={snackBarMessage}
                    open={snackBarOpen}
                    closeNotification={() => this.setState({snackBarOpen: false})}
                    close
                />
                <NewTemplateContainerForm
                    templateGroups={templateGroups}
                    initialSelected={templateGroupSelected}
                    onTemplateGroupChange={this.handleTemplateGroupChange}
                    onSubmit={this.submit}
                    onCancel={this.cancel}
                />
            </div>
        )
    }
}


function mapStateToProps(state) {
    const {templates} = state

    if (!templates) {
        return {}
    }

    return {
        templateGroups: templates.templateGroups || {},
        loading: templates.loading || false,
        saving: templates.saving || false
    }
}

export default connect(mapStateToProps)(
    withStyles(newTemplateContainerFormsStyle)(NewTemplateContainerPage)
)


class NewTemplateContainerForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            initialTemplateGroupSet: false,
            templateGroupSelected: null
        }
        this.handleTemplateGroupChange = this.handleTemplateGroupChange.bind(this)
    }

    handleTemplateGroupChange(newSelection, formApi) {
        const {templateGroupSelected} = this.state
        const {onTemplateGroupChange} = this.props

        if (templateGroupSelected !== newSelection) {
            this.setState({
                templateGroupSelected: newSelection,
                initialTemplateGroupSet: true
            })
            onTemplateGroupChange(newSelection)
            formApi.change('templateGroup', newSelection)
        }
    }

    componentWillUpdate(_, __, ___) {
        const {initialTemplateGroupSet, templateGroupSelected} = this.state
        const {initialSelected} = this.props
        if (
            !initialTemplateGroupSet &&
            initialSelected &&
            templateGroupSelected !== initialSelected
        ) {
            this.setState({
                templateGroupSelected: initialSelected
            })
        }
    }

    render() {
        const {classes, onSubmit, onCancel, templateGroups} = this.props
        // const templateGroupIds = Object.keys(templateGroups)
        // let selectableTemplateGroups = templateGroupIds.map(templateGroupId => {
        //     let templateGroup = templateGroups[templateGroupId]
        //     return {label: templateGroup.name, value: templateGroup.id}
        // })
        const selectableTemplateGroups = Object.values(templateGroups).map(
            templateGroup => {
                return {
                    label: templateGroup.name,
                    value: templateGroup.id
                }
            }
        )

        if (!selectableTemplateGroups.length) {
            return null
        }
        const { templateGroupSelected } = this.state

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={9}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="rose">
                                <Assignment />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>Add a new Template</h4>
                        </CardHeader>
                        <CardBody>
                            <Form onSubmit={values => onSubmit(values)} validate={validate}>
                                {({form, handleSubmit, pristine, submitting}) => (
                                    <form onSubmit={handleSubmit} autoComplete="off">
                                        <GridContainer>
                                            <GridItem xs={12}>
                                                <Field name="templateGroup">
                                                    {props => (
                                                        <CustomisableSelect
                                                            controlId={'templateGroup'}
                                                            handleChange={newSelection => {
                                                                this.handleTemplateGroupChange(
                                                                    newSelection,
                                                                    form
                                                                )
                                                            }}
                                                            items={selectableTemplateGroups}
                                                            label={'Template Group'}
                                                            value={templateGroupSelected}
                                                        />
                                                    )}
                                                </Field>
                                            </GridItem>
                                            <GridItem xs={12}>
                                                <Field name="name">
                                                    {props => (
                                                        <CustomTextField
                                                            label={'NAME *'}
                                                            onChange={props.input.onChange}
                                                            value={props.input.value}
                                                            fullWidth
                                                        />
                                                    )}
                                                </Field>
                                            </GridItem>
                                            <GridItem xs={12}>
                                                <Field name="description">
                                                    {props => (
                                                        <CustomTextField
                                                            label={'DESCRIPTION *'}
                                                            onChange={props.input.onChange}
                                                            value={props.input.value}
                                                            fullWidth
                                                        />
                                                    )}
                                                </Field>
                                            </GridItem>
                                            <GridItem xs={12}>
                                                <div className={classes.formCategory}>
                                                    <small>*</small> Required fields
                                                </div>
                                            </GridItem>
                                        </GridContainer>
                                        <Button
                                            color="success"
                                            type="submit"
                                            className={classes.registerButton}
                                            disabled={submitting}
                                        >
                                            <Create className={classes.icon} />
                                            Create
                                        </Button>
                                        <Button
                                            color="danger"
                                            className={classes.registerButton}
                                            style={{marginRight: '10px'}}
                                            disabled={submitting}
                                            onClick={() => onCancel(pristine)}
                                        >
                                            <Cancel className={classes.icon} />
                                            Cancel
                                        </Button>
                                        <Button
                                            color="primary"
                                            className={classes.registerButton}
                                            style={{marginRight: '25px'}}
                                            disabled={pristine || submitting}
                                            onClick={form.reset}
                                        >
                                            <Clear className={classes.icon} />
                                            Clear
                                        </Button>
                                    </form>
                                )}
                            </Form>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        )
    }
}


NewTemplateContainerForm = withStyles(newTemplateContainerFormsStyle)(
    NewTemplateContainerForm
)
