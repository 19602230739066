import { Category } from './category'
import { EntityMap, Epoch, EpochUtc } from 'types/generics'
import { ReportState } from './entities'
import { AvailableResourceStatistics, Resource } from './api'
import { StatisticType } from './statistic'
import { OccupancyCategoryDay, OccupancySummary } from './summary'
import {
  EventStatisticType,
  ParkingStatInterval,
  StatisticClassification
} from 'features/resource/types/parkingStat'
import { FinancialReportState } from 'features/resource/types/paymentTypes'


/** @interface ResourceSlice - Shape for Redux resourceSlice */
export interface ResourceSlice extends WorkerParams, FinancialReportState {
  displayActual: boolean
  error: null | string | Error
  hideResourceIds: string[]
  init: boolean
  loading: boolean
  reportsLoading: boolean
  requestId: string | null
  showReport: boolean
  terms: TermMap
}



/** @interface WorkerParams - Parameters used by reportWorker & dashboardWorker */
export interface WorkerParams extends ReportParameters, ReportSummary {
  smoothValue: number
  monthStarts: Epoch[]
  overflowSecondsBase: number
  reportState: ReportState[]
  weekStarts: EpochUtc[]
}

/** @interface ReportSummary - Parameters used for Monthly Summary Reports */
export interface ReportSummary {
  summaryData: (OccupancySummary | OccupancyCategoryDay)[]
  summaryIsMultiTenant: boolean
  summaryLoading: boolean
  summaryMonth: number
  summaryOffset: number
  summarySegmentHours: number
  summaryUseOccupancy: boolean
}

export interface TermMap {
  [termKey: string]: string
}

//-- Parameters
/** @interface ReportParameters - Report configuration related fields */
export interface ReportParameters {
  availableStatistics: AvailableResourceStatistics // Statistics for each resource
  categoriesLoading: boolean
  cumulative: boolean
  dateRangeSelections: DateRange[] // Date ranges to include in the report
  daysOfTheWeek: DayOfTheWeek[]
  groupBy: ReportGroupBy
  interval: ParkingStatInterval
  reportType: ReportType // Report Type for a report that is already generated
  resourceCategories: AvailableCategories // Categories for each resource
  resources: EntityMap<Resource>
  selectedCategories: Category[]
  selectedClassifications: StatisticClassification[]
  selectedReportType: ReportType // Future report type
  selectedResourceIds: string[]
  selectedStatisticTypes: EventStatisticType[]
  smoothValue: number
  statisticsLoading: boolean
}

/** @interface SavableParameters - Parameters that users can save.
 * These automatically get reloaded when visiting the "Utilization Reports" page
 */
export interface SavableParameters {
  cumulative: boolean
  dateRangeSelections: DateRange[] // Date ranges to include in the report
  daysOfTheWeek: DayOfTheWeek[]
  groupBy: ReportGroupBy
  interval: ParkingStatInterval
  selectedCategories: Category[]
  selectedClassifications: StatisticClassification[]
  selectedReportType: ReportType
  selectedResourceIds: string[]
  selectedStatisticTypes: EventStatisticType[]
  smoothValue: number
  summaryMonth: number
  summaryOffset: number
  summarySegmentHours: number
}

export interface AvailableCategories {
  [resourceId: string]: Category[]
}

export enum ReportGroupBy {
  week = 'week',
  day = 'day'
}

export interface DateRange {
  dateRangeId: number
  end: Epoch
  id: number
  isCustom: boolean
  label: string
  start: Epoch
}

export enum ReportType {
  occupancy = 'occupancy',
  predictive = 'predictive',
  // profileTimeSeries = 'profileTimeSeries',
  profileComparison = 'profileComparison',
  ev = 'ev',
  summary = 'summary',
  // Statistics Reports
  // SD-632
  // maxSessionTimeExceeded = 'maxSessionTimeExceeded',
  occupancyStats = 'occupancyStats',
  parkingSession = 'parkingSession',
  sessionStats = 'sessionStats',
  unknownVehicleExit = 'unknownVehicleExit',
  vehicleViolationEvent = 'vehicleViolationEvent',
  heatMap = 'heatMap'
}

export type DayOfTheWeek = 1 | 2 | 3 | 4 | 5 | 6 | 7
