/* eslint-disable */

import React from 'react'
import Add from '@material-ui/icons/Add'
import Assignment from '@material-ui/icons/Assignment'
import Cancel from '@material-ui/icons/Cancel'
import { connect } from 'react-redux'
import Create from '@material-ui/icons/Create'
import Delete from '@material-ui/icons/Delete'
import Done from '@material-ui/icons/Done'
import Edit from '@material-ui/icons/Edit'
import { Field, Form } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import Revert from '@material-ui/icons/GetApp'
import SweetAlert from 'react-bootstrap-sweetalert'
import Tooltip from '@material-ui/core/Tooltip'
import ViewHeadline from '@material-ui/icons/ViewHeadline'
import withStyles from '@material-ui/core/styles/withStyles'

import Button from 'components/CustomButtons/Button'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import ColorSelector from 'components/ColorSelector'
import CustomTextField from 'components/redux-form/CustomTextField/CustomTextField'
import editTemplateFormsStyle from 'assets/jss/spot-admin/views/newTemplateGroupStyle'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Snackbar from 'components/Snackbar/Snackbar'
import { stringOfLengthBetween } from 'helpers/validation'
import Table from 'components/Table/Table'
import { templateService } from 'services'
import { templateActions } from 'actions'

const validate = values => {
  const errors = {}

  if (!values.description) {
    errors.description = 'Description is a required field'
  } else {
    if (!stringOfLengthBetween(values.description, 1, 200)) {
      errors.description = 'Description must has a maximum of 200 characters'
    }
  }

  return errors
}

class EditTemplatePage extends React.Component {
  constructor(props) {
    super(props)
    const { match, templates } = props

    let editTemplateId = match.params.id
    let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'))

    let initialState = {
      alert: null,
      snackBarOpen: false,
      snackBarStatus: 'success',
      snackBarMessage: '',
      editTemplateId,
      baseCurrency: jurisdiction['BASE_CURRENCY'] || 'USD',
      jurisdiction
    }

    this.state = initialState

    this.submit = this.submit.bind(this)
    this.cancel = this.cancel.bind(this)
    this.finished = this.finished.bind(this)
    this.removeAlert = this.removeAlert.bind(this)
    this.showNotification = this.showNotification.bind(this)
    this.showSuccess = this.showSuccess.bind(this)

    this.handleConfigurationDelete = this.handleConfigurationDelete.bind(this)
    this.addConfiguration = this.addConfiguration.bind(this)
    this.viewConfiguration = this.viewConfiguration.bind(this)
    this.editConfiguration = this.editConfiguration.bind(this)
  }

  componentDidMount() {
    const { dispatch, templates, templateContainers, signConfigs } = this.props
    const { jurisdiction } = this.state

    if (Object.keys(templates).length == 0) {
      dispatch(templateActions.getTemplates(jurisdiction.id))
    }

    if (Object.keys(templateContainers).length == 0) {
      dispatch(templateActions.getTemplateContainers(jurisdiction.id))
    }

    if (Object.keys(signConfigs).length == 0) {
      dispatch(templateActions.getSignConfigs(jurisdiction.id))
    }
  }

  componentWillUnmount() {
    if (this.notificationTimer) {
      clearTimeout(this.notificationTimer)
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { templates, templateContainers, match } = nextProps

    let editTemplateId = match.params.id

    if (templates) {
      let template = templates[editTemplateId]
      if (!template) {
        return {}
      }

      return {
        editTemplateId,
        formInitialValues: {
          description: template.description['en-US'],
          templateContainer: templateContainers[template.templatecontainer.id]
        }
      }
    }

    return {}
  }

  handleConfigurationDelete(configId) {
    const { classes } = this.props

    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: 'block' }}
          title="Warning!"
          onConfirm={e => this.deleteConfiguration(e, configId)}
          onCancel={() => this.removeAlert()}
          cancelBtnCssClass={classes.button + ' ' + classes.danger}
          confirmBtnCssClass={classes.button + ' ' + classes.warning}
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        >
          Deleting a configuration will also remove it on any corresponding
          parking assets. Do you want to continue?
        </SweetAlert>
      )
    })
  }

  showNotification(status, message) {
    if (!this.state.snackBarOpen) {
      this.setState({
        snackBarOpen: true,
        snackBarStatus: status,
        snackBarMessage: message
      })
      this.notificationTimer = setTimeout(
        function () {
          this.setState({
            snackBarOpen: false
          })
          this.notificationTimer = null
        }.bind(this),
        3000
      )
    }
  }

  deleteConfiguration(e, configId) {
    const { templates, dispatch } = this.props
    const { editTemplateId } = this.state

    let template = templates[editTemplateId]

    this.setState({
      alert: null
    })

    template.affected.lots = template.affected.lots.filter(
      reference => reference.configId !== configId
    )
    template.affected.bayGroups = template.affected.bayGroups.filter(
      reference => reference.configId !== configId
    )
    template.affected.pamReferenceIds =
      template.affected.pamReferenceIds.filter(
        reference => reference.configId !== configId
      )

    delete template.signConfig[configId]
    delete template.attributes[configId]

    return templateService
      .updateTemplate(template)
      .then(updatedTemplate => {
        dispatch(templateActions.registerTemplate(updatedTemplate))
        this.showNotification(
          'success',
          'Template configuration has been successfully removed!'
        )
        return updatedTemplate
      })
      .catch(error => {
        console.error('ERROR updating template version ', error)
        this.showNotification(
          'danger',
          'There was a problem updating the template version!'
        )
        return {
          [FORM_ERROR]: 'There was a problem updating the template version.'
        }
      })
  }

  finished() {
    const { history } = this.props

    this.setState({
      alert: null
    })
    history.goBack()
  }

  removeAlert() {
    this.setState({
      alert: null
    })
  }

  addConfiguration() {
    const { history } = this.props
    const { editTemplateId } = this.state

    history.push(
      `/template-admin/template-configuration/new-template-configuration/${editTemplateId}`
    )
  }

  editConfiguration(configId) {
    const { history } = this.props
    const { editTemplateId } = this.state

    history.push(
      `/template-admin/template-configuration/edit-template-configuration/${editTemplateId}/${configId}`
    )
  }

  viewConfiguration(configId) {
    const { history } = this.props
    const { editTemplateId } = this.state

    history.push(
      `/template-admin/template-configuration/view-template-configuration/${editTemplateId}/${configId}`
    )
  }

  showSuccess() {
    const { classes } = this.props

    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: 'block' }}
          title="Success!"
          onConfirm={() => this.finished()}
          confirmBtnCssClass={classes.button + ' ' + classes.success}
          confirmBtnText="Ok"
        >
          The template version has been updated successfully
        </SweetAlert>
      )
    })
  }

  submit(values) {
    const { dispatch, templates } = this.props
    const { editTemplateId } = this.state

    let user = JSON.parse(localStorage.getItem('user'))

    const previousTemplate = templates[editTemplateId]

    let template = {
      id: editTemplateId,
      description: { 'en-US': values.description },
      affected: previousTemplate.affected,
      signConfig: previousTemplate.signConfig,
      attributes: previousTemplate.attributes,
      version: previousTemplate.version,
      published: previousTemplate.published,
      modifiedBy: user.id,
      templatecontainer: previousTemplate.templatecontainer.id
    }

    return templateService
      .updateTemplate(template)
      .then(updatedTemplate => {
        dispatch(templateActions.registerTemplate(updatedTemplate))
        this.showNotification(
          'success',
          'Template version has been successfully updated!'
        )
        this.showSuccess()
        return updatedTemplate
      })
      .catch(error => {
        console.error('ERROR updating template version ', error)
        this.showNotification(
          'danger',
          'There was a problem updating the template version!'
        )
        return {
          [FORM_ERROR]: 'There was a problem updating the template version.'
        }
      })
  }

  cancel(pristine) {
    const { classes, history } = this.props

    if (!pristine) {
      this.setState({
        alert: (
          <SweetAlert
            warning
            style={{ display: 'block' }}
            title="Warning!"
            onConfirm={() => this.finished()}
            onCancel={() => this.removeAlert()}
            confirmBtnCssClass={classes.button + ' ' + classes.success}
            cancelBtnCssClass={classes.button + ' ' + classes.danger}
            confirmBtnText="Yes"
            cancelBtnText="No"
            showCancel
          >
            Cancelling will discard any changes made. Are you sure?
          </SweetAlert>
        )
      })
    } else {
      history.goBack()
    }
  }

  render() {
    const {
      alert,
      snackBarOpen,
      snackBarMessage,
      snackBarStatus,
      baseCurrency,
      editTemplateId,
      formInitialValues
    } = this.state
    const { signConfigs, templates, readOnlyMode } = this.props

    return (
      <div>
        {alert}
        <Snackbar
          place="tr"
          color={snackBarStatus}
          icon={Assignment}
          message={snackBarMessage}
          open={snackBarOpen}
          closeNotification={() => this.setState({ snackBarOpen: false })}
          close
        />
        <EditTemplateForm
          readOnlyMode={readOnlyMode}
          baseCurrency={baseCurrency}
          signConfigs={signConfigs}
          templates={templates}
          editTemplateId={editTemplateId}
          formInitialValues={formInitialValues}
          onConfigurationEdit={this.editConfiguration}
          onConfigurationDelete={this.handleConfigurationDelete}
          onConfigurationView={this.viewConfiguration}
          onAddConfiguration={this.addConfiguration}
          onSubmit={this.submit}
          onCancel={this.cancel}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { templates } = state

  if (!templates) {
    return {}
  }

  return {
    templates: templates.templates || {},
    templateContainers: templates.templateContainers || {},
    signConfigs: templates.signConfigs || {},
    loading: templates.loading || false,
    saving: templates.saving || false
  }
}

export default connect(mapStateToProps)(
  withStyles(editTemplateFormsStyle)(EditTemplatePage)
)

class EditTemplateForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      initialValuesLoaded: false,
      reset: false,
      baseCurrency: props.baseCurrency
    }

    this.handleRevert = this.handleRevert.bind(this)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { initialValuesLoaded, reset } = this.state
    const { formInitialValues } = this.props

    if (!initialValuesLoaded && formInitialValues) {
      this.setState({ initialValuesLoaded: true })
    }

    if (reset) {
      this.setState({ reset: false })
    }
  }

  handleRevert() {
    const { formInitialValues } = this.props
    this.setState({
      reset: true
    })
  }

  viewConfigurationDetails(e, configId) {
    const { onConfigurationView } = this.props
    onConfigurationView(configId)
  }

  editConfigurationDetails(e, configId) {
    const { onConfigurationEdit } = this.props
    onConfigurationEdit(configId)
  }

  deleteConfigurationDetails(e, configId) {
    const { onConfigurationDelete } = this.props
    onConfigurationDelete(configId)
  }

  renderActionButtons(configId) {
    const { classes, templates, editTemplateId, readOnlyMode } = this.props

    const template = templates[editTemplateId]
    const numberOfConfigurations = Object.keys(template.signConfig).length

    const actionButtons = [
      {
        task: 'viewConfigurationDetails',
        color: 'success',
        icon: ViewHeadline,
        displayIfReadOnlyMode: true,
        tooltip: `View This Configuration's Details`
      },
      {
        task: 'editConfigurationDetails',
        color: 'rose',
        icon: Edit,
        displayIfReadOnlyMode: false,
        tooltip: `Edit This Configuration's Details`
      },
      {
        task: 'deleteConfigurationDetails',
        color: 'danger',
        icon: Delete,
        displayIfReadOnlyMode: false,
        showOnlyWithMultipleConfigs: true,
        tooltip: 'Delete This Configuration'
      }
    ]

    let renderedButtons = actionButtons
    if (readOnlyMode) {
      renderedButtons = renderedButtons.filter(
        e => e.displayIfReadOnlyMode !== undefined && e.displayIfReadOnlyMode
      )
    }

    if (numberOfConfigurations < 2) {
      renderedButtons = renderedButtons.filter(
        e => !e.showOnlyWithMultipleConfigs === true
      )
    }

    return (
      <div>
        {renderedButtons.map((button, key) => {
          return (
            <Tooltip key={key} title={button.tooltip}>
              <Button
                color={button.color}
                className={classes.actionButton}
                onClick={e => this[button.task](e, configId)}
              >
                <button.icon className={classes.icon} />
                {''}
              </Button>
            </Tooltip>
          )
        })}
      </div>
    )
  }

  render() {
    const {
      classes,
      editTemplateId,
      formInitialValues,
      onAddConfiguration,
      onCancel,
      onSubmit,
      readOnlyMode,
      signConfigs,
      templates
    } = this.props
    const { initialValuesLoaded } = this.state

    const signConfigIds = Object.keys(signConfigs)

    if (signConfigIds.length === 0 || !templates || templates.length === 0) {
      return null
    }

    if (!formInitialValues) {
      return (
        <GridContainer>
          <GridItem xs={12}>
            <h4>{`The requested Template Version for ${
              readOnlyMode ? 'view' : 'edit'
            }ing could not be found.`}</h4>
          </GridItem>
        </GridContainer>
      )
    }

    const templateSignConfigs = templates[editTemplateId].signConfig

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={9}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{`${
                readOnlyMode ? 'View' : 'Edit'
              } existing Template Version`}</h4>
            </CardHeader>
            <CardBody>
              <Form
                initialValues={formInitialValues}
                onSubmit={values => onSubmit(values)}
                validate={validate}
              >
                {({ form, handleSubmit, pristine, submitting }) => (
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <GridContainer>
                      <GridItem xs={12}>
                        <p>
                          {formInitialValues.templateContainer
                            ? `Configured Template: ${formInitialValues.templateContainer.name} (v${templates[editTemplateId].version})`
                            : null}
                        </p>
                      </GridItem>
                      <GridItem xs={12}>
                        <Field name="description">
                          {props => (
                            <CustomTextField
                              disabled={readOnlyMode}
                              fullWidth
                              label="DESCRIPTION *"
                              onChange={props.input.onChange}
                              value={props.input.value}
                            />
                          )}
                        </Field>
                      </GridItem>
                      <GridItem xs={12}>
                        <h5>
                          <br />
                          Configurations
                        </h5>
                        <Table
                          hover
                          striped
                          tableHead={[
                            'Color',
                            'Description',
                            'Configuration',
                            'Priority',
                            'Actions'
                          ]}
                          tableData={Object.keys(templateSignConfigs).map(
                            configId => {
                              let config = templateSignConfigs[configId]
                              return [
                                <ColorSelector
                                  color={config.color}
                                  displayOnly={true}
                                />,
                                config.description,
                                signConfigs[config.signConfig].category.replace(
                                  /[_]/g,
                                  ' '
                                ),
                                config.priority,
                                this.renderActionButtons(configId)
                              ]
                            }
                          )}
                          customCellClasses={[
                            classes.columnVersion,
                            classes.columnDescription,
                            classes.columnPublished,
                            classes.center,
                            classes.columnActions
                          ]}
                          customClassesForCells={[0, 1, 2, 3, 4]}
                          customHeadCellClasses={[
                            classes.center,
                            classes.right
                          ]}
                          customHeadClassesForCells={[3, 4]}
                        />
                      </GridItem>
                      {readOnlyMode || (
                        <GridItem xs={12}>
                          <Tooltip title={`Add A New Configuration`}>
                            <Button
                              color="success"
                              style={{ marginTop: '20px' }}
                              onClick={e => onAddConfiguration(e)}
                            >
                              <Add className={classes.icon} />
                              Add Configuration
                            </Button>
                          </Tooltip>
                        </GridItem>
                      )}
                      {readOnlyMode || (
                        <GridItem xs={12}>
                          <div className={classes.formCategory}>
                            <small>*</small> Required fields
                          </div>
                        </GridItem>
                      )}
                    </GridContainer>
                    {readOnlyMode || (
                      <Button
                        color="success"
                        type="submit"
                        className={classes.registerButton}
                        disabled={pristine || submitting}
                      >
                        <Create className={classes.icon} />
                        Update
                      </Button>
                    )}
                    {readOnlyMode || (
                      <Button
                        color="danger"
                        className={classes.registerButton}
                        style={{ marginRight: '10px' }}
                        disabled={submitting}
                        onClick={() => onCancel(pristine)}
                      >
                        <Cancel className={classes.icon} />
                        Cancel
                      </Button>
                    )}
                    {readOnlyMode || (
                      <Button
                        color="primary"
                        className={classes.registerButton}
                        style={{ marginRight: '25px' }}
                        disabled={pristine || submitting}
                        onClick={() => this.handleRevert()}
                      >
                        <Revert className={classes.icon} />
                        Revert
                      </Button>
                    )}
                    {!readOnlyMode || (
                      <Button
                        color="success"
                        className={classes.registerButton}
                        disabled={submitting}
                        onClick={() => onCancel(pristine)}
                      >
                        <Done className={classes.icon} />
                        Ok
                      </Button>
                    )}
                  </form>
                )}
              </Form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

EditTemplateForm = withStyles(editTemplateFormsStyle)(EditTemplateForm)
