export interface OccupancySummary {
  data: OccupancyRow[]
  dateKey: string
  summaryType: SummaryType
  timestamp: number
  title: string
  total: OccupancyRow
}

export interface OccupancyRow {
  count: number
  hour: number
  hourKey: string
  percent: number
  timestamp: number
}

export enum SummaryType {
  weekDay = 1,
  weekEnd = 2,
  daily = 3
}

//-- Multi-tenant summary Types (tenantOccupancyStatsByTimeSegment)

export interface ColumnTitle {
  category: string // Maps to categoryOccupancyRow.data keys
  label: string
}

export interface OccupancyCategoryDay {
  columnTitles: ColumnTitle[] // ORDERING IS IMPORTANT
  data: CategoryDayRow[] // Each record in `data` = 1 row. Each row needs to have a column for ach category + 1 for totals
  dateKey: string
  summaryType: SummaryType
  timestamp: number
  title: string
  total: Record<string, CategoryDayColumn>
}

export interface CategoryDayRow {
  data: Record<string, CategoryDayColumn> // { [category: string]: CategoryDayColumn }
  hour: number
  hourKey: string
  index: number
  timestamp: number
}

export interface CategoryDayColumn {
  category: string
  count: number
  percent: number
}
