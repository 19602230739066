import { DateTime } from 'luxon'
import { pipe, replace, toLower } from 'ramda'
import { v4 as uuidv4 } from 'uuid'

import { colors } from 'constants/constants'
import cyclicIndex from 'utils/cyclicIndex'
import { PaymentSessionDimension, PaymentSessionReportWithId } from 'features/resource/types/paymentSessionTypes'
import { RequiredDateRangeType, Resource, TimeRange } from 'features/resource/types/paymentTypes'
import { titleCase } from 'utils/textUtils'


export interface TimeValues {
    year: number
    quarter: number
    month: number
    week: number
    dayOfMonth: number
    dayOfWeek: number
    hour: number
    hourFromBase: number
    dateTime: DateTime
    epoch: number
}



export function convertUTCTimestampToLocale(timestamp: Date, locale: string): TimeValues {
    const dateTime = DateTime.fromJSDate(timestamp, {
        zone: locale
    })
    const year = dateTime.weekYear // Equivalent to date_part('isoyear', ...)
    const quarter = dateTime.quarter // Equivalent to date_part('quarter', ...)
    const month = dateTime.month // month() returns 0-11, so add 1 for 1-12 range
    const week = dateTime.weekNumber // Equivalent to date_part('week', ...)
    const dayOfMonth = dateTime.day // Equivalent to date_part('day', ...)
    const dayOfWeek = dateTime.weekday // Equivalent to date_part('isodow', ...)
    const hour = dateTime.hour // Equivalent to date_part('hour', ...)
    const hourFromBase = hour + (dateTime.weekday - 1) * 24

    return {
        year,
        quarter,
        month,
        week,
        dayOfMonth,
        dayOfWeek,
        hour,
        hourFromBase,
        dateTime,
        epoch: dateTime.toMillis()
    }
}


const dateTimeToTimeValueMap = {
    year: 'year',
    month: 'month',
    day: 'dayOfMonth',
    hour: 'hour'
}


export function timeValueToDateTime(timeValue: TimeValues, zone: string): DateTime {
    let dtObject = Object.entries(dateTimeToTimeValueMap).reduce((accum, [dateTimeKey, timeValueKey]) => {
        const value = timeValue[timeValueKey]
        if (value) {
            accum[dateTimeKey] = value
        }
        return accum
    }, {})
    return DateTime.fromObject(dtObject, {
        zone
    })
}



export function floorEpoch(epoch: number, zone: string): DateTime {
    return DateTime.fromMillis(epoch, {zone})
        .set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 1
        })
}



export function timeRangeToDateRange(timeRange: TimeRange): RequiredDateRangeType {
    const startDateTime = convertUTCTimestampToLocale(new Date(timeRange.startUTC), timeRange.locale)
    const endDateTime = convertUTCTimestampToLocale(new Date(timeRange.endUTC), timeRange.locale)
    const end: DateTime = endDateTime.dateTime.plus({days: 1}).minus({seconds: 1})
    return {
        start: startDateTime.epoch,
        end: end.toMillis()
    }
}


function getRelatedTimeRanges(resource: Resource): TimeRange[] {
    let allTimeRanges: TimeRange[] = resource.timeRanges ? [resource.timeRanges] : []
    if (!resource.relationships?.length) {
        return allTimeRanges
    }
    let childRanges: TimeRange[] = resource.relationships.reduce((accum, child: Resource) => {
        if (child.timeRanges) {
            accum.push(child.timeRanges)
        }
        return accum
    }, [])

    return allTimeRanges.concat(childRanges)
}


export function localeForResources(resources: Resource[]): string | null {
    // Try to pull from root-level resources first
    for (let resource of resources) {
        if (resource.timeRanges?.locale) {
            return resource.timeRanges.locale
        }
        // Save yourself a loop & introspect related resources
        if (resource.relationships) {
            for (let child of resource.relationships) {
                if (child.timeRanges?.locale) {
                    return child.timeRanges.locale
                }
            }
        }
    }
    return null
}


export function allowedDateRangeForResource(resource: Resource): RequiredDateRangeType | null {
    if (!resource.timeRanges) {
        return null
    }
    if (!resource.relationships?.length) {
        return timeRangeToDateRange(resource.timeRanges)
    }
    let allTimeRanges: TimeRange[] = getRelatedTimeRanges(resource)
    return allTimeRanges.map(timeRangeToDateRange).reduce((accum, dateRange) => {
        return {
            start: Math.min(accum.start, dateRange.start),
            end: Math.max(accum.end, dateRange.end)
        }
    }, {
        start: Infinity,
        end: -Infinity
    })
}


export function allowedDateRangeForResources(resources: Resource[]): RequiredDateRangeType | null {
    const allowed = resources.reduce(
        (accum, resource: Resource) => {
            const dateRange = allowedDateRangeForResource(resource)
            if (dateRange) {
                return {
                    start: Math.min(accum.start, dateRange.start),
                    end: Math.max(accum.end, dateRange.end)
                }
            }
            return accum
        },
        {
            start: Infinity,
            end: -Infinity
        }
    )
    if (allowed.start !== Infinity && allowed.end !== -Infinity) {
        return allowed
    }
    return null
}


export const formatPaymentSessionDimension: (value: string) => string = pipe(
    replace(/_/g, ' '),
    toLower,
    titleCase
)


interface SeriesData extends Record<string, any> {
    x: number
    y: number
}


type LineGroup = {
    id: string
    data: SeriesData[]
    label?: string
    color: string
}
const displayShortDateWithYear = (dateTime: DateTime): string => dateTime.toLocaleString(DateTime.DATE_SHORT)
const displayShortTime = (dateTime: DateTime): string => dateTime.toLocaleString(DateTime.TIME_SIMPLE)
type AxisConfig = {
    formats: string[]
    values: number[]
}


interface AxisConfigMap {
    xAxisConfig: AxisConfig
    yAxisConfig: AxisConfig
}


export interface LineGroupAndConfig extends AxisConfigMap {
    lineGroup: LineGroup
}


export interface LineGroupsAndConfigs extends AxisConfigMap {
    dimension: PaymentSessionDimension
    lineGroups: LineGroup[]
}


export function lineGroupsAndConfigsForReports(dimension: PaymentSessionDimension, reports: PaymentSessionReportWithId[]): LineGroupsAndConfigs {
    let isRevenue = dimension.toLowerCase().includes('revenue')
    let isDuration = dimension.toLowerCase().includes('duration')
    let currency = 'USD'
    let xAxisConfig: AxisConfig = {
        formats: [],
        values: []
    }

    let yAxisConfig: AxisConfig = {
        formats: [],
        values: []
    }
    let lineGroups: LineGroup[] = []

    reports.forEach((report, index) => {
        const lineGroupAndAxisConfigs = lineGroupAndAxisConfigsForReport(
            dimension,
            report,
            index
        )
        lineGroups.push(lineGroupAndAxisConfigs.lineGroup)
        xAxisConfig.formats = xAxisConfig.formats.concat(lineGroupAndAxisConfigs.xAxisConfig.formats)
        xAxisConfig.values = xAxisConfig.values.concat(lineGroupAndAxisConfigs.xAxisConfig.values)
        yAxisConfig.formats = yAxisConfig.formats.concat(lineGroupAndAxisConfigs.yAxisConfig.formats)
        yAxisConfig.values = yAxisConfig.values.concat(lineGroupAndAxisConfigs.yAxisConfig.values)
    })

    const yAxis: AxisConfig = getYAxisConfig(
        [...new Set(yAxisConfig.values)],
        10,
        isRevenue,
        currency
    ) || yAxisConfig

    return {
        dimension,
        lineGroups,
        xAxisConfig,
        yAxisConfig: yAxis
    }
}


function lineGroupAndAxisConfigsForReport(dimension: PaymentSessionDimension, report: PaymentSessionReportWithId, index: number = 0): LineGroupAndConfig {
    let isRevenue = dimension.toLowerCase().includes('revenue')
    let isDuration = dimension.toLowerCase().includes('duration')
    const yPrefix = isRevenue
        ? '$'
        : isDuration
            ? 'Seconds '
            : ''
    const {locale, series, currency, tag, provider, providerZoneId} = report
    const groupLabel = [provider, providerZoneId].filter(Boolean).join(': ')
    let xAxisConfig: AxisConfig = {
        formats: [],
        values: []
    }

    let yAxisConfig: AxisConfig = {
        formats: [],
        values: []
    }
    const color = cyclicIndex(colors, index)

    const lineGroup = {
        id: uuidv4(),
        color,
        label: groupLabel,
        data: series.map(datum => {
            // @ts-ignore
            // const dt = timeValueToDateTime(datum, locale)
            // const epoch = dt.toMillis()
            const xLabelAndValue = getXLabelAndValue(datum, locale)
            const xLabel = xLabelAndValue.label
            if (!xAxisConfig.values.includes(xLabelAndValue.value)) {
                xAxisConfig.values.push(xLabelAndValue.value)
                xAxisConfig.formats.push(xLabel)
            }
            const yRaw = datum[dimension] || 0
            const y = isRevenue
                ? yRaw / 100
                : yRaw
            const yLabel = yPrefix + String(y)
            if (!yAxisConfig.values.includes(y)) {
                yAxisConfig.values.push(y)
                yAxisConfig.formats.push(yLabel)
            }
            return {
                x: xLabelAndValue.value,
                y,
                label: `${yLabel}, ${xLabel}`,
                yLabel,
                xLabel,
                yRaw
            }
        })
    }

    return {
        lineGroup,
        xAxisConfig,
        yAxisConfig
    }
}


function getXLabelAndValue(datum: TimeValues, locale: string): { value: number, label: string } {
    // @ts-ignore
    const dt = timeValueToDateTime(datum, locale)
    if ('hour' in datum) {
        return {
            value: datum.hour,
            label: displayShortTime(dt)
        }
    }

    const epoch = dt.toMillis()
    const xLabel = displayShortDateWithYear(dt)
    return {
        value: epoch,
        label: xLabel
    }
}


function getYAxisConfig(yValues: number[], nValues: number = 10, isRevenue = true, currency = 'USD'): AxisConfig | null {
    const baseOptions = {
        style: 'currency',
        // trailingZeroDisplay: 'stripIfInteger',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    }
    const formatterOptions = !currency
        ? {currency: 'USD'}
        : {currency}
    const formatter = new Intl.NumberFormat('en-US', {
        ...baseOptions,
        ...formatterOptions
    })
    try {
        const maxY = Math.max(...yValues)
        const yIncrement = Math.round(maxY / nValues)
        let formats: string[] = []
        let values: number[] = []
        let y = 0
        while (y <= maxY) {
            const thisY: number = y + yIncrement
            values.push(thisY)
            if (isRevenue) {
                const formatted: string = formatter.format(thisY)
                formats.push(formatted)
            } else {
                formats.push(String(thisY))
            }
            y = thisY
        }
        return {
            values,
            formats
        }

    } catch (error) {
        return null
    }
}