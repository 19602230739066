import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles({
  grid: {
    margin: '0 -15px !important',
    width: 'calc(100% + 30px)'
  }
})

export default function GridContainer({ ...props }) {
  const classes = useStyles()
  const { children, className = '', ...rest } = props
  return (
    <Grid container {...rest} className={classes.grid + ' ' + className}>
      {children}
    </Grid>
  )
}

GridContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
}
