import React from 'react'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import indexRoutes from 'routes/index'
import { alertActions } from 'features/alert/alertSlice'
import PrivateRoute from './components/PrivateRoute/PrivateRoute'

import 'assets/scss/material-dashboard-pro-react.css?v=1.2.0'
import 'assets/scss/spot.scss'
import Snackbar from './components/Snackbar/Snackbar'
import {
  selectAlertMessage,
  selectAlertType
} from 'features/alert/alertSelectors'

export function App() {
  const dispatch = useDispatch()
  const message = useSelector(selectAlertMessage)
  const type = useSelector(selectAlertType)
  const AlertComponent = !message ? null : (
    <Snackbar
      place="tr"
      color={type}
      message={message}
      open={Boolean(message)}
      closeNotification={() => dispatch(alertActions.clear())}
      close
    />
  )
  return (
    <Router>
      <Switch>
        {indexRoutes.map((prop, key) => {
          if (prop.admin) {
            return (
              <PrivateRoute
                path={prop.path}
                component={prop.component}
                key={key}
              />
            )
          }
          return <Route path={prop.path} component={prop.component} key={key} />
        })}
      </Switch>
      {AlertComponent}
    </Router>
  )
}

export default App
