import React, { useState } from 'react'
import Cancel from '@material-ui/icons/Cancel'
import Check from '@material-ui/icons/Check'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ContentState } from 'draft-js'
import { convertFromHTML } from 'draft-js'
import Create from '@material-ui/icons/Create'
import DateTimePicker from 'react-datetime-picker'
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker'
import Done from '@material-ui/icons/Done'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Message from '@material-ui/icons/Message'
import MUIEditor, { MUIEditorState, toHTML as toHtml } from 'react-mui-draft-wysiwyg'
import { useSelector } from 'react-redux'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import 'assets/css/react-draft-wysiwyg.css'
import Button from 'components/CustomButtons/Button'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import CustomTextField from 'components/redux-form/CustomTextField/CustomTextField'
import CustomisableSelect from '../controls/CustomisableSelect'
import newTemplateGroupFormsStyle from 'assets/jss/spot-admin/views/newTemplateGroupStyle'
import { stringOfLengthBetween } from 'helpers/validation'
import { SelectOption } from 'types/generics'
import { DateRangeType, IdMessage, IMessage, MessageType } from 'features/message/types'
import { selectLoading, selectSaving } from 'features/message/messageSelectors'
import stripHtml from 'utils/stripHtml'


/**
 * Constants & Interfaces
 * ****************************
 */
interface CategoryOption extends SelectOption {
    value: MessageType
}


const typeCategories: CategoryOption[] = [
    {label: 'User Views Once Only', value: MessageType.once},
    {label: 'User Views Once Every Session', value: MessageType.session}
]


interface DateRangeOption extends SelectOption {
    value: DateRangeType
}


const timeRangeCategories: DateRangeOption[] = [
    {label: 'Always', value: DateRangeType.always},
    {label: 'From Date', value: DateRangeType.fromDate},
    {label: 'To Date', value: DateRangeType.toDate},
    {label: 'From/To Date', value: DateRangeType.fromToDate}
]


export interface MessageFormData extends IMessage {
    // fromDate: string | null
    // toDate: string | null
}


let defaultFromDate = new Date()
defaultFromDate.setHours(0, 0, 0, 0)
let defaultToDate = new Date(defaultFromDate)
defaultToDate.setDate(defaultFromDate.getDate() + 1)



const defaultMessage: MessageFormData = {
    closeButtonText: 'Ok',
    content: '',
    fromDate: null,
    heading: '',
    isActive: true,
    publishedDate: null,
    showInPopup: false,
    toDate: null,
    type: MessageType.session
}

const defaultValidationResults: Partial<MessageFormData> = {
    content: '',
    heading: '',
    fromDate: null,
    toDate: null
}


/**
 * Validation
 * ****************************
 */

function validate(values: MessageFormData): Partial<MessageFormData> {
    let errors: Partial<MessageFormData> = {}
    if (!values.heading) {
        errors.heading = 'Heading is a required field'
    } else if (!stringOfLengthBetween(values.heading, 4, 100)) {
        const minLengthString = new Intl.NumberFormat().format(4)
        const maxLengthString = new Intl.NumberFormat().format(100)
        errors.heading = `Heading must be between ${minLengthString} & ${maxLengthString} characters`
    }
    const parsedContent = values.content
        ? stripHtml(values.content)
        : ''
    if (!parsedContent) {
        errors.content = 'Content is a required field'
    } else if (!stringOfLengthBetween(parsedContent, 4, 10000)) {
        const minLengthString = new Intl.NumberFormat().format(4)
        const maxLengthString = new Intl.NumberFormat().format(10000)
        errors.content = `Content must be between ${minLengthString} & ${maxLengthString} characters`
    }
    return errors
}


export interface MessageFormProps extends Record<string, any> {
    message: null | IdMessage
    onCancel(): void
    onSubmit(value: MessageFormData): Promise<void>
    readOnlyMode: boolean
}


const editorConfig = {
    editor: {
        style: {
            height: '275px',
            border: '1px solid #F1F1F1',
            padding: '5px',
            borderRadius: '2px'
        }
    }
}

// @ts-ignore
export const useFormStyles = makeStyles(newTemplateGroupFormsStyle)

export const MessageForm = ({message, onCancel, onSubmit, readOnlyMode}: MessageFormProps) => {
    const classes = useFormStyles()
    const loading = useSelector(selectLoading)
    const saving = useSelector(selectSaving)

    const [editorState, setEditorState] = useState(() => {
        if (!message?.content) {
            return MUIEditorState.createEmpty()
        }
        // const blocksFromHTML = convertFromHTML(message.content)
        const blocksFromHtml = convertFromHTML(message.content)
        const state = ContentState.createFromBlockArray(
            blocksFromHtml.contentBlocks,
            blocksFromHtml.entityMap
        )
        return MUIEditorState.createWithContent(
            editorConfig,
            state
        )
    })
    const [dateRangeType, setDateRangeType] = useState<DateRangeType>(() => {
        if (message) {
            const {fromDate, toDate} = message
            return fromDate && toDate // Do we have both date fields? It's fromToDate
                ? DateRangeType.fromToDate
                : fromDate // Do we have a 'fromDate' field? It's fromDate
                    ? DateRangeType.fromDate
                    : toDate // Do we have a 'toDate' field? It's toDate
                        ? DateRangeType.toDate
                        : DateRangeType.always  // Default to always
        }
        return DateRangeType.always
    })
    const [data, setData] = useState<MessageFormData>(() => {
        if (message) {
            return {
                ...defaultMessage,
                ...message
            }
        }
        return {...defaultMessage}
    })

    const [validationResults, setValidationResults] = useState<Partial<MessageFormData>>({...defaultValidationResults})

    //-- Callbacks
    const updateDateRangeType = (type: DateRangeType) => {
        // Set default from/to dates on the form data
        if (type !== DateRangeType.always) {
            if (type === DateRangeType.fromDate && !data.fromDate) {
                updateData({
                    fromDate: defaultFromDate.toJSON(),
                    toDate: null
                })
            } else if (type === DateRangeType.toDate && !data.toDate) {
                updateData({
                    fromDate: null,
                    toDate: defaultToDate.toJSON()
                })
            } else if (type === DateRangeType.fromToDate && !(data.fromDate && data.toDate)) {
                updateData({
                    fromDate: defaultFromDate.toJSON(),
                    toDate: defaultToDate.toJSON()
                })
            }
        } else {
            updateData({
                fromDate: null,
                toDate: null
            })
        }

        setDateRangeType(type)
    }

    const updateEditorState = (_editorState) => {
        setEditorState(_editorState)
        const content = toHtml(_editorState.getCurrentContent())
        if (content !== data.content) {
            updateData({
                content
            })
        }
    }

    const updateData = (partialData: Partial<MessageFormData>) => {
        setData({...data, ...partialData})
    }

    const reset = () => {
        setValidationResults(defaultValidationResults)
        return onCancel()
    }

    const messageIsValid = (): boolean => {
        const result: Partial<MessageFormData> = validate(data)
        setValidationResults(result)
        return !Object.keys(result).length
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const isValid = messageIsValid()
        if (saving || loading || !isValid) {
            return
        }
        return onSubmit(data)
    }

    if (loading) {
        return <CircularProgress />
    }

    const fromDate = data.fromDate
        ? new Date(data.fromDate)
        : defaultFromDate
    const toDate = data.toDate
        ? new Date(data.toDate)
        : defaultToDate

    // View/edit/create existing/new message
    const titlePrefix = readOnlyMode
        ? 'View'
        : message
            ? 'Edit'
            : 'Create'
    const titleSuffix = message ? 'existing message' : 'new message'
    const title = `${titlePrefix} ${titleSuffix}`

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={9}>
                <Card>
                    <CardHeader color="primary" icon>
                        <CardIcon color="rose">
                            <Message />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>{title}</h4>
                    </CardHeader>
                    <CardBody>
                        <form onSubmit={handleSubmit} autoComplete={'off'}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        fullWidth
                                        disabled={readOnlyMode}
                                        label="HEADING *"
                                        onChange={e => updateData({heading: e.target.value})}
                                        value={data.heading}
                                        error={!!validationResults.heading}
                                        helperText={validationResults.heading}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomisableSelect
                                        disabled={readOnlyMode}
                                        handleChange={newSelection => {
                                            updateData({type: newSelection})
                                        }}
                                        items={typeCategories}
                                        label={'Type *'}
                                        value={data.type}
                                        noPlaceholder
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <div style={{paddingBottom: 20, paddingTop: 20}}>
                                        CONTENT *
                                    </div>
                                    <MUIEditor
                                        config={editorConfig}
                                        editorState={editorState}
                                        onChange={updateEditorState}
                                        readOnly={readOnlyMode}
                                    />
                                    {!validationResults.content ? null : (
                                        <FormHelperText
                                            component={'div'}
                                            error
                                            required
                                            style={{paddingBottom: 20}}
                                        >
                                            {validationResults.content}
                                        </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomisableSelect
                                        controllId={'dateRangeType'}
                                        disabled={readOnlyMode}
                                        handleChange={newSelection => {
                                            updateDateRangeType(newSelection)
                                        }}
                                        items={timeRangeCategories}
                                        label={'Time Range Type *'}
                                        value={dateRangeType}
                                        noPlaceholder
                                    />
                                </Grid>
                                {dateRangeType === DateRangeType.always
                                    ? null
                                    : dateRangeType === DateRangeType.fromDate
                                        ? (
                                            <Grid item xs={12} style={{marginTop: '10px'}}>
                                                <DateTimePicker
                                                    clearIcon={null}
                                                    disabled={readOnlyMode}
                                                    onChange={fromDate => {
                                                        updateData({fromDate, toDate: null})
                                                    }}
                                                    value={fromDate}
                                                />
                                            </Grid>
                                        )
                                        : dateRangeType === DateRangeType.toDate
                                            ? (
                                                <Grid item xs={12} style={{marginTop: '10px'}}>
                                                    <DateTimePicker
                                                        clearIcon={null}
                                                        disabled={readOnlyMode}
                                                        onChange={toDate => {
                                                            updateData({toDate, fromDate: null})
                                                        }}
                                                        value={toDate}
                                                    />
                                                </Grid>
                                            )
                                            : (
                                                <Grid item xs={12} style={{marginTop: '10px'}}>
                                                    <DateTimeRangePicker
                                                        clearIcon={null}
                                                        disableClock
                                                        disabled={readOnlyMode}
                                                        onChange={dateTimeRange => {
                                                            const [fromDate, toDate] = dateTimeRange
                                                            updateData({
                                                                fromDate: fromDate || defaultFromDate,
                                                                toDate: toDate || defaultToDate
                                                            })
                                                        }}
                                                        value={[fromDate, toDate]}
                                                    />
                                                </Grid>
                                            )}

                                <Grid item xs={12}>
                                    <div className={classes.checkboxAndRadio}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    tabIndex={-1}
                                                    onClick={_ => updateData({isActive: !data.isActive})}
                                                    checkedIcon={
                                                        <Check className={classes.checkedIcon} />
                                                    }
                                                    icon={
                                                        <Check className={classes.uncheckedIcon} />
                                                    }
                                                    classes={{
                                                        checked: classes.checked
                                                    }}
                                                    checked={data.isActive}
                                                    disabled={readOnlyMode}
                                                />
                                            }
                                            classes={{
                                                label: classes.label
                                            }}
                                            label="Make active"
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.checkboxAndRadio}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    tabIndex={-1}
                                                    onClick={_ => updateData({showInPopup: !data.showInPopup})}
                                                    checkedIcon={
                                                        <Check className={classes.checkedIcon} />
                                                    }
                                                    icon={
                                                        <Check className={classes.uncheckedIcon} />
                                                    }
                                                    classes={{
                                                        checked: classes.checked
                                                    }}
                                                    checked={data.showInPopup}
                                                    disabled={readOnlyMode}
                                                />
                                            }
                                            classes={{
                                                label: classes.label
                                            }}
                                            label="Show in Popup?"
                                        />
                                    </div>
                                </Grid>
                                {readOnlyMode ? null : (
                                    <Grid item xs={12}>
                                        <div className={classes.formCategory}>
                                            <small>*</small> Required fields
                                        </div>
                                    </Grid>
                                )}
                            </Grid>
                            {readOnlyMode ? null : (
                                <Grid container justifyContent={'flex-end'} spacing={2}>
                                    <Grid item>
                                        {/*
                                         // @ts-ignore */}
                                        <Button
                                            className={classes.registerButton}
                                            color="danger"
                                            disabled={saving || loading || readOnlyMode}
                                            onClick={reset}
                                            type={'reset'}
                                        >
                                            <Cancel />
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        {/*
                                         // @ts-ignore */}
                                        <Button
                                            className={classes.registerButton}
                                            color="success"
                                            disabled={saving || loading || readOnlyMode}
                                            type="submit"
                                        >
                                            <Done />
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                        </form>
                    </CardBody>
                </Card>
            </Grid>
        </Grid>
    )

}


