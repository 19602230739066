import {
    EventStatisticType,
    ParkingEventType,
    ReportType,
    StatisticType
} from 'features/resource/types'


export const ReportTypeStatisticMap = {
    [ReportType.occupancy]: [
        StatisticType.occupancyRate,
        StatisticType.tenantOccupancyProfile
    ],
    [ReportType.predictive]: [
        StatisticType.occupancyRate,
        StatisticType.tenantOccupancyProfile
    ],
    [ReportType.profileComparison]: [StatisticType.tenantOccupancyProfile],
    [ReportType.ev]: [StatisticType.chargePointStatus],
    [ReportType.heatMap]: [StatisticType.occupancyRate],
    //-- Statistic reports
    [ReportType.summary]: [
        StatisticType.occupancyStatsByTimeSegment,
        StatisticType.tenantOccupancyStatsByTimeSegment,
        StatisticType.tenantOccupancyCountsByTimeSegment
    ],
    // [ReportType.summary]: [StatisticType.occupancyStatsByTimeSegment, StatisticType.tenantOccupancyStatsByTimeSegment],
    // SD-632
    // [ReportType.maxSessionTimeExceeded]: [StatisticType.maxSessionTimeExceeded],
    [ReportType.parkingSession]: [StatisticType.parkingSession],
    [ReportType.sessionStats]: [StatisticType.sessionStats],
    [ReportType.unknownVehicleExit]: [StatisticType.unknownVehicleExit],
    [ReportType.vehicleViolationEvent]: [StatisticType.vehicleViolationEvent],
    [ReportType.occupancyStats]: [
        StatisticType.occupancyStats,
        StatisticType.occupancyRate,
        StatisticType.tenantOccupancyStats,
        StatisticType.tenantOccupancyCounts
    ]
}

export const eventTypeStatisticTypeMap = {
    [ParkingEventType.sessionStats]: [
        EventStatisticType.avg,
        EventStatisticType.max,
        // EventStatisticType.min,
        EventStatisticType.stdDev,
        EventStatisticType.sum
    ],
    [ParkingEventType.parkingSession]: [
        EventStatisticType.avg,
        EventStatisticType.sum
    ],
    [ParkingEventType.vehicleViolationEvent]: [EventStatisticType.sum],
    // SD-632
    // [ParkingEventType.maxSessionTimeExceeded]: [EventStatisticType.sum],
    [ParkingEventType.unknownVehicleExit]: [EventStatisticType.sum],
    [ParkingEventType.occupancyStats]: [
        EventStatisticType.min,
        EventStatisticType.max
    ],
    [ParkingEventType.tenantOccupancyStats]: [
        EventStatisticType.min,
        EventStatisticType.max
    ]
}
