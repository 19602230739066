import { createSlice } from '@reduxjs/toolkit'
import { alertActions } from 'features/alert/alertSlice'
import baseReducer from 'features/baseReducer'
import { reportService } from 'services'
import logError from '../../utils/logError'

export const initialState = {
  auditReport: null,
  auditReportloading: false,
  impactReport: null,
  impactReportloading: false,
  timeFilters: null,
  timeFiltersLoading: false,
  error: null
}

export const { name, reducer, actions } = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    ...baseReducer
  }
})

export const getAuditLogs = params => async (dispatch, getState) => {
  try {
    dispatch(
      actions.setState({
        auditReport: null,
        auditReportloading: true
      })
    )
    if (undefined === params.timeFilterMode) {
      params['timeFilterMode'] = 98
    }
    const auditReport = await reportService.getAuditLogs(params)
    dispatch(
      actions.setState({
        auditReport,
        auditReportloading: false
      })
    )
    return auditReport
  } catch (error) {
    logError(error)
    dispatch(
      actions.setState({
        error,
        auditReportloading: false
      })
    )
    dispatch(alertActions.error(error))
  }
  return null
}

export const resetAuditLogs = () => async dispatch => {
  dispatch(
    actions.setState({
      auditReport: null,
      auditReportloading: false
    })
  )
}

export const getImpactLogs = params => async dispatch => {
  try {
    dispatch(
      actions.setState({
        impactReport: null,
        impactReportloading: true
      })
    )
    if (undefined === params.timeFilterMode) {
      params['timeFilterMode'] = 98
    }
    const impactReport = await reportService.getImpactLogs(params)
    dispatch(
      actions.setState({
        impactReport,
        impactReportloading: false
      })
    )
    return impactReport
  } catch (error) {
    logError(error)
    dispatch(
      actions.setState({
        error,
        impactReportloading: false
      })
    )
    dispatch(alertActions.error(error))
  }
  return null
}

export const resetImpactLogs = () => dispatch => {
  dispatch(
    actions.setState({
      impactReport: null
    })
  )
}

export const getTimeFilters = params => async dispatch => {
  try {
    dispatch(
      actions.setState({
        timeFilters: null,
        timeFiltersLoading: true
      })
    )
    const timeFilters = await reportService.getTimeFilters(params)
    dispatch(
      actions.setState({
        timeFilters,
        timeFiltersLoading: false
      })
    )
    return timeFilters
  } catch (error) {
    logError(error)
    dispatch(
      actions.setState({
        error,
        timeFiltersLoading: false
      })
    )
    dispatch(alertActions.error(error))
  }
  return null
}

export const reportActions = {
  getAuditLogs,
  resetAuditLogs,
  getImpactLogs,
  resetImpactLogs,
  getTimeFilters
}
