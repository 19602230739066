import { createSelector } from '@reduxjs/toolkit'
import { initialState } from './alertSlice'

const selectDomain = state => state.alert || initialState

const domainSelector = (key: string) =>
  createSelector([selectDomain], containerState => containerState[key])

export const selectAlert = createSelector([selectDomain], state => state)

export const selectAlertMessage = domainSelector('message')
export const selectAlertType = domainSelector('type')
export const selectAlertId = domainSelector('id')
