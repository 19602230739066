import { ApiStub } from 'services/stubs/apiStub'
import { createInstance } from 'helpers'
import {
  CreateJurisdictionRequest,
  CreateJurisdictionResponse,
  JurisdictionDetail,
  JurisdictionDetailResponse,
  JurisdictionListResponse,
  UpdateJurisdictionRequest,
  UpdateJurisdictionResponse
} from 'features/jurisdiction/types'

const request = createInstance(
  'https://data-collection-api.spotparking.com.au/core'
)

// READ
const getAll = async (
  signal?: AbortSignal
): Promise<JurisdictionListResponse> => {
  return request.get('/jurisdiction/list', signal)
}

const getJurisdictionDetail = async (
  jurisdictionId: string,
  signal?: AbortSignal
): Promise<JurisdictionDetailResponse> => {
  return request.get(`/jurisdiction/${jurisdictionId}`, signal)
}

// CREATE
const createJurisdiction = async (
  body: CreateJurisdictionRequest,
  signal?: AbortSignal
): Promise<CreateJurisdictionResponse> => {
  return request.post(`/jurisdiction/${body.jurisdiction.id}`, body, signal)
}

// UPDATE
const updateJurisdiction = async (
  body: UpdateJurisdictionRequest,
  signal?: AbortSignal
): Promise<UpdateJurisdictionResponse> => {
  return request.put(`/jurisdiction/${body.jurisdiction.id}`, body, signal)
}

// DELETE
const deleteJurisdiction = async (
  jurisdictionId: string,
  signal?: AbortSignal
): Promise<string> => {
  await request.delete(`/jurisdiction/${jurisdictionId}`, signal)
  return jurisdictionId
}

const publishJurisdiction = async (
  jurisdictionId: string,
  signal?: AbortSignal
): Promise<JurisdictionDetailResponse> => {
  // TODO: CR 2022-Nov-28 - Wire this up to the appropriate API endpoint
  return getJurisdictionDetail(jurisdictionId, signal)
}

//-- History API
const historyResponse = [
  {
    id: '4587e7b3-dea8-4367-9ba7-97976a83405a',
    name: 'Test',
    abbreviation: 'test',
    locality: 'city',
    parent: null,
    jurisdictionAttributes: {
      DEFAULT_LOCALE: 'en-US',
      LANGUAGE_DICTIONARY: {},
      PUBLIC_HOLIDAYS: [],
      SUPPORTED_LOCALES: ['en-US'],
      TIMEZONE: 'America/Denver',
      BASE_CURRENCY: 'USD',
      COUNTRY_CODE: 'USA',
      SCHOOL_HOLIDAYS: [],
      CUSTOM_DAYS: {}
    },
    createdAt: '2022-10-31T19:42:19.989Z',
    updatedAt: '2022-10-31T13:42:19.989-06:00'
  },
  {
    id: '4587e7b3-dea8-4367-9ba7-97976a83405b',
    name: 'Test',
    abbreviation: 'test',
    locality: 'city',
    parent: null,
    jurisdictionAttributes: {
      DEFAULT_LOCALE: 'en-US',
      LANGUAGE_DICTIONARY: {},
      PUBLIC_HOLIDAYS: [],
      SUPPORTED_LOCALES: ['en-US', 'en-AU'],
      TIMEZONE: 'America/Denver',
      BASE_CURRENCY: 'USD',
      COUNTRY_CODE: 'USA',
      SCHOOL_HOLIDAYS: [],
      CUSTOM_DAYS: {}
    },
    createdAt: '2022-10-31T19:42:19.989Z',
    updatedAt: '2022-11-01T13:42:19.989-06:00'
  },
  {
    id: '4587e7b3-dea8-4367-9ba7-97976a83405c',
    name: 'Test',
    abbreviation: 'test',
    locality: 'city',
    parent: null,
    jurisdictionAttributes: {
      DEFAULT_LOCALE: 'en-US',
      LANGUAGE_DICTIONARY: {},
      PUBLIC_HOLIDAYS: [],
      SUPPORTED_LOCALES: ['en-US', 'en-AU'],
      TIMEZONE: 'America/Denver',
      BASE_CURRENCY: 'USD',
      COUNTRY_CODE: 'USA',
      SCHOOL_HOLIDAYS: [
        {
          start: '24/11/2022',
          end: '27/11/2022'
        }
      ],
      CUSTOM_DAYS: {}
    },
    createdAt: '2022-10-31T19:42:19.989Z',
    updatedAt: '2022-11-02T13:42:19.989-06:00'
  },
  {
    id: '4587e7b3-dea8-4367-9ba7-97976a83405d',
    name: 'Test',
    abbreviation: 'test',
    locality: 'city',
    parent: null,
    jurisdictionAttributes: {
      DEFAULT_LOCALE: 'en-US',
      LANGUAGE_DICTIONARY: {},
      PUBLIC_HOLIDAYS: [],
      SUPPORTED_LOCALES: ['en-US', 'en-AU'],
      TIMEZONE: 'America/Denver',
      BASE_CURRENCY: 'USD',
      COUNTRY_CODE: 'USA',
      SCHOOL_HOLIDAYS: [
        {
          start: '24/11/2022',
          end: '27/11/2022'
        }
      ],
      CUSTOM_DAYS: {
        ACADEMIC_BREAKS: [
          {
            name: 'Thanksgiving',
            start: '21/11/2022',
            end: '26/11/2022'
          }
        ]
      }
    },
    createdAt: '2022-10-31T19:42:19.989Z',
    updatedAt: '2022-11-03T13:42:19.989-06:00'
  },
  {
    id: '4587e7b3-dea8-4367-9ba7-97976a83405f',
    name: 'Test',
    abbreviation: 'test',
    locality: 'city',
    parent: null,
    jurisdictionAttributes: {
      DEFAULT_LOCALE: 'en-US',
      LANGUAGE_DICTIONARY: {},
      PUBLIC_HOLIDAYS: [],
      SUPPORTED_LOCALES: ['en-US', 'en-AU'],
      TIMEZONE: 'America/Denver',
      BASE_CURRENCY: 'USD',
      COUNTRY_CODE: 'USA',
      SCHOOL_HOLIDAYS: [
        {
          start: '24/11/2022',
          end: '27/11/2022'
        }
      ],
      CUSTOM_DAYS: {
        ACADEMIC_BREAKS: [
          {
            name: 'Thanksgiving',
            start: '21/11/2022',
            end: '26/11/2022'
          }
        ]
      },
      DEFAULT_LOCATION: {
        latitude: 39.7727603158,
        longitude: -104.99909372
      }
    },
    createdAt: '2022-10-31T19:42:19.989Z',
    updatedAt: '2022-11-04T13:42:19.989-06:00'
  }
]

export const historyStub = new ApiStub(historyResponse)

const getHistory = async (
  { id }: { id: string },
  signal?: AbortSignal
): Promise<JurisdictionDetail[]> => {
  const history = await historyStub.get()
  return signal?.aborted ? [] : history
}

export const jurisdictionService = {
  getAll,
  getJurisdictionDetail,
  createJurisdiction,
  updateJurisdiction,
  deleteJurisdiction,
  publishJurisdiction,
  // History
  getHistory
}
export default jurisdictionService
