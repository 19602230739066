import React from 'react'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import customisableSelectStyle from 'assets/jss/spot-admin/views/controls/customisableSelectStyle'


class CustomisableSelect extends React.Component {
    constructor(props) {
        super(props)

        this.onChange = this.onChange.bind(this)
    }

    onChange(e) {
        const {handleChange} = this.props

        handleChange(e.target.value)
    }

    render() {
        const {classes, controlId, label, items, value, noPlaceholder, disabled} =
            this.props

        if (!items || items.length === 0) {
            return null
        }

        let selectedValue = value
        let options = items

        if (!noPlaceholder) {
            if (!options.find(item => item.value === value)) {
                options = [
                    {
                        label: 'Please make selection',
                        value: -1,
                        disabled: true
                    },
                    ...options
                ]
                // options.unshift({
                //     label: 'Please make selection',
                //     value: -1,
                //     disabled: true
                // })
                selectedValue = -1
            }
        }

        return (
            <FormControl
                fullWidth
                className={classes.selectFormControl}
                disabled={disabled}
            >
                <InputLabel htmlFor={controlId} className={classes.selectLabel}>
                    {label}
                </InputLabel>
                <Select
                    MenuProps={{
                        className: classes.selectMenu
                    }}
                    classes={{
                        select: classes.select
                    }}
                    value={selectedValue}
                    onChange={this.onChange}
                    inputProps={{
                        id: controlId
                    }}
                >
                    {options.map((item, key) => {
                        return (
                            <MenuItem
                                key={key}
                                disabled={item.disabled || false}
                                value={item.value}
                                classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                }}
                            >
                                {item.label}
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        )
    }
}


export default withStyles(customisableSelectStyle)(CustomisableSelect)
