export const templateConstants = {
  OUTLINES_REQUEST: 'TEMPLATE_OUTLINES_REQUEST',
  OUTLINES_SUCCESS: 'TEMPLATE_OUTLINES_SUCCESS',
  OUTLINES_FAILURE: 'TEMPLATE_OUTLINES_FAILURE',

  BAYGROUPS_REQUEST: 'TEMPLATE_BAYGROUPS_REQUEST',
  BAYGROUPS_SUCCESS: 'TEMPLATE_BAYGROUPS_SUCCESS',
  BAYGROUPS_FAILURE: 'TEMPLATE_BAYGROUPS_FAILURE',

  LOTREFERENCES_REQUEST: 'LOTREFERENCES_REQUEST_REQUEST',
  LOTREFERENCES_SUCCESS: 'LOTREFERENCES_REQUEST_SUCCESS',
  LOTREFERENCES_FAILURE: 'LOTREFERENCES_REQUEST_FAILURE',

  CLEAR_LOTREFERENCE: 'CLEAR_LOTREFERENCE',
  CLEAR_LOTREFERENCES: 'CLEAR_LOTREFERENCES',

  SIGNCONFIGS_REQUEST: 'SIGNCONFIGS_REQUEST_REQUEST',
  SIGNCONFIGS_SUCCESS: 'SIGNCONFIGS_REQUEST_SUCCESS',
  SIGNCONFIGS_FAILURE: 'SIGNCONFIGS_REQUEST_FAILURE',

  // template groups

  TEMPLATE_GROUPS_REQUEST: 'TEMPLATE_GROUPS_REQUEST',
  TEMPLATE_GROUPS_SUCCESS: 'TEMPLATE_GROUPS_SUCCESS',
  TEMPLATE_GROUPS_FAILURE: 'TEMPLATE_GROUPS_FAILURE',

  TEMPLATE_GROUP_REQUEST: 'TEMPLATE_GROUP_REQUEST',
  TEMPLATE_GROUP_SUCCESS: 'TEMPLATE_GROUP_SUCCESS',
  TEMPLATE_GROUP_FAILURE: 'TEMPLATE_GROUP_FAILURE',

  ADD_NEW_TEMPLATE_GROUP_REQUEST: 'ADD_NEW_TEMPLATE_GROUP_REQUEST',
  ADD_NEW_TEMPLATE_GROUP_SUCCESS: 'ADD_NEW_TEMPLATE_GROUP_SUCCESS',
  ADD_NEW_TEMPLATE_GROUP_FAILURE: 'ADD_NEW_TEMPLATE_GROUP_FAILURE',

  UPDATE_TEMPLATE_GROUP_REQUEST: 'UPDATE_TEMPLATE_GROUP_REQUEST',
  UPDATE_TEMPLATE_GROUP_SUCCESS: 'UPDATE_TEMPLATE_GROUP_SUCCESS',
  UPDATE_TEMPLATE_GROUP_FAILURE: 'UPDATE_TEMPLATE_GROUP_FAILURE',

  DELETE_TEMPLATE_GROUP_REQUEST: 'DELETE_TEMPLATE_GROUP_REQUEST',
  DELETE_TEMPLATE_GROUP_SUCCESS: 'DELETE_TEMPLATE_GROUP_SUCCESS',
  DELETE_TEMPLATE_GROUP_FAILURE: 'DELETE_TEMPLATE_GROUP_FAILURE',

  REGISTER_TEMPLATE_GROUP: 'REGISTER_TEMPLATE_GROUP',

  // template containers

  TEMPLATE_CONTAINERS_REQUEST: 'TEMPLATE_CONTAINERS_REQUEST',
  TEMPLATE_CONTAINERS_SUCCESS: 'TEMPLATE_CONTAINERS_SUCCESS',
  TEMPLATE_CONTAINERS_FAILURE: 'TEMPLATE_CONTAINERS_FAILURE',

  TEMPLATE_CONTAINER_REQUEST: 'TEMPLATE_CONTAINER_REQUEST',
  TEMPLATE_CONTAINER_SUCCESS: 'TEMPLATE_CONTAINER_SUCCESS',
  TEMPLATE_CONTAINER_FAILURE: 'TEMPLATE_CONTAINER_FAILURE',

  ADD_NEW_TEMPLATE_CONTAINER_REQUEST: 'ADD_NEW_TEMPLATE_CONTAINER_REQUEST',
  ADD_NEW_TEMPLATE_CONTAINER_SUCCESS: 'ADD_NEW_TEMPLATE_CONTAINER_SUCCESS',
  ADD_NEW_TEMPLATE_CONTAINER_FAILURE: 'ADD_NEW_TEMPLATE_CONTAINER_FAILURE',

  UPDATE_TEMPLATE_CONTAINER_REQUEST: 'UPDATE_TEMPLATE_CONTAINER_REQUEST',
  UPDATE_TEMPLATE_CONTAINER_SUCCESS: 'UPDATE_TEMPLATE_CONTAINER_SUCCESS',
  UPDATE_TEMPLATE_CONTAINER_FAILURE: 'UPDATE_TEMPLATE_CONTAINER_FAILURE',

  DELETE_TEMPLATE_CONTAINER_REQUEST: 'DELETE_TEMPLATE_CONTAINER_REQUEST',
  DELETE_TEMPLATE_CONTAINER_SUCCESS: 'DELETE_TEMPLATE_CONTAINER_SUCCESS',
  DELETE_TEMPLATE_CONTAINER_FAILURE: 'DELETE_TEMPLATE_CONTAINER_FAILURE',

  REGISTER_TEMPLATE_CONTAINER: 'REGISTER_TEMPLATE_CONTAINER',

  // template

  TEMPLATES_REQUEST: 'TEMPLATES_REQUEST',
  TEMPLATES_SUCCESS: 'TEMPLATES_SUCCESS',
  TEMPLATES_FAILURE: 'TEMPLATES_FAILURE',

  TEMPLATE_REQUEST: 'TEMPLATE_REQUEST',
  TEMPLATE_SUCCESS: 'TEMPLATE_SUCCESS',
  TEMPLATE_FAILURE: 'TEMPLATE_FAILURE',

  ADD_NEW_TEMPLATE_REQUEST: 'ADD_NEW_TEMPLATE_REQUEST',
  ADD_NEW_TEMPLATE_SUCCESS: 'ADD_NEW_TEMPLATE_SUCCESS',
  ADD_NEW_TEMPLATE_FAILURE: 'ADD_NEW_TEMPLATE_FAILURE',

  UPDATE_TEMPLATE_REQUEST: 'UPDATE_TEMPLATE_REQUEST',
  UPDATE_TEMPLATE_SUCCESS: 'UPDATE_TEMPLATE_SUCCESS',
  UPDATE_TEMPLATE_FAILURE: 'UPDATE_TEMPLATE_FAILURE',

  DELETE_TEMPLATE_REQUEST: 'DELETE_TEMPLATE_REQUEST',
  DELETE_TEMPLATE_SUCCESS: 'DELETE_TEMPLATE_SUCCESS',
  DELETE_TEMPLATE_FAILURE: 'DELETE_TEMPLATE_FAILURE',

  COPYFROM_TEMPLATE_REQUEST: 'COPYFROM_TEMPLATE_REQUEST',
  COPYFROM_TEMPLATE_SUCCESS: 'COPYFROM_TEMPLATE_SUCCESS',
  COPYFROM_TEMPLATE_FAILURE: 'COPYFROM_TEMPLATE_FAILURE',

  REGISTER_TEMPLATE: 'REGISTER_TEMPLATE',

  // template instances

  TEMPLATE_INSTANCES_REQUEST: 'TEMPLATE_INSTANCES_REQUEST',
  TEMPLATE_INSTANCES_SUCCESS: 'TEMPLATE_INSTANCES_SUCCESS',
  TEMPLATE_INSTANCES_FAILURE: 'TEMPLATE_INSTANCES_FAILURE',

  TEMPLATE_INSTANCE_REQUEST: 'TEMPLATE_INSTANCES_REQUEST',
  TEMPLATE_INSTANCE_SUCCESS: 'TEMPLATE_INSTANCES_SUCCESS',
  TEMPLATE_INSTANCE_FAILURE: 'TEMPLATE_INSTANCES_FAILURE',

  ADD_NEW_TEMPLATE_INSTANCE_REQUEST: 'ADD_NEW_TEMPLATE_INSTANCE_REQUEST',
  ADD_NEW_TEMPLATE_INSTANCE_SUCCESS: 'ADD_NEW_TEMPLATE_INSTANCE_SUCCESS',
  ADD_NEW_TEMPLATE_INSTANCE_FAILURE: 'ADD_NEW_TEMPLATE_INSTANCE_FAILURE',

  UPDATE_TEMPLATE_INSTANCE_REQUEST: 'UPDATE_TEMPLATE_INSTANCE_REQUEST',
  UPDATE_TEMPLATE_INSTANCE_SUCCESS: 'UPDATE_TEMPLATE_INSTANCE_SUCCESS',
  UPDATE_TEMPLATE_INSTANCE_FAILURE: 'UPDATE_TEMPLATE_INSTANCE_FAILURE',

  DELETE_TEMPLATE_INSTANCE_REQUEST: 'DELETE_TEMPLATE_INSTANCE_REQUEST',
  DELETE_TEMPLATE_INSTANCE_SUCCESS: 'DELETE_TEMPLATE_INSTANCE_SUCCESS',
  DELETE_TEMPLATE_INSTANCE_FAILURE: 'DELETE_TEMPLATE_INSTANCE_FAILURE',

  REGISTER_TEMPLATE_INSTANCE: 'REGISTER_TEMPLATE_INSTANCE',

  // template version publish

  PUBLISH_TEMPLATE_VERSION_REQUEST: 'PUBLISH_TEMPLATE_VERSION_REQUEST',
  PUBLISH_TEMPLATE_VERSION_SUCCESS: 'PUBLISH_TEMPLATE_VERSION_SUCCESS',
  PUBLISH_TEMPLATE_VERSION_FAILURE: 'PUBLISH_TEMPLATE_VERSION_FAILURE',

  UNPUBLISH_TEMPLATE_REQUEST: 'UNPUBLISH_TEMPLATE_REQUEST',
  UNPUBLISH_TEMPLATE_SUCCESS: 'UNPUBLISH_TEMPLATE_SUCCESS',
  UNPUBLISH_TEMPLATE_FAILURE: 'UNPUBLISH_TEMPLATE_FAILURE',

  PUBLISH_TEMPLATE_INSTANCE_REQUEST: 'PUBLISH_TEMPLATE_INSTANCE_REQUEST',
  PUBLISH_TEMPLATE_INSTANCE_SUCCESS: 'PUBLISH_TEMPLATE_INSTANCE_SUCCESS',
  PUBLISH_TEMPLATE_INSTANCE_FAILURE: 'PUBLISH_TEMPLATE_INSTANCE_FAILURE',

  UNPUBLISH_TEMPLATE_INSTANCE_REQUEST: 'UNPUBLISH_TEMPLATE_INSTANCE_REQUEST',
  UNPUBLISH_TEMPLATE_INSTANCE_SUCCESS: 'UNPUBLISH_TEMPLATE_INSTANCE_SUCCESS',
  UNPUBLISH_TEMPLATE_INSTANCE_FAILURE: 'UNPUBLISH_TEMPLATE_INSTANCE_FAILURE',

  // template instance conflict detection

  CONFLICT_DETECTION_TEMPLATE_INSTANCE_REQUEST:
    'CONFLICT_DETECTION_TEMPLATE_REQUEST',
  CONFLICT_DETECTION_TEMPLATE_INSTANCE_SUCCESS:
    'CONFLICT_DETECTION_TEMPLATE_SUCCESS',
  CONFLICT_DETECTION_TEMPLATE_INSTANCE_FAILURE:
    'CONFLICT_DETECTION_TEMPLATE_FAILURE',

  CONFLICT_DETECTION_TEMPLATE_REQUEST: 'CONFLICT_DETECTION_TEMPLATE_REQUEST',
  CONFLICT_DETECTION_TEMPLATE_SUCCESS: 'CONFLICT_DETECTION_TEMPLATE_SUCCESS',
  CONFLICT_DETECTION_TEMPLATE_FAILURE: 'CONFLICT_DETECTION_TEMPLATE_FAILURE',

  CONFLICT_DETECTION_RESET: 'CONFLICT_DETECTION_RESET',

  // generic ignore request

  TEMPLATE_IGNORE_REQUEST: 'TEMPLATE_IGNORE_REQUEST'
}
