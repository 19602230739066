import _setPath from 'utils/setPath'
import getPath from 'utils/getPath'

/**
 * @func setState - Replace key/val pairs in `state` with key/val pairs in `payload`
 * @param {object} state
 * @param {object} payload
 */
export const setState = (state, { payload }) => {
  for (let k in payload) {
    state[k] = payload[k]
  }
}

/**
 * @func setProp - Set a specific key/value pair
 * @param state
 * @param payload
 * dispatch(setProp({ key: 'myKey', value: 123 }))
 */
export const setProp = (state, { payload }) => {
  const { key, value } = payload
  state[key] = value
}

/**
 * @func mergeProp - Merge the value of `key` with the given value
 * @param state
 * @param payload
 *
 * dispatch(mergeProp({ key: 'myObjectKey', value: { a: 0, b: 1, c: 2 } }))
 */
export const mergeProp = (state, { payload }) => {
  const { key, value } = payload
  state[key] = { ...state[key], ...value }
}

/**
 * @func setPath - Set a nested key/val pair
 * @param state
 * @param payload
 * dispatch(setPath({ path: ['myObjectKey', 'a'], value: 2 }))
 */
export const setPath = (state, { payload }) => {
  const { path, value } = payload
  _setPath(path, value, state, true)
}

/**
 * @func mergePath - Same as setPath, but for Objects
 * @param state
 * @param payload
 */
export const mergePath = (state, { payload }) => {
  const { path, value } = payload
  const currentValue = getPath(path, state) || {}
  _setPath(path, { ...currentValue, ...value }, state, true)
}

export const baseReducer = {
  setState,
  setProp,
  mergeProp,
  setPath,
  mergePath
}
export default baseReducer
