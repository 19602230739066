import { IdEntity } from 'types/generics'

export const wait = (ms: number = 500) =>
  new Promise(resolve => setTimeout(resolve, ms))

export class ApiStub<T> {
  source: (T & IdEntity)[]

  constructor(source: (T & IdEntity)[]) {
    this.source = source
    this._id = 10
  }

  _id: number

  get id() {
    this._id = this._id + 1
    return this._id
  }

  async get(fields: object | null = null) {
    await wait()
    if (fields) {
      const pairs = Object.entries(fields)
      return Promise.resolve(
        this.source.filter(item =>
          pairs.every(([key, value]) => value === item[key])
        )
      )
    }
    return Promise.resolve(this.source)
  }

  async post(data) {
    const record = {
      ...data,
      id: this.id
    }
    this.source.push(record)
    await wait()
    return Promise.resolve(record)
  }

  async patch(data) {
    const index = this.source.findIndex(item => data.id === item.id)
    this.source.splice(index, 1)
    this.source.push(data)
    await wait()
    return Promise.resolve(data)
  }

  async delete(id) {
    const index = this.source.findIndex(item => id === item.id)
    this.source.splice(index, 1)
    await wait()
    return Promise.resolve(this.source)
  }
}
