import React, { useState } from 'react'
import {
    createTheme,
    makeStyles,
    ThemeProvider as MuiThemeProvider
} from '@material-ui/core/styles'
import Select from 'components/Select/Select'
import { getJurisdictionId } from 'utils/useJurisdictionId'
import { jurisdictionOptions } from 'constants/jurisdictions'


const theme = createTheme({
    palette: {
        type: 'dark'
    }
})

const useStyles = makeStyles({
    root: {
        width: '100%',
        maxWidth: '100%',
        padding: '10px 25px'
    },
    picker: {
        minWidth: '2rem'
    }
})


export const JurisdictionPicker = () => {
    const classes = useStyles()
    const [value, setValue] = useState(getJurisdictionId())

    const onUpdate = event => {
        const jurisdictionId = event.target.value
        setValue(event.target.value)
        localStorage.setItem(
            'user.jurisdiction',
            JSON.stringify({id: jurisdictionId})
        )
        window.location.reload()
    }

    return (
        <MuiThemeProvider theme={theme}>
            <div className={classes.root}>
                <Select
                    options={jurisdictionOptions}
                    value={value}
                    onChange={onUpdate}
                    label={'Jurisdiction'}
                    className={classes.picker}
                    fullWidth
                />
            </div>
        </MuiThemeProvider>
    )
}

export default JurisdictionPicker
