import * as Comlink from 'comlink'
import { v4 as uuidv4 } from 'uuid'
import { DateTime, IANAZone } from 'luxon'
import Papa from 'papaparse'
import { saveAs } from 'file-saver'
import { actions, name } from './resourceSlice'
import { alertActions } from 'features/alert/alertSlice'
import {
  AvailableResourceStatistics,
  AvailableStatisticsResponse,
  Category,
  CsvSerializer,
  DateRange,
  EvReport,
  MonthStart,
  OccupancyCategoryDay,
  OccupancyPredictionReport,
  OccupancyReport,
  OccupancySummary,
  OverflowReport,
  ParkingEventTypeData,
  ParkingStatInterval,
  ParkingStatsTwoIntervalResponse,
  ParkingStatsTwoRequest,
  ParkingStatsTwoResourceRequest,
  ParkingStatsTwoSegmentResponse,
  ReportType,
  ReportWorker,
  Resource,
  ResourceCategory,
  ResourceSlice,
  ResourceStatistics,
  ResourceTimeGroupingCategory,
  ResourceTimeSeriesCategory,
  SavableParameters,
  StatisticType,
  StatisticWorker,
  SummaryType,
  SummaryWorker,
  TimeGroupingStatistic,
  TimeGroupingStatisticMap,
  TimeSeriesStatistic,
  WorkerParams
} from './types'
import { Cache } from 'utils/cache'
import { Epoch } from 'types/generics'
import { ErrorResponseCode } from 'types/request'
import {
  EventStatisticType,
  ParkingEventType
} from 'features/resource/types/parkingStat'
import {
  eventTypeStatisticTypeMap,
  ReportTypeStatisticMap
} from 'features/resource/mappings'
import { getJurisdictionId } from 'utils/useJurisdictionId'
import {
  getMonthStartEnd,
  getTzOffsetMs,
  getZoneOffsetMs,
  weekStartsForDateRanges
} from './reportUtils'
import { getZoneName } from 'utils/useTimeZone'
import { heatMapActions } from 'features/heatMap/heatMapSlice'
import { MS_PER_WEEK } from 'constants/constants'
import { loadConfig } from 'config/configs'
import logError from 'utils/logError'
import { resourceService } from 'services'
import Serializer from './csvSerializer?worker'
import { sortAsc } from 'utils/sortBy'
import StatisticWorker_ from '../resource/statisticWorker?worker'
import Summary from './summaryWorker?worker'
import toObject from 'utils/toObject'
import { userActions } from 'features/user/userSlice'
import WorkerObject from './reportWorker?worker'
import { jurisdictionZone } from 'constants/jurisdictions'

const reportWorker: ReportWorker = Comlink.wrap(new WorkerObject())
const csvSerializer: CsvSerializer = Comlink.wrap(new Serializer())
const summaryWorker: SummaryWorker = Comlink.wrap(new Summary())
const statisticWorker: StatisticWorker = Comlink.wrap(new StatisticWorker_())

export const initResourceReports =
  (abortController?: AbortController) => async (dispatch, getState) => {
    dispatch(
      actions.setState({
        init: true,
        loading: true,
        error: null
      })
    )
    const hideResourceIds = await getHiddenResourceIds()
    await dispatch(getAllResources(false, abortController))

    try {
      await dispatch(heatMapActions.loadOutlines())
    } catch (error) {
      // Ignore 401 errors, throw anything else
      if (ErrorResponseCode.UNAUTHORIZED !== error?.status) {
        throw error
      }
      dispatch(handleError(error))
    }

    const parameters: SavableParameters | null = await loadParameters()
    // Always get the available resources
    if (!parameters) {
      // Return early if we couldn't find any settings
      dispatch(
        actions.setState({
          init: true,
          loading: false,
          hideResourceIds
        })
      )
      return
    }
    const {
      cumulative,
      dateRangeSelections,
      daysOfTheWeek,
      groupBy,
      interval,
      selectedCategories,
      selectedClassifications,
      selectedReportType,
      selectedResourceIds,
      selectedStatisticTypes,
      smoothValue,
      summaryMonth,
      summaryOffset,
      summarySegmentHours
    } = parameters
    await dispatch(
      onSelectedResourceIdsChange(selectedResourceIds, abortController)
    )
    dispatch(actions.setDateRangeSelections(dateRangeSelections))
    await dispatch(onDateRangeChange(abortController))
    dispatch(
      actions.setState({
        cumulative,
        dateRangeSelections,
        daysOfTheWeek,
        groupBy,
        interval,
        loading: false,
        selectedCategories: selectedCategories || [],
        selectedClassifications: selectedClassifications || [],
        selectedReportType,
        selectedResourceIds,
        selectedStatisticTypes: selectedStatisticTypes || [
          EventStatisticType.avg,
          EventStatisticType.max,
          EventStatisticType.stdDev,
          EventStatisticType.sum
        ],
        smoothValue: smoothValue || 0,
        summaryMonth,
        summaryOffset,
        summarySegmentHours
      })
    )
  }

const getHiddenResourceIds = async (): Promise<string[]> => {
  try {
    const jurisdictionId = getJurisdictionId()
    const config = await loadConfig(jurisdictionId)
    return config?.hideResourceIds || []
  } catch (error) {
    return []
  }
}

export const getAllResources =
  (setLoading = true, abortController?: AbortController) =>
  async dispatch => {
    if (setLoading) {
      dispatch(actions.setLoading(true))
    }
    dispatch(
      actions.setState({
        init: true,
        error: null
      })
    )
    try {
      const { resources } = await resourceService.getAll(
        getJurisdictionId(),
        abortController?.signal
      )
      const newState = {
        resources: toObject<Resource>(
          resources,
          ({ resourceId }) => resourceId
        ),
        init: true
      }
      dispatch(actions.setState(newState))
      if (setLoading) {
        dispatch(actions.setLoading(false))
      }
      return newState
    } catch (err) {
      dispatch(handleResponseError(err))
    }
  }

/** @func onSelectedResourceIdsChange - Load available statistics for the given resourceIds */
export const onSelectedResourceIdsChange =
  (selectedResourceIds: string[], abortController?: AbortController) =>
  async dispatch => {
    dispatch(actions.setSelectedResourceIds(selectedResourceIds))
    dispatch(actions.setStatisticsLoading(selectedResourceIds.length > 0))
    if (selectedResourceIds.length) {
      // Only hit the API if we actually need to
      try {
        const { availableStatistics }: AvailableStatisticsResponse =
          await resourceService.getAvailableStatistics(
            selectedResourceIds,
            getJurisdictionId(),
            abortController?.signal
          )
        dispatch(actions.setAvailableStatistics(availableStatistics))
        // Extract weekStarts & monthStarts
        let weekStarts: Epoch[] = []
        let monthStarts: Epoch[] = []
        for (let resourceId in availableStatistics) {
          const statisticsForResource: ResourceStatistics | null =
            availableStatistics[resourceId]
          if (
            statisticsForResource !== null &&
            selectedResourceIds.includes(resourceId)
          ) {
            Object.values(statisticsForResource).forEach(statistic => {
              const weekStartSource =
                statistic?.TIME_SERIES?.WEEK_START ||
                statistic?.TIME_GROUPING?.WEEK_START ||
                {}
              weekStarts = weekStarts.concat(
                Object.keys(weekStartSource).map(Number)
              )
              const monthStartsSource =
                statistic?.TIME_GROUPING?.MONTH_START || {}
              monthStarts = monthStarts.concat(
                Object.keys(monthStartsSource).map(Number)
              )
            })
          }
        }
        dispatch(actions.setStatisticsLoading(false))
        dispatch(
          actions.setWeekStarts([...new Set(weekStarts.filter(Boolean))])
        )
        dispatch(
          actions.setMonthStarts([...new Set(monthStarts.filter(Boolean))])
        )
      } catch (err) {
        return dispatch(handleResponseError(err))
      }
    } else {
      // Clear the respective parameters if a resource isn't selected
      dispatch(actions.setAvailableStatistics({}))
      dispatch(actions.setMonthStarts([]))
      dispatch(actions.setMonthStarts([]))
    }
  }

export const onDateRangeChange =
  (abortController?: AbortController) => async dispatch => {
    try {
      return dispatch(onReportTypeChange(abortController))
    } catch (err) {
      return dispatch(handleError(err))
    }
  }

export const onReportTypeChange =
  (abortController?: AbortController) => async (dispatch, getState) => {
    const {
      dateRangeSelections,
      availableStatistics,
      monthStarts,
      selectedReportType,
      selectedResourceIds,
      summaryMonth,
      weekStarts,
      ...state
    } = getState()[name] as ResourceSlice
    if (!(dateRangeSelections.length && weekStarts.length)) {
      return
    }
    dispatch(actions.setCategoriesLoading(true))
    const zoneName = jurisdictionZone[getJurisdictionId()]
    const tzOffsetMs = getTzOffsetMs(zoneName)
    const weekStartParameters = weekStartsForDateRanges(
      weekStarts,
      dateRangeSelections,
      tzOffsetMs
    )
    const { monthStart, monthEnd } = getMonthStartEnd(summaryMonth, zoneName)
    const availableMonthStarts = monthStartsForDateRangeSelections(
      monthStart,
      monthEnd,
      monthStarts
    )
    const resourceCategories = await loadCategoriesForResourcesAndStatistics(
      selectedReportType,
      weekStartParameters,
      availableMonthStarts,
      selectedResourceIds,
      availableStatistics,
      abortController
    )
    dispatch(actions.setResourceCategories(resourceCategories))
    dispatch(actions.setCategoriesLoading(false))
  }

export const loadCategoriesForResourcesAndStatistics = async (
  selectedReportType: ReportType,
  weekStarts: Epoch[],
  monthStarts: Epoch[],
  selectedResourceIds: string[],
  availableStatistics: AvailableResourceStatistics,
  abortController?: AbortController
): Promise<{ [resourceId: string]: Category[] }> => {
  let resourceCategories: { [resourceId: string]: Category[] } = {}
  let parameters = {}
  const statisticTypes: StatisticType[] =
    ReportTypeStatisticMap[selectedReportType]
  for (let resourceId of selectedResourceIds) {
    const resourceStatisticsMap: ResourceStatistics =
      availableStatistics[resourceId]
    if (resourceStatisticsMap) {
      let resourceParameters = {}
      Object.entries(resourceStatisticsMap).forEach(
        ([statisticType, resourceStatistics]: [
          StatisticType,
          ResourceStatistics
        ]) => {
          if (!statisticTypes.includes(statisticType)) {
            return
          }
          // Handle TIME_SERIES.WEEK_START structures
          if ('TIME_GROUPING' in resourceStatistics) {
            resourceParameters[statisticType] = {
              TIME_GROUPING: {
                MONTH_START: monthStarts
              }
            }
          } else if ('TIME_SERIES' in resourceStatistics) {
            resourceParameters[statisticType] = {
              TIME_SERIES: {
                WEEK_START: weekStarts
              }
            }
          }
        }
      )
      parameters[resourceId] = resourceParameters
    }
  }
  const { categories } = await resourceService.getReportCategories(
    parameters,
    getJurisdictionId(),
    abortController?.signal
  )
  for (let resourceId of selectedResourceIds) {
    const requestStatisticTypes = Object.keys(
      availableStatistics[resourceId] || {}
    ).filter((statisticType: StatisticType) =>
      statisticTypes.includes(statisticType)
    )
    const resourceStatisticsCategories: ResourceCategory | null =
      categories[resourceId]
    if (resourceStatisticsCategories) {
      let allCategoriesProfilesAndLevels: Category[] = []
      requestStatisticTypes.forEach(statisticType => {
        const resourceTimeSeriesCategory:
          | ResourceTimeSeriesCategory
          | ResourceTimeGroupingCategory
          | null = resourceStatisticsCategories[statisticType]
        if (resourceTimeSeriesCategory) {
          if ('TIME_SERIES' in resourceTimeSeriesCategory) {
            // Grab all categories for all statistic types & week starts, push them into a list
            resourceTimeSeriesCategory.TIME_SERIES?.WEEK_START?.forEach(
              ({ base, categories }) => {
                allCategoriesProfilesAndLevels =
                  allCategoriesProfilesAndLevels.concat(categories)
              }
            )
          } else if ('TIME_GROUPING' in resourceTimeSeriesCategory) {
            resourceTimeSeriesCategory.TIME_GROUPING?.MONTH_START?.forEach(
              ({ base, categories }) => {
                allCategoriesProfilesAndLevels =
                  allCategoriesProfilesAndLevels.concat(categories)
              }
            )
          }
        }
      })
      // Cast array -> set -> array to remove duplicates
      resourceCategories[resourceId] = [
        ...new Set(allCategoriesProfilesAndLevels)
      ]
    }
  }
  return resourceCategories
}

export const generateReports =
  (setLoading = true, abortController?: AbortController) =>
  async dispatch => {
    await dispatch(loadReports(setLoading, abortController))
    return true
  }

export const loadReports =
  (setLoading = true, abortController?: AbortController) =>
  async (dispatch, getState) => {
    const requestId = uuidv4()

    dispatch(actions.setState({ requestId }))
    if (setLoading) {
      dispatch(
        actions.setState({
          reportsLoading: true,
          showReport: false,
          summaryLoading: true
        })
      )
    }
    try {
      const state = getState()[name]
      const {
        dateRangeSelections,
        selectedCategories,
        selectedResourceIds,
        selectedReportType,
        weekStarts
      } = state
      // Make sure the report request hasn't re-fired
      if (getState()[name].requestId !== requestId) {
        return Promise.resolve(false)
      }
      // Get the report data from the server & pass it all to our worker thread
      const { reports }: OccupancyReport | OverflowReport =
        await requestOccupancyReport(state, undefined, abortController)
      const jurisdictionId = getJurisdictionId()
      const zoneName = jurisdictionZone[jurisdictionId]
      let summaryData = []
      let reportState = []

      if (ReportType.occupancy === selectedReportType) {
        // Utilization/Occupancy
        reportState = await reportWorker.generateOccupancyReport(
          state,
          { reports } as OccupancyReport,
          zoneName
        )
      } else if (ReportType.predictive === selectedReportType) {
        // Predictive
        const occupancyPredictionReport: OccupancyPredictionReport =
          await loadOccupancyPredictionReport(
            selectedResourceIds,
            weekStarts,
            dateRangeSelections,
            selectedCategories,
            abortController
          )
        reportState = await reportWorker.generatePredictionReport(
          state,
          { reports } as OccupancyReport,
          occupancyPredictionReport,
          zoneName
        )
      } else if (ReportType.profileComparison === selectedReportType) {
        // Histogram
        reportState = await reportWorker.generateOverflowReport(
          state,
          { reports } as OverflowReport,
          zoneName
        )
      } else if (ReportType.ev === selectedReportType) {
        reportState = await reportWorker.generateEvReport(
          state,
          { reports } as EvReport,
          zoneName
        )
      } else if (ReportType.summary === selectedReportType) {
        // Summary
        reportState = []
        const { isMultiTenant, summaryStats } = await requestSummaryStats(
          state,
          jurisdictionId,
          zoneName
        )
        summaryData = !isMultiTenant
          ? await summaryWorker.generateSummaryReport(
              state,
              summaryStats,
              zoneName
            )
          : await summaryWorker.generateMultiTenantSummaryReport(
              state,
              summaryStats,
              zoneName
            )
        dispatch(actions.setSummaryIsMultiTenant(isMultiTenant))
      } else if (ReportType.sessionStats === selectedReportType) {
        // Session Stats
        const parkingStats = (await requestStats(
          state,
          jurisdictionId,
          zoneName,
          abortController
        )) as ParkingStatsTwoIntervalResponse
        reportState = [
          await statisticWorker.generateSessionChart(
            state,
            zoneName,
            parkingStats,
            false
          )
        ]
      } else if (ReportType.occupancyStats === selectedReportType) {
        // Occupancy Stats
        const occupancyStats: ParkingStatsTwoIntervalResponse =
          await requestOccupancyStats(
            state,
            jurisdictionId,
            zoneName,
            abortController
          )
        const occupancyReport = await requestOccupancyReport({
          ...state,
          selectedReportType: ReportType.occupancy
        })
        reportState = await reportWorker.generateOccupancyStatsReport(
          state,
          occupancyReport as OccupancyReport,
          occupancyStats,
          zoneName
        )
      } else if (ReportType.heatMap === selectedReportType) {
        await dispatch(
          heatMapActions.generateHeatMap({ reports } as OccupancyReport, {
            selectedDateRange: dateRangeSelections[0] || undefined,
            selectedResourceIds
          })
        )
      } else {
        const parkingStats = (await requestStats(
          state,
          jurisdictionId,
          zoneName,
          abortController
        )) as ParkingStatsTwoIntervalResponse
        reportState = [
          await statisticWorker.generateStatsChart(
            state,
            zoneName,
            parkingStats,
            false
          )
        ]
      }
      // Make sure the report request hasn't re-fired
      if (getState()[name].requestId !== requestId) {
        return Promise.resolve(false)
      }

      dispatch(
        actions.setState({
          reportState,
          reportType: selectedReportType,
          summaryData
        })
      )
    } catch (error) {
      dispatch(actions.setState({ requestId: null }))
      dispatch(handleError(error))
      return Promise.reject(error)
    } finally {
      if (setLoading) {
        dispatch(
          actions.setState({
            reportsLoading: false,
            showReport: true,
            summaryLoading: false
          })
        )
      }
    }
  }

export const requestOccupancyReport = async (
  slice: WorkerParams,
  lastRefresh?: number,
  abortController?: AbortController
): Promise<OccupancyReport | OverflowReport> => {
  const {
    dateRangeSelections,
    selectedResourceIds,
    availableStatistics,
    selectedCategories,
    selectedReportType,
    weekStarts
  } = slice
  const jurisdictionId = getJurisdictionId()
  // Build out parameters
  const categories = selectedCategories.length ? selectedCategories : ['*']
  const zoneName = getZoneName()
  const tzOffsetMs = getTzOffsetMs(zoneName)
  const weekStartMap = weekStartsForDateRanges(
    weekStarts,
    dateRangeSelections,
    tzOffsetMs
  ).reduce((parameters, weekStart) => {
    // @ts-ignore
    parameters[weekStart] = {
      categories,
      start: 0,
      end: 10080
    }
    return parameters
  }, {})

  if (lastRefresh) {
    const requestWeekStart = DateTime.fromMillis(lastRefresh, {
      zone: zoneName
    })
      .minus({ minutes: 70 })
      .toMillis()
    weekStartMap[requestWeekStart] = {
      categories,
      inRealTime: true,
      start: 0
    }
  }
  const statisticTypes = ReportTypeStatisticMap[selectedReportType] || [
    StatisticType.occupancyRate
  ]
  const requestParameters = selectedResourceIds.reduce(
    (parameters, resourceId) => {
      let resourceParameters = {}
      statisticTypes
        .filter(statisticType =>
          Object.keys(availableStatistics[resourceId] || {}).includes(
            statisticType
          )
        )
        .forEach(statisticType => {
          resourceParameters[statisticType] = {
            TIME_SERIES: {
              WEEK_START: weekStartMap
            }
          }
        })
      parameters[resourceId] = resourceParameters
      return parameters
    },
    {}
  )
  const { reports }: OccupancyReport | OverflowReport =
    await resourceService.getStatisticReports(
      requestParameters,
      jurisdictionId,
      true,
      abortController?.signal
    )
  return { reports } as OccupancyReport | OverflowReport
}

export const requestRealTimeOccupancyReport = async (
  slice: WorkerParams,
  lastRefresh: number,
  abortController?: AbortController
): Promise<OccupancyReport> => {
  const {
    availableStatistics,
    selectedCategories,
    selectedReportType,
    selectedResourceIds
  } = slice
  const jurisdictionId = getJurisdictionId()
  // Build out parameters
  const categories = selectedCategories.length ? selectedCategories : ['*']
  const zoneName = getZoneName()
  const requestWeekStart = DateTime.fromMillis(lastRefresh, { zone: zoneName })
    .minus({ minutes: 70 })
    .toMillis()

  const statisticTypes = ReportTypeStatisticMap[selectedReportType] || [
    StatisticType.occupancyRate
  ]
  const requestParameters = selectedResourceIds.reduce(
    (parameters, resourceId) => {
      let resourceParameters = {}
      statisticTypes
        .filter(statisticType =>
          Object.keys(availableStatistics[resourceId] || {}).includes(
            statisticType
          )
        )
        .forEach(statisticType => {
          resourceParameters[statisticType] = {
            TIME_SERIES: {
              WEEK_START: {
                [requestWeekStart]: {
                  categories,
                  inRealTime: true,
                  start: 0
                }
              }
            }
          }
        })
      parameters[resourceId] = resourceParameters
      return parameters
    },
    {}
  )
  const { reports }: OccupancyReport | OverflowReport =
    await resourceService.getStatisticReports(
      requestParameters,
      jurisdictionId,
      false,
      abortController?.signal
    )
  return { reports } as OccupancyReport
}

export const loadOccupancyPredictionReport = async (
  selectedResourceIds: string[],
  weekStarts: number[],
  dateRangeSelections: DateRange[],
  selectedCategories: Category[],
  abortController?: AbortController
): Promise<OccupancyPredictionReport> => {
  const zoneName = getZoneName()
  const tzOffsetMs = getZoneOffsetMs(IANAZone.create(zoneName))
  const requestedWeekStarts = weekStartsForDateRanges(
    weekStarts,
    dateRangeSelections,
    tzOffsetMs
  )
  const maxRequest = Math.max(...requestedWeekStarts) + MS_PER_WEEK
  const extraParams = !selectedCategories.length
    ? [{}]
    : selectedCategories
        .filter(
          category =>
            category.includes('permitType') || category.includes('profile')
        )
        .map(category => ({
          level: '*',
          multiTenant: true,
          profile: category
        }))

  const resources = requestedWeekStarts
    .concat(maxRequest)
    .flatMap(weekStart => {
      const dt = DateTime.fromMillis(weekStart + tzOffsetMs, { zone: zoneName })
      const utcWeekStart = dt.toUTC().toJSON()
      const resourceIdParams = selectedResourceIds.map(resourceId => ({
        resourceId,
        latestForUtcDate: utcWeekStart
      }))
      return resourceIdParams.flatMap(resourceParam => {
        return extraParams.map(params => ({
          ...params,
          ...resourceParam
        }))
      })
    })
  return (await resourceService.getOccupancyPredictions(
    resources,
    abortController?.signal
  )) as OccupancyPredictionReport
}

export async function requestStats(
  slice: WorkerParams,
  jurisdictionId: string,
  zoneName: string,
  abortController?: AbortController
): Promise<ParkingStatsTwoIntervalResponse | ParkingStatsTwoSegmentResponse> {
  try {
    const {
      availableStatistics,
      dateRangeSelections,
      interval,
      selectedCategories,
      selectedReportType,
      selectedResourceIds,
      selectedStatisticTypes,
      weekStarts
    } = slice
    const statisticTypes = ReportTypeStatisticMap[selectedReportType]
    if (!statisticTypes) {
      return {
        results: []
      }
    }

    const categories =
      selectedCategories.filter(c => c !== 'overall').length > 0
        ? selectedCategories
        : undefined
    const tzOffsetMs = getTzOffsetMs(zoneName)
    const weekStartParameters = weekStartsForDateRanges(
      weekStarts,
      dateRangeSelections,
      tzOffsetMs
    )
    const parameters: ParkingStatsTwoResourceRequest[] =
      selectedResourceIds.reduce((accum, resourceId: string) => {
        let statisticTypeParameters: ParkingEventTypeData = {}
        statisticTypes.forEach(statisticType => {
          const timeGroupingMap =
            availableStatistics[resourceId]?.[statisticType]
          if (timeGroupingMap?.TIME_GROUPING?.WEEK_START) {
            for (let weekStartKey in timeGroupingMap.TIME_GROUPING.WEEK_START) {
              if (weekStartParameters.includes(Number(weekStartKey))) {
                const stats = selectedStatisticTypes.length
                  ? selectedStatisticTypes
                  : eventTypeStatisticTypeMap[statisticType]
                const timeGroupingValue = {
                  ...timeGroupingMap.TIME_GROUPING.WEEK_START[weekStartKey],
                  categories,
                  // Only request the interval & statisticTypes that correspond with our interval selection & event type
                  intervals: [interval],
                  statisticTypes: stats
                }
                if (statisticTypeParameters[statisticType]) {
                  statisticTypeParameters[
                    statisticType
                  ].TIME_GROUPING.WEEK_START[weekStartKey] = timeGroupingValue
                } else {
                  statisticTypeParameters[statisticType] = {
                    TIME_GROUPING: {
                      WEEK_START: {
                        [weekStartKey]: timeGroupingValue
                      }
                    }
                  }
                }
              }
            }
          }
        })
        return accum.concat({
          resourceIds: selectedResourceIds,
          ...statisticTypeParameters
        })
      }, [])
    const body: ParkingStatsTwoRequest = JSON.parse(
      JSON.stringify({
        jurisdictionId,
        resources: parameters
      })
    )
    return await resourceService.getStatsTwo(body, abortController?.signal)
  } catch (err) {
    throw err
  }
}

export const requestSummaryStats = async (
  slice: ResourceSlice,
  jurisdictionId: string,
  zoneName: string,
  abortController?: AbortController
): Promise<{
  summaryStats: ParkingStatsTwoSegmentResponse
  isMultiTenant: boolean
}> => {
  try {
    const {
      selectedCategories,
      selectedResourceIds,
      summaryMonth,
      summaryOffset,
      summarySegmentHours,
      summaryUseOccupancy
    } = slice
    const useOccupancy = selectedCategories.length > 0
    // Fetch the available statistics so we can get the MONTH_START epochs that correspond w/ the selectedDateRange values
    const { availableStatistics } =
      await resourceService.getAvailableStatistics(
        selectedResourceIds,
        jurisdictionId,
        abortController?.signal
      )
    const resourceStatisticsMap = availableStatistics[
      selectedResourceIds[0]
    ] as TimeGroupingStatisticMap
    let statisticType: StatisticType = resourceStatisticsMap[
      StatisticType.tenantOccupancyStatsByTimeSegment
    ]
      ? StatisticType.tenantOccupancyStatsByTimeSegment
      : StatisticType.occupancyStatsByTimeSegment
    if (useOccupancy) {
      statisticType = StatisticType.tenantOccupancyCountsByTimeSegment
    }

    const { TIME_GROUPING } = resourceStatisticsMap[statisticType]
    const monthStarts = Object.keys(TIME_GROUPING?.MONTH_START || {}).map(
      Number
    )
    const { monthStart, monthEnd } = getMonthStartEnd(summaryMonth, zoneName)
    const monthStartsForDateRange = monthStartsForDateRangeSelections(
      monthStart,
      monthEnd,
      monthStarts
    )

    const multiTenantStatisticTypes = [
      StatisticType.tenantOccupancyStatsByTimeSegment,
      StatisticType.tenantOccupancyCountsByTimeSegment
    ]
    const isMultiTenant =
      selectedCategories.length &&
      multiTenantStatisticTypes.includes(statisticType)
    const categories: Category[] = selectedCategories.length
      ? selectedCategories
      : ['overall' as Category]
    // Use the month start epochs to build out the MONTH_START part of the request body
    const requestMonthStart: MonthStart = monthStartsForDateRange.reduce(
      (accum, epoch) => {
        const { end, start, source } = TIME_GROUPING.MONTH_START[epoch]
        accum[epoch] = {
          categories,
          end,
          // intervals: [
          //     ParkingStatInterval.hour
          // ],
          segmentTypes: [
            {
              segmentHours: summarySegmentHours,
              offsets: [summaryOffset]
            }
          ],
          source,
          start,
          statisticTypes: [EventStatisticType.avgMax]
        }
        return accum
      },
      {}
    )
    let eventType: ParkingEventType =
      statisticType === StatisticType.tenantOccupancyStatsByTimeSegment
        ? ParkingEventType.tenantOccupancyStatsByTimeSegment
        : ParkingEventType.occupancyStatsByTimeSegment
    if (useOccupancy) {
      eventType = ParkingEventType.tenantOccupancyCountsByTimeSegment
    }
    const body: ParkingStatsTwoRequest = {
      jurisdictionId,
      resources: [
        {
          resourceIds: selectedResourceIds,
          [eventType]: {
            TIME_GROUPING: {
              MONTH_START: requestMonthStart
            }
          }
        }
      ]
    }
    const summaryStats = (await resourceService.getStatsTwo(
      JSON.parse(JSON.stringify(body)),
      abortController?.signal
    )) as ParkingStatsTwoSegmentResponse
    return {
      isMultiTenant,
      summaryStats
    }
  } catch (error) {
    console.error(`Uncaught exception when attempting to request summary stats`)
    throw error
  }
}

export const requestOccupancyStats = async (
  slice: ResourceSlice,
  jurisdictionId: string,
  zoneName: string,
  abortController?: AbortController
): Promise<ParkingStatsTwoIntervalResponse> => {
  try {
    const {
      availableStatistics,
      dateRangeSelections,
      selectedCategories,
      selectedReportType,
      selectedResourceIds
    } = slice
    // const intervals = [60]
    const startEnds = dateRangeSelections.flatMap(({ start, end }) => [
      start,
      end
    ])
    const minEpoch = Math.min(...startEnds)
    const maxEpoch = Math.max(...startEnds)
    const minDate = DateTime.fromMillis(minEpoch, { zone: zoneName })
    const maxDate = DateTime.fromMillis(maxEpoch, { zone: zoneName })
    let resourceParams: ParkingStatsTwoResourceRequest[] = []
    const categories = selectedCategories.length
      ? selectedCategories
      : ['overall']

    selectedResourceIds.forEach(resourceId => {
      const statisticTypes = ReportTypeStatisticMap[selectedReportType].filter(
        statisticType => availableStatistics[resourceId]?.[statisticType]
      )

      statisticTypes.forEach(statisticType => {
        const timeGroupingStatisticsMap:
          | TimeGroupingStatistic
          | TimeSeriesStatistic
          | null = availableStatistics[resourceId]?.[statisticType] || null
        if (
          timeGroupingStatisticsMap &&
          'TIME_GROUPING' in timeGroupingStatisticsMap
        ) {
          const { MONTH_START } = (
            timeGroupingStatisticsMap as TimeGroupingStatistic
          ).TIME_GROUPING
          const availableMonthStarts = Object.keys(MONTH_START).map(Number)
          const monthStartsForEpochs = monthStartsForDateRangeSelections(
            minDate,
            maxDate,
            availableMonthStarts
          )
          const requestMonthStart: MonthStart = monthStartsForEpochs.reduce(
            (accum, epoch) => {
              const { end, intervals, source, start, statisticTypes } =
                MONTH_START[epoch]
              accum[epoch] = {
                categories,
                end,
                intervals: [Math.min(...intervals)],
                source,
                start,
                statisticTypes
              }
              if (StatisticType.tenantOccupancyStats === statisticType) {
                accum[epoch].categories = selectedCategories.length
                  ? selectedCategories
                  : ['overall']
              }
              return accum
            },
            {}
          )
          const eventType =
            statisticType === StatisticType.occupancyStats
              ? ParkingEventType.occupancyStats
              : ParkingEventType.tenantOccupancyStats
          resourceParams.push({
            resourceIds: [resourceId],
            [eventType]: {
              TIME_GROUPING: {
                MONTH_START: requestMonthStart
              }
            }
          })
        }
      })
      // const timeGroupingStatisticsMap: TimeGroupingStatistic | TimeSeriesStatistic | null = availableStatistics[resourceId]?.[statisticType] || null
      //
      // if (timeGroupingStatisticsMap && 'TIME_GROUPING' in timeGroupingStatisticsMap) {
      //     const {MONTH_START} = (timeGroupingStatisticsMap as TimeGroupingStatistic).TIME_GROUPING
      //     const availableMonthStarts = Object.keys(MONTH_START).map(Number)
      //     const monthStartsForEpochs = monthStartsForDateRangeSelections(minDate, maxDate, availableMonthStarts)
      //     const requestMonthStart: MonthStart = monthStartsForEpochs.reduce((accum, epoch) => {
      //         const {
      //             end,
      //             intervals,
      //             source,
      //             start,
      //             statisticTypes
      //         } = MONTH_START[epoch]
      //         accum[epoch] = {
      //             categories,
      //             end,
      //             intervals: [Math.min(...intervals)],
      //             source,
      //             start,
      //             statisticTypes
      //         }
      //         if (StatisticType.tenantOccupancyStats === statisticType) {
      //             accum[epoch].categories = selectedCategories.length
      //                 ? selectedCategories
      //                 : ['overall']
      //         }
      //         return accum
      //     }, {})
      //     const eventType = statisticType === StatisticType.occupancyStats
      //         ? ParkingEventType.occupancyStats
      //         : ParkingEventType.tenantOccupancyStats
      //     resourceParams.push({
      //         resourceIds: [resourceId],
      //         [eventType]: {
      //             TIME_GROUPING: {
      //                 MONTH_START: requestMonthStart
      //             }
      //         }
      //     })
      // }
    })
    const body: ParkingStatsTwoRequest = {
      jurisdictionId,
      resources: resourceParams
    }
    return (await resourceService.getStatsTwo(
      JSON.parse(JSON.stringify(body)),
      abortController?.signal
    )) as ParkingStatsTwoIntervalResponse
  } catch (error) {
    console.error(`Uncaught exception when attempting to request summary stats`)
    throw error
  }
}

const monthStartsForDateRangeSelections = (
  monthStart: DateTime,
  monthEnd: DateTime,
  monthStarts: number[]
): number[] => {
  let matchingMonthStarts: Set<number> = new Set()
  const minDate = monthStart.minus({ month: 1, day: 1 }).toMillis()
  const maxDate = monthEnd.plus({ month: 1, day: 1 }).toMillis()
  monthStarts.forEach(epoch => {
    if (epoch >= minDate && epoch <= maxDate) {
      matchingMonthStarts.add(epoch)
    }
  })
  return [...matchingMonthStarts].filter(Boolean).sort(sortAsc)
}

export const generateSummaryReportPdf =
  (abortController?: AbortController) =>
  async (dispatch, getState): Promise<boolean> => {
    const {
      resources,
      selectedResourceIds,
      summaryData,
      summaryIsMultiTenant,
      summaryMonth
    } = getState()[name]
    const weekDay: OccupancySummary | OccupancyCategoryDay = summaryData.find(
      ({ summaryType }) => SummaryType.weekDay === summaryType
    )
    const weekEnd: OccupancySummary | OccupancyCategoryDay = summaryData.find(
      ({ summaryType }) => SummaryType.weekEnd === summaryType
    )
    const dailyList: (OccupancySummary | OccupancyCategoryDay)[] =
      summaryData.filter(({ summaryType }) => SummaryType.daily === summaryType)
    const data: Blob = await resourceService.getSummaryReportPdf(
      {
        dailyList,
        weekDay,
        weekEnd
      },
      summaryIsMultiTenant,
      abortController?.signal
    )

    let date: DateTime = DateTime.now()
      .setZone(getZoneName())
      .set({ month: Math.abs(summaryMonth) })
    if (summaryMonth < 0) {
      date = date.minus({ years: 1 })
    }
    const dateString = date.toFormat('yyyy-LLLL') // Ex. 2022-July

    const resourceNames = selectedResourceIds
      .map(resourceId => resources[resourceId].name)
      .join(', ')
    saveAs(
      data,
      // 2022-July-Arvada Transit Station-MonthlySummary.pdf
      `${dateString}-${resourceNames}-MonthlySummary.pdf`
    )
    return true
  }

export const generateReportCsv =
  () =>
  async (dispatch, getState): Promise<boolean> => {
    const state: ResourceSlice = getState()[name]
    const { selectedResourceIds, resources } = state
    const { reports }: OccupancyReport | OverflowReport =
      await requestOccupancyReport(state)
    const resourceNames: string = selectedResourceIds
      .map(resourceId => resources[resourceId].name)
      .join(',')

    const csvRows = await csvSerializer.serializeOccupancyReport(
      state,
      { reports } as OccupancyReport,
      getZoneName()
    )
    const data = Papa.unparse(csvRows)
    saveAs(
      new Blob([data], { type: 'text/plain;charset=utf-8' }),
      `${resourceNames}.csv`
    )
    return true
  }

const settingsCache = new Cache(MS_PER_WEEK * 4)
const settingsKey = () => `occupancyReportSettings/${getJurisdictionId()}`

export const saveParameters = () => async (dispatch, getState) => {
  const {
    cumulative,
    dateRangeSelections,
    daysOfTheWeek,
    groupBy,
    interval,
    selectedCategories,
    selectedClassifications,
    selectedReportType,
    selectedResourceIds,
    selectedStatisticTypes,
    smoothValue,
    summaryMonth,
    summaryOffset,
    summarySegmentHours
  } = getState()[name]
  const parameters: SavableParameters = {
    cumulative,
    dateRangeSelections,
    daysOfTheWeek,
    groupBy,
    interval,
    selectedCategories,
    selectedClassifications,
    selectedReportType,
    selectedResourceIds,
    selectedStatisticTypes,
    smoothValue,
    summaryMonth,
    summaryOffset,
    summarySegmentHours
  }
  const payload = JSON.parse(JSON.stringify(parameters))
  await settingsCache.set(settingsKey(), payload)
  return true
}

const loadParameters = async (): Promise<SavableParameters | null> => {
  const parameters: SavableParameters | null | undefined =
    await settingsCache.get(settingsKey())
  return parameters || null
}

//-- Utils
/**
 * @func handleResponseError - Handles unauthorized responses (HTTP_STATUS_CODE = 401)
 * @param {Response} response
 * @param {string} message
 * @returns {(function(*): Promise<string>)}
 */
export const handleResponseError =
  (response, message = 'Invalid permissions') =>
  async dispatch => {
    if (response.status !== 401) {
      dispatch(
        actions.setState({
          error: response?.statusText || response?.status || response,
          reportsLoading: false,
          showReport: false
        })
      )
      return Promise.reject(response)
    }
    const data = await tryToParseResponse(response)
    const error = data?.error || response.statusText || response.status
    logError(error)
    const newState = {
      error,
      loading: false,
      init: true,
      resources: {},
      reportsLoading: false,
      showReport: false
    }
    dispatch(actions.setState(newState))
    dispatch(alertActions.error(message))
    // Wait for a second so the user can see the error message propagate
    await dispatch(userActions.logout())
    window.location.assign('/')
    return Promise.resolve(message)
  }

export const handleError = (error: Error | string | any) => dispatch => {
  const value = String(error)
  logError(error)
  dispatch(actions.setState({ error: value }))
  dispatch(alertActions.error(value))
  throw error
}

const tryToParseResponse = async response => {
  try {
    return response.json().then(data => data)
  } catch (error) {
    return Promise.resolve(null)
  }
}

