import CircularProgress from '@material-ui/core/CircularProgress'
import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { uniq, intersection } from 'lodash'
import withStyles from '@material-ui/core/styles/withStyles'
import Header from 'components/Header/Header'
import Footer from 'components/Footer/Footer'
import Sidebar from 'components/Sidebar/Sidebar'
import dashboardRoutes from 'routes/dashboard'
import nonDashboardRoutes from 'routes/non-dashboard'
import appStyle from 'assets/jss/material-dashboard-pro-react/layouts/dashboardStyle'
import { userActions } from 'features/user/userSlice'

// CR 2021-Oct-28 - Use EMPTY_USER in place of null/undefined so we can handle redirects in the logout workflow
// without encountering render errors as state/URL changes propagate
const EMPTY_USER = {
  firstName: '',
  lastName: '',
  roles: []
}

class SwitchRoutes extends React.Component {
  render() {
    const user = JSON.parse(localStorage.getItem('user')) || EMPTY_USER
    const jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'))
    const jurisdictionId = jurisdiction?.id || null
    const jurisdictionParameter = `jurisdictionId=${jurisdictionId}`
    let roles = user.roles || null
    if (jurisdictionId) {
      roles = roles.filter(o => {
        if (o.role === 'superAdmin') {
          return true
        }
        if (o.parameters && o.parameters === jurisdictionParameter) {
          return true
        }
        if (o.role === 'jurisdictionScope' && o.parameters === jurisdictionId) {
          return true
        }
        return false
      })
    }

    const roleNames = uniq(roles.map(o => o.role))

    let routes = [...nonDashboardRoutes, ...dashboardRoutes]
    routes = routes.filter(route => {
      if (route.admin) {
        if (route.roles) {
          if (intersection(route.roles, roleNames).length > 0) {
            return true
          } else {
            return false
          }
        } else {
          return true
        }
      } else {
        return true
      }
    })

    return (
      <React.Suspense fallback={<CircularProgress />}>
        <Switch>
          {/* {nonDashboardRoutes.map((prop, key) => {
                     return  <Route path={prop.path} component={prop.component} key={key} />;
                     })} */}
          {routes.map((prop, key) => {
            if (prop.redirect) {
              return <Redirect from={prop.path} to={prop.pathTo} key={key} />
            }
            if (prop.collapse) {
              return prop.views.map((prop, key) => {
                return (
                  <Route
                    path={prop.path}
                    component={prop.component}
                    render={prop.render}
                    key={key}
                  />
                )
              })
            }
            return (
              <Route
                path={prop.path}
                component={prop.component}
                render={prop.render}
                key={key}
              />
            )
          })}
        </Switch>
      </React.Suspense>
    )
  }
}

class Dashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileOpen: false,
      miniActive: true
    }
    this.handleLogout = this.handleLogout.bind(this)
  }

  async componentDidMount() {
    const mobileOpen = JSON.parse(localStorage.getItem('sidebar') || 'false')
    if (mobileOpen !== null && mobileOpen !== undefined) {
      this.setState({
        mobileOpen: Boolean(mobileOpen),
        miniActive: Boolean(mobileOpen)
      })
    }
  }

  handleDrawerToggle = () => {
    const mobileOpen = !this.state.mobileOpen
    this.setState({ mobileOpen })
    localStorage
      .setItem('sidebar', JSON.stringify(mobileOpen))
      .then(value => value)
      .catch(console.error)
  }

  handleLogout(e) {
    e.preventDefault()

    const { dispatch } = this.props
    dispatch(userActions.logout())
  }

  getRoute() {
    let path = this.props.location.pathname
    return !(
      path.startsWith('/template-admin/view-template-affected/') ||
      path.startsWith('/template-admin/template-affected/')
    )
  }

  sidebarMinimize() {
    const miniActive = !this.state.miniActive
    this.setState({ miniActive })
    localStorage.setItem('sidebar', JSON.stringify(miniActive))
  }

  render() {
    const { classes, ...rest } = this.props
    let user = this.props.user || EMPTY_USER
    const mainPanel =
      classes.mainPanel +
      ' ' +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive
        // [classes.mainPanelWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1
      })
    const route = this.getRoute()
    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={dashboardRoutes}
          logoText={'Modii'}
          handleDrawerToggle={this.handleDrawerToggle}
          handleLogout={this.handleLogout}
          open={this.state.mobileOpen}
          color="blue"
          bgColor="black"
          miniActive={this.state.miniActive}
          authenticatedUser={user}
          {...rest}
        />
        <div
          className={mainPanel}
          ref={component => {
            this.mainPanel = component
          }}
        >
          {route && (
            <Header
              sidebarMinimize={this.sidebarMinimize.bind(this)}
              miniActive={this.state.miniActive}
              routes={[...dashboardRoutes, ...nonDashboardRoutes]}
              handleDrawerToggle={this.handleDrawerToggle}
              {...rest}
            />
          )}
          {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {route ? (
            <div className={classes.content}>
              <div className={classes.container}>
                <SwitchRoutes />
              </div>
            </div>
          ) : (
            <div className={classes.map}>
              <SwitchRoutes />
            </div>
          )}
          {route && <Footer fluid />}
        </div>
      </div>
    )
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func
}

function mapStateToProps(state) {
  const { authentication } = state
  const { user } = authentication

  return {
    user
  }
}

export default connect(mapStateToProps)(withStyles(appStyle)(Dashboard))
