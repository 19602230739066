import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { alertActions } from 'features/alert/alertSlice'
import baseReducer from 'features/baseReducer'
import { getJurisdictionId } from 'utils/useJurisdictionId'
import { IdMessage, IMessage, MessageListResponse, MessageSlice } from 'features/message/types'
import logError from 'utils/logError'
import { messageService } from 'services'


export const initialState: MessageSlice = {
    // Values
    messages: {
        // messageId: Message
    },
    // CRUD states
    loading: false,
    saving: false,
    deleting: false,
    error: null
}

export const {name, reducer, actions} = createSlice({
    name: 'messages',
    initialState,
    reducers: {
        ...baseReducer,
        setLoading: (state, {payload}: PayloadAction<boolean>) => {
            state.loading = payload
        },
        setSaving: (state, {payload}: PayloadAction<boolean>) => {
            state.saving = payload
        },
        setDeleting: (state, {payload}: PayloadAction<boolean>) => {
            state.deleting = payload
        },
        setError: (state, {payload}: PayloadAction<null | Error | any>) => {
            state.error = payload
        },
        setMessages: (state, {payload}: PayloadAction<Record<string, IdMessage>>) => {
            state.messages = payload
        },
        updateMessage: (state, {payload}: PayloadAction<IdMessage>) => {
            if (state.messages) {
                state.messages[payload.id] = payload
            } else {
                state.messages = {
                    [payload.id]: payload
                }
            }
        },
        removeMessage: (state, {payload}: PayloadAction<string>) => {
            if (state.messages) {
                delete state.messages[payload]
            }
        }
    }
})


//--- Actions
export const getMessages = jurisdictionId => async (dispatch, getState) => {
    dispatch(actions.setLoading(true))
    try {
        const messageMap: MessageListResponse = await messageService.getMessages(jurisdictionId)
        const messages: Record<string, IdMessage> = Object.keys(messageMap).reduce((accum, messageId) => {
            accum[messageId] = messageMap[messageId]
            return accum
        }, {})
        dispatch(actions.setMessages(messages))
        dispatch(actions.setLoading(false))
    } catch (error) {
        dispatch(handleError(error))
        dispatch(
            alertActions.error('There was a problem retrieving message information.')
        )
    }
    return getState[name]
}


export const getMessage = messageId => async dispatch => {
    dispatch(actions.setLoading(true))
    try {
        const [message, ..._] = await messageService.getMessage(messageId)
        dispatch(actions.updateMessage(message))
        dispatch(actions.setLoading(false))
        return message
    } catch (error) {
        dispatch(handleError(error))
        dispatch(
            alertActions.error('There was a problem retrieving message information.')
        )
    }
    return null
}


export const addNewMessage = (message: IMessage) => async (dispatch): Promise<IdMessage | null> => {
    try {
        dispatch(actions.setSaving(true))
        const payload = {
            ...message,
            resourcetype: 'jurisdiction',
            resource: getJurisdictionId(),
            closeText: 'ok'
        }
        const newMessage = await messageService.addNewMessage(payload)
        dispatch(actions.updateMessage(newMessage))
        dispatch(actions.setSaving(false))
        return newMessage
    } catch (error) {
        dispatch(handleError(error))
        dispatch(alertActions.error('There was a problem creating a new message.'))
    }
    return null
}


export const updateMessage = (message: IdMessage, actionVerb = 'updated') => async dispatch => {
    try {
        dispatch(actions.setSaving(true))
        const payload = {
            ...message,
            resourcetype: 'jurisdiction',
            resource: getJurisdictionId(),
            closeText: 'ok'
        }
        const newMessage = await messageService.updateMessage(payload)
        dispatch(actions.updateMessage(newMessage))
        dispatch(actions.setSaving(false))
        dispatch(
            alertActions.success(`Message has been successfully ${actionVerb}.`)
        )
        return newMessage
    } catch (error) {
        dispatch(handleError(error))
        dispatch(alertActions.error('There was a problem updating the message.'))
    }
    return null
}


export const deleteMessage = (messageId: string) => async (dispatch, getState) => {
    try {
        dispatch(actions.setDeleting(true))
        await messageService.deleteMessage(messageId)
        dispatch(actions.removeMessage(messageId))
        dispatch(actions.setDeleting(true))
        dispatch(alertActions.success(`Message has been successfully deleted.`))
        return messageId
    } catch (error) {
        dispatch(alertActions.error('There was a problem deleting the message.'))
        dispatch(handleError(error))
    }
    return null
}


const handleError = (error: Error | any, showAlert = false) => (dispatch) => {
    logError(error)
    dispatch(actions.setError(error))
    if (showAlert && error) {
        dispatch(alertActions.error(String(error)))
    }
    dispatch(actions.setLoading(false))
    dispatch(actions.setDeleting(false))
    dispatch(actions.setSaving(false))
}


export const registerMessage = (message: IdMessage) => (dispatch, getState) => {
    dispatch(actions.updateMessage(message))
    return getState()[name]
}

export const messageActions = {
    getMessages,
    getMessage,
    addNewMessage,
    updateMessage,
    deleteMessage,
    registerMessage
}
