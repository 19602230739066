import React from 'react'
import MaterialTextField from '@material-ui/core/TextField'

export const TextField = ({ defaultValue, ...props }) => (
  <MaterialTextField
    defaultValue={defaultValue}
    {...props}
    {...mapError(props)}
  />
)
export default TextField

const mapError = (
  { meta: { touched, error, warning } = {}, input, ...props },
  errorProp = 'errorText'
) =>
  !(touched && (error || warning))
    ? { ...input, ...props }
    : {
        ...props,
        ...input,
        [errorProp]: error || warning
      }
