import { useMemo } from 'react'


export interface AuthUser {
    firstName: string
    id: string
    lastName: string
    token: string
    username: string
    roles: Role[]
}


export interface Role {
    parameters: string
    role: string
}


interface StoredJurisdiction {
    id: string
}


export const getUser = (): AuthUser | null => {
    try {
        return JSON.parse(localStorage.getItem('user'))
    } catch (error) {
        return null
    }
}

export const useUser = (): AuthUser | null => {
    return useMemo(
        () => getUser(),
        [localStorage.getItem('user')]
    )
}

export const getUserRoles = (): string[] => {
    const authUser: AuthUser | null = getUser()
    if (!authUser) {
        return []
    }
    const jurisdiction: StoredJurisdiction = JSON.parse(
        localStorage.getItem('user.jurisdiction')
    )
    const jurisdictionId: string | null = jurisdiction?.id || null
    const jurisdictionParameter = `jurisdictionId=${jurisdictionId}`
    let roles: Role[] = authUser.roles || []
    if (jurisdictionId) {
        roles = roles.filter(
            o =>
                'superAdmin' === o.role ||
                ('jurisdictionScope' === o.role && jurisdictionId === o.parameters) ||
                (o.parameters && jurisdictionParameter === o.parameters)
        )
    }
    return [...new Set(roles.map(o => o.role))]
}

/**
 * @func useUserRoles - Hook to retrieve a memoized array of role names for the current user
 * @returns {string[]}
 */
export const useUserRoles = (): string[] => {
    return useMemo(() => getUserRoles(), [localStorage.getItem('user')])
}

export default useUserRoles

export const useIsSuperAdmin = (): boolean => {
    return useMemo(
        () => getUserRoles().includes('superAdmin'),
        [localStorage.getItem('user')]
    )
}
