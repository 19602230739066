import React, { lazy } from 'react'
import DashboardIcon from '@material-ui/icons/Dashboard'
import Description from '@material-ui/icons/Description'
import EditAttributesIcon from '@material-ui/icons/EditAttributes'
import Map from '@material-ui/icons/Map'
import Message from '@material-ui/icons/Message'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import Room from '@material-ui/icons/Room'
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications'
import TimelineIcon from '@material-ui/icons/Timeline'
import User from '@material-ui/icons/People'

import Terms from 'features/resource/terms'


const AreaStartsTable = lazy(() => import('views/Tables/AreaStartsTable'))
const AuditLogsTable = lazy(() => import('views/Tables/AuditLogTable'))
const Dashboard = lazy(() => import('views/Dashboard/Dashboard'))
const ImpactLogsTable = lazy(() => import('views/Tables/ImpactLogTable'))
const ManageConfig = lazy(() => import('views/ManageConfig'))
const ManageJurisdiction = lazy(() => import('views/ManageJurisdiction'))
const MessagesPage = lazy(() => import('views/Tables/MessagesTable'))
const ResourceReport = lazy(() => import('views/ResourceReport/ResourceReport'))
const TemplateGroupsPage = lazy(
    () => import('views/Tables/TemplateGroupsTable')
)
const UsersTable = lazy(() => import('views/Tables/UsersTable'))
const PaymentReport = lazy(()=> import('views/PaymentReport/PaymentReport'))

export const OCCUPANCY_REPORTING_ROLES = [
    'jurisdictionScope',
    'occupancyReportManager',
    'queryAll',
    'superAdmin'
]

const dashRoutes = [
    {
        path: '/dashboard',
        name: 'Dashboard',
        icon: DashboardIcon,
        component: () => <Dashboard />,
        admin: true,
        roles: [
            'jurisdictionScope',
            'occupancyReportManager',
            'queryAll',
            'superAdmin'
        ]
    },
    {
        path: '/area-starts',
        name: 'Area Starts',
        icon: Room,
        component: AreaStartsTable,
        admin: true,
        roles: ['superAdmin']
    },
    {
        path: '/user-admin',
        name: 'User Admin',
        icon: User,
        component: UsersTable,
        admin: true,
        roles: ['superAdmin', 'corporateAdmin', 'userAdmin', 'userReader']
    },
    {
        path: '/template-admin',
        name: 'Templates Admin (beta)',
        icon: Map,
        component: TemplateGroupsPage,
        admin: true,
        roles: [
            'superAdmin',
            'corporateAdmin',
            'templateAdmin',
            'templateMaintainer',
            'templateReader'
        ]
    },
    {
        path: '/message-admin',
        name: 'Messages Admin',
        icon: Message,
        component: MessagesPage,
        admin: true,
        roles: ['superAdmin', 'corporateAdmin', 'messageAdmin', 'messageReader']
    },
    {
        path: '/audit-report',
        name: 'Audit Reports',
        icon: Description,
        component: AuditLogsTable,
        admin: true,
        roles: ['superAdmin', 'corporateAdmin', 'auditReporter']
    },
    {
        path: '/impact-report',
        name: 'Impact Reports',
        icon: Description,
        component: ImpactLogsTable,
        admin: true,
        roles: ['superAdmin', 'corporateAdmin', 'inventoryImpactReporter']
    },
    {
        path: '/resource-report',
        name: Terms.reportPageTitle,
        icon: TimelineIcon,
        component: ResourceReport,
        admin: true,
        roles: OCCUPANCY_REPORTING_ROLES
    },
    {
        path: '/payment-report',
        name: Terms.paymentReportPageTitle,
        icon: MonetizationOnIcon,
        component: PaymentReport,
        admin: true,
        roles: OCCUPANCY_REPORTING_ROLES
    },
    {
        path: '/manage-jurisdictions',
        name: Terms.manageJurisdictionsTitle,
        icon: EditAttributesIcon,
        component: ManageJurisdiction,
        admin: true,
        roles: ['superAdmin']
    },
    {
        path: '/manage-config',
        name: Terms.manageConfigsTitle,
        icon: SettingsApplicationsIcon,
        component: ManageConfig,
        admin: true,
        roles: ['superAdmin']
    },
    {redirect: true, path: '/', pathTo: '/dashboard', name: 'Dashboard'}
]

export default dashRoutes
