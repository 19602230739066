import React, { useState } from 'react'
import classNames from 'classnames'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Divider from '@material-ui/core/Divider'
import Grow from '@material-ui/core/Grow'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import PropTypes from 'prop-types'

import Button from 'components/CustomButtons/Button'
import customDropdownStyle from 'assets/jss/material-dashboard-pro-react/components/customDropdownStyle'


const useStyles = makeStyles(customDropdownStyle)

const CustomDropdown = props => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const classes = useStyles()
    const handleClick = event => {
        if (anchorEl && anchorEl.contains(event.target)) {
            setAnchorEl(null)
        } else {
            setAnchorEl(event.currentTarget)
        }
    }
    const handleClose = event => {
        if (anchorEl.contains(event.target)) {
            return
        }
        setAnchorEl(null)
    }
    const handleCloseMenu = (event, index, prop) => {
        setAnchorEl(null)
        if (props && props.onClick) {
            props.onClick(event, index, prop)
        }
    }
    const {
        buttonText,
        buttonIcon,
        dropdownList,
        buttonProps,
        dropup,
        dropdownHeader,
        caret,
        hoverColor,
        dropPlacement,
        rtlActive,
        noLiPadding,
        innerDropDown,
        navDropdown
    } = props
    const caretClasses = classNames({
        [classes.caret]: true,
        [classes.caretDropup]: dropup && !anchorEl,
        [classes.caretActive]: Boolean(anchorEl) && !dropup,
        [classes.caretRTL]: rtlActive
    })
    const dropdownItem = classNames({
        [classes.dropdownItem]: true,
        [classes[hoverColor + 'Hover']]: true,
        [classes.noLiPadding]: noLiPadding,
        [classes.dropdownItemRTL]: rtlActive
    })
    const dropDownMenu = (
        <MenuList role="menu" className={classes.menuList}>
            {dropdownHeader !== undefined ? (
                <MenuItem
                    onClick={() => handleCloseMenu(dropdownHeader)}
                    className={classes.dropdownHeader}
                >
                    {dropdownHeader}
                </MenuItem>
            ) : null}
            {dropdownList.map((option, index) => {
                if (option.divider) {
                    return (
                        <Divider
                            key={index}
                            onClick={() => handleCloseMenu('divider')}
                            className={classes.dropdownDividerItem}
                        />
                    )
                } else if (
                    option.props !== undefined &&
                    option.props['data-ref'] === 'multi'
                ) {
                    return (
                        <MenuItem
                            key={index}
                            className={dropdownItem}
                            style={{overflow: 'visible', padding: 0}}
                        >
                            {option}
                        </MenuItem>
                    )
                }
                return (
                    <MenuItem
                        key={index}
                        onClick={event => handleCloseMenu(event, index, option)}
                        className={dropdownItem}
                    >
                        {option}
                    </MenuItem>
                )
            })}
        </MenuList>
    )
    return (
        <div className={innerDropDown ? classes.innerManager : classes.manager}>
            <div className={buttonText !== undefined ? '' : classes.target}>
                <Button
                    aria-label="Notifications"
                    aria-owns={anchorEl ? 'menu-list' : null}
                    aria-haspopup="true"
                    {...buttonProps}
                    onClick={handleClick}
                >
                    {buttonIcon !== undefined ? (
                        <props.buttonIcon className={classes.buttonIcon} />
                    ) : null}
                    {buttonText !== undefined ? buttonText : null}
                    {caret ? <b className={caretClasses} /> : null}
                </Button>
            </div>
            <Popper
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                transition
                disablePortal
                placement={dropPlacement}
                className={classNames({
                    [classes.popperClose]: !anchorEl,
                    [classes.popperResponsive]: true,
                    [classes.popperNav]: Boolean(anchorEl) && navDropdown
                })}
            >
                {() => (
                    <Grow
                        in={Boolean(anchorEl)}
                        id="menu-list"
                        style={
                            dropup
                                ? {transformOrigin: '0 100% 0'}
                                : {transformOrigin: '0 0 0'}
                        }
                    >
                        <Paper className={classes.dropdown}>
                            {innerDropDown ? (
                                dropDownMenu
                            ) : (
                                <ClickAwayListener onClickAway={handleClose}>
                                    {dropDownMenu}
                                </ClickAwayListener>
                            )}
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    )
}

CustomDropdown.defaultProps = {
    caret: true,
    dropup: false,
    hoverColor: 'primary'
}

CustomDropdown.propTypes = {
    hoverColor: PropTypes.oneOf([
        'dark',
        'primary',
        'info',
        'success',
        'warning',
        'danger',
        'rose'
    ]),
    buttonIcon: PropTypes.object,
    buttonProps: PropTypes.object,
    buttonText: PropTypes.node,
    caret: PropTypes.bool,
    dropdownHeader: PropTypes.node,
    dropdownList: PropTypes.array,
    dropup: PropTypes.bool,
    rtlActive: PropTypes.bool,
    dropPlacement: PropTypes.oneOf([
        'bottom',
        'top',
        'right',
        'left',
        'bottom-start',
        'bottom-end',
        'top-start',
        'top-end',
        'right-start',
        'right-end',
        'left-start',
        'left-end'
    ]),
    noLiPadding: PropTypes.bool,
    innerDropDown: PropTypes.bool,
    navDropdown: PropTypes.bool,
    // This is a function that returns the clicked menu item
    onClick: PropTypes.func
}

export default CustomDropdown
