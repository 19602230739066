import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { alertActions } from 'features/alert/alertSlice'
import { actions as authActions } from 'features/auth/authSlice'
import baseReducer from 'features/baseReducer'
import {
    IJurisdictionDefaults,
    IUser,
    IUserRole,
    IdUser,
    IdUserRole,
    JurisdictionScope,
    UserSlice
} from 'features/user/types'
import { userService } from 'services'
import logError from 'utils/logError'



export const initialState: UserSlice = {
    users: [],
    userListLoading: false,

    newUser: null,
    requestingNewUser: false,

    updatingUser: false,
    updatingUserId: null,

    requestingPasswordReset: false,
    requestingEnabledChange: false,
    requestingUserRoleChange: false,
    requestingUserRole: false,

    assignableRoles: [],
    requestingAssignableRoles: false,

    jurisdictionScopes: {},
    checkingJurisdictionScope: false,

    jurisdictionDefaults: {
        BASE_CURRENCY: 'USD',
        DEFAULT_LOCALE: 'en-US',
        DEFAULT_LOCATION: {
            latitude: 29.89838709936453,
            longitude: -81.31556696011495
        },
        TIMEZONE: 'America/Denver'
    },
    checkingJurisdictionDefaults: false,
    error: null
}

export const {name, reducer, actions} = createSlice({
    name: 'users',
    initialState,
    reducers: {
        ...baseReducer,
        setCheckingJurisdictionDefaults: (state, {payload}: PayloadAction<boolean>) => {
            state.checkingJurisdictionDefaults = payload
        },
        setJurisdictionDefaults: (state, {payload}: PayloadAction<IJurisdictionDefaults>) => {
            state.jurisdictionDefaults = payload
        }
    }
})

export const login = (username: string, password: string) => async dispatch => {
    dispatch(
        authActions.setState({
            loggingIn: true,
            user: {username}
        })
    )
    try {
        const user = await userService.login(username, password)
        dispatch(
            authActions.setState({
                user,
                loggingIn: false
            })
        )
        dispatch(await checkJurisdictionDefaults(
            JSON.parse(localStorage.getItem('user.jurisdiction')).id
        ))
        return user
    } catch (error) {
        dispatch(handleError(error))
        dispatch(
            authActions.setState({
                user: undefined,
                loggingIn: false
            })
        )
        dispatch(alertActions.error('Invalid login credentials'))
        return null
    }
}

export const logout = () => async dispatch => {
    userService.logout().then(() => true)
    dispatch(
        authActions.setState({
            loggingIn: false,
            user: undefined
        })
    )
}

export const getAll = jurisdictionId => async (dispatch, getState) => {
    const loadingKey = 'userListLoading'
    dispatch(
        actions.setState({
            [loadingKey]: true
        })
    )
    try {
        const users = await userService.getAll(jurisdictionId)
        dispatch(
            actions.setState({
                users,
                [loadingKey]: false
            })
        )
        return users
    } catch (error) {
        dispatch(
            actions.setState({
                [loadingKey]: false
            })
        )
        dispatch(handleError(error))

    }
    return null
}

export const addNewUser = user => async (dispatch, getState) => {
    const loadingKey = 'requestingNewUser'
    dispatch(
        actions.setState({
            [loadingKey]: true
        })
    )
    try {
        const newUser = await userService.addNewUser(user)
        const users = getState()[name].users.concat(newUser)
        dispatch(
            actions.setState({
                users,
                newUser,
                [loadingKey]: false
            })
        )
        dispatch(alertActions.success('User has been successfully created.'))
        return newUser
    } catch (error) {
        dispatch(
            actions.setState({
                newUser: false,
                [loadingKey]: false
            })
        )
        dispatch(handleError(error))

    }
    return null
}

export const updateUser = user => async (dispatch, getState) => {
    try {
        const {id} = user
        dispatch(
            actions.setState({
                updatingUserId: id,
                updatingUser: true
            })
        )
        const updatedUser = await userService.updateUser(user)
        const users = getState()
            [name].users.filter(u => id !== u.id)
            .concat(updatedUser)
        dispatch(
            actions.setState({
                users,
                updatingUserId: null,
                updatingUser: false
            })
        )
        dispatch(alertActions.success('User has been successfully updated.'))
        return updatedUser
    } catch (error) {
        dispatch(
            actions.setState({
                updatingUserId: null,
                updatingUser: false
            })
        )
        dispatch(handleError(error))
    }
    return null
}

export const registerUser = user => async (dispatch, getState) => {
    if (!user.roles) {
        user.roles = []
    }
    const users = getState()
        [name].users.filter(u => user.id !== u.id)
        .concat(user)
    dispatch(
        actions.setState({
            users
        })
    )
    return getState()[name]
}

export const passwordReset =
    (email, forSelf = false) =>
        async dispatch => {
            dispatch(
                actions.setState({
                    requestingPasswordReset: true
                })
            )
            try {
                const newUser = await userService.passwordReset(email)
                dispatch(
                    actions.setState({
                        requestingPasswordReset: false
                    })
                )
                dispatch(
                    alertActions.success(
                        `Password reset request has been successfully created for ${email}. ${
                            forSelf ? 'You' : 'The user'
                        } will be sent an email with further instructions.`
                    )
                )
                return newUser
            } catch (error) {
                dispatch(
                    actions.setState({
                        requestingPasswordReset: false
                    })
                )
                dispatch(handleError(error))
            }
            return null
        }

export const switchEnabledStatus =
    (userId, enabledStatus) => async (dispatch, getState) => {
        dispatch(
            actions.setState({
                requestingEnabledChange: true
            })
        )
        try {
            const {user} = await userService.switchEnabledStatus(
                userId,
                enabledStatus
            )
            const users = getState()[name].users.reduce((list, u) => {
                return user.id !== u.id
                    ? list.concat(u)
                    : list.concat({
                        ...u,
                        ...user
                    })
            }, [])
            dispatch(
                actions.setState({
                    users,
                    requestingEnabledChange: false
                })
            )
            dispatch(
                alertActions.success(
                    `User ${user.email} has been successfully set with ${
                        user.enabled ? 'en' : 'dis'
                    }abled status.`
                )
            )
            return user
        } catch (error) {
            dispatch(
                actions.setState({
                    requestingEnabledChange: false
                })
            )
            dispatch(handleError(error))
        }
        return null
    }

export const addNewUserRole =
    (userId, role, roleParameters) => async (dispatch, getState) => {
        dispatch(
            actions.setState({
                requestingUserRoleChange: true
            })
        )
        try {
            const {userRole} = await userService.addNewUserRole(
                userId,
                role,
                roleParameters
            )
            delete userRole.userId
            const allUsers = getState()[name].users
            const user = allUsers.find(({id}) => userId === id)
            const users = allUsers.filter(({id}) => userId !== id)

            dispatch(
                actions.setState({
                    users: !user
                        ? users
                        : users.concat({
                            ...user,
                            roles: user.roles.concat(userRole)
                        }),
                    requestingUserRoleChange: false
                })
            )
            dispatch(
                alertActions.success(
                    `User role ${userRole.userRole} has been successfully added.`
                )
            )
            return userRole
        } catch (error) {
            dispatch(
                actions.setState({
                    requestingUserRoleChange: false
                })
            )
            dispatch(handleError(error))
        }
        return null
    }

export const updateUserRole =
    (id, role, roleParameters) => async (dispatch, getState) => {
        dispatch(
            actions.setState({
                requestingUserRoleChange: true
            })
        )
        try {
            const {userRole} = await userService.updateUserRole(
                id,
                role,
                roleParameters
            )
            const {userId, roleId} = userRole
            const allUsers = getState()[name].users
            const user = allUsers.find(u => userId === u.id)
            const newUser = !user
                ? null
                : {
                    ...user,
                    roles: user.roles.filter(r => roleId !== r.roleId).concat(userRole)
                }
            const users = !newUser
                ? allUsers
                : allUsers.filter(u => userId !== u.id).concat(newUser)
            dispatch(
                actions.setState({
                    users,
                    requestingUserRoleChange: false
                })
            )
            dispatch(alertActions.success(`User role has been successfully updated.`))
            return newUser
        } catch (error) {
            dispatch(
                actions.setState({
                    requestingUserRoleChange: false
                })
            )
            dispatch(handleError(error))

        }
        return null
    }

export const deleteUserRole = id => async (dispatch, getState) => {
    dispatch(
        actions.setState({
            requestingUserRoleChange: true
        })
    )
    try {
        const {userRole} = await userService.deleteUserRole(id)
        const {userId, roleId} = userRole
        const allUsers = getState()[name].users
        const user = allUsers.find(u => userId === u.id)
        if (user) {
            const users = allUsers.reduce((list, u) => {
                return userId !== u.id
                    ? list.concat(u)
                    : list.concat({
                        ...u,
                        roles: u.roles.filter(r => roleId !== r.roleId)
                    })
            }, [])
            dispatch(
                actions.setState({
                    users: users
                })
            )
        }

        dispatch(
            actions.setState({
                requestingUserRoleChange: false
            })
        )
        dispatch(alertActions.success(`User role has been successfully deleted.`))
        return userRole
    } catch (error) {
        dispatch(
            actions.setState({
                requestingUserRoleChange: false
            })
        )
        dispatch(handleError(error))
    }
    return null
}

export const getAssignableRoles = () => async dispatch => {
    dispatch(
        actions.setState({
            requestingAssignableRoles: true
        })
    )
    try {
        const {assignableRoles} = await userService.getAssignableRoles()
        dispatch(
            actions.setState({
                assignableRoles,
                requestingAssignableRoles: false
            })
        )
        return assignableRoles
    } catch (error) {
        dispatch(
            actions.setState({
                requestingAssignableRoles: false
            })
        )
        dispatch(handleError(error))

    }
    return null
}

export const checkJurisdictionScope = userId => async dispatch => {
    dispatch(
        actions.setState({
            checkingJurisdictionScope: true
        })
    )
    try {
        const resp = await userService.getAssignableRoles()
        const {jurisdictionScope} = resp
        dispatch(
            actions.setPath({
                value: jurisdictionScope,
                path: ['jurisdictionScopes', userId]
            })
        )
        dispatch(
            actions.setState({
                checkingJurisdictionScope: false
            })
        )
        return jurisdictionScope
    } catch (error) {
        dispatch(handleError(error))
    }
    return null
}

export const checkJurisdictionDefaults = jurisdictionId => async dispatch => {
    dispatch(actions.setCheckingJurisdictionDefaults(true))

    try {
        const jurisdictionDefaults = await userService.checkJurisdictionDefaults(
            jurisdictionId
        )

        dispatch(actions.setJurisdictionDefaults(jurisdictionDefaults))
        dispatch(actions.setCheckingJurisdictionDefaults(false))

        return jurisdictionDefaults
    } catch (error) {
        dispatch(handleError(error))
    } finally {
        dispatch(actions.setCheckingJurisdictionDefaults(false))
    }
    return null
}

const handleError = (error) => dispatch => {
    logError(error)
    dispatch(actions.setState({
        error,
        checkingJurisdictionDefaults: false
    }))
    if (error?.message) {
        dispatch(alertActions.error(error?.message))
    }
}

export const userActions = {
    login,
    logout,
    getAll,
    addNewUser,
    updateUser,
    registerUser,
    passwordReset,
    switchEnabledStatus,
    addNewUserRole,
    updateUserRole,
    deleteUserRole,
    getAssignableRoles,
    checkJurisdictionScope,
    checkJurisdictionDefaults
}
