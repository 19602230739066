/**
 * @func getPath
 * @param path
 * @param obj
 *
 * @example
 * >>>  myObj = {
        a: 0,
        b: 1,
        c: {
            ca: 2,
            cb: 3
        },
        d: 4
    }
 * >>> getPath(['c', 'cb'], myObj) // => 3
 */
export default function getPath(path, obj) {
  const [key, ...keys] = path
  return !keys.length ? obj[key] : getPath(keys, obj[key])
}
