import { JurisdictionAttributes } from 'features/jurisdiction/types/attributes'

export enum JurisdictionStatus {
  draft = 0,
  published = 1
}

export interface Jurisdiction {
  abbreviation: string
  createdAt: string | Date
  id: string
  locality: string
  name: string
  parent: string | null
  status?: JurisdictionStatus
  updatedAt: string | Date
}

export interface JurisdictionDetail extends Jurisdiction {
  jurisdictionAttributes: JurisdictionAttributes
}

// GET
export interface JurisdictionListResponse {
  jurisdictions: Jurisdiction[]
}

export interface JurisdictionDetailResponse {
  jurisdiction: JurisdictionDetail
}

// Create/POST
export interface CreateJurisdictionRequest {
  jurisdiction: JurisdictionDetail
}

export interface CreateJurisdictionResponse {
  jurisdiction: JurisdictionDetail
}

// Update/PUT
export interface UpdateJurisdictionRequest {
  jurisdiction: JurisdictionDetail
}

export interface UpdateJurisdictionResponse {
  jurisdiction: JurisdictionDetail
}
