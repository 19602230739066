import React from 'react'
import PropTypes from 'prop-types'
import { MAP } from 'react-google-maps/lib/constants'

class MapControl extends React.Component {
  static contextTypes = {
    [MAP]: PropTypes.object
  }

  static propTypes = {
    controlPosition: PropTypes.number,
    children: PropTypes.element
  }

  static defaultProps = {
    controlPosition: 1
  }

  componentDidMount() {
    this.map = this.context[MAP]
  }

  componentWillUnmount() {
    const { controlPosition } = this.props
    const index = this.map.controls[controlPosition].getArray().indexOf(this.el)
    this.map.controls[controlPosition].removeAt(index)
  }

  render() {
    this.map = this.context[MAP]
    const { controlPosition, children } = this.props

    return (
      <div
        ref={el => {
          if (!this.renderedOnce) {
            this.el = el
            this.map.controls[controlPosition].push(el)
          } else if (el && this.el && el !== this.el) {
            this.el.innerHTML = ''
            ;[].slice
              .call(el.childNodes)
              .forEach(child => this.el.appendChild(child))
          }
          this.renderedOnce = true
        }}
      >
        {children}
      </div>
    )
  }
}

export default MapControl
