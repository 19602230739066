/* eslint-disable */
import React from 'react'
import { connect } from 'react-redux'
import { FORM_ERROR } from 'final-form'
import Style from '@material-ui/icons/Style'
import SweetAlert from 'react-bootstrap-sweetalert'
import withStyles from '@material-ui/core/styles/withStyles'

import Snackbar from 'components/Snackbar/Snackbar'
import { userService } from 'services'
import { userActions } from 'features/user/userSlice'
import validationFormsStyle from 'assets/jss/spot-admin/views/newTemplateGroupStyle'
import UserForm from 'views/Forms/UserForm'


class NewUserPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            alert: null,
            snackBarOpen: false,
            snackBarStatus: 'success',
            snackBarMessage: ''
        }

        this.submit = this.submit.bind(this)
        this.cancel = this.cancel.bind(this)
        this.continue = this.continue.bind(this)
        this.finished = this.finished.bind(this)
        this.removeAlert = this.removeAlert.bind(this)
        this.confirmContinue = this.confirmContinue.bind(this)
        this.showNotification = this.showNotification.bind(this)
    }

    componentWillMount() {
        const {dispatch, assignableRoles, jurisdictionScopes} = this.props
        let user = JSON.parse(localStorage.getItem('user') || {})

        if (assignableRoles === undefined) {
            dispatch(userActions.getAssignableRoles())
        }

        if (jurisdictionScopes === undefined) {
            dispatch(userActions.checkJurisdictionScope(user.id))
        }
    }

    componentWillUnmount() {
        if (this.notificationTimer) {
            clearTimeout(this.notificationTimer)
        }
    }

    showNotification(status, message) {
        if (!this.state.snackBarOpen) {
            this.setState({
                snackBarOpen: true,
                snackBarStatus: status,
                snackBarMessage: message
            })
            this.notificationTimer = setTimeout(
                function () {
                    this.setState({
                        snackBarOpen: false
                    })
                    this.notificationTimer = null
                }.bind(this),
                3000
            )
        }
    }

    confirmContinue() {
        const {classes} = this.props
        this.setState({
            alert: (
                <SweetAlert
                    success
                    style={{display: 'block'}}
                    title="Success!"
                    onConfirm={() => this.continue()}
                    onCancel={() => this.finished()}
                    confirmBtnCssClass={classes.button + ' ' + classes.success}
                    cancelBtnCssClass={classes.button + ' ' + classes.danger}
                    confirmBtnText="Add another"
                    cancelBtnText="Finished"
                    showCancel
                >
                    Would you like to add another user?
                </SweetAlert>
            )
        })
    }

    continue() {
        const {dispatch} = this.props
        this.setState({
            alert: null
        })
    }

    finished() {
        const {history} = this.props

        this.setState({
            alert: null
        })
        history.goBack()
    }

    removeAlert() {
        this.setState({
            alert: null
        })
    }

    async submit(values) {
        const {dispatch} = this.props
        try {
            const {firstName, lastName, phone, email, password} = values
            const newUser = await userService.addNewUser({
                firstName: firstName.trim(),
                lastName: lastName.trim(),
                phone: phone.trim(),
                email: email.trim(),
                password: password.trim()
            })
            dispatch(userActions.registerUser(newUser.user))
            this.showNotification('success', 'User has been successfully created!')
            this.confirmContinue()
            return newUser.user
        } catch (error) {
            this.showNotification('danger', 'There was a problem creating the user!')
            return Promise.reject({
                [FORM_ERROR]: 'There was a problem creating the user.'
            })
        }
    }

    cancel(pristine) {
        const {classes, history} = this.props

        if (!pristine) {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{display: 'block'}}
                        title="Warning!"
                        onConfirm={() => this.finished()}
                        onCancel={() => this.removeAlert()}
                        confirmBtnCssClass={classes.button + ' ' + classes.success}
                        cancelBtnCssClass={classes.button + ' ' + classes.danger}
                        confirmBtnText="Yes"
                        cancelBtnText="No"
                        showCancel
                    >
                        Cancelling will discard any changes made. Are you sure?
                    </SweetAlert>
                )
            })
        } else {
            history.goBack()
        }
    }

    render() {
        const {alert, snackBarOpen, snackBarMessage, snackBarStatus} = this.state
        const {classes} = this.props

        return (
            <div>
                {alert}
                <Snackbar
                    place="tr"
                    color={snackBarStatus}
                    icon={Style}
                    message={snackBarMessage}
                    open={snackBarOpen}
                    closeNotification={() => this.setState({snackBarOpen: false})}
                    close
                />
                <UserForm
                    classes={classes}
                    onCancel={this.cancel}
                    onSubmit={this.submit}
                    readOnlyMode={false}
                />
            </div>
        )
    }
}


function mapStateToProps(state) {
    const {alert, users} = state

    return {
        assignableRoles: users.assignableRoles,
        requestingAssignableRoles: users.requestingAssignableRoles,
        jurisdictionScopes: users.jurisdictionScopes,
        alert
    }
}

export default connect(mapStateToProps)(
    withStyles(validationFormsStyle)(NewUserPage)
)
