export const isProd = import.meta.env.MODE === 'production'
export const isDev = !isProd

export const adminUrl = import.meta.env.VITE_MODII_ADMIN_API


export enum ConfigEnvironment {
    development = 'development',
    stage = 'stage',
    production = 'production'
}


export const environment: ConfigEnvironment = import.meta.env.VITE_MODII_ADMIN_ENVIRONMENT || ConfigEnvironment.production

const overrideApi = import.meta.env.VITE_API_OVERRIDE
// Use the 'VITE_API_OVERRIDE' env variable to determine the API endpoint
// used for *MOST* requests
const overrideMap = {
    default: 'https://data-collection-api.spotparking.com.au',
    'demo': 'https://data-collection-api-demo.spotparking.com.au',
    'demo-usa': 'https://data-collection-api-demo-usa.spotparking.com.au'
}


export interface AppConfig {
    apiUrl: string
    adminUrl: string
    environment: ConfigEnvironment
    isStage: boolean
    isProduction: boolean
    isDevelopment: boolean
}


export const config: AppConfig = {
    apiUrl: overrideMap[overrideApi] || overrideMap.default,
    adminUrl,
    environment,
    isStage: environment === ConfigEnvironment.stage,
    isProduction: environment === ConfigEnvironment.production,
    isDevelopment: environment === ConfigEnvironment.development
}


export default config
