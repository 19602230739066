/* eslint-disable */
import React from 'react'
import Assignment from '@material-ui/icons/Assignment'
import Cancel from '@material-ui/icons/Cancel'
import { connect } from 'react-redux'
import Create from '@material-ui/icons/Create'
import { Field, Form } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import SweetAlert from 'react-bootstrap-sweetalert'
import Revert from '@material-ui/icons/GetApp'
import withStyles from '@material-ui/core/styles/withStyles'

import Button from 'components/CustomButtons/Button'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import CustomTextField from 'components/redux-form/CustomTextField/CustomTextField'
import editTemplateContainerFormsStyle from 'assets/jss/spot-admin/views/newTemplateGroupStyle'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Snackbar from 'components/Snackbar/Snackbar'
import { stringOfLengthBetween } from 'helpers/validation'
import { templateActions } from 'actions'
import { templateService } from 'services'

const validate = values => {
  const errors = {}

  if (!values.name) {
    errors.name = 'Name is a required field'
  } else {
    if (!stringOfLengthBetween(values.name, 4, 50)) {
      errors.name = 'Name must be between 4 and 50 characters'
    }
  }

  if (!values.description) {
    errors.description = 'Description is a required field'
  } else {
    if (!stringOfLengthBetween(values.description, 1, 200)) {
      errors.description = 'Description must has a maximum of 200 characters'
    }
  }

  return errors
}

class EditTemplateContainerPage extends React.Component {
  constructor(props) {
    super(props)
    const { match, templateContainers } = props

    let editTemplateContainerId = match.params.id

    let initialState = {
      alert: null,
      snackBarOpen: false,
      snackBarStatus: 'success',
      snackBarMessage: '',
      editTemplateContainerId
    }

    this.state = initialState

    this.submit = this.submit.bind(this)
    this.cancel = this.cancel.bind(this)
    this.finished = this.finished.bind(this)
    this.removeAlert = this.removeAlert.bind(this)
    this.showNotification = this.showNotification.bind(this)
    this.showSuccess = this.showSuccess.bind(this)
  }

  componentDidMount() {
    const { dispatch, templateContainers } = this.props

    let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'))

    if (Object.keys(templateContainers).length === 0) {
      dispatch(templateActions.getTemplateContainers(jurisdiction.id))
    }
  }

  componentWillUnmount() {
    if (this.notificationTimer) {
      clearTimeout(this.notificationTimer)
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { templateContainers, match } = nextProps

    let editTemplateContainerId = match.params.id

    if (templateContainers) {
      let templateContainer = templateContainers[editTemplateContainerId]
      if (!templateContainer) {
        return {}
      }

      return {
        editTemplateContainerId,
        formInitialValues: {
          name: templateContainer.name,
          description: templateContainer.description['en-US'],
          templateGroup: templateContainer.templategroup
        }
      }
    }

    return {}
  }

  showNotification(status, message) {
    if (!this.state.snackBarOpen) {
      this.setState({
        snackBarOpen: true,
        snackBarStatus: status,
        snackBarMessage: message
      })
      this.notificationTimer = setTimeout(
        function () {
          this.setState({
            snackBarOpen: false
          })
          this.notificationTimer = null
        }.bind(this),
        3000
      )
    }
  }

  finished() {
    const { history } = this.props

    this.setState({
      alert: null
    })
    history.goBack()
  }

  removeAlert() {
    this.setState({
      alert: null
    })
  }

  showSuccess() {
    const { classes } = this.props

    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: 'block' }}
          title="Success!"
          onConfirm={() => this.finished()}
          confirmBtnCssClass={classes.button + ' ' + classes.success}
          confirmBtnText="Ok"
        >
          The template has been updated successfully
        </SweetAlert>
      )
    })
  }

  submit(values) {
    const { dispatch, templateContainers } = this.props
    const { editTemplateContainerId } = this.state

    let user = JSON.parse(localStorage.getItem('user'))
    let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'))

    const previousContainer = templateContainers[editTemplateContainerId]

    let templateContainer = {
      id: editTemplateContainerId,
      name: values.name,
      description: { 'en-US': values.description },
      modifiedBy: user.id,
      jurisdiction: jurisdiction.id,
      templategroup: previousContainer.templategroup.id
    }

    return templateService
      .updateTemplateContainer(templateContainer)
      .then(updatedTemplateContainer => {
        dispatch(
          templateActions.registerTemplateContainer(updatedTemplateContainer)
        )
        this.showNotification(
          'success',
          'Template has been successfully updated!'
        )
        this.showSuccess()
        return updatedTemplateContainer
      })
      .catch(error => {
        console.error('ERROR updating template container ', error)
        this.showNotification(
          'danger',
          'There was a problem updating the template!'
        )
        return {
          [FORM_ERROR]: 'There was a problem updating the template.'
        }
      })
  }

  cancel(pristine) {
    const { classes, history } = this.props

    if (!pristine) {
      this.setState({
        alert: (
          <SweetAlert
            warning
            style={{ display: 'block' }}
            title="Warning!"
            onConfirm={() => this.finished()}
            onCancel={() => this.removeAlert()}
            confirmBtnCssClass={classes.button + ' ' + classes.success}
            cancelBtnCssClass={classes.button + ' ' + classes.danger}
            confirmBtnText="Yes"
            cancelBtnText="No"
            showCancel
          >
            Cancelling will discard any changes made. Are you sure?
          </SweetAlert>
        )
      })
    } else {
      history.goBack()
    }
  }

  render() {
    const {
      alert,
      snackBarOpen,
      snackBarMessage,
      snackBarStatus,
      editTemplateContainerId,
      formInitialValues
    } = this.state

    return (
      <div>
        {alert}
        <Snackbar
          place="tr"
          color={snackBarStatus}
          icon={Assignment}
          message={snackBarMessage}
          open={snackBarOpen}
          closeNotification={() => this.setState({ snackBarOpen: false })}
          close
        />
        <EditTemplateContainerForm
          editTemplateContainerId={editTemplateContainerId}
          formInitialValues={formInitialValues}
          onSubmit={this.submit}
          onCancel={this.cancel}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { templates } = state

  if (!templates) {
    return {}
  }

  return {
    templateContainers: templates.templateContainers || {},
    loading: templates.loading || false,
    saving: templates.saving || false
  }
}

export default connect(mapStateToProps)(
  withStyles(editTemplateContainerFormsStyle)(EditTemplateContainerPage)
)

class EditTemplateContainerForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      initialValuesLoaded: false
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { initialValuesLoaded } = this.state
    const { formInitialValues } = this.props
    if (!initialValuesLoaded && formInitialValues) {
      this.setState({ initialValuesLoaded: true })
    }
  }

  render() {
    const { classes, formInitialValues, onCancel, onSubmit } = this.props
    const { initialValuesLoaded } = this.state

    if (!formInitialValues) {
      return (
        <GridContainer>
          <GridItem xs={12}>
            <h4>The requested Template for editing could not be found.</h4>
          </GridItem>
        </GridContainer>
      )
    }

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={9}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Edit existing Template</h4>
            </CardHeader>
            <CardBody>
              <Form
                initialValues={formInitialValues}
                onSubmit={values => onSubmit(values)}
                validate={validate}
              >
                {({ form, handleSubmit, pristine, submitting }) => (
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <GridContainer>
                      <GridItem xs={12}>
                        <p>
                          Configured Template Group:{' '}
                          {formInitialValues.templateGroup.name}
                        </p>
                      </GridItem>
                      <GridItem xs={12}>
                        <Field name="name">
                          {props => (
                            <CustomTextField
                              fullWidth
                              label="NAME *"
                              onChange={props.input.onChange}
                              value={props.input.value}
                            />
                          )}
                        </Field>
                      </GridItem>
                      <GridItem xs={12}>
                        <Field name="description">
                          {props => (
                            <CustomTextField
                              fullWidth
                              label="DESCRIPTION *"
                              onChange={props.input.onChange}
                              value={props.input.value}
                            />
                          )}
                        </Field>
                      </GridItem>
                      <GridItem xs={12}>
                        <div className={classes.formCategory}>
                          <small>*</small> Required fields
                        </div>
                      </GridItem>
                    </GridContainer>
                    <Button
                      color="success"
                      type="submit"
                      className={classes.registerButton}
                      disabled={pristine || submitting}
                    >
                      <Create className={classes.icon} />
                      Update
                    </Button>
                    <Button
                      color="danger"
                      className={classes.registerButton}
                      style={{ marginRight: '10px' }}
                      disabled={submitting}
                      onClick={() => onCancel(pristine)}
                    >
                      <Cancel className={classes.icon} />
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      className={classes.registerButton}
                      style={{ marginRight: '25px' }}
                      disabled={pristine || submitting}
                      onClick={form.reset}
                    >
                      <Revert className={classes.icon} />
                      Revert
                    </Button>
                  </form>
                )}
              </Form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

EditTemplateContainerForm = withStyles(editTemplateContainerFormsStyle)(
  EditTemplateContainerForm
)
