/**
 * @module reducers/index - Exports all reducers for the app. Store gets configured in `helpers/store.js`
 */
import { combineReducers } from 'redux'
import { reducer as alert } from 'features/alert/alertSlice'
import { reducer as areas } from 'features/area/areaSlice'
import { reducer as authentication } from 'features/auth/authSlice'
import { reducer as config } from 'features/config/configSlice'
import { reducer as dashboardTs } from 'features/dashboard/dashboardSlice'
import { reducer as form } from 'redux-form'
import { reducer as heatMap } from 'features/heatMap/heatMapSlice'
import { reducer as jurisdiction } from 'features/jurisdiction/slice'
import { reducer as messages } from 'features/message/messageSlice'
import { reducer as reports } from 'features/report/reportSlice'
import { reducer as resourcesTs } from 'features/resource/resourceSlice'
import { reducer as users } from 'features/user/userSlice'
import { templates } from './template.reducer'

export const allReducers = {
  alert,
  areas,
  authentication,
  config,
  dashboardTs,
  form,
  heatMap,
  jurisdiction,
  messages,
  reports,
  resourcesTs,
  templates,
  users
}

const rootReducer = combineReducers(allReducers)

export default rootReducer
