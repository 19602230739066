import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'
import { alertActions } from 'features/alert/alertSlice'
import baseReducer from 'features/baseReducer'
import {
  CreateJurisdictionRequest,
  Jurisdiction,
  JurisdictionAttributes,
  JurisdictionDetail,
  JurisdictionSlice,
  JurisdictionStatus,
  JurisdictionView,
  LanguageCodeMap,
  LanguageDictionary,
  UpdateJurisdictionRequest
} from 'features/jurisdiction/types'
import { ErrorResponseCode } from 'types/request'
import jurisdictionService from 'services/jurisdiction.service'
import { getZoneName } from 'utils/useTimeZone'

export const initialState: JurisdictionSlice = {
  error: null,
  init: false,
  jurisdictionDetail: null,
  jurisdictionHistory: [],
  jurisdictionHistoryLoading: false,
  jurisdictionView: JurisdictionView.simple,
  jurisdictions: {},
  loading: false,
  saving: false,
  selectedJurisdictionId: null
}

const jurisdictionSlice = createSlice({
  initialState,
  name: 'jurisdiction',
  reducers: {
    ...baseReducer,
    reset: state => {
      const payload = { ...initialState }
      for (let key in payload) {
        state[key] = payload[key]
      }
    },
    setError: (state, { payload }: PayloadAction<string | null>) => {
      state.error = payload
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },
    setInit: (state, { payload }: PayloadAction<boolean>) => {
      state.init = payload
    },
    setSaving: (state, { payload }: PayloadAction<boolean>) => {
      state.saving = payload
    },
    // Jurisdictions
    setJurisdictions: (
      state,
      { payload }: PayloadAction<Record<string, Jurisdiction>>
    ) => {
      state.jurisdictions = payload
    },
    updateJurisdiction: (state, { payload }: PayloadAction<Jurisdiction>) => {
      state.jurisdictions[payload.id] = payload
    },
    removeJurisdiction: (state, { payload }: PayloadAction<string>) => {
      delete state.jurisdictions[payload]
    },

    // Detail View
    setSelectedJurisdictionId: (
      state,
      { payload }: PayloadAction<string | null>
    ) => {
      state.selectedJurisdictionId = payload
    },
    setJurisdictionView: (
      state,
      { payload }: PayloadAction<JurisdictionView>
    ) => {
      state.jurisdictionView = payload
    },
    setJurisdictionDetail: (
      state,
      { payload }: PayloadAction<JurisdictionDetail | null>
    ) => {
      state.jurisdictionDetail = payload
    },
    mergeJurisdictionDetail: (
      state,
      { payload }: PayloadAction<Partial<JurisdictionDetail>>
    ) => {
      if (state.jurisdictionDetail) {
        for (let key in payload) {
          state.jurisdictionDetail[key] = payload[key]
        }
      }
    },
    setJurisdictionHistory: (
      state,
      { payload }: PayloadAction<JurisdictionDetail[]>
    ) => {
      state.jurisdictionHistory = payload
    },
    setJurisdictionHistoryLoading: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.jurisdictionHistoryLoading = payload
    },
    // Jurisdiction Detail Attributes
    mergeJurisdictionDetailAttributes: (
      state,
      { payload }: PayloadAction<Partial<JurisdictionAttributes>>
    ) => {
      if (state.jurisdictionDetail) {
        for (let key in payload) {
          state.jurisdictionDetail.jurisdictionAttributes[key] = payload[key]
        }
      }
    },
    setLanguageDictionary: (
      state,
      { payload }: PayloadAction<{ value: LanguageDictionary; merge: boolean }>
    ) => {
      if (state.jurisdictionDetail) {
        const { merge, value } = payload
        if (merge) {
          for (let key in payload) {
            state.jurisdictionDetail.jurisdictionAttributes.LANGUAGE_DICTIONARY[
              key
            ] = payload[key]
          }
        } else {
          state.jurisdictionDetail.jurisdictionAttributes.LANGUAGE_DICTIONARY =
            value
        }
      }
    },
    updateLanguageDictionaryAttribute: (
      state,
      {
        payload
      }: PayloadAction<{
        attribute: string
        value: LanguageCodeMap
        merge: boolean
      }>
    ) => {
      if (state.jurisdictionDetail) {
        const { attribute, merge, value } = payload
        if (!merge) {
          state.jurisdictionDetail.jurisdictionAttributes.LANGUAGE_DICTIONARY[
            attribute
          ] = value
        } else {
          const currentValue =
            state.jurisdictionDetail.jurisdictionAttributes.LANGUAGE_DICTIONARY[
              attribute
            ]
          state.jurisdictionDetail.jurisdictionAttributes.LANGUAGE_DICTIONARY[
            attribute
          ] = {
            ...currentValue,
            ...value
          }
        }
      }
    }
  }
})

export const { actions, name, reducer } = jurisdictionSlice

//-- Extra Actions

const init =
  (isNew: boolean, signal?: AbortSignal) => async (dispatch, getState) => {
    dispatch(actions.setLoading(true))
    // Reset detail fields
    dispatch(actions.setSelectedJurisdictionId(null))
    dispatch(actions.setJurisdictionDetail(null))

    const jurisdictionList = await jurisdictionService.getAll(signal)
    const jurisdictionsMap = jurisdictionList.jurisdictions.reduce(
      (accum, jurisdiction) => {
        accum[jurisdiction.id] = jurisdiction
        return accum
      },
      {}
    )

    dispatch(actions.setJurisdictions(jurisdictionsMap))
    if (isNew) {
      await dispatch(initNewJurisdiction())
    }
    dispatch(actions.setLoading(false))
    dispatch(actions.setInit(true))
    return jurisdictionsMap
  }

const initNewJurisdiction = () => async dispatch => {
  dispatch(actions.setLoading(true))
  const defaultLocale = navigator.language
  const jurisdiction: JurisdictionDetail = {
    id: uuidv4(),
    name: '',
    abbreviation: '',
    locality: 'city',
    parent: null,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    jurisdictionAttributes: {
      DEFAULT_LOCALE: defaultLocale,
      LANGUAGE_DICTIONARY: {},
      PUBLIC_HOLIDAYS: [],
      SUPPORTED_LOCALES: [defaultLocale],
      TIMEZONE: getZoneName()
    }
  }
  dispatch(actions.setJurisdictionDetail(jurisdiction))
  dispatch(actions.setLoading(false))
  return jurisdiction
}

/**
 * @func selectJurisdiction - Set up the data for a jurisdiction detail view (ie. to edit a Jurisdiction)
 * @param {string} id
 * @param {AbortSignal} signal
 * @returns {(dispatch, getState) => Promise<any>}
 */
const selectJurisdiction =
  (id: string, signal?: AbortSignal) => async (dispatch, getState) => {
    dispatch(actions.setLoading(true))
    dispatch(actions.setSelectedJurisdictionId(id))
    try {
      const jurisdictionList = await jurisdictionService.getAll(signal)
      const jurisdictionsMap = jurisdictionList.jurisdictions.reduce(
        (accum, jurisdiction) => {
          accum[jurisdiction.id] = jurisdiction
          return accum
        },
        {}
      )

      dispatch(actions.setJurisdictions(jurisdictionsMap))
      const { jurisdiction } = await jurisdictionService.getJurisdictionDetail(
        id,
        signal
      )
      const { selectedJurisdictionId } = getState()[name]
      if (selectedJurisdictionId !== id) {
        dispatch(actions.setLoading(false))
        // Make sure the selected ID hasn't changed
        return
      }
      dispatch(actions.setJurisdictionDetail(jurisdiction))
      dispatch(actions.setLoading(false))
      dispatch(actions.setInit(true))
      return jurisdiction
    } catch (error) {
      dispatch(actions.setSelectedJurisdictionId(null))
      dispatch(actions.setJurisdictionDetail(null))
      return dispatch(handleError(error))
    }
  }

const loadJurisdictionHistory =
  (id: string, signal?: AbortSignal) => async dispatch => {
    dispatch(actions.setJurisdictionHistoryLoading(true))
    try {
      const history: JurisdictionDetail[] =
        await jurisdictionService.getHistory({ id }, signal)
      dispatch(actions.setJurisdictionHistory(history))
      dispatch(actions.setJurisdictionHistoryLoading(false))
      return history
    } catch (error) {
      return dispatch(handleError(error))
    } finally {
      dispatch(actions.setJurisdictionHistoryLoading(false))
    }
  }

//-- API CRUD
const createJurisdiction =
  (body: CreateJurisdictionRequest, signal?: AbortSignal) =>
  async (dispatch, getState) => {
    dispatch(actions.setSaving(true))
    try {
      const { jurisdiction } = await jurisdictionService.createJurisdiction(
        body,
        signal
      )
      dispatch(actions.updateJurisdiction(jurisdiction))
      dispatch(actions.setSaving(false))
      return jurisdiction
    } catch (error) {
      return dispatch(handleError(error))
    }
  }

const patchJurisdiction =
  (body: UpdateJurisdictionRequest, signal?: AbortSignal) =>
  async (dispatch, getState) => {
    dispatch(actions.setSaving(true))
    try {
      const { jurisdiction } = await jurisdictionService.updateJurisdiction(
        body,
        signal
      )
      dispatch(actions.updateJurisdiction(jurisdiction))
      const { selectedJurisdictionId } = getState()[name]
      if (selectedJurisdictionId === body.jurisdiction.id) {
        dispatch(actions.setJurisdictionDetail(jurisdiction))
      }
      dispatch(actions.setSaving(false))
      return jurisdiction
    } catch (error) {
      return dispatch(handleError(error))
    }
  }

const deleteJurisdiction =
  (id: string, signal?: AbortSignal) => async (dispatch, getState) => {
    dispatch(actions.setSaving(true))
    try {
      await jurisdictionService.deleteJurisdiction(id, signal)
      dispatch(actions.removeJurisdiction(id))
      // Deselect if we just deleted the selected jurisdiction
      const { selectedJurisdictionId } = getState()[name]
      if (selectedJurisdictionId === id) {
        dispatch(actions.setJurisdictionDetail(null))
        dispatch(actions.setSelectedJurisdictionId(null))
      }
      dispatch(actions.setSaving(false))
      return id
    } catch (error) {
      return dispatch(handleError(error))
    }
  }

const publishJurisdiction =
  (signal?: AbortSignal) =>
  async (dispatch, getState): Promise<void> => {
    dispatch(actions.setSaving(true))
    try {
      const id = getState()[name].selectedJurisdictionId
      const { jurisdiction } = await jurisdictionService.publishJurisdiction(
        id,
        signal
      )
      const { selectedJurisdictionId } = getState()[name]
      if (selectedJurisdictionId !== id) {
        dispatch(actions.setLoading(false))
        // Make sure the selected ID hasn't changed
        return
      }
      dispatch(
        actions.setJurisdictionDetail({
          ...jurisdiction,
          status: JurisdictionStatus.published
        })
      )
      dispatch(actions.setSaving(false))
      return
    } catch (error) {
      dispatch(handleError(error))
      return
    }
  }

//-- Common
const handleError = (error: Error | string | any) => dispatch => {
  dispatch(actions.setError(String(error)))
  dispatch(actions.setLoading(false))
  dispatch(actions.setInit(true))
  dispatch(actions.setSaving(false))
  dispatch(alertActions.error(String(error)))
  if ('status' in error) {
    // Rethrow request/response errors
    const throwableStatusCodes = [
      ErrorResponseCode.INVALID,
      ErrorResponseCode.UNAUTHORIZED,
      ErrorResponseCode.EXCEPTION
    ]
    if (throwableStatusCodes.includes(error.status)) {
      throw error
    }
  }
  return error
}

export const jurisdictionActions = {
  ...actions,
  init,
  initNewJurisdiction,
  selectJurisdiction,
  createJurisdiction,
  patchJurisdiction,
  deleteJurisdiction,
  publishJurisdiction,
  // Jurisdiction History
  loadJurisdictionHistory
}
