import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles({
  root: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
})

export const ErrorComponent = () => {
  const classes = useStyles()

  return (
    <Grid container>
      <Grid item xs={10}>
        <Card variant={'outlined'}>
          <CardContent>
            <div>
              <SentimentVeryDissatisfiedIcon
                color={'error'}
                fontSize={'large'}
              />
            </div>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              Uh oh
            </Typography>
            <Typography variant="body2" component="p">
              We encountered an error that we couldn't recover from. It's been
              reported to the development team.
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default ErrorComponent
